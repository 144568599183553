<template>
  <div
    v-if="!keyboardOpen"
    id="bottom"
    class="px-2 bottom-bar align-items-center justify-content-between no-select"
    :class="{ hide: $route.name === 'social-space-chatroom', 'is-disabled': ongoingOnboarding && !isStepActive(18) }"
    @click="onBoardingClickedAction"
  >
    <div
      v-for="(item, index) of tabs"
      :key="index"
      class="divider d-flex align-items-center h-100 position-relative"
      :class="{ disabled: disabledForNewUser && item.pageLabel === 'Events' }"
    >
      <i
        v-if="(disabledForNewUser && item.pageLabel === 'Events') || isSocialSpaceDisabled(item)"
        class="ti-lock position-absolute"
        :class="{
          'disabled-icon ': disabledForNewUser && item.pageLabel === 'Events',
          'disabled-space-icon': isSocialSpaceDisabled(item),
        }"
      />
      <div
        class="freebie"
        style="background: linear-gradient(90deg, #6adf32 0%, rgba(56, 226, 83, 0) 100%), #1b856b !important"
        v-if="get(usersFreebie, 'status') === 'closed' && item.free"
      >
        FREE
      </div>
      <ion-button
        mode="ios"
        fill="clear"
        color="light"
        :id="item.pageLabel === 'Home' ? 'o-home' : ''"
        :class="{
          active: isActive(item.pageName || ''),
          create: item.pageLabel === 'Create',
        }"
        :style="{
          background: isActive(item.pageName || '') ? 'rgba(255, 255, 255, 0.2) !important' : '',
          padding: isActive(item.pageName || '') ? `0 10px !important` : '',
          height: isActive(item.pageName || '') ? `45px !important` : '',
          borderRadius: isActive(item.pageName || '') ? `10px !important` : '',
        }"
        class="no-select"
        @click="goTo(item, $event)"
      >
        <div>
          <div
            class="fixed-h d-flex align-items-center justify-content-center"
            :class="{ 'disabled-space': isSocialSpaceDisabled(item) }"
          >
            <inline-svg class="icon no-select" :class="{ stroke: item.stroke, fill: item.fill }" :src="item.icon" />
          </div>

          <div class="name no-select">
            {{ item.pageLabel }}
          </div>
        </div>

        <div v-if="hasUnreadWorldMsgs && item.pageName === 'worlds'" class="ping no-select" />
      </ion-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import store from '@/shared/store';
import { mainStore } from '@/shared/pinia-store/main';
import { onboardingStore } from '@/shared/pinia-store/onboarding';
import { uiStore } from '@/shared/pinia-store/ui';
import { authStore } from '@/shared/pinia-store/auth';
import { useonGoingOnBoarding } from '~/composables/useonGoingOnBoarding';
import { popovers } from '@/shared/native';
import CharacterCreationPopover from '@/shared/components/popovers/character-creation-popover.vue';
const { toggleCreate, toggleMyCreations } = uiStore();
const { usersFreebie, userHasCharacters } = authStore();
const { keyboardOpen } = mainStore();
const { onBoardingClickedAction } = useonGoingOnBoarding();
const { ongoingOnboarding, isStepActive } = onboardingStore();
const tabs = [
  {
    icon: '/icons/home.svg',
    pageName: 'home',
    pageLabel: 'Home',
    stroke: true,
  },
  {
    icon: '/icons/creations.svg',
    action: 'toggleMyCreationsDropdown',
    pageLabel: 'My Creations',
    stroke: true,
  },
  {
    icon: '/icons/create.svg',
    action: 'toggleCreateDropdown',
    pageLabel: 'Create',
  },
  {
    icon: '/icons/social-spaces.svg',
    pageName: 'social-space',
    pageLabel: 'Social Space',
    width: true,
    stroke: true,
    fill: true,
  },
  {
    icon: '/icons/marketplace.svg',
    pageName: 'shop',
    pageLabel: 'Shop',
    stroke: true,
    free: true,
  },
];

async function goTo(item: any, ev: any) {
  if (['social-space'].includes(item.pageName) && !userHasCharacters.value) {
    await popovers.open(ev, CharacterCreationPopover);
    return;
  }
  const router = useRouter();
  if (item.pageName) {
    const name = item.pageName;
    router.replace({ name });
    return;
  }

  if (item.action && item.action === 'toggleMyCreationsDropdown') {
    toggleMyCreations(ev);
  }

  if (item.action && item.action === 'toggleCreateDropdown') {
    toggleCreate();
  }
}

const { disabledForNewUser } = onboardingStore();

const pageName = computed(() => {
  const { pageName } = mainStore();
  return pageName.value;
});
const isSocialSpaceDisabled = (item: any) => {
  return item.pageName === 'social-space' && !userHasCharacters.value;
};
function isActive(page: any) {
  return page === pageName.value;
}

const hasUnreadWorldMsgs = store.getters['WorldsModule/hasUnreadWorldMsgs'];
</script>

<style scoped lang="sass">
.disabled-space-icon
  top: 6px
  right: 13px
  color: white
.disabled-space
  opacity: 0.5
.dark .bottom-bar
  background: linear-gradient(90deg, rgb(17, 2, 42) 0%, rgb(17, 2, 42) 50%, rgb(120, 0, 76) 100%) !important
.freebie
  font-size: 12px
  display: flex
  z-index: 2
  top: 2px
  color: white
  background: linear-gradient(90deg, #6ADF32 0%, rgba(56, 226, 83, 0) 100%), #1B856B
  border: 2px solid #214163
  border-radius: 20px
  position: absolute
  height: 19px
  width: 46px
  justify-content: center
  text-align: center
.dark .bottom-bar .freebie
  background: linear-gradient(90deg, #6ADF32 0%, rgba(56, 226, 83, 0) 100%), #1B856B !important
.disabled-icon
  z-index: 1
  color: #FFF
  right: calc(30% - 8px)
  top: 3px
  background: rgba(0,0,0,0.5)
  padding: 5px
  border-radius: 20px
.fixed-h
  height: 25px
  background: transparent !important
  --background: transparent !important
.create
  background: #fff
  border-radius: 6px
  color: var(--ion-color-primary)
  width: 48px
  height: 48px !important
  margin: 0
  .name
    color: var(--ion-color-primary)
  ::v-deep
    path
      stroke: var(--ion-color-primary)
.name
  font-size: 10px
  font-weight: bold
.icon
  min-width: 26px
.hide
  display: none !important
  visibility: hidden !important
.width
  width: 26px
.fill
  ::v-deep
    path
      fill: white
.stroke
  ::v-deep
    *
      stroke: white
    path
      stroke: white
.white-fill path
  fill: white !important
.bottom-bar
  height: calc(var(--default-bottom-bar-height) + var(--safe-ios-margin)/3)
  min-height: var(--default-bottom-bar-height)
  min-height: calc(var(--default-bottom-bar-height) + calc(constant(safe-area-inset-top)/3))
  min-height: calc(var(--default-bottom-bar-height) + calc(env(safe-area-inset-top)/3))
  padding-bottom: calc(var(--safe-ios-margin)/3)
  background: linear-gradient(90deg, rgba(126, 224, 255, 0.4) 0%, rgba(95, 169, 238, 0) 100%), #A83CFF !important
  position: sticky
  bottom: -1px
  width: 100%
  z-index: 112
  display: flex
  ion-button
    --padding-start: 5px
    --padding-end: 5px
    --height: 45px
    &:not(.create)
      --background: transparent
  .divider
    display: flex
    align-items: center
    justify-content: center
    width: 20%
    position: relative
    &.disabled
      &:after
        content: ""
        display: block
        width: 100%
        height: 100%
        position: absolute
        background: rgba(0,0,0,0.2)
.ping
  position: absolute
  left: -3px
  top: 7px
  padding-left: 1em
.ping::before,
.ping::after
  background-color: #eb445a
  content: ''
  position: absolute
  top: 0.25em
  left: 0
  width: 0.75em
  height: 0.75em
  border-radius: 50%
.ping::before
  animation: ping 1.7s ease infinite
  opacity: 0.25
@keyframes ping
  0%
    transform: scale(1)
    opacity: 0.5

  100%
    transform: scale(2)
    opacity: 0
</style>
