<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="isComplimented ? false : true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
    :style="isComplimented ? '--width: 480px' : ''"
  >
    <ion-header translucent v-if="!isComplimented">
      <ion-toolbar class="toolbar">
        <div class="px-3 py-3">
          <div class="d-flex justify-content-between align-items-center">
            <div></div>
            <div>
              <div class="title">{{ isFanartFlare ? 'Submit FanArt of' : 'Compliment' }} this Character!</div>
            </div>
            <div>
              <ion-buttons slot="end">
                <ion-button @click="closeModal">
                  <i class="ti-close" />
                </ion-button>
              </ion-buttons>
            </div>
          </div>
          <ion-skeleton-text v-if="isCbLoader" :animated="true" class="bar-loader" />
          <div class="d-flex total-char-section mt-1" v-else>
            <div class="d-flex align-items-center character-text-section">
              Characters
              {{ socialItemCount }}/{{ totalInSet }}
            </div>
            <div class="px-1 py-1 mx-1 character-bar">
              <div class="d-flex" style="gap: 2px">
                <div
                  v-for="(character, index) in totalInSet"
                  :key="index"
                  class="selected-char-bar"
                  :class="{
                    'bar-color-changed': index < socialItemCount,
                  }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>

    <div class="modal-inner-content" :style="isComplimented ? 'background: #fff' : `background: ${bg}`">
      <ChLoading size="lg" v-if="loading" class="spinner mx-auto" />
      <div v-if="isComplimented" class="p-3">
        <ComplimentBombAndFlareSubmission
          :submission-title="submissionTitle"
          :count="submissionCount"
          :total-in-set="totalInSet"
          :currency="currency"
          :reward="reward"
        />
      </div>
      <div class="px-3" v-else>
        <character-profile :char-slug="characterSlug" :is-compliment-bomb="true" />
      </div>
    </div>
    <ion-footer translucent>
      <ion-toolbar class="toolbar" style="--min-height: 76px">
        <div v-if="isComplimented" class="d-flex justify-content-between px-2">
          <ion-button color="transparent" @click="closeModal" class="done-btn"> I'm done </ion-button>
          <ion-button color="primary" @click="onClickNextCharacter" class="done-btn"> Next Character </ion-button>
        </div>
        <div v-else class="d-flex p-3 align-items-center">
          <div class="w-100 mr-3">
            <div v-if="isComplimentBomb" class="position-relative">
              <ion-textarea
                v-model="text"
                rows="3"
                class="c-textarea z-0 mb-3"
                placeholder="Share something fantastic that will make somebody's day."
                required
              />
              <div class="character-counter text-[0.125rem] z-10" :class="{ 'over-limit': trimmedTextLength >= 30 }">
                {{ trimmedTextLength }}
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between" v-else>
              <UploadForm
                v-if="get(user, 'is_email_verified')"
                :fanwork="true"
                :class="{ 'is-disabled': isLoading || loader }"
                @loading="setLoading"
                @uploaded="uploadFanArt"
                class="w-100"
              >
                <ion-button class="w-100 submit-art" :disabled="isLoading || loader">
                  <ChLoading size="sm" v-if="isLoading || loader" class="spinner" />
                  <div v-else>
                    <i class="ti-download mx-1" />
                    Submit Art
                  </div></ion-button
                >
              </UploadForm>

              <VerificationButton actionName="Submit Art" :displayFullSizeBtn="true" v-else />

              <div v-if="skipLoading" class="skip-loader ml-3">
                <ion-skeleton-text animated />
              </div>
              <div v-else class="skip ml-3 clickable-item-hov" @click="skipChars">skip</div>
            </div>
            <div class="reward d-flex align-items-center text-black justify-content-center text-center mt-1">
              Reward: <inline-svg class="coins mx-1" src="/hubuck.svg" /> <strong>{{ reward }}</strong>
            </div>
          </div>

          <div class="d-flex flex-column w-50 align-items-center mx-auto" v-if="isComplimentBomb">
            <ion-button class="compliment-button" :disabled="!isFormComplete || loader" @click="action">
              <ChLoading size="sm" v-if="loader" />
              <div v-else>Compliment</div>
            </ion-button>
            <div v-if="skipLoading" class="skip-loader">
              <ion-skeleton-text animated />
            </div>
            <div v-else class="skip clickable-item-hov" @click="skipChars">skip</div>
          </div>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-modal>
</template>

<script lang="ts" setup>
import characterProfile from '@/shared/pages/character-profile-new/index.vue';
import { authStore } from '@/shared/pinia-store/auth';
import UploadForm from '@/shared/components/upload-form.vue';
import { createComment } from '@/shared/actions/comments';
import { characterStylizeStore } from '@/shared/pinia-store/character-stylize';
import ComplimentBombAndFlareSubmission from '~/shared/components/ComplimentBombAndFlareSubmission.vue';
import { COMPLIMENT_BOMB, FANART_FLARE } from '../statics/constants';
import { createFanwork } from '../actions/fanwork';
import VerificationButton from '@/shared/components/VerificationButton.vue';
const { currentTemplate } = characterStylizeStore();
const document = useDocument();
const { user } = authStore();
const bg = computed(() => `${get(currentTemplate.value, 'data.theme.backgrounds.page')} !important`);

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
  character: {
    type: Object,
    default: {},
  },
  dailyLimit: {
    type: Object,
    default: {},
  },
  complimentCharacters: {
    type: Array,
    default: [],
  },
  isCbLoader: {
    type: Boolean,
    default: false,
  },
  cbRewards: {
    type: Array,
    default: [],
  },
  currency: {
    type: String,
    default: '',
  },
  reward: {
    type: Number,
    default: 0,
  },
});

const dailyLimit: any = toRef(props, 'dailyLimit');
const complimentCharacters: any = toRef(props, 'complimentCharacters');
const emits = defineEmits(['close', 'fetch', 'open', 'mission']);
const text = ref('');
const isOpen = toRef(props, 'isOpen');
const character: any = toRef(props, 'character');
const currentIndex: any = ref(null);
const loading = ref(false);
const skipLoading = ref(false);
const apiCallsCompleted = ref(false);
const prevIndex = ref(0);
const loader = ref(false);
const isLoading = ref(false);
const isBonusRewarded = ref(false);
const bonusRewarded: any = ref([]);
const submissionCount = ref(0);
const isComplimented = ref(false);
const fanart: any = ref({});
const { user: loggedInUser, updateTempSocial, previousSocial } = authStore();
const isFanartFlare = computed(() => {
  return get(character.value, 'compliment_bombed_type') === FANART_FLARE;
});

const isComplimentBomb = computed(() => {
  return get(character.value, 'compliment_bombed_type') === COMPLIMENT_BOMB;
});

const socialItemCount = computed(() => {
  return isComplimentBomb.value ? previousSocial.value.complimentCount : previousSocial.value.fanArtCount;
});
const dismissModal = (val: any) => {
  showCrisp();
  isComplimented.value = false;
  submissionCount.value = 0;
  emits('close');
  prevIndex.value = 0;
  isBonusRewarded.value = false;
  bonusRewarded.value = [];
  text.value = '';
  if (get(val, 'detail.role') === 'backdrop') {
    handleFetch();
  }
};

const uploadFanArt = (val: any) => {
  fanart.image = val;
  action();
};

const submissionTitle = computed(() => {
  return isFanartFlare.value ? 'FanArt Successfully Submitted!' : 'Character Successfully Complimented!';
});
const setLoading = (val: boolean) => {
  isLoading.value = val;
};

const closeModal = () => {
  isComplimented.value = false;
  submissionCount.value = 0;
  dismissModal('');
  setTimeout(() => {
    handleFetch();
  }, 50);
};

const getChDailyLimit = computed(() => {
  return dailyLimit.value;
});
const setData = () => {
  const type = character.value.compliment_bombed_type;

  const prevAvailableCount =
    type === COMPLIMENT_BOMB ? previousSocial.value.tempAvailableCompliment : previousSocial.value.tempAvailableFanArt;
  const tempTotalCC =
    type === COMPLIMENT_BOMB ? previousSocial.value.totalComplimentedCount : previousSocial.value.totalFanArtCount;

  if (isNull(prevAvailableCount) && isNull(tempTotalCC)) {
    updateTempSocial({
      [type === COMPLIMENT_BOMB ? 'tempAvailableCompliment' : 'tempAvailableFanArt']:
        getChDailyLimit.value.available_compliments,
      [type === COMPLIMENT_BOMB ? 'totalComplimentedCount' : 'totalFanArtCount']: getChDailyLimit.value.done_in_time,
    });
  }
};
watch(
  getChDailyLimit,
  (value: any) => {
    if (isOpen.value && value) {
      setData();
    }
  },
  { immediate: true }
);
watch(isOpen, async () => {
  if (isOpen.value && !isEmpty(complimentCharacters.value)) {
    document.value.getElementsByTagName('html')[0].style.overscrollBehavior = 'none';
    document.value.getElementsByTagName('html')[0].style.touchAction = 'none';

    (window as any).$crisp?.push(['do', 'chat:hide']);
    const el = document.value?.querySelector('.crisp-client') as any;
    if (el) el.style.display = 'none';

    prevIndex.value = findIndex(complimentCharacters.value, (char: any) => char.id === character.value.id);
    currentIndex.value = prevIndex.value;
    setData();
  }
});

const totalInSet = computed(() => {
  const type = character.value.compliment_bombed_type;
  const { tempAvailableCompliment, tempAvailableFanArt, totalComplimentedCount, totalFanArtCount } =
    previousSocial.value;

  let prevAvailableCount;
  if (type === COMPLIMENT_BOMB) {
    prevAvailableCount = isNull(tempAvailableCompliment)
      ? getChDailyLimit.value.available_compliments
      : tempAvailableCompliment;
  } else {
    prevAvailableCount = isNull(tempAvailableFanArt)
      ? getChDailyLimit.value.available_compliments
      : tempAvailableFanArt;
  }

  const socialTotalCount = type === COMPLIMENT_BOMB ? totalComplimentedCount : totalFanArtCount;
  return Math.min(prevAvailableCount, getChDailyLimit.value.default_limit - socialTotalCount);
});

const showCrisp = () => {
  document.value.getElementsByTagName('html')[0].style.overscrollBehavior = 'auto';
  document.value.getElementsByTagName('html')[0].style.touchAction = 'auto';

  (window as any).$crisp?.push(['do', 'chat:show']);
  const el = document.value?.querySelector('.crisp-client') as any;
  if (el) el.style.display = 'block';
};

const currentCharacter = computed(() => {
  const lastIndex = complimentCharacters.value.length - 1;
  return currentIndex.value === null
    ? character.value
    : currentIndex.value === lastIndex
    ? complimentCharacters.value[lastIndex]
    : complimentCharacters.value[currentIndex.value];
});

const isFormComplete = computed(() => {
  return !isEmpty(text.value);
});

const trimmedTextLength = computed(() => text.value.trim().length);

const characterSlug = computed(() => {
  if (isEmpty(currentCharacter.value)) return;
  return get(currentCharacter.value, 'slug');
});

const resetIndex = async () => {
  currentIndex.value = 0;
  isCurrEqualToPrev();
};

const onClickNextCharacter = async () => {
  isComplimented.value = false;
  updateIndex();
};
const isCurrEqualToPrev = () => {
  const prevIn = prevIndex.value;

  if (currentIndex.value === prevIn) {
    emits('open', submissionCount.value, true);
  }
};

const skipChars = async () => {
  skipLoading.value = true;
  if (currentIndex.value >= complimentCharacters.value.length - 1) {
    await resetIndex();
  } else {
    updateIndex();
  }
  skipLoading.value = false;
};
const isLimitReached = computed(() => {
  return socialItemCount.value >= totalInSet.value;
});
watch(apiCallsCompleted, () => {
  if (isOpen.value && apiCallsCompleted.value) {
    if (isLimitReached.value) {
      if (bonusRewarded.value.includes(true)) {
        isBonusRewarded.value = true;
      }
      emits('mission', socialItemCount.value, isBonusRewarded.value);
      apiCallsCompleted.value = false;
    } else {
      updateCount();
      apiCallsCompleted.value = false;
    }
  }
});

const updateIndex = async () => {
  currentIndex.value = currentIndex.value + 1;
  isCurrEqualToPrev();
};

const resetText = () => {
  text.value = '';
};

const handleFetch = async () => {
  emits('fetch', true);
};

const updateCount = async () => {
  let tempCount = socialItemCount.value + 1;

  if (isLimitReached.value) return;

  isComplimentBomb.value
    ? updateTempSocial({ complimentCount: tempCount })
    : updateTempSocial({ fanArtCount: tempCount });
};

const handleSubmissionResponse = (res: any) => {
  submissionCount.value = socialItemCount.value;
  resetText();
  bonusRewarded.value = [...bonusRewarded.value, get(res, 'set_rewarded')];
  isBonusRewarded.value = get(res, 'set_rewarded');
  apiCallsCompleted.value = true;
};

const post = async (data: any) => {
  try {
    let res;
    if (data === 'fanart_flare') {
      res = await createFanwork({
        submitted_for_id: currentCharacter.value.id,
        submitted_for_type: 'character',
        type: 'art',
        note: '',
        image_url: fanart.image,
        fanart_flare: currentCharacter.value.compliment_bombed_id,
      });
    } else {
      res = await createComment({
        text: text.value,
        commenter_id: loggedInUser.value.id,
        commenter_type: 'user',
        commented_on_type: 'character',
        commented_on_object_id: currentCharacter.value.id,
        compliment_bomb: currentCharacter.value.compliment_bombed_id,
      });
    }
    handleSubmissionResponse(res);
  } catch (error) {
    console.error('Error Posting:', error);
  }
};
const action = async () => {
  const trimmedText = text.value.trim();
  if (trimmedText.length < 30 && isComplimentBomb.value) {
    alert('This compliment is too short. Try writing a little more!');
    return;
  }
  loader.value = true;
  if (socialItemCount.value <= getChDailyLimit.value.default_limit) {
    isFanartFlare.value ? await post('fanart_flare') : await post('compliment_bomb');
    if (currentIndex.value >= complimentCharacters.value.length - 1) {
      resetIndex();
    } else {
      isComplimented.value = true;
    }
  }
  loader.value = false;
};
</script>
<style lang="sass" scoped>
.submit-art::part(native)
  color: #fff

.done-btn
  color: var(--ion-color-primary)
  font-weight: bolder
  font-size: 18px

.dark .done-btn
  color: white
.coins
  width: 18px
  height: 18px
.reward
  color: #431C89
.bar-loader
  width: 100%
  height: 17px

.skip-loader
  width: 60px
  max-width: 100%
  height: 15px
.skip
  font-weight: bold
  text-decoration: underline
  text-align: center
.compliment-button
  width: 100%
  height: 40px
.compliment-button::part(native)
  color: #fff !important
.selected-char-bar
  background: #e6e6e6
  width: 100%
  border-radius: 20px
  padding-bottom: 0.4rem
  padding-top: 0.4rem
.bar-color-changed
  background: #00b4c5 !important
.character-bar
  border: 2px solid
  border-radius: 20px
  height: 24px
  width: 100%
.total-char-section
  white-space: nowrap
  color: #00b4c5
.character-text-section
  font-weight: bold
  @media(max-width:360px)
    font-size: 14px
.dark .toolbar
  --background: #17074c !important

.modal-inner-content
  background: var(--ion-background-color, #fff)
  max-height: 55vh
  overflow: auto

.modal-big
  --width: 600px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
.close-button
  width: 50px
.title
  color: #214163
  font-size: 20px
  font-weight: bold
  text-align: center
  align-items: center
.char-compliment-bombed
  border-radius: 10px
  border: 2px solid #cfcaca
.character-counter
  position: absolute
  bottom: 4px
  right: 12px
  font-size: 12px
  color: #FF6961
  transition: color 0.3s ease
.character-counter.over-limit
  color: #28a745
</style>
