<template>
  <div>
    <h4>User Lookup</h4>
    <multiselect
      v-model="userSearchField"
      tag-placeholder="Look up a username"
      select-label=""
      deselect-label=""
      :custom-label="formatSearchLabel"
      :options="searchUserList"
      :multiple="false"
      :taggable="false"
      :searchable="true"
      :loading="isSearchLoading"
      open-direction="bottom"
      :close-on-select="true"
      option-height="92"
      class="c-multiselect"
      @select="selectSearchUser"
      @search-change="debouncedSearchUser"
    />
    <h4>Report Categories</h4>
    <ion-button
      v-for="(c, index) in allCategories"
      :key="index"
      :color="isCurrentCategory(c) ? 'secondary' : 'primary'"
      @click="selectCategoryIndex(index)"
    >
      <span class="no-select" :class="{ underline: isCurrentCategory(c) }">{{ c.title }}</span>
    </ion-button>
    <ion-button 
      :color="currentCategory === 'General' ? 'secondary' : 'primary'"
      @click="selectCategoryIndex(-9)"
    >
    General
    </ion-button>
    <h4>Filter Status</h4>
    <ion-button
      :color="currentReviewFilter === 'unreviewed' ? 'secondary' : 'primary'"
      @click="selectFilter('unreviewed')"
    >
      <span class="no-select">Unreviewed</span>
    </ion-button>
    <ion-button
      :color="currentReviewFilter === 'reviewed' ? 'secondary' : 'primary'"
      @click="selectFilter('reviewed')"
    >
      <span class="no-select">Reviewed</span>
    </ion-button>
    <ion-button
      :color="currentReviewFilter === 'all' ? 'secondary' : 'primary'"
      @click="selectFilter('all')"
    >
      <span class="no-select">All</span>
    </ion-button>
    <h1 class="title">
      <span v-if="currentCategory">Reports</span><span v-else>Reported Users</span> ({{ currentCount }})
    </h1>
    <section v-if="currentCategory">
      <ReportCard
        v-for="report in reports"
        :key="report.id"
        :report="report"
        @click="emit('reviewReport', report?.id)"
      />
    </section>
    <section v-else>
      <div v-for="userLog in filteredUserModLogs" :key="userLog.id">
        <UserModLogCard
          class="clickable-item-hov"
          :userModLogId="userLog.id"
          @click="emit('reviewUserModLog', userLog.id)"
        />
      </div>
      <ion-button v-if="hasNext" @click="loadNextPage"><span class="no-select">Load More</span></ion-button>
    </section>
  </div>
</template>

<script lang="ts" setup>
import { User } from '@/shared/types/static-types';
import Multiselect from 'vue-multiselect';
import { adminStore } from '@/shared/pinia-store/admin';
import { partialLookup } from '@/shared/actions/users';
import ReportCard from '../components/ReportCard.vue';
import UserModLogCard from '../components/UserModLogCard.vue';

interface ReportCategory {
  id: string;
  title: string;
}

const emit = defineEmits(['reviewUserModLog', 'reviewReport']);
const userSearchField = ref('');
const searchUserList = ref([]);
const isSearchLoading = ref(false);

const currentCount = computed(() => {
  const { currentPagingCount } = adminStore();
  return currentPagingCount.value;
});

const loadNextPage = () => {
  const { loadMoreReports, loadMoreUserModLogs } = adminStore();
  if (!currentCategory.value) {
    loadMoreUserModLogs();
  } else {
    loadMoreReports();
  }
};

const reports = computed(() => {
  const { reports: _reports } = adminStore();
  return _reports.value;
});

const hasNext = computed(() => {
  const { currentPagingHasNext } = adminStore();
  return currentPagingHasNext.value;
});

const filteredUserModLogs = computed(() => {
  const { filteredUserModLogs } = adminStore();
  return filteredUserModLogs.value;
});

const currentReviewFilter = computed(() => {
  const { currentReviewFilter } = adminStore();
  return currentReviewFilter.value;
});

const currentCategory = computed(() => {
  const { currentCategory } = adminStore();
  return currentCategory.value;
});

const allCategories = computed(() => {
  const { categories } = adminStore();
  return categories.value;
});

const isCurrentCategory = (category: ReportCategory) => {
  return typeof currentCategory.value !== 'string' && 
         currentCategory.value && 
         category && 
         currentCategory.value.id === category.id;
};

const selectCategoryIndex = (index: number) => {
  const { onPressCategoryTab } = adminStore();
  onPressCategoryTab(index);
};

const selectFilter = (filter: 'all' | 'reviewed' | 'unreviewed') => {
  const { setReviewFilter } = adminStore();
  setReviewFilter(filter);
};

const searchUser = async (username: string) => {
  if (!username || username.length < 3) {
    searchUserList.value = [];
    return;
  }
  isSearchLoading.value = true;
  try {
    const data = await partialLookup(username);
    searchUserList.value = data;
  } catch (e) {
    searchUserList.value = [];
    console.error(e);
  } finally {
    isSearchLoading.value = false;
  }
};

const formatSearchLabel = (user: User) => {
  return user.username;
};

const selectSearchUser = async (user: User) => {
  isSearchLoading.value = true;
  const { fetchUserModLogByUserId } = adminStore();
  try {
    await fetchUserModLogByUserId(user.id);
  } catch (e) {
    console.error(e);
  } finally {
    isSearchLoading.value = false;
  }
};
const debouncedSearchUser = debounce(searchUser, 500);
</script>

<style lang="sass" scoped>
h1.title
  font-size: 1.5rem
  margin-bottom: 1rem
  font-weight: bold
</style>
