import { chAxios } from '@/shared/lib/axios';
import { sendAnalyticsEvent } from '../services/analytics';

export enum ReorderItem {
  characters = 'characters',
  folders = 'folders',
  storyworlds = 'storyworlds',
  socialspaces = 'socialspaces',
}

export async function reorderItems(order: string[], type: ReorderItem) {
  const url = `/user-profiles/reorder/`;
  await chAxios().post(url, { order, type });
}


export async function addUserProfileVirtualItem(payload: {},value?:string) {
  const url = `/user-profiles/add-virtual-item/`;
  const { data } = await chAxios().post(url, payload);
  sendAnalyticsEvent(`Added ${value}`);
  return data;
}


export async function addUserProfileBackgroundImage(payload: {}) {
  const url = `/user-profiles/background-image/`;
  const { data } = await chAxios().post(url, payload);
  sendAnalyticsEvent(`Added Profile Background Image`);
  return data;
}
