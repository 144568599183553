import { Onboarding, OnboardingCreateBody, OnboardingUpdateBody } from '@/shared/types/static-types';
import { chAxios } from '@/shared/lib/axios';
import { onboardingStore } from '../pinia-store/onboarding';
import { authStore } from '../pinia-store/auth';
import { characterEditorStore } from '../pinia-store/character-editor';

export async function updateOnboarding(body: OnboardingUpdateBody, trackerEvent?: any): Promise<Onboarding | null> {
  try {
    const { currentStep } = onboardingStore();
    const { trackEvent } = useAnalytics();
    const { firstCharacterCreation, updateCharacterCreationStatus } = characterEditorStore();
    const { isFirstCharacterCreationChallenge } = authStore();
    const { data } = await chAxios().patch(`/onboarding/mine/update/`, body);
    const stepEvents: { [key: number]: string } = {
      2: 'Completed onboarding step: welcome',
      3: 'Completed onboarding step: exploring the site',
      4: 'Completed onboarding step: showcasing characters',
      5: 'Completed onboarding step: premium plans overview',
      6: 'Completed onboarding step: pay wall',
      8: 'Completed onboarding step: CH discovery source question',
      9: 'Completed onboarding step: user intent/goals survey',
      10: 'Completed onboarding step: interests',
      12: 'Completed onboarding step: character likes',
      14: 'Completed onboarding step: profile picture',
      15: 'Completed onboarding step: cover picture',
      16: 'Completed onboarding step: age over 18',
      17: 'Completed onboarding step: age range',
      18: 'Completed onboarding step: share profile',
      // 19: 'Completed onboarding step: character creation intimation',
      20: 'Completed onboarding step: character creation',
    };

    const activeStep = Object.keys(stepEvents).find((step) => currentStep.value === Number(step)) as any;
    if (activeStep) {
      const event = stepEvents[activeStep];
      const eventMessage = typeof event === 'string' ? event : '';
      const eventData =
        Number(activeStep) === 8
          ? data.heard_about
          : Number(activeStep) === 9
          ? data.accomplishments
          : Number(activeStep) === 10
          ? data.interests
          : Number(activeStep) === 12
          ? data.liked_characters
          : null;
      if (Number(activeStep) === 20) {
        if (firstCharacterCreation.value) {
          trackEvent(eventMessage, eventData, isFirstCharacterCreationChallenge.value);
          setTimeout(() => {
            updateCharacterCreationStatus(false);
          }, 50);
          
        }
      } else if (eventMessage && !trackerEvent) {
        trackEvent(eventMessage, eventData, false);
      }
    }

    return data;
  } catch (e) {
    return null;
  }
}

export async function getUserOnboarding(): Promise<Onboarding | null> {
  try {
    const { data } = await chAxios().get(`/onboarding/mine/`);
    return data;
  } catch (e) {
    return null;
  }
}

export async function createOnboarding(body: OnboardingCreateBody): Promise<Onboarding | null> {
  try {
    const { data } = await chAxios().post(`/onboarding/`, body);
    return data;
  } catch (e) {
    return null;
  }
}

export async function getUserChallengeJoiningEligibilityRewards() {
  try {
    const { data } = await chAxios().get(`/users/joining-challenge-eligibility`);
    return data;
  } catch (e) {
    return null;
  }
}
