<template>
  <ion-page>
    <ion-content>
      <ion-alert
        :is-open="true"
        header="Confirmation"
        message="Resetting password requires you to be logged out. Do you want to log out?"
        :buttons="alertButtons"
      ></ion-alert>
    </ion-content>
  </ion-page>
</template>

<script>
import { authStore } from '@/shared/pinia-store/auth';
import { useRouter, useRoute } from 'vue-router';

export default {
  name: 'ResetPasswordValidationPage',
  setup() {
    const { logout } = authStore();
    const router = useRouter();
    const route = useRoute();
    const token = String(route.query.token);
    const alertButtons = [
      {
        text: 'Cancel',
        role: 'cancel',
        cssClass: 'text-secondary',
        handler: () => {
          navigateTo({
            name: 'home',
          });
        },
      },
      {
        text: 'Log out',
        role: 'confirm',
        cssClass: 'text-secondary',
        handler: async () => {
          await logout();
          navigateTo({
            name: 'reset-password',
            query: { token },
          });
        },
      },
    ];

    return { alertButtons };
  },
};
</script>
