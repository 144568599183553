<template>
  <div v-if="ready" class="text">
    <div class="title mb-3">Add to folder</div>
    <div v-if="allCollections.length" class="custom-select bg-white select pr-2 text" @click="openCollectionsModal">
      <span class="placeholder">
        {{
          selectedCollectionsName.length
            ? selectedCollectionsName.join(', ')
            : 'Select folders you want your character to be a part of'
        }}
      </span>
      <ion-icon name="chevron-down-outline"></ion-icon>
    </div>
    <span v-else>Folders let you organize your characters and worlds!</span>
    <CollectionsModal
      v-if="showCollectionsModal"
      :isOpen="showCollectionsModal"
      :collections="allCollections"
      :selectedCollections="selectedCollections"
      @close="closeCollectionsModal"
      @update-selected="updateSelectedCollections"
      @update-collections="updateCollections"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, nextTick } from 'vue';
import { characterEditorStore } from '@/shared/pinia-store/character-editor';
import { getCharacterCollections } from '@/shared/actions/collections';
import { authStore } from '@/shared/pinia-store/auth';
import CollectionsModal from '~/shared/modals/CollectionsModal.vue';

const { user: loggedInUser } = authStore();
const { changeKey } = characterEditorStore();

const props = defineProps({
  usedKey: {
    type: String,
    default: '',
  },
  value: {
    type: Array,
    default: [],
  },
});

const ready = ref(true);
const showCollectionsModal = ref(false);
const characterCollections = toRef(props, 'value');
let selectedCollections = ref([] as any[]);
let selectedCollectionsName = ref([] as any[]);
const allCollections = ref([] as any[]);

const fetchUserCollections = async () => {
  try {
    const response = await getCharacterCollections(loggedInUser.value.id);
    allCollections.value = response;
  } catch (error) {
    console.error('Failed to fetch collections');
  }
};

onMounted(async () => {
  await fetchUserCollections();
  selectedCollections.value = characterCollections.value;
  selectedCollectionsName.value = characterCollections.value.map(
    (id) => allCollections.value.find((c) => c.id === id)?.name || 'Unknown'
  );
  ready.value = false;
  nextTick(() => {
    ready.value = true;
  });
});

const openCollectionsModal = () => {
  showCollectionsModal.value = true;
};

const closeCollectionsModal = () => {
  showCollectionsModal.value = false;
};

const updateCollections = async (updatedCollections: any[]) => {
  allCollections.value = updatedCollections;
  selectedCollectionsName.value = selectedCollections.value.map(
    (id) => updatedCollections.find((c) => c.id === id)?.name || 'Unknown'
  );
  const existingIds = updatedCollections.map((c) => c.id);
  selectedCollections.value = selectedCollections.value.filter((id) => existingIds.includes(id));
  changeKey('character_collections', selectedCollections.value);
};

const updateSelectedCollections = async (updatedCollections: string[]) => {
  let namesArray = updatedCollections.map((id) => {
    const collection = allCollections.value.find((c) => c.id === id);
    return collection ? collection.name : null;
  });
  // eslint-disable-next-line unicorn/prefer-includes
  if (namesArray.some((name) => name === null)) {
    try {
      await fetchUserCollections();
      namesArray = updatedCollections.map((id) => {
        const collection = allCollections.value.find((c) => c.id === id);
        return collection ? collection.name : 'Unknown';
      });
    } catch (error) {
      console.error('Failed to refresh collections');
      namesArray = namesArray.map((name) => name || 'Unknown');
    }
  }
  selectedCollectionsName.value = namesArray;
  selectedCollections.value = updatedCollections;
  changeKey('character_collections', updatedCollections);
};
</script>

<style lang="sass" scoped>
.title
  font-size: 16px
  font-weight: bold
.custom-select
  border-radius: 10px
  padding: 8px 10px
  display: flex
  justify-content: space-between
  align-items: center
  border: 1px solid #ccc
  cursor: pointer
.placeholder
  font-size: 14px
  color: #6b7280
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
.custom-select:hover
  border-color: #4a90e2
.text
  font-size: 14px
</style>
