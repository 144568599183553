<template>
  <ion-card class="task-card m-0 clickable-item-hov" :style="getTaskBorderStyle(task)">
    <div class="d-flex flex-column" @click="taskAction">
      <!-- <ion-badge
        v-if="task.user_submission_count || ((task.action_url || '').includes('?quest=oc') && perDaySubCount === 6)"
        class="completed"
        color="tertiary"
        title="Done!"
        ><i class="ti-check"
      /></ion-badge> -->
      <div>
        <router-link :to="toEvent(task.event_slug!)" target="_blank" @click.stop
          ><ion-badge
            v-if="task.event_title"
            class="event-badge clickable-item-hov"
            color="secondary"
            :title="task.event_title"
            >{{ truncateEasy(task.event_title, isSmallMobScreen ? 15 : 17) }}</ion-badge
          ></router-link
        >
      </div>

      <img
        loading="lazy"
        v-image
        class="event-img"
        style="padding: 5px"
        :src="task.event_image_url"
        :class="{
          'event-oc-img': (task.action_url || '').includes('?quest=oc'),
        }"
      />

      <div class="d-flex flex-column">
        <div class="name d-flex flex-wrap justify-content-center align-items-center text-center title h-100">
          {{ truncateEasy(task.name, 21) }}
          <span v-if="(task.action_url || '').includes('?quest=oc')" style="font-size: 11px">
            (Day {{ computeDays - computeCurrentDay }} / {{ computeDays }})
          </span>
        </div>

        <div class="d-flex tsk-btn text-black align-items-center flex-column mt-auto mb-2">
          <ion-button
            v-if="(task.starts_at || task.ends_at) && computedEndsAt"
            class="end-btn m-0"
            :class="{ 'text-font': showRefreshTimer(task) }"
            :color="dateHasPassed(task.starts_at) ? (showRefreshTimer(task) ? 'tertiary' : 'primary') : 'medium'"
          >
            <i class="ti-time icon" />
            <div v-if="dateHasPassed(task.starts_at)">
              <!-- Ongoing -->
              <vue-countdown
                v-if="computedEndsAt"
                v-slot="{ days, hours, minutes, seconds }"
                :time="formatFromNowInMilliseconds(computedEndsAt)"
                @end="onEnd"
              >
                <span
                  ><span v-if="showRefreshTimer(task)">Restarts in </span><span v-else>Ends in </span
                  ><span v-if="days">{{ days }}d </span><span v-if="hours">{{ hours }}h </span
                  ><span v-if="!days">{{ minutes }}m </span><span v-if="!days && !hours">{{ seconds }}s</span></span
                >
              </vue-countdown>
            </div>

            <div v-else>
              <!-- Upcoming -->
              <vue-countdown
                v-if="task?.starts_at"
                v-slot="{ days, hours, minutes, seconds }"
                :time="formatFromNowInMilliseconds(task.starts_at)"
                @end="onEnd"
              >
                <span
                  >Starts in <span v-if="days">{{ days }}d </span><span v-if="hours">{{ hours }}h </span
                  ><span v-if="!days">{{ minutes }}m </span><span v-if="!days && !hours">{{ seconds }}s</span></span
                >
              </vue-countdown>
            </div>
          </ion-button>
        </div>
      </div>
    </div>
  </ion-card>
</template>

<script lang="ts">
import { SiteEventTask } from '@/shared/types/static-types';
import { dateHasPassed, formatFromNowInMilliseconds } from '@/shared/utils/dateTime';
import { truncateEasy } from '@/shared/utils/string';
export default defineComponent({
  name: 'EventTaskCard',
  props: {
    task: { type: Object as PropType<SiteEventTask>, required: true },
    openCharacterRoulette: { type: Function, required: true },
    openArtRoulette: { type: Function, required: true },
    openOcDecoration: { type: Function, required: true },
    perDaySubCount: { type: Number, default: 0 },
  },
  data() {
    return {
      truncateEasy,
    };
  },
  computed: {
    computeDays() {
      return Math.floor((Date.parse(this.task.ends_at) - Date.parse(this.task.starts_at)) / 86400000);
    },

    isSmallMobScreen() {
      const { isLessThan570px } = useWindowSize();
      return isLessThan570px.value;
    },

    computedEndsAt() {
      return (this.task.action_url || '').includes('?quest=oc') ? this.computeEndDate : this.task.ends_at;
    },

    computeCurrentDay() {
      return Math.floor((Date.parse(this.task.ends_at) - +new Date()) / 86400000);
    },

    computeEndDate() {
      const nowDate = new Date();
      if ((this.task.action_url || '').includes('?quest=oc')) {
        const nowHours = nowDate.getHours();
        const nowMins = nowDate.getMinutes();

        const startDate = new Date(this.task.starts_at);
        const startHours = startDate.getHours();
        const startMins = startDate.getMinutes();

        let totalHours;

        if (nowHours < startHours) {
          totalHours = nowHours + (24 - startHours);
        } else {
          totalHours = nowHours - startHours;
        }

        const totalMinutes = nowMins - startMins + 60;

        const remainingHours = 24 - totalHours;
        const remainingMins = 60 - totalMinutes;

        nowDate.setHours(nowHours + remainingHours);
        nowDate.setMinutes(nowMins + remainingMins);

        return nowDate.toISOString();
      } else {
        return nowDate.toISOString();
      }
    },
  },
  mounted() {
    if (this.$route.query && this.$route.query.event === 'decoration-fest') {
      if ((this.task.action_url || '').includes('?quest=oc')) {
        this.$emit('select', this.task);
        return this.openOcDecoration();
      }
    }
  },
  methods: {
    truncate,
    formatFromNowInMilliseconds,
    dateHasPassed,
    onEnd() {
      this.$emit('end', this.task);
    },
    taskAction() {
      const task = this.task!;
      if (!this.task) return;
      if (task.response_type === 'A') {
        if ((task.action_url || '').includes('?quest=cr')) {
          return this.openCharacterRoulette();
        }
      }
      const router = useRouter();
      if (task.response_type === 'A') {
        if ((task.action_url || '').includes('?quest=ar')) {
          return this.openArtRoulette();
        }
      }

      if (task.response_type === 'A') {
        if ((task.action_url || '').includes('?quest=oc')) {
          this.$emit('select', task);
          return this.openOcDecoration();
        }
      }

      if (task.task_type === 'poll') {
        window.open(`/events/poll/${this.task.id}`, '_blank');
      } else {
        window.open(`/events/task/${task.id}`, '_blank');
      }
    },
    toEvent(slug: string) {
      return `/events/event/${slug}`;
    },
    showEventBadge(task: SiteEventTask) {
      if (task.event_slug === 'character-roulette' || task.event_slug === 'art-roulette') return false;
      if (task.response_type === 'A') {
        if ((task.action_url || '').includes('?quest=ar') || (task.action_url || '').includes('?quest=oc')) {
          return false;
        }
      }
      return true;
    },
    showRefreshTimer(task: SiteEventTask) {
      return task.user_submission_count && task.will_refresh;
    },
    getTaskBorderStyle(task: any) {
      const promotions = JSON.parse(localStorage.getItem('task_promotions') || '{}');
      return task.is_new || promotions[task.id] <= 3 ? 'border: 3px solid red !important' : '';
    },
  },
});
</script>

<style lang="sass" scoped>
.tsk-btn
  margin-left: 6px
  margin-right: 6px
.text-font
  @media(max-width:419px)
    font-size: 11px !important
.icon
  margin-right: 3px
  @media(max-width:419px)
    font-size: 9px

ion-badge
    padding-left: 5px
    padding-right: 5px
p
  margin: 0rem 0 0.75rem 0
.task-card
  transition: 150ms ease all
  overflow: unset !important
  background: none
  border: 2.5px solid #7050B7 !important
  border-radius: 12px
  min-width: 145px !important
  max-width: 145px !important
  min-height: 180px
  max-height: 180px
.event-oc-img
  height: 94.5px !important

.event-img
  width: 160px !important
  object-fit: cover
  height: 105.5px
  border-radius: 17px !important
  @media(max-width:570px)
    height: 106.5px



.name
  font-size: 12px
  font-weight: 400
  color: #431C89
  cursor: pointer
.dark .text-primary
  color: white
.dark .text-tertiary
  color: gray
.completed
  position: absolute
  top: 5px
  right: 5px
  z-index: 1
.event-badge
  display: flex
  font-size:11px
  border-radius: 7px
  height: 23px
  justify-content: center
  text-align: center
  align-items: center
  border-radius: 20px
  margin: 5px 5px 0px 5px
  height: 20px

.end-btn
  height: 18px !important
  --border-radius: 20px
  text-transform: none
  font-size: 10.5px
  @media(max-width: 480px)
    font-size: 7.5px
</style>
