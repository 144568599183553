<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ion-header translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-between align-items-center px-3">
          <div @click="dismissModal" class="clickable-item-hov">
            <i class="ti-arrow-left icon" />
          </div>
          <div>
            <div class="title">It's time to {{ socialType === COMPLIMENT_BOMB ? 'Compliment' : 'FanArt' }}</div>
          </div>
          <div>
            <ion-buttons slot="end">
              <ion-button @click="dismissModal">
                <i class="ti-close icon" />
              </ion-button>
            </ion-buttons>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>

    <div class="modal-inner-content">
      <div class="px-3 py-3">
        <div class="char-compliment-bombed px-3 pt-3">
          <compliment-target-bomb-card :socialType="socialType" />
        </div>
        <div class="mt-2">
          <div
            class="d-flex text-black pt-1"
            style="font-size: 15px"
            sty
            v-for="(warning, index) in targetWarning"
            :key="index"
          >
            {{ warning }}
          </div>
        </div>
      </div>
    </div>
    <ion-footer translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-center text-center align-items-center">
          <ion-button @click="actionClicked">Ok!</ion-button>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-modal>
</template>

<script lang="ts" setup>
import ComplimentTargetBombCard from '@/shared/components/ComplimentTargetBombCard.vue';
import { COMPLIMENT_BOMB } from '../statics/constants';

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
  socialType: {
    type: String,
    default: '',
  },
});
const emits = defineEmits(['close', 'done']);
const socialType = toRef(props, 'socialType');

const targetWarning = computed(() => {
  return socialType.value === COMPLIMENT_BOMB
    ? [
        `if you don't have anything nice to say, click skip`,
        `if you aren't nice, you won't be allowed to participate anymore.`,
      ]
    : [`if you're struggling to come up with ideas, click skip`, `Low-effort entries will get their rewards removed.`];
});
const isOpen = toRef(props, 'isOpen');

const actionClicked = () => {
  emits('done');
};
const dismissModal = () => {
  emits('close');
};
</script>
<style lang="sass" scoped>
.icon
  font-weight: bold
.dark .toolbar
  --background: #17074c !important

.modal-inner-content
  background: var(--ion-background-color, #fff)
  max-height: 90vh
  overflow: auto

.modal-big
  --width: 450px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
.close-button
  width: 50px
.title
  color: #214163
  font-size: 20px
  font-weight: bold
  text-align: center
  align-items: center
.char-compliment-bombed
  border-radius: 10px
  border: 2px solid #c6c8d6
</style>
