import { chAxios } from '../lib/axios';

export async function logout(token?: string) {
  return chAxios().post(`/logout/`, { token });
}

export async function delete_account(payload: { token?: string; feedback?: string; password?: string }) {
  return chAxios().post(`/delete-account/`, {
    token: payload.token,
    feedback: payload.feedback,
    password: payload.password,
  });
}
