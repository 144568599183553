<template>
  <ion-modal
    class="modal-big"
    css-class=""
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="close"
  >
    <div class="inner-content" :class="{ 'set-scroller': !isEmpty(template) && template?.length >= 1 }">
      <div class="d-flex flex-column py-3" :class="isNewCustomizationTemplate ? 'px-2' : 'px-3'">
        <div class="d-flex justify-content-between">
          <div class="title mx-2">
            {{ stylizeCharacterTitle }}
          </div>
          <ion-buttons>
            <ion-button class="close-button text-black" @click="close">
              <i class="ti-close" />
            </ion-button>
          </ion-buttons>
        </div>
        <div class="mx-2 text" :class="isMobSmallScreen ? 'pb-2' : 'py-2'">
          <div>
            <div v-if="!isEmpty(template) && !isSwitch && !isNewCustomizationTemplate">
              Create a
              <strong> New Template</strong>
              or edit over
              <strong> an existing one</strong> (changes made will affect other Character Profiles).
            </div>
            <div v-else-if="!isEmpty(template) && isSwitch">Switch or create a New Template</div>

            {{ stylizeCharacterSubtitle }}
          </div>
        </div>
        <div
          :class="{
            'change-direction px-1': !isMobSmallScreen && isNotEmptyCustomizationTemplate,
            'px-2 mx-1': isNewCustomizationTemplate,
          }"
        >
          <div
            :class="{
              'set-card-border w-100 px-3': isNotEmptyCustomizationTemplate,
            }"
          >
            <div class="stylize-character">
              <div
                v-if="!isNewCustomizationTemplate || !isEmpty(template)"
                class="character-card w-100 pt-3 pb-1"
                :class="{ 'd-flex': isSmallScreen, hide: isNewCustomizationTemplate || isEmpty(template) }"
              >
                <div v-if="!get(selectedTempItem, 'iconDel')" class="set-bg-color w-100 py-1">
                  <div class="character-profile-header d-flex flex-column mt-2 char-box">
                    <div class="cover"></div>
                    <div class="rest">
                      <div v-if="!isSmallScreen">
                        <div class="name d-flex justify-content-center">
                          <div class="change-font">{{ get(customizeCharacter, 'entity.info.name') }}</div>
                        </div>
                        <div class="quote">
                          {{ truncateText(stripHtmlTags(get(customizeCharacter, 'entity.info.quote'))) }}
                        </div>

                        <div class="button-container px-3 pb-2">
                          <div class="button-row">
                            <ion-button class="small-button w-100" :style="`--background: ${getButtonColor}`" />
                            <ion-button class="small-button rolechat" :style="`--background: ${getButtonColor}`" />
                            <ion-button
                              class="w-100 small-button inside-reaction"
                              :style="`--background: ${getButtonColor}`"
                            />
                          </div>
                        </div>
                      </div>
                      <div v-else class="rest-applied py-1">
                        <div class="d-flex flex-column align-items-center spacing">
                          <ion-button class="px-2 small-char-button"></ion-button>
                          <ion-button class="px-2 small-quote-button"></ion-button>
                          <ion-button class="px-2 small-applied-button"></ion-button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="info-app-section py-1 mt-2">
                    <div v-if="isSmallScreen" class="d-flex flex-column align-items-center spacing">
                      <ion-button class="px-2 title-button"></ion-button>
                      <ion-button class="px-2 small-default-button"></ion-button>
                      <ion-button class="px-2 small-default-button"></ion-button>
                    </div>
                    <div v-else class="px-2 py-2">
                      <div class="info-title">title</div>
                      <div class="info-des mt-1">default font</div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <StylizeCharacterPreviewSmallCard :showTempName="get(selectedTempItem, 'iconDel')" />
                </div>
                <div
                  v-if="!isEmpty(template) && !isNewCustomizationTemplate"
                  :class="{
                    hide: isEmpty(template) || isEmpty(selectedTemp),
                    'w-100 px-2': isSmallScreen,
                    'px-1 mt-1': !isSmallScreen,
                  }"
                >
                  <div class="text-black" :class="{ hide: isSmallScreen && template?.length > 1 }">
                    <strong>Template Name:</strong>
                  </div>
                  <div class="text-black" :class="{ hide: isSmallScreen && template?.length > 1 }">
                    {{
                      template?.length === 1
                        ? truncateText(stripHtmlTags(get(template[0], 'name')))
                        : truncateText(stripHtmlTags(get(selectedTemp, 'name')))
                    }}
                  </div>
                  <div v-if="!isEmpty(tempres)" class="text-black"><strong>Used By:</strong></div>
                  <div v-if="!isEmpty(tempres)" class="d-flex text-black" :class="{ hide: isSmallScreen }">
                    <div v-for="(char, index) of displayedChars" :key="index" class="px-2 py-2">
                      <router-link
                        :to="{ name: 'character-profile-new', params: { slug: get(char, 'slug') } }"
                        @click="close"
                      >
                        <img
                          :src="resizeUpload(get(char, 'info.cropProfilePicture', '/empty.png'), '80x80')"
                          style="width: 30px; height: 30px; border-radius: 20px"
                        />
                      </router-link>
                    </div>
                  </div>
                  <div v-if="isSmallScreen" class="used-char">
                    <div class="content-id">
                      <div v-for="(char, index) of displayedChars" :key="index" class="mx-1">
                        <router-link
                          :to="{ name: 'character-profile-new', params: { slug: get(char, 'slug') } }"
                          @click="close"
                        >
                          <img
                            :src="resizeUpload(get(char, 'info.cropProfilePicture', '/empty.png'))"
                            style="width: 40px; height: 40px; border-radius: 20px"
                          />
                        </router-link>
                      </div>
                      <div
                        v-if="!isEmpty(hiddenChars) && hiddenChars?.length !== 0"
                        style="
                          background: rgb(33 65 99);
                          width: 38px;
                          height: 40px;
                          border-radius: 20px;
                          margin-left: 4px;
                        "
                      >
                        <div style="justify-content: center; display: flex; margin-top: 10px; color: white">
                          +{{ hiddenChars?.length <= 99 ? hiddenChars?.length : 99 }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="!hideTempsList && isNotEmptyCustomizationTemplate" class="w-100 py-2">
                <SearchInput
                  v-model="text"
                  :blur-on-esc="true"
                  :select-on-focus="false"
                  :shortcut-listener-enabled="true"
                  :clear-icon="false"
                  :hide-shortcut-icon-on-blur="false"
                  :clear-on-esc="true"
                  placeholder="Type anything to search..."
                  @input="onSearch"
                />
              </div>
              <div v-if="!isEmpty(text) && isEmpty(template) && isSearching" class="no-data mb-2">
                No search results found
              </div>

              <div class="template-content" v-if="!hideTempsList">
                <div v-for="(temp, index) in getTemplates" :key="temp.id">
                  <div :id="`template-${temp.id}`">
                    <ion-radio-group v-model="selectedItem" mode="md">
                      <ion-item
                        class="mb-2 clickable-item"
                        :class="isSelectedTemplate(temp.id) ? 'selected-bg' : 'not-selected-bg'"
                        :style="
                          get(temp, 'iconDel')
                            ? `border: 1px solid #eaecf6;border-radius: 8px; --background: transparent;color: ${defaultNoTempColor} `
                            : '--border-radius: 8px'
                        "
                        @click="changeTemp(temp, index)"
                      >
                        <i class="ti-na" v-if="get(temp, 'iconDel')" style="font-size: 20px" />
                        <ion-radio
                          v-else
                          class="clickable-item-hov"
                          :value="temp.id"
                          style="margin-inline-end: 5px"
                          :color="dark ? 'light' : 'light'"
                        ></ion-radio>
                        <ion-label class="mx-2 label-name">{{ truncateText(stripHtmlTags(temp.name)) }}</ion-label>
                        <ion-button
                          v-if="!get(temp, 'iconDel')"
                          color="transparent"
                          :class="isSelectedTemplate(temp.id) ? 'selected-icon' : 'not-selected-icon'"
                          @click="dropdownToggle($event, temp.id)"
                        >
                          <ion-icon :icon="ellipsisVertical" />
                        </ion-button>
                      </ion-item>
                    </ion-radio-group>
                  </div>
                </div>
                <p v-show="nextPageExists" class="clickable-item-hov load-text" @click="loadMore">Load More</p>
              </div>
            </div>
            <div
              v-if="(user.tmp_quickapply && !isNewCustomizationTemplate) || (isSwitch && !isNewCustomizationTemplate)"
              class="d-flex"
              :class="{ 'hide-app-btn': isEmpty(template) }"
            >
              <ion-button
                class="w-100 switch-btn"
                :disabled="isApplying || isEmpty(selectedTempItem)"
                style="
                  margin-right: 10px;
                  --background: linear-gradient(250deg, rgb(81 79 202) 0%, rgb(104 71 203) 33%, rgb(189 61 214) 100%);
                "
                @click="applyTemplate"
              >
                <ChLoading size="sm" v-if="isApplying" class="spinner" />
                <span v-else>Apply</span>
              </ion-button>
              <ion-button
                v-if="!get(selectedTempItem, 'iconDel')"
                :disabled="isEmpty(selectedTemp)"
                class="w-100 switch-btn"
                style="--background: #328fd2"
                @click="openStylize"
              >
                Try on</ion-button
              >
            </div>
            <div
              v-else-if="!user.tmp_quickapply && !isSwitch"
              :class="{
                'mb-2': !isMobSmallScreen,
                'hide-app-btn': isEmpty(template) || isNewCustomizationTemplate,
                'set-reuse-btn': template?.length === 1,
              }"
            >
              <ion-button
                class="w-100 set-new-height"
                style="--border-radius: 20px; --background: #765fa7"
                @click="getRoute"
              >
                <div>Reuse/Edit</div>
              </ion-button>
            </div>
          </div>

          <div
            class="d-flex flex-column"
            :class="{
              'set-card-border px-3 w-100': isNotEmptyCustomizationTemplate,
              'mt-2': isMobSmallScreen,
            }"
          >
            <div>
              <div v-if="!isMobSmallScreen" class="character-card w-100 py-3">
                <StylizeCharacterPreviewSmallCard :isNewCustomizationTemplate="isNewCustomizationTemplate" />
              </div>
            </div>
            <div class="d-flex mt-auto" :class="{ 'px-3': isNotEmptyCustomizationTemplate }">
              <ion-button
                v-if="isNotEmptyCustomizationTemplate"
                class="set-border set-new-height w-100"
                :class="{ 'set-customize-btn': !user.tmp_quickapply }"
                style="margin-right: 10px"
                @click="actionClicked"
              >
                <ion-icon class="mx-1" :icon="addCircleOutline" />
                New Customization</ion-button
              >
              <ion-input
                v-else-if="isNewCustomizationTemplate"
                v-model="name"
                class="c-input one-input mb-1"
                placeholder="Type here Template name"
                maxlength="50"
                required
              />
            </div>
          </div>
        </div>
        <div v-if="isEmpty(template) && !isSearching">
          <div class="msg-content mb-3">
            <div class="info-content mx-1 px-3">
              <div class="d-flex">
                <ion-icon
                  :class="{ 'change-color': isEmpty(name) }"
                  class="icon mx-2"
                  :icon="documentOutline"
                  style="font-size: 20px; margin-top: 13px"
                />
                <ion-input
                  v-model="name"
                  ref="inputField"
                  placeholder="Type your New Template name..."
                  class="chat-box"
                  no
                  margin
                  rows="2"
                  style="--highlight-color-focused: none; min-height: 44px !important; margin-top: 3px !important"
                  maxlength="50"
                  required
                />
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex">
          <ion-button
            v-if="isEmpty(template) && !isSearching"
            :disabled="isEmpty(name) || isSubmitting"
            class="w-100"
            :class="
              !isEmpty(name) && !isEmpty(template)
                ? 'continue-btn px-2'
                : !isEmpty(name) && isEmpty(template)
                ? 'save-btn'
                : 'name-temp-btn '
            "
            @click="applyTemp"
          >
            <div v-if="isEmpty(template)" class="d-flex">
              <ion-icon class="mx-2" style="font-size: 18px; --ionicon-stroke-width: 64px" :icon="saveOutline" />
              <ChLoading size="sm" v-if="isSubmitting" class="spinner" />
              <div v-else style="font-weight: bold; text-transform: none; margin-top: 3px">Save</div>
            </div>
          </ion-button>
          <ion-button
            v-if="!isEmpty(template) && isNewCustomizationTemplate"
            :disabled="isEmpty(name) || isEditing"
            class="w-100 px-2"
            :class="!isEmpty(name) ? 'continue-btn' : 'name-temp-btn'"
            @click="editNewTemp"
          >
            <ChLoading size="sm" v-if="isEditing" class="spinner" />
            <span v-else>Edit</span>
          </ion-button>
        </div>

        <new-template-modal
          :id="getTemplateId"
          :is-open="isModalOpen"
          :is-edit-popover="isEditPopover"
          :modal-title="modalName"
          :temp-name="selectedTempName"
          @fetch="fetchTemplate"
          @updated="updatedEditTemplate"
          @dismiss-modal="closeModal"
        />
      </div>
      <switching-template-design-modal
        :is-open="isSwitchingModal"
        :template-id="prevSelectedTempId"
        :template-name="prevSelectedTempName"
        :customize="customizeCharacter"
        :current-customize-data="currentTemplate"
        @close="closeSwitchModal"
      />
      <template-design-updated-modal
        :id="selectedDeleteTempId"
        :is-open="isUpdateCharModal"
        :template-name="selectedTempName"
        :deleted="true"
        @deleted="closeAndFetchTemplates"
        @cancel="closeUpdatedModal"
      />
    </div>
  </ion-modal>
</template>

<script lang="ts" setup>
import { resizeUpload } from '@/shared/utils/upload';
import { addCircleOutline, closeCircleOutline, ellipsisVertical, saveOutline, documentOutline } from 'ionicons/icons';
import SearchInput from 'vue-search-input';
import SwitchingTemplateDesignModal from './SwitchingTemplateDesignModal.vue';
import NewTemplateModal from './CreateNewTemplateModal.vue';
import TemplateDesignUpdatedModal from '@/shared/modals/TemplateDesignUpdatedModal.vue';
import { characterStylizeStore } from '@/shared/pinia-store/character-stylize';
import { stripHtmlTags } from '@/shared/utils/string';
import { authStore } from '@/shared/pinia-store/auth';
import StylizeCharacterPreviewSmallCard from '../components/StylizeCharacterPreviewSmallCard.vue';
import { mainStore } from '@/shared/pinia-store/main';
import { popovers, toast } from '@/shared/native';
import { nanoid } from 'nanoid';
import { menuController } from '@ionic/vue';
import CustomizeTemplatePopover from '@/shared/components/popovers/customize-template-popover.vue';
import {
  applyCharacterStylizeTemplate,
  duplicateCharacterTemplate,
  getTemplateUsedByOtherChar,
  postCharacterStylizeTemplate,
} from '@/shared/actions/characters';
import { truncateText } from '@/shared/utils/string';
const {
  loadCharacterTemplate,
  template,
  paging,
  currentTemplate,
  customizeCharacter,
  loadTemplate,
  requestLoadMoreTemplate,
} = characterStylizeStore();

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
  id: {
    type: String,
  },
  customize: {
    type: Object,
    default: {},
  },
  isSwitch: {
    type: Boolean,
    default: false,
  },
});

const isOpen = toRef(props, 'isOpen');
const selectedDeleteTempId = ref('');
const isSearching = ref(false);
const { updatePreviousCustomize, updatePreviousTemplate, updateProfileTryOnAnimation } = authStore();
const isSwitch = toRef(props, 'isSwitch');
const id = toRef(props, 'id');
const inputField = ref<HTMLInputElement | null>(null);
const isApplying = ref(false);
const isUpdateCharModal = ref(false);
const isEditPopover = ref(false);
const isEditing = ref(false);
const windowWidth = ref(window.innerWidth);
const isSubmitting = ref(false);
const getUsedCharTemp = ref([]);
const isNewCustomizationTemplate = ref(false);
const name = ref('');
const isHeader = ref(false);
const prevSelectedTempId: any = ref('');
const prevSelectedTempName: any = ref('');
const isSwitchingModal = ref(false);
const router = useRouter();
const selectedItem: any = ref(0);
const selectedTempItem: any = ref({});
const isModalOpen = ref(false);
const emits = defineEmits(['close', 'fetch', 'applied', 'dismiss', 'charTemp', 'loadPage']);
const selectedTemplateId: any = ref('');
const selectedEditTemplateId: any = ref('');
const route = useRoute();
const selectedTempName: any = ref('');
const customize = toRef(props, 'customize');
const windowHeight = ref('0px');
const text = ref('');
const tempres = ref([]);
const { width } = useWindowSize();

const getUsedCharactersInTemplates = async (value: any) => {
  if (!isEmpty(value) && !get(selectedTemp.value, 'iconDel')) {
    const res = await getTemplateUsedByOtherChar(value);
    tempres.value = res.results;
  }
};
const init = () => {
  const input = inputField.value as any;
  if (input) {
    const inputElWrapper = input.$el as any;
    const inputEl = inputElWrapper.children[0] as any;
    inputEl.focus();
  }
};

watch(
  () => props.isOpen,
  async () => {
    if (isOpen.value) {
      nextTick(() => {
        document
          .getElementById(`template-${get(customizeCharacter.value, 'template_applied')}`)
          ?.scrollIntoView({ behavior: 'auto', block: 'nearest' });
      });
      setTimeout(() => {
        init();
      }, 300);
      if (!isEmpty(template.value)) {
        // selectedItem.value = get(currentTemplate.value, 'id');
        let tempId = !isEmpty(get(route.query, 'tempId'))
          ? get(route.query, 'tempId')
          : !isEmpty(get(customizeCharacter.value, 'template_applied'))
          ? get(customizeCharacter.value, 'template_applied')
          : get(currentTemplate.value, 'id');

        if (isEmpty(tempId) && template.value?.length === 1) {
          tempId = get(template.value?.[0], 'id');
        }
        getUsedCharactersInTemplates(tempId);
        const currentTemp = find(template.value, (temp: any) => temp.id === tempId);
        selectedItem.value = selectedTemplateId.value = currentTemp?.id;
        selectedTempItem.value = currentTemp;
        selectedTempName.value = get(currentTemp, 'name');
      }
    }
  }
);

const loadMore = async () => {
  await requestLoadMoreTemplate();
};
const selectedTemp = computed(() => {
  if (!isEmpty(template.value)) {
    const temp = find(template.value, (temp: any) => temp.id === selectedItem.value);
    return temp;
  }
});

const hideTempsList = computed(() => {
  return (
    (isEmpty(template.value) && !isSearching.value) ||
    (!isSearching.value &&
      isEmpty(template.value) &&
      (!isNewCustomizationTemplate.value || isNewCustomizationTemplate.value)) ||
    (!isEmpty(template.value) && isNewCustomizationTemplate.value) ||
    (!isEmpty(template.value) && template.value!.length <= 1 && !isSwitch.value)
  );
});

watch(selectedTemp, async () => {
  const usedtempIds = template.value!.length === 1 ? get(template.value?.[0], 'id') : get(selectedTemp.value, 'id');
  getUsedCharactersInTemplates(usedtempIds);
});

const displayedChars = computed(() => {
  return tempres.value.slice(0, 4);
});
const hiddenChars = computed(() => {
  if (tempres.value.length >= 4) return tempres.value.slice(4);
});

const modalName = computed(() => {
  return 'Edit Template Name';
});
const onSearch = () => {
  isSearching.value = true;
  searchTextChanged();
};

const getTemplateId = computed(() => {
  return isEditPopover.value ? selectedEditTemplateId.value : selectedTemplateId.value;
});

const searchTextChanged = debounce(async () => {
  await loadCharacterTemplate(text.value);
  if (isEmpty(text.value)) isSearching.value = false;
}, 100);

const setTopSectionBackground = computed(() => {
  return !isEmpty(template.value) && template.value?.length === 1 && !isNewCustomizationTemplate.value
    ? get(template.value[0], 'data.theme.backgrounds.topSection')
    : !isEmpty(template.value) && isNewCustomizationTemplate.value
    ? '#E6E6E6'
    : !isEmpty(template.value) &&
      (!isEmpty(get(customize.value, 'template_applied')) || !isEmpty(selectedTemplateId.value))
    ? get(selectedTemp.value, 'data.theme.backgrounds.topSection')
    : get(customize.value, 'data.theme.backgrounds.topSection');
});

const updatedEditTemplate = async () => {
  closeModal();
  await loadCharacterTemplate();
};

const fetchTemplate = async (tempid: any) => {
  closeModal();
  close();
  await loadCharacterTemplate();
  const routeName = route.name === 'character-stylize' ? 'character-stylize' : 'character-draft-stylize';
  router.replace({
    name: routeName,
    params: { id: id.value },
    query: { tempId: tempid, tab: 'theme' },
  });
  await loadTemplate(tempid);
};
const getTemplates = computed(() => {
  if (template.value) {
    return isSwitch.value
      ? [{ id: nanoid(8), name: 'No Template', iconDel: true, data: {} }, ...template.value]
      : template.value;
  }
});

const close = () => {
  if (text.value) {
    text.value = '';
    onSearch();
    isSearching.value = false;
  }
  isNewCustomizationTemplate.value = false;
  emits('close');
};

const duplicateTemplate = async (tempid: any) => {
  const payload = {
    template_id: tempid,
  };
  await duplicateCharacterTemplate(payload);
  await loadCharacterTemplate();
};
const getQuoteFont = computed(() => {
  if (!isEmpty(template.value)) {
    return get(selectedTemp.value, 'data.theme.text.quote.font');
  }
});
const getQuoteColor = computed(() => {
  return !isEmpty(template.value) && template.value?.length === 1 && !isNewCustomizationTemplate.value
    ? get(template.value[0], 'data.theme.text.quote.color')
    : !isEmpty(template.value) &&
      (!isEmpty(get(customize.value, 'template_applied')) || !isEmpty(selectedTemplateId.value))
    ? get(selectedTemp.value, 'data.theme.text.quote.color')
    : get(customize.value, 'data.theme.text.quote.color');
});

const applyTemp = async () => {
  isSubmitting.value = true;

  try {
    const res = await postCharacterStylizeTemplate({
      name: name.value,
      data: customize.value.data,
    });
    if (isEmpty(template.value)) {
      const payload = {
        entity_id: customizeCharacter.value?.entity_id,
        entity_type: get(customizeCharacter.value, 'entity.type'),
        applied_template: res.id,
        data: customize.value.data,
      };
      await applyCharacterStylizeTemplate(payload);

      const temp = {
        data: customizeCharacter.value?.data,
      };
      updatePreviousCustomize({
        previousCustomizeData: temp,
        isUndo: false,
      });
      updatePreviousTemplate({
        previousTempData: temp,
      });
    }

    emits('applied', 'temp');
  } catch (_err) {
    await toast.show('Some error has occurred. Please try again later.', 'nonative', 'danger');
    close();
  }

  isSubmitting.value = false;
};

const isEmptyTemplateAndText = computed(() => {
  return isEmpty(template.value) && isEmpty(text.value);
});

const stylizeCharacterTitle = computed(() => {
  return (isEmpty(template.value) && isNewCustomizationTemplate.value) || (!isEmpty(template.value) && !isSwitch.value)
    ? `Let's Customize ${get(customizeCharacter.value, 'entity.info.name')}!`
    : isEmptyTemplateAndText.value
    ? 'Save Changes to Apply'
    : 'Switch Template';
});

const stylizeCharacterSubtitle = computed(() => {
  return isEmpty(template.value) && isNewCustomizationTemplate.value
    ? 'Name Template to continue.'
    : !isEmpty(template.value) && isNewCustomizationTemplate.value
    ? `You'll need a template to save this customization.`
    : isEmptyTemplateAndText.value
    ? 'To complete customization, save to a Template.'
    : '';
});
const getCharNameBold = computed(() => {
  return get(currentTemplate.value, 'data.theme.text.charName.bold') ? '800' : '100';
});
const getQuoteBold = computed(() => {
  return get(currentTemplate.value, 'data.theme.text.quote.bold') ? '800' : '100';
});
const getQuoteItalic = computed(() => {
  return get(currentTemplate.value, 'data.theme.text.quote.italics') ? 'italic' : 'normal';
});
const getCharNameItalic = computed(() => {
  return get(currentTemplate.value, 'data.theme.text.charName.italics') ? 'italic' : 'normal';
});
const getCharNameColor = computed(() => {
  return !isEmpty(template.value) && template.value?.length === 1 && !isNewCustomizationTemplate.value
    ? get(template.value[0], 'data.theme.text.charName.color')
    : !isEmpty(template.value) &&
      (!isEmpty(get(customize.value, 'template_applied')) || !isEmpty(selectedTemplateId.value))
    ? get(selectedTemp.value, 'data.theme.text.charName.color')
    : get(customize.value, 'data.theme.text.charName.color');
});
const getDefaultFontColor = computed(() => {
  return !isEmpty(template.value) && template.value?.length === 1 && !isNewCustomizationTemplate.value
    ? get(template.value[0], 'data.theme.text.default.color')
    : !isEmpty(template.value) &&
      (!isEmpty(get(customize.value, 'template_applied')) || !isEmpty(selectedTemplateId.value))
    ? get(selectedTemp.value, 'data.theme.text.default.color')
    : get(customize.value, 'data.theme.text.default.color');
});
const titlesTextColor = computed(() => {
  return !isEmpty(template.value) && template.value?.length === 1 && !isNewCustomizationTemplate.value
    ? get(template.value[0], 'data.theme.text.titles.color')
    : !isEmpty(template.value) &&
      (!isEmpty(get(customize.value, 'template_applied')) || !isEmpty(selectedTemplateId.value))
    ? get(selectedTemp.value, 'data.theme.text.titles.color')
    : get(customize.value, 'data.theme.text.titles.color');
});

const applyTemplate = async () => {
  if (isEmpty(selectedTempItem.value)) return;
  isApplying.value = true;
  try {
    const payload = {
      entity_id: get(customizeCharacter.value, 'entity_id'),
      entity_type: get(customizeCharacter.value, 'entity.type'),
      data: get(selectedTemp.value, 'data'),
      applied_template: get(selectedTempItem.value, 'iconDel') ? null : selectedTemplateId.value,
    };
    await applyCharacterStylizeTemplate(payload);
    isSwitch.value ? emits('applied', 'design') : emits('close', (isHeader.value = true));
  } catch (_err) {
    await toast.show('Some error has occurred. Please try again later.', 'nonative', 'danger');
    close();
  }
  isApplying.value = false;
};

const closeUpdatedModal = () => {
  isUpdateCharModal.value = false;
  selectedItem.value = selectedTemplateId.value;
};

const closeAndFetchTemplates = async () => {
  closeUpdatedModal();
  get(customize.value, 'template_applied') === selectedDeleteTempId.value
    ? emits('loadPage')
    : await loadCharacterTemplate();
};
const openNewModal = async () => {
  selectedItem.value = null;
  selectedTemplateId.value = null;
  const res = await postCharacterStylizeTemplate({
    name: 'New Template',
  });
  await loadCharacterTemplate();
  selectedItem.value = selectedTemplateId.value = res.id;
  selectedTempName.value = res.name;

  nextTick(() => {
    document.getElementById(`template-${selectedItem.value}`)?.scrollIntoView({ behavior: 'auto', block: 'nearest' });
  });
  isModalOpen.value = true;
};

const closeModal = () => {
  isModalOpen.value = false;
};
const dropdownToggle = async (ev: CustomEvent, tempid: any) => {
  ev.stopPropagation();
  const tempName = find(template.value, (temp: any) => temp.id === tempid);
  selectedTempName.value = get(tempName, 'name');

  const popover = await popovers.open(ev, CustomizeTemplatePopover, { id: tempid });
  try {
    const { data } = await popover.onDidDismiss();
    if (!isEmpty(data.value)) {
      if (data.value === 'edit') {
        selectedEditTemplateId.value = tempid;
        isModalOpen.value = true;
        isEditPopover.value = true;
      } else if (data.value === 'duplicate') {
        duplicateTemplate(tempid);
      } else {
        selectedDeleteTempId.value = tempid;
        isUpdateCharModal.value = true;
      }
    }
  } catch (e) {}
};

const setBackgroundColor = computed(() => {
  return !isEmpty(template.value) && template.value?.length === 1 && !isNewCustomizationTemplate.value
    ? get(template.value[0], 'data.theme.backgrounds.page')
    : !isEmpty(template.value) && isNewCustomizationTemplate.value
    ? '#F3F3F3'
    : !isEmpty(template.value) &&
      (!isEmpty(get(customize.value, 'template_applied')) || !isEmpty(selectedTemplateId.value))
    ? get(selectedTemp.value, 'data.theme.backgrounds.page')
    : get(customize.value, 'data.theme.backgrounds.page');
});

const dark = computed(() => {
  const { dark } = mainStore();
  return dark.value;
});
const user = computed(() => {
  try {
    const { user } = authStore();
    return user.value;
  } catch (error) {
    return false;
  }
});

const setSectionColor = computed(() => {
  return !isEmpty(template.value) && template.value?.length === 1
    ? get(template.value[0], 'data.theme.backgrounds.infoSection')
    : !isEmpty(template.value) && isNewCustomizationTemplate.value
    ? '#E6E6E6'
    : !isEmpty(template.value) &&
      (!isEmpty(get(customize.value, 'template_applied')) || !isEmpty(selectedTemplateId.value))
    ? get(selectedTemp.value, 'data.theme.backgrounds.infoSection')
    : get(customize.value, 'data.theme.backgrounds.infoSection');
});
const isNotEmptyCustomizationTemplate = computed(() => {
  return (
    !isNewCustomizationTemplate.value && ((isEmpty(template.value) && isSearching.value) || !isEmpty(template.value))
  );
});
const defaultTextColor = computed(() => {
  return !isEmpty(template.value) && template.value?.length === 1
    ? get(template.value[0], 'data.theme.text.default.color')
    : !isEmpty(template.value) && isNewCustomizationTemplate.value
    ? '#000000'
    : !isEmpty(template.value) &&
      (!isEmpty(get(customize.value, 'template_applied')) || !isEmpty(selectedTemplateId.value))
    ? get(selectedTemp.value, 'data.theme.text.default.color')
    : get(customize.value, 'data.theme.text.default.color');
});
const defaultTextFont = computed(() => {
  return !isEmpty(template.value) && template.value?.length === 1
    ? get(template.value[0], 'data.theme.text.default.font')
    : !isEmpty(template.value) && isNewCustomizationTemplate.value
    ? 'comic sans'
    : !isEmpty(template.value) &&
      (!isEmpty(get(customize.value, 'template_applied')) || !isEmpty(selectedTemplateId.value))
    ? get(selectedTemp.value, 'data.theme.text.default.font')
    : get(customize.value, 'data.theme.text.default.font');
});
const selectedTempdefaultBold = computed(() => {
  return get(selectedTemp.value, 'data.theme.text.default.bold') ? 'bold' : 'normal';
});
const appliedTempdefaultBold = computed(() => {
  return get(customize.value, 'data.theme.text.default.bold') ? 'bold' : 'normal';
});

const defaultTextBold = computed(() => {
  return !isEmpty(template.value) && isNewCustomizationTemplate
    ? 'true'
    : !isEmpty(template.value) &&
      (!isEmpty(get(customize.value, 'template_applied')) || !isEmpty(selectedTemplateId.value))
    ? selectedTempdefaultBold.value
    : appliedTempdefaultBold.value;
});

const appliedDefaultItalic = computed(() => {
  return get(customize.value, 'data.theme.text.default.italics') ? 'italic' : 'normal';
});
const selectedDefaultItalic = computed(() => {
  return get(customize.value, 'data.theme.text.default.italics') ? 'italic' : 'normal';
});

const defaultNoTempColor = computed(() => {
  return dark.value ? '#fff' : '#214163';
});
const defaultTextItalic = computed(() => {
  return !isEmpty(template.value) && isNewCustomizationTemplate.value
    ? 'false'
    : !isEmpty(template.value) &&
      (!isEmpty(get(customize.value, 'template_applied')) || !isEmpty(selectedTemplateId.value))
    ? appliedDefaultItalic.value
    : selectedDefaultItalic.value;
});
const sectionTitlesColor = computed(() => {
  return !isEmpty(template.value) &&
    (!isEmpty(get(customize.value, 'template_applied')) || !isEmpty(selectedTemplateId.value))
    ? get(selectedTemp.value, 'data.theme.text.titles.color')
    : get(customize.value, 'data.theme.text.titles.color');
});

const getButtonColor = computed(() => {
  return !isEmpty(template.value) && template.value?.length === 1 && !isNewCustomizationTemplate.value
    ? get(template.value[0], 'data.theme.backgrounds.buttons')
    : !isEmpty(template.value) && isNewCustomizationTemplate.value
    ? 'var(--ion-color-primary)'
    : !isEmpty(template.value) &&
      (!isEmpty(get(customize.value, 'template_applied')) || !isEmpty(selectedTemplateId.value))
    ? get(selectedTemp.value, 'data.theme.backgrounds.buttons')
    : get(customize.value, 'data.theme.backgrounds.buttons');
});
const nextPageExists = computed(() => {
  return !!get(paging.value, 'next');
});

const isSelectedTemplate = (templateId: any) => {
  return templateId === selectedTemplateId.value;
};

const changeTemp = (temp: any, index: any) => {
  selectedTemplateId.value = temp.id;
  selectedItem.value = temp.id;
  selectedTempItem.value = temp;
};
const getRoute = () => {
  emits('close');
  const queryVal = template.value?.length === 1 ? get(template.value[0], 'id') : get(selectedTemp.value, 'id');
  (route.name === 'character-profile-draft-new' || route.name === 'char-draft-details') && isSwitch.value
    ? router.push({ name: 'character-draft-stylize', params: { id: id.value }, query: { tab: 'theme' } })
    : (route.name === 'character-profile-new' || route.name === 'character-profile') && isSwitch.value
    ? router.push({ name: 'character-stylize', params: { id: id.value }, query: { tab: 'theme' } })
    : route.name === 'character-profile-draft-new' || route.name === 'char-draft-details'
    ? router.push({
        name: 'character-draft-stylize',
        params: { id: customizeCharacter.value?.entity_id },
        query: { tempId: queryVal, tab: 'theme' },
      })
    : route.name === 'character-profile-new' || route.name === 'character-profile'
    ? router.push({
        name: 'character-stylize',
        params: { id: customizeCharacter.value?.entity_id },
        query: { tempId: queryVal, tab: 'theme' },
      })
    : '';
};
const fetchTemplateDetails = async () => {
  if (
    isSwitch.value &&
    !isEmpty(template.value) &&
    (!isEmpty(selectedTemplateId.value) || !isEmpty(customize.value.template_applied)) &&
    !isSwitchingModal.value
  ) {
    const val = selectedTemplateId.value || get(customize.value, 'template_applied');
    // changeTemplateName(selectedTempName.value);
    const routeName = route.name === 'character-stylize' ? 'character-stylize' : 'character-draft-stylize';
    router.replace({
      name: routeName,
      params: { id: id.value },
      query: { tempId: selectedTemplateId.value, tab: 'theme' },
    });
    await loadTemplate(val);
  }
  getRoute();
};

const closeSwitchModal = async () => {
  isSwitchingModal.value = false;
  fetchTemplateDetails();
};

const editNewTemp = async () => {
  isEditing.value = true;
  try {
    const res = await postCharacterStylizeTemplate({
      name: name.value,
      data: customize.value.data,
    });
    route.name === 'character-profile-draft-new' || route.name === 'char-draft-details'
      ? router.push({
          name: 'character-draft-stylize',
          params: { id: customizeCharacter.value?.entity_id },
          query: { tempId: res.id, tab: 'theme' },
        })
      : route.name === 'character-profile-new' || route.name === 'character-profile'
      ? router.push({
          name: 'character-stylize',
          params: { id: customizeCharacter.value?.entity_id },
          query: { tempId: res.id, tab: 'theme' },
        })
      : '';

    emits('close');

    isEditing.value = false;
    setTimeout(async () => {
      if (width.value < 1185) await menuController.open('end');
    }, 100);
  } catch (_err) {
    await toast.show('Some error has occurred. Please try again later.', 'nonative', 'danger');
    isEditing.value = false;
  }
};

const openStylize = async () => {
  if (isEmpty(selectedTemp.value)) return;
  if (isSwitch.value) {
    const prevSelctedTemp = !isEmpty(get(route.query, 'tempId'))
      ? template.value?.find((temp: any) => temp.id === get(route.query, 'tempId'))
      : template.value?.find((temp: any) => temp.id === customizeCharacter.value!.template_applied);
    prevSelectedTempName.value = prevSelctedTemp?.name;
    prevSelectedTempId.value = prevSelctedTemp?.id;
    if (
      JSON.stringify(get(currentTemplate.value, 'data.theme')) !== JSON.stringify(get(prevSelctedTemp, 'data.theme')) &&
      (!isEmpty(get(route.query, 'tempId')) ||
        (customizeCharacter.value!.template_applied === prevSelctedTemp?.id &&
          selectedTemplateId.value !== prevSelctedTemp?.id))
    ) {
      isSwitchingModal.value = true;
      return;
    }

    if (!isEmpty(selectedTemplateId.value)) {
      const currentTempName = find(template.value, (temp: any) => temp.id === selectedTemplateId.value);
      selectedTempName.value = get(currentTempName, 'name');
    }
    getUsedCharTemplates(selectedTemplateId.value);
  }
  fetchTemplateDetails();
  updateProfileTryOnAnimation({});
  setTimeout(async () => {
    if (width.value < 1185) await menuController.open('end');
  }, 500);
};

const isMobSmallScreen = computed(() => {
  return windowWidth.value <= 800;
});

const isSmallScreen = computed(() => {
  return windowWidth.value <= 800;
});

const getUsedCharTemplates = async (value: any) => {
  const res = await getTemplateUsedByOtherChar(value as string);
  getUsedCharTemp.value = res.results;
  emits('charTemp', getUsedCharTemp.value);
};
const actionClicked = () => {
  isSwitch.value ? openNewModal() : (isNewCustomizationTemplate.value = true);
};

onMounted(async () => {
  windowHeight.value = `${window.innerHeight}px`;
  window.addEventListener('resize', handleResize);
});

const handleResize = () => {
  windowWidth.value = window.innerWidth;
  windowHeight.value = `${window.innerHeight}px`;
};
onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<style lang="sass" scoped>
.spacing
  grid-gap: 1px
.small-char-button
  --background: v-bind(getCharNameColor)
  overflow: hidden
  height: 4px
  --border-radius: 0px
  margin-top: 0px
.small-quote-button
  --background: v-bind(getQuoteColor)
  overflow: hidden
  height: 4px
  --border-radius: 0px
  margin-top: 0px
.small-applied-button
  --background: v-bind(getButtonColor)
  overflow: hidden
  height: 4px
  --border-radius: 0px
  margin-top: 0px
.title-button
  --background: v-bind(getButtonColor)
  overflow: hidden
  height: 4px
  --border-radius: 0px
  margin-top: 1px
.small-default-button
  --background: v-bind(getButtonColor)
  overflow: hidden
  height: 4px
  --border-radius: 0px
  margin-top: 0px
.set-customize-btn
  @media(min-width:800px)
    margin-top: -8px
.set-reuse-btn
  margin-top: 30px
.set-new-height
  height: 30px
  @media(max-width:800px)
    height: 27px !important
.hide-app-btn
  display: none !important
.used-char
  @media(max-width:800px)
    max-width: calc(75% + 38px)
    min-width: calc(75% + 54px)
.set-small-height
  @media(min-width:800px) and (max-height:480px)
    margin-top: 27px
.set-search-margin
  margin-top: -11px !important
  @media(max-width:480px)
    margin-top: 0px !important
.char-box
  max-width: 300px
  min-width: 300px
  margin: 0 auto
  @media(max-width:800px)
    max-width: 95px !important
    min-width: 95px !important

.switch-btn
  color: white
  --border-radius: 20px
  height: 30px

.content-id
  display: flex
  flex-wrap: wrap
  img
    width: 25%
    min-width: 40px
.change-direction
  flex-direction: row
  display: flex
  gap: 17px
.save-btn
  --background: var(--ion-color-primary)
  --border-radius: 20px
.change-color
  color: #8b8b8e !important
.dark .chat-box
  color: #fff !important
.chat-box
  color: black
  pointer-events: auto
  margin: 0 !important
  ::v-deep
    textarea
      margin-left: 10px !important
    .textarea-highlight
      display: none !important
.msg-content
  width: 100%
  .info-content
    font-size: 14px
    background: #eaecf680
    border-radius: 7px
.dark .info-content
  background: #17074C !important
.set-scroller
  overflow: auto !important
.set-card-width
  @media(min-width:330px) and (max-width:400px)
    max-width: 236px
    min-width: 174px
    width: 270px
    min-height: 114px
    max-height: 114px

.set-card-border
  border: 3px solid lightgray
  border-radius: 10px
  height: auto
.hide
  display: none !important
.rolechat
  min-width: 33%
  width: 33%
.reaction
  min-width: 33%
  width: 33%
  display: inline-block !important
  ::v-deep
    .like-btn
      width: 100% !important
    ion-button
      --border-radius: 8px

.template-content
  overflow: auto
  flex-grow: 1
  color: black

.dark .load-text
  color: white !important
.section-text
  color: v-bind(defaultTextColor)
  font-weight: v-bind(defaultTextBold)
  font-family: v-bind(defaultTextFont)
  font-style: v-bind(defaultTextItalic)
.section-title
  color: v-bind(sectionTitlesColor)
.set-margin
  @media(max-width:800px)
    margin-inline: 0px !important

.info-section
  background: v-bind(setSectionColor)
  border-radius: 10px
.info-app-section
  max-width: 300px
  min-width: 300px
  margin: 0 auto
  background: v-bind(setSectionColor)
  border-radius: 10px
  @media(max-width:800px)
    max-width: 95px
    min-width: 95px

.dark .info-section
  background: v-bind(setSectionColor)
.set-bg-color
  background: v-bind(setBackgroundColor)
  border-radius: 10px
  @media(min-width:301px)
    padding-bottom: 8px !important
  @media(max-width:300px)
    height: 117px !important
.dark .set-bg-color
  background: v-bind(setBackgroundColor) !important

.cancel-btn
  --background: #ffcad6
  color: black
  --border-radius: 20px
.apply-btn
  --background: var(--ion-color-primary)
  color: white
  --border-radius: 20px

.selected-bg
  --background: #41476c !important
  --color: white !important
  color: white !important
.set-border
  --border-radius: 20px
.selected-icon
  color: white
.not-selected-icon
  color: #545454
.not-selected-bg
  --background: #eaecf6
.stylize-character
  display: flex
  flex-direction: column
  max-height: calc(v-bind(windowHeight) - 200px) !important
  @media(max-width: 800px)
    max-height: calc(v-bind(windowHeight) - 250px) !important

.character-card
  color: black
  @media(max-height: 480px)
    display: none !important
.text
  font-weight: normal
  font-size: 16px
.content
  max-width: 700px
  .description
    font-size: 14px
    background: #F2F2F2
    border-radius: 7px
.dark .description
  background: #060606
.img
  max-width: 100% !important
  height: auto !important
.fanart-img
  max-width: 100% !important
  max-height: 400px

.content
  max-width: 700px
  .fanwork-content
    background: #F2F2F2
    border-radius: 7px
.dark .fanwork-content
  background: #060606
.close-button
  width: 50px
.yay-btn
  width: 120px
.note-text
  font-size: 18px
  font-style: italic
.text
  font-size: 16px
.title
  font-weight: bold
  font-size: 22px
.info-title
  font-weight: bold
  font-size: 18px
  color: v-bind(titlesTextColor)
  @media(max-width:1000px)
    font-size: 16px
.info-des
  color: v-bind(getDefaultFontColor)
  font-size: 14px

.sub-title
  font-size: 16px

.modal-big
  --width: 960px
  --height: auto
  @media(max-width: 576px)
    --width: 100%

.inner-content
  background: var(--ion-background-color, #fff)
  max-height: 96vh
  max-height: 96dvh
  @media(max-width:1460px)
    overflow: auto !important
  @media(max-width:600px)
    max-height: 99vh
    max-height: 99dvh

.button-container
  margin: 0 auto
  display: flex
  flex-direction: column
  align-items: center
  max-width: 200px
  pointer-events: none
  ::v-deep
    ion-button
      --ion-color-secondary: #328FD2
      --ion-color-secondary-rgb: 80,200,255
      --ion-color-secondary-contrast: #ffffff
      --ion-color-secondary-contrast-rgb: 255,255,255
      --ion-color-secondary-shade: #2d81bd
      --ion-color-secondary-tint: #479ad7
.wide-button
  width: 82%
  height: 14px
  --background: v-bind(getButtonColor)
  color: #fff
  border: none
  border-radius: 8px
  margin-bottom: 15px
  text-transform: unset
  font-size: 18px
  --border-radius: 8px
  @media(max-width:1000px)
    height: 11px
.button-row
  display: flex
  justify-content: space-between
  width: 100%
  &:first-child
    margin-left: -2px !important
.button-row-2
  display: flex
  justify-content: space-between
  width: 100%
  gap: 0
.small-button
  display: inline-block !important
  width: 33%
  height: 11px
  color: #fff
  font-size: 16px
  border: none
  border-radius: 8px
  --border-radius: 8px
  @media(max-width:1000px)
    height: 11px

@media(max-width: 800px)
  .character-profile-header
    .cover
      height: 15px !important
    .name
      font-size: 16px !important

.character-profile-header
  color: black
  border-radius: 20px
  overflow: hidden
  .cover
    height: 39px
    overflow: hidden
  .rest
    text-align: center
    background: v-bind(setTopSectionBackground)

  .quote
    margin-top: -7px
    font-weight: v-bind(getQuoteBold)
    font-size: 14px
    font-style: v-bind(getQuoteItalic)
    font-family: v-bind(getQuoteFont)
    color: v-bind(getQuoteColor)
  .name
    font-size: 18px
    margin: 9px !important
    font-weight: v-bind(getCharNameBold)
    font-style: v-bind(getCharNameItalic)
    color: v-bind(getCharNameColor)
.cover
  background-color: v-bind(getButtonColor)
.dark .cover
  background-color: v-bind(getButtonColor)

ion-item.item-radio-checked::part(container)
  background: var(--ion-color-primary)

ion-item.item-radio-checked::part(mark)
  background: var(--ion-color-primary)
.dark .selected-bg
  color: white !important
.dark .not-selected-bg
  color: black
.dark ion-radio::part(container)
  border: 2px solid #222428 !important
.dark .rest
  background: v-bind(setTopSectionBackground) !important
.continue-btn
  --background: #00b4c5
  color: white
  --border-radius: 10px
.name-temp-btn
  --background: #606581
  color: white
  --border-radius: 20px
ion-item::part(native)
  min-height: 25px !important
</style>
