<template>
  <div class="text">
    <ion-reorder-group v-if="ready" :disabled="false" @ionItemReorder="handleReorder($event)">
      <div v-for="(element, index) of mutatedMusic" :key="index" class="d-flex align-items-center mt-2 w-100 one-row">
        <ion-reorder slot="end" class="reorder-desktop">
          <inline-svg id="about-drag-handle" class="clickable-item-hov mx-2 about-drag-icon" src="/drag.svg" />
          <!-- <i id="about-drag-handle" class="ti-menu clickable-item-hov mx-2 about-drag-icon" /> -->
        </ion-reorder>
        <ReorderMobile :index="index" :total="mutatedMusic.length" class="reorder-mobile" @reorder="handleReorder" />
        <div class="w-100">
          <div class="d-flex align-items-center">
            <div class="w-100">
              <div
                class="d-flex align-items-center clickable-item-hov"
                v-if="!isOpeningLabelField && !get(element, 'label')"
                style="padding: 0.75rem"
                @click="labelAction"
              >
                <inline-svg src="/pencil.svg" class="mr-1 pencil-svg" />
                <div class="text-primary">Label the song - e.g., "Theme Song" (optional)</div>
              </div>
              <div v-else>
                <ion-input
                  class="c-input one-input mx-2"
                  :value="element.label"
                  v-model="element.label"
                  placeholder="Label the song"
                  @ionBlur="updatedLink(index, $event, 'label')"
                />
              </div>
              <ion-input
                class="c-input one-input mx-2"
                :value="element.link"
                v-model="element.link"
                :placeholder="placeholder"
                @ionBlur="
                  updatedLink(index, $event, 'link');
                  handleLinkValidation(index, element.link);
                "
              />
              <div v-if="element.embedded" class="embed-container mt-2">
                <iframe
                  v-if="element.link.includes('youtube') || element.link.includes('youtu.be')"
                  :src="`https://www.youtube.com/embed/${extractYouTubeID(element.link)}?rel=0&fs=0`"
                  style="border-radius: 8px"
                  class="mb-2"
                  width="98%"
                  height="120"
                  title="YouTube player"
                  frameborder="0"
                  donotallowfullscreen
                  allow="clipboard-write; encrypted-media; web-share"
                ></iframe>
                <iframe
                  v-else-if="element.link.includes('spotify') && !element.link.includes('track')"
                  :src="`https://open.spotify.com/embed/playlist/${extractSpotifyID(
                    element.link
                  )}?utm_source=generator`"
                  style="border-radius: 8px"
                  width="98%"
                  height="80"
                  title="YouTube player"
                  frameborder="0"
                  donotallowfullscreen
                  allow="clipboard-write; encrypted-media; web-share"
                ></iframe>
                <iframe
                  v-else-if="element.link.includes('spotify')"
                  :src="`https://open.spotify.com/embed/track/${extractSpotifyID(element.link)}`"
                  style="border-radius: 8px"
                  width="98%"
                  height="80"
                  title="YouTube player"
                  frameborder="0"
                  donotallowfullscreen
                  allow="clipboard-write; encrypted-media; web-share"
                ></iframe>
                <iframe
                  v-if="element.link.includes('soundcloud')"
                  :src="`https://w.soundcloud.com/player/?url=${encodeURIComponent(
                    element.link
                  )}&color=%23ff5500&auto_play=false&show_user=true`"
                  style="border-radius: 8px"
                  class="mb-2"
                  width="98%"
                  height="120"
                  title="SoundCloud player"
                  frameborder="0"
                  allow="autoplay"
                ></iframe>
              </div>
            </div>
          </div>
        </div>

        <ion-button class="inline-button clickable-item-hov" color="transparent" @click="deleteMusicField(index)">
          <inline-svg src="/trash.svg" />
        </ion-button>
      </div>
    </ion-reorder-group>
    <ion-custom-button class="add-btn text-white w-100 mt-2" @click.self="addMusicField">Add More</ion-custom-button>
  </div>
</template>
<script lang="ts" setup>
import ReorderMobile from './ReorderMobile.vue';
import { storyWorldCreatorStore } from '@/shared/pinia-store/storyworld-creator';
import { isValidMusicURL } from '@/shared/utils/string';
import { toast } from '~/shared/native';

const { changeExtraKey } = storyWorldCreatorStore();
const router = useRouter();
const props = defineProps({
  usedKey: {
    type: String,
    default: '',
  },
  value: {
    type: String,
    default: {},
  },
  isDragging: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: {},
  },
});
const ready = ref(true);
const music = toRef(props, 'value');
const usedKey = toRef(props, 'usedKey');
const mutatedMusic = ref([] as any);
const isOpeningLabelField = ref(false);
const mutate = () => {
  changeExtraKey(usedKey.value, mutatedMusic.value);
  ready.value = false;
  nextTick(() => {
    ready.value = true;
  });
};
const deleteMusicField = (index: number) => {
  mutatedMusic.value?.splice(index, 1);
  mutate();
};

const labelAction = () => {
  isOpeningLabelField.value = true;
};

const handleReorder = (ev: CustomEvent) => {
  const { from, to } = ev.detail;
  mutatedMusic.value.splice(to, 0, mutatedMusic.value.splice(from, 1)[0]);
  mutate();
  ev.detail.complete();
  ev.stopPropagation();
  ready.value = false;
  nextTick(() => {
    ready.value = true;
  });
};

const addMusicField = () => {
  mutatedMusic.value.push({ label: '', link: '' });
  mutate();
};
onMounted(() => {
  mutatedMusic.value = map(music.value, (item: any, index) => {
    return { label: item.label, link: item.link };
  });

  ready.value = false;
  nextTick(() => {
    ready.value = true;
  });
});

const updatedLink = (index: number, event: any, text: any) => {
  mutatedMusic.value[index][text] = event.target.value;
  mutate();
};

const normalizeURL = (url: string): string => {
  if (url.includes('youtu.be')) {
    return url.replace('youtu.be/', 'www.youtube.com/watch?v=');
  }
  if (url.includes('m.youtube.com')) {
    return url.replace('m.youtube.com', 'www.youtube.com');
  }
  if (url.includes('on.soundcloud')) {
    return '';
  }
  return url;
};

const handleLinkValidation = (index: number, url: string) => {
  if (isValidMusicURL(normalizeURL(url))) {
    mutatedMusic.value[index].embedded = true;
  } else if (url !== '') {
    mutatedMusic.value[index].embedded = false;
    if (url.includes('on.soundcloud')) {
      toast.show('Shortened SoundCloud links are not supported. Please use the full link.', 'nonative', 'primary');
    } else {
      toast.show('Invalid link. Please enter a valid YouTube, Spotify, or SoundCloud link.', 'nonative', 'primary');
    }
    mutatedMusic.value[index].link = '';
  }
};

const extractYouTubeID = (url: string): string => {
  const normalizedURL = normalizeURL(url);
  const match = normalizedURL.match(/(?:youtube\.com\/.*v=|youtu\.be\/)([^&]+)/);
  return match ? match[1] : '';
};

const extractSpotifyID = (url: string): string => {
  if (url.includes('playlist')) {
    const match = url.match(/spotify\.com\/playlist\/([^?]+)/);
    return match ? match[1] : '';
  }
  const match = url.match(/spotify\.com\/track\/([^?]+)/);
  return match ? match[1] : '';
};
</script>

<style lang="sass" scoped>
.pencil-svg
  width: 18px
  height: 18px
  font-size: 18px
.close-btn
  width: 90%
  position: absolute
  left: 0
  right: 0
  margin: auto
.reorder-mobile
  display: none !important
.reorder-desktop
  display: block
  i
    -webkit-user-drag: element
    cursor: move !important
@media(max-width: 500px)
  .reorder-desktop
    display: none !important
  .reorder-mobile
    display: block !important
.one-row
  &:not(:first-of-type)
    margin-top: 20px !important
@media(max-width: 500px)
  .reorder-desktop
    display: none !important
  .reorder-mobile
    display: block !important
  .rest
    width: 100% !important
    flex-direction: column !important
  .c-input
    margin-bottom: 0.25rem
    &:last-of-type
      margin-right: 0 !important
  .selector
    width: unset
  .d-flex.mt-2.mr-2
    margin-top: 5px !important
  .data
    width: 100% !important
    flex-direction: column !important
    .col-4, .col-6
      width: 100% !important
      flex: unset !important
      max-width: unset !important
      padding-right: 0.25rem !important
    .c-select, .c-input
      width: 100% !important
      margin-top: 5px
.add-btn
  margin-left: 32px
  width: calc(100% - 65px) !important
  ::v-deep
    .button
      height: 30px !important
      text-transform: unset !important
.c-input, .c-select
  height: 45px !important
  border-radius: 10px !important
  border: 2px solid #CCC !important
  width: unset
  ::v-deep
    input
      border: 0 !important
.option
  &:hover
    user-select: none !important
    cursor: pointer
    opacity: 0.7
    --background: #eee !important
.dark
  ion-modal
    --ion-item-background: #333538 !important
  .option
    &:hover
      --background: #666 !important
.one-input
  margin-bottom: 10px
  border-radius: 10px !important
  ::v-deep
    input, textarea
      border: 0 !important
    textarea
      padding: 12px
.key-button
  white-space: normal
.scroll
  overflow-y: scroll !important
  height: calc(100vh - 70px - var(--safe-ios-margin) - calc(var(--safe-ios-margin)/3))
.select-modal
  --width: 100% !important
  --height: 100%
  --border-radius: 0
  @media(min-width: 599px) and (min-height: 767px)
    --height: 70%
    --width: 50%
    --border-radius: 12px
</style>
