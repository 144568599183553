<template>
  <div @click="openCharacterSelectionModal">
    <slot />
    <compliment-bomb-use-and-purchase-modal
      :is-open="isOpenComplimentBombModal"
      :inventories="inventories"
      :selectedInventoryItem="selectedInventoryItem"
      :showGoBackButton="showGoBackButton"
      :purchaseMore="purchaseMore"
      @close="closePurchaseAndUseModal"
      @gifted="giftedItems"
      @updated="emits('updated')"
      @buy="emits('buy')"
      @use="isOpenCharSelectionModal = true"
    />
    <character-selection-modal
      :is-open="isOpenCharSelectionModal"
      :is-compliment-bomb="true"
      :isLoader="isLoader"
      :selectedInventoryItem="selectedInventoryItem"
      @close="closeCharSelectionModal"
      @selected="openSelectedCharacterModal"
    />
    <compliment-bombs-selected-character-modal
      :is-open="isOpenSelectedCharacterModal"
      :modal-title="modalTitle"
      :selectedInventoryItem="selectedInventoryItem"
      :section-title="sectionTitle"
      :section-description="description"
      :svg-icon="'/compliment-heart.svg'"
      :button-text="'Done'"
      @close="closeSelectedModal"
    />
  </div>
</template>

<script lang="ts" setup>
import CharacterSelectionModal from '@/shared/modals/CharacterSelectionModal.vue';
import ComplimentBombUseAndPurchaseModal from '@/shared/modals/ComplimentBombUseAndPurchaseModal.vue';
import { createComplimentBomb } from '@/shared/actions/complimentBomb';
import ComplimentBombsSelectedCharacterModal from '@/shared/modals/ComplimentBombsSelectedAndGiftedModal.vue';
import { COMPLIMENT_BOMB } from '~/shared/statics/constants';
const props = defineProps({
  inventories: {
    type: Object,
    default: {},
  },
  showGoBackButton: {
    type: Boolean,
    default: true,
  },
  character: {
    type: Object,
    default: {},
  },
  displayUseModal: {
    type: Boolean,
    default: false,
  },
  selectedInventoryItem: {
    type: Object,
    default: {},
  },
  purchaseMore: {
    type: Boolean,
    default: false,
  },
});
const isOpenCharSelectionModal = ref(false);
const selectedChar: any = ref({});
const isLoader = ref(false);
const emits = defineEmits(['updated', 'gifted', 'purchase', 'buy']);
const inventories: any = toRef(props, 'inventories');
const isOpenSelectedCharacterModal = ref(false);
const character: any = toRef(props, 'character');
const displayUseModal = toRef(props, 'displayUseModal');
const isOpenComplimentBombModal = ref(false);
const selectedInventoryItem: any = toRef(props, 'selectedInventoryItem');
const openCharacterSelectionModal = () => {
  if (displayUseModal.value) {
    isOpenComplimentBombModal.value = true;
    return;
  }

  if (isEmpty(selectedInventoryItem.value)) return;
  !isEmpty(character.value) ? postComplimentBomb() : (isOpenCharSelectionModal.value = true);
};
const giftedItems = (val: any) => {
  emits('gifted', val);
  isOpenComplimentBombModal.value = false;
};
const sectionTitle = computed(() => {
  return `SUCESSFULLY ${get(selectedInventoryItem.value, 'type') === COMPLIMENT_BOMB ? 'BOMBED' : 'FLARED'}`;
});
const closeCharSelectionModal = () => {
  isOpenCharSelectionModal.value = false;
};
const modalTitle = computed(() => {
  return `${isEmpty(character.value) ? 'Character' : get(character.value, 'info.name')} Selected`;
});

const description = computed(() => {
  return `${
    isEmpty(character.value) ? 'The Character you selected' : get(character.value, 'info.name')
  }  was <strong>${sectionTitle.value.toLowerCase()}.</strong> You should star seeing ${
    get(selectedInventoryItem.value, 'type') === COMPLIMENT_BOMB
      ? 'compliments trickle in within 48 hours of bombing.'
      : 'fan art within a few days!'
  } `;
});
const closePurchaseAndUseModal = () => {
  isOpenComplimentBombModal.value = false;
};
const openCharSelectedModal = () => {
  isOpenSelectedCharacterModal.value = true;
};
const postComplimentBomb = async () => {
  isLoader.value = true;
  const payload = {
    targeted_char: !isEmpty(character.value) ? character.value.id : selectedChar.value.id,
    type: get(selectedInventoryItem.value, 'type'),
  };
  await createComplimentBomb(payload);
  isLoader.value = false;
  closeCharSelectionModal();
  openCharSelectedModal();
};

const closeSelectedModal = () => {
  isOpenSelectedCharacterModal.value = false;
  isOpenComplimentBombModal.value = false;
  emits('updated');
};

const openSelectedCharacterModal = (data: any) => {
  selectedChar.value = data.character;
  postComplimentBomb();
};
</script>

<style lang="sass" scoped></style>
