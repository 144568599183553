<template>
  <ion-modal
    class="modal-with-content"
    :is-open="isOpen"
    :backdrop-dismiss="false"
    :show-backdrop="true"
    @didDismiss="close"
  >
    <ion-content no-bounce>
      <ion-button class="clickable-item-hov bg-transparent top-right" @click.prevent="close">
        <i class="ti-close" color="medium" />
      </ion-button>
      <div class="flex header items-center justify-between">
        <div class="text-xl font-bold">Manage Collections</div>
        <ion-button class="clickable-item-hov" color="dark" @click="createNewCollection">
          Create New Collection
        </ion-button>
      </div>
      <div class="collections-container mt-4">
        <div
          v-for="(collection, index) in localCollections"
          :key="collection.id"
          class="collection-card relative border my-2 p-3 rounded-lg shadow-sm flex flex-col"
          @click="toggleCollection(collection.id)"
        >
          <div class="card-content w-full">
            <div class="flex mt-4 items-center w-full">
              <span v-if="!collection.isEditing" class="text-sm font-bold truncate w-full">{{ collection.name }}</span>
              <input
                v-else
                v-model="collection.name"
                class="text-sm font-bold input-box w-full px-1 outline-none"
                @click.stop
                @blur="saveCollectionName(collection)"
                @keyup.enter="saveCollectionName(collection)"
              />
              <i class="ti ti-pencil w-4 h-4 cursor-pointer mr-2" @click.stop="editCollectionName(collection)" />
            </div>
          </div>
          <input
            type="checkbox"
            class="select-box mr-3"
            :checked="selectedCollections.includes(collection.id)"
            @change="toggleCollection(collection.id)"
            @click="toggleCollection(collection.id)"
          />
          <div class="drop-down" @click.stop.prevent="toggleDropDown(collection.id)">
            <i class="ti-more-alt" />
          </div>
          <div v-if="activeDropdownId === collection.id" class="drop-down-content">
            <div class="drop-down-item" @click.stop.prevent="confirmDelete(collection)">Delete Collection</div>
          </div>
        </div>
      </div>
      <div class="flex justify-center mt-4">
        <ion-button color="dark" class="close-bottom" @click="close">Close</ion-button>
      </div>
    </ion-content>
  </ion-modal>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import {
  createCollection,
  updateCollection,
  deleteCollection as deleteCollectionApi,
} from '@/shared/actions/collections';
import { toast } from '@/shared/native';
import { alertController } from '@ionic/vue';

const activeDropdownId = ref<string | null>(null);
const localCollections = ref([] as any[]);

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
  selectedCollections: {
    type: Array,
    required: true,
  },
  collections: {
    type: Array,
    required: true,
  },
});

const emits = defineEmits(['close', 'update-selected', 'update-collections']);

watch(
  () => props.collections,
  (newCollections) => {
    localCollections.value = [...newCollections];
  },
  { immediate: true }
);

const selectedCollections = ref([...props.selectedCollections]);

const close = () => emits('close');

const toggleCollection = (id: string) => {
  const index = selectedCollections.value.indexOf(id);
  if (index === -1) selectedCollections.value.push(id);
  else selectedCollections.value.splice(index, 1);
  emits('update-selected', [...selectedCollections.value]);
  toast.show('Collection updated successfully.', 'nonative', 'success');
};

const createNewCollection = async () => {
  try {
    const newCollection = await createCollection({ name: 'New Collection' });
    localCollections.value.push({ ...newCollection, isEditing: true });
    emits('update-collections', [...localCollections.value]);
    selectedCollections.value.push(newCollection.id);
    emits('update-selected', [...selectedCollections.value]);
  } catch (e) {
    toast.show('Error creating collection.', 'nonative', 'danger');
  }
};

const editCollectionName = (collection: any) => (collection.isEditing = true);

const saveCollectionName = async (collection: any) => {
  if (!collection.name.trim()) {
    toast.show('Collection name cannot be empty.', 'nonative', 'danger');
    collection.isEditing = false;
    return;
  }
  try {
    await updateCollection(collection.id, { name: collection.name });
    collection.isEditing = false;
    emits('update-collections', [...localCollections.value]);
    toast.show('Collection updated successfully.', 'nonative', 'success');
  } catch (e) {
    toast.show('Error saving collection.', 'nonative', 'danger');
  }
};

const confirmDelete = async (collection: any) => {
  const alert = await alertController.create({
    header: 'Are you sure?',
    message: 'This will permanently delete the collection.',
    buttons: [
      { text: 'Cancel', role: 'cancel' },
      {
        text: 'Delete',
        role: 'confirm',
        handler: async () => {
          try {
            await deleteCollectionApi(collection.id);
            localCollections.value = localCollections.value.filter((c) => c.id !== collection.id);
            selectedCollections.value = selectedCollections.value.filter((id) => id !== collection.id);
            emits('update-collections', [...localCollections.value]);
            emits('update-selected', [...selectedCollections.value]);

            activeDropdownId.value = null;
            toast.show('Collection deleted.', 'nonative', 'success');
          } catch (e) {
            toast.show('Error deleting collection.', 'nonative', 'danger');
          }
        },
      },
    ],
  });
  await alert.present();
};

const toggleDropDown = (id: string) => {
  activeDropdownId.value = activeDropdownId.value === id ? null : id;
};
</script>

<style lang="sass" scoped>
.panel
  font-size: 22px
  border-bottom: 2px solid rgba(33, 65, 99, .2)
  padding-bottom: 6px
  font-weight: bold
  margin: 17px 0
.badge-modal-container
  background-color: white
  width: 100%
.collection-img
  width: 80px
  height: 80px
  border-radius: 8px
  object-fit: cover
  margin-bottom: 20px
.top-right
  color: black
  position: absolute
  top: 0.5rem
  right: 0.5rem
.clickable-item-hov
  background: none
.close-bottom
  position: absolute
  bottom: 0.5rem
  width: 200px
  left: 0
  right: 0
  margin-left: auto
  margin-right: auto
.coll-row
  padding: 0.25rem
  margin: 0.5rem
.collections-container
  margin: 0 auto
  padding: 0 20%
  overflow-y: scroll
  max-height: 70%
.card-content
  padding: 0.5rem
  border-bottom: 1px solid #e2e8f0
  cursor: pointer
  &:hover
    background-color: #f7fafc
  .dark &:hover
    background-color: #17073c
  .selected-card
    background-color: #f7fafc
  .select-box
    position: absolute
    top: 2px
    right: 2px
.header
  margin-top: 1.5rem
  padding: 0 1rem
.collections-container
  margin: 0 auto
  padding: 0 1rem
  overflow-y: auto
  max-height: 70%
.collection-card
  transition: box-shadow 0.2s
  &:hover
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)
.select-box
  position: relative
.drop-down
  position: relative
  cursor: pointer
.drop-down-content
  position: absolute
  top: 16px
  right: 0
  background: white
  border: 1px solid #e2e8f0
  border-radius: 0.375rem
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1)
.drop-down-item
  padding: 0.5rem 1rem
  cursor: pointer
.select-box
  position: absolute
  top: 2px
  left: 4px
.drop-down
  position: absolute
  top: 2px
  right: 12px
  cursor: pointer
.header
  margin-top: 3rem
  padding: 0 1rem
.ti-pencil
  color: var(--ion-color-primary)
.drop-down-content
  position: absolute
  right: 0
  top: 16px
  background: white
  border: 1px solid #e2e8f0
  border-radius: 0.375rem
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1)
  z-index: 10
.dark .drop-down-content
  background: #17073c
  &:hover
    background: #17072c
.drop-down-item
  padding: 0.5rem 1rem
  cursor: pointer
  white-space: nowrap
.dark .ti-pencil 
  color: white
.input-box
  background: var(--ion-color-base)
  border: none
</style>
