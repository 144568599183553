<template>
  <ion-page class="page scrollable">
    <div class="title text-center py-3">Post a Listing</div>

    <div>
      <div class="sub-title mb-1 mt-3">Listing name*</div>
      <ion-input
        v-model.trim="listing.title"
        class="c-input"
        placeholder="Name your listing something people will want to buy!"
        maxlength="250"
      />
    </div>

    <div>
      <div class="mb-1 mt-3 d-flex align-items-center">
        <div @click="() => (changeColor = true)">
          <ion-checkbox v-model="listing.is_nsfw" class="mr-1"></ion-checkbox>
        </div>
        <ion-label class="label"
          >Mark listing as Mature
          <a
            target="_blank"
            href="https://docs.google.com/document/d/1xSdAdkRj7n8BfJuz0KKPiM2IJTi8MiAVePruZqr4Gag/edit#heading=h.sqkkh44ifgqo"
            >(see content guidelines)</a
          ></ion-label
        >
      </div>
      <div
        v-if="!(user.is_nsfw || user.show_nsfw)"
        class="d-flex align-items-center p-2 my-2"
        style="height: 30px"
        :style="changeColor ? `background: #ffc409 !important` : ''"
      >
        <ion-icon class="mr-1" style="font-size: 12px !important" :icon="warningOutline" />
        <p style="font-size: 10px; color: grey" :style="changeColor ? `font-weight: bold` : ''">
          You must turn on “show mature content” and confirm you’re 18+ in
          <router-link :style="changeColor ? `font-weight: bold` : ''" to="/account/user-settings" target="_blank">
            <strong class="clickable-item-hov">Content Settings</strong>
          </router-link>
          to share mature content
        </p>
      </div>

      <div class="sub-title mb-1 mt-3">Base Pricing*</div>
      <div class="d-flex align-items-center clickable-item-hov" @click="actionClicked">
        <div class="header mx-3">Choose Currency</div>

        <div class="clickable-item-hov">
          <i class="ti-angle-down toggle-icon" v-if="isSectionCollapsed" />
          <i class="ti-angle-up toggle-icon" v-else />
        </div>
      </div>
      <div v-if="!isSectionCollapsed" class="d-flex align-items-center clickable-item-hov" style="margin-left: 30px">
        <ion-list class="bg-transparent">
          <ion-radio-group v-model="listing.currency_type" mode="md">
            <ion-item
              v-for="category in currencyCategories"
              :key="`lst-cat-${category.value}`"
              lines="none"
              class="bg-transparent"
            >
              <ion-radio :value="category.value"></ion-radio>
              <ion-label class="mx-2 d-flex align-items-center w-100"
                >{{ category.name }}
                <div class="gem-fee-content mx-2" v-if="category.value === 'gem'">
                  <small>Fees are lower when trading in Gems!</small>
                </div>
              </ion-label>
            </ion-item>
          </ion-radio-group>
        </ion-list>
      </div>

      <ion-card style="border: 2px solid #ced4da" :style="isGemMinimum ? 'border: 2px solid red' : ''">
        <div class="d-flex mx-2 align-items-center" style="height: 40px !important">
          <img
            v-if="get(listing, 'currency_type') === 'usd'"
            class="img clickable-item-hov"
            src="/dollars.png"
            height="20"
            width="20"
          />
          <img v-else class="img clickable-item-hov" src="/gemOutline.png" height="20" width="20" />
          <ion-input
            v-model="listing.base_price"
            placeholder="Enter price of lowest package"
            class="a-input"
            style="--padding-top: 17px; --padding-bottom: 0px; height: 30px"
            required
            type="number"
          />
        </div>
      </ion-card>
      <div class="d-flex justify-content-end mx-3" v-if="isGemMinimum">
        <small class="text-danger">Minimum amount to send is 200 gems</small>
      </div>
      <!-- 
      <currency-input
        v-model="listing.base_price"
        :options="{ currency: 'USD', currencyDisplay: 'hidden' }"
        placeholder="Enter price of lowest package"
      /> -->
    </div>

    <div>
      <div class="sub-title mb-1 mt-3">Description*</div>
      <Editor
        :key="rerenderEditorKey"
        v-model="listing.description"
        :api-key="`${tinyMCEKey}`"
        :init="{
          height: 500,
          relative_urls: false,
          menu: {
            insert: {
              title: 'Insert',
              items:
                'image link media addcomment pageembed template codesample inserttable | charmap emoticons hr  | insertdatetime',
            },
          },
          menubar: 'edit view insert format tools table help',
          plugins:
            'quickbars advlist autolink lists link image charmap preview anchor searchreplace visualblocks code insertdatetime media table code help wordcount',
          toolbar:
            'undo redo | bold italic backcolor | blocks | fontfamily | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | help',
          contextmenu: false,
          quickbars_insert_toolbar: false,
          quickbars_selection_toolbar: 'bold italic underline strikethrough link',
        }"
        placeholder="Details about process, turnaround time, additional pricing, and delivery should be put here. You can also include what you will or won’t do."
      />
    </div>

    <div class="section mt-4">
      <div class="label mb-1 mt-3 sub-title"><b>Tags</b></div>
      <multiselect
        v-model="listing.tags"
        tag-placeholder="Select to create a tag"
        placeholder="Press Enter to Add Tags ↩"
        select-label=""
        deselect-label=""
        :show-no-options="false"
        :options="tagList"
        :multiple="true"
        :taggable="true"
        :loading="areTagsLoading"
        open-direction="bottom"
        :close-on-select="false"
        class="mt-2"
        @tag="addTag"
        @search-change="lookupTagsDebounced"
      ></multiselect>
    </div>
    <div>
      <div class="sub-title mb-1 mt-3">Do's and Don'ts*</div>

      <Editor
        :key="rerenderEditorKey"
        v-model="listing.usage_term"
        :api-key="`${tinyMCEKey}`"
        :init="{
          height: 300,
          relative_urls: false,
          menu: {
            insert: {
              title: 'Insert',
              items: 'image link media inserttable | charmap emoticons hr  | insertdatetime',
            },
          },
          menubar: 'edit view insert format tools table help',
          plugins:
            'quickbars advlist autolink lists link image charmap preview anchor searchreplace visualblocks code insertdatetime media table code help wordcount',
          toolbar:
            'undo redo | bold italic backcolor | blocks | fontfamily | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | help',
          contextmenu: false,
          quickbars_insert_toolbar: false,
          quickbars_selection_toolbar: 'bold italic underline strikethrough link',
        }"
        placeholder="What are people allowed and not allowed to do with what you sell? Commercial use? Does it need crediting? Can it be re-sold? Do you retain the rights to the work or do they? These are your terms and conditions."
      />
    </div>

    <div class="section mt-3">
      <div class="sub-title">Images*</div>

      <div class="max-width">
        <UploadForm :is-multiple="true" @uploaded="addVisual" />
      </div>
      <div class="visuals mt-2">
        <div class="content d-flex mt-2">
          <div v-for="(visual, index) of visuals" :key="index" class="visual mx-2 mt-2 position-relative">
            <img
              class="visual visual-image clickable-item"
              :src="visual"
              @click="openVisualPreview(visual)"
            />
            <ion-button
              color="transparent"
              class="float-circle-btn clickable-item-hov"
              @click.prevent="removeVisual(index)"
            >
              <i class="ti-close" />
            </ion-button>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-3">
      <ion-list class="bg-transparent">
        <ion-radio-group v-model="listing.category" mode="md">
          <div class="sub-title">Category*</div>

          <ion-item v-for="category in listingCategories" :key="`lst-cat-${category.value}`" class="bg-transparent">
            <ion-label>{{ category.name }}</ion-label>
            <ion-radio :value="category.value"></ion-radio>
          </ion-item>
        </ion-radio-group>
      </ion-list>
    </div>

    <div>
      <!-- <p v-for="error of v$.$errors" :key="error.$uid" class="mt-1">
        <small class="error-msg"
          ><strong>{{ mapFieldNames[error.$property] }}: </strong>{{ error.$message }}</small
        >
      </p> -->
    </div>
    <div
      v-if="!listing.is_active && listing.is_nsfw && !user.is_nsfw && !user.show_nsfw"
      class="d-flex align-items-center mt-2"
    >
      <ion-icon class="mr-1" style="font-size: 12px !important" :icon="warningOutline" />
      <p style="font-size: 10px; color: grey">
        You can not post listing until you confirm you're 18+ in
        <router-link to="/account/user-settings" target="_blank">
          <strong class="clickable-item-hov">Content Settings</strong>
        </router-link>
      </p>
    </div>
    <div
      class="d-flex justify-content-center mb-4"
      :class="!listing.is_active && listing.is_nsfw && !user.is_nsfw && !user.show_nsfw ? '' : 'mt-4'"
    >
      <div v-if="$route.name === 'edit-mp-listing'">
        <ion-button
          class="status-btn mr-2"
          color="danger"
          :disabled="isStatusUpdating || isPublishing"
          @click="deleteListing"
        >
          Delete</ion-button
        >
        <ion-button
          v-if="listing.is_active"
          class="status-btn mr-2"
          color="warning"
          :disabled="isStatusUpdating || isPublishing"
          @click="toggleStatus()"
          ><ChLoading size="sm" v-if="isStatusUpdating" class="spinner" /> <span v-else>Pause Listing</span></ion-button
        >
        <ion-button
          v-else
          class="status-btn mr-2"
          color="success"
          :disabled="isStatusUpdating || isPublishing"
          @click="toggleStatus()"
          ><ChLoading size="sm" v-if="isStatusUpdating" class="spinner" />
          <span v-else>Resume Listing</span></ion-button
        >
      </div>

      <ion-button
        v-if="get(user, 'is_email_verified', true)"
        class="publish-btn"
        size="large"
        :disabled="isPublishing"
        @click="onPublish"
      >
        <ChLoading size="sm" v-if="isPublishing" class="spinner" />
        <span v-else>{{ actionName }}</span>
      </ion-button>
      <VerificationButton v-else />
    </div>

    <VisualPreviewModal
      :image-url="selectedVisualImg"
      :is-open="openVisualPreviewModal"
      @dismiss-modal="closeVisualPreviewModal"
    />
    <DeleteConfirmationModal
      :isOpen="isDeleteConfirmationOpen"
      @dismissModal="isDeleteConfirmationOpen = false"
      @deleteListing="commitDeleteListing"
    />
  </ion-page>
</template>

<script lang="ts" setup>
import Multiselect from 'vue-multiselect';
// import { useVuelidate } from '@vuelidate/core';
// import { helpers, required, minLength, minValue, or, sameAs } from '@vuelidate/validators';
import VisualPreviewModal from '../../modals/VisualPreviewModal.vue';
import DeleteConfirmationModal from './DeleteConfirmationModal.vue';
import { MarketplaceListing } from '@/shared/types/static-types';
import { toast } from '@/shared/native';
import UploadForm from '@/shared/components/upload-form.vue';
import constants from '@/shared/statics/constants';
import { warningOutline } from 'ionicons/icons';
import { resizeUpload } from '@/shared/utils/upload';
import { deleteMarketplaceListing, updateMPListingStatus } from '@/shared/actions/marketplace';
import CurrencyInput from '@/shared/components/CurrencyInput.vue';
import { getTags } from '@/shared/actions/tags';
import { marketplaceStore } from '@/shared/pinia-store/marketplace';
import { mainStore } from '@/shared/pinia-store/main';

import VerificationButton from '@/shared/components/VerificationButton.vue';
import { authStore } from '@/shared/pinia-store/auth';
const { user } = authStore();

const route = useRoute();
const isPublishing = ref(false);
const isStatusUpdating = ref(false);
const changeColor = ref(false);
const currencyCategories = constants.currencyCategories;
const {
  public: { tinyMCEKey },
} = useRuntimeConfig();
const edit = ref(false);
const listing = ref<MarketplaceListing>({
  title: '',
  base_price: undefined,
  description: '',
  usage_term: '',
  visuals: [],
  category: '',
  is_nsfw: false,
  is_active: true,
  id: '',
  tags: [],
  currency_type: 'usd',
});
const mapFieldNames: { [key: string]: string } = {
  base_price: 'Base Price',
  title: 'Title',
  description: 'Description',
  usage_term: 'Usage Terms',
  category: 'Category',
  visuals: 'Images',
};
const listingCategories = constants.mpListingCategories;

const selectedVisualImg = ref('');
const openVisualPreviewModal = ref(false);
const rerenderEditorKey = ref(0);
const isSectionCollapsed = ref(false);

const commitDeleteListing = async () => {
  if (!listing.value.id) return;

  await deleteMarketplaceListing(listing.value.id);

  const router = useRouter();
  router.push({ name: 'manage-mp-listings' });
};

const isGemMinimum = computed(() => {
  return listing.value.base_price && listing.value.base_price < 200 && listing.value.currency_type === 'gem';
});

const tagList: any = ref([]);
const areTagsLoading = ref(false);

const isDeleteConfirmationOpen = ref(false);

const deleteListing = () => {
  isDeleteConfirmationOpen.value = true;
};

// const minValueDollar = (min: number) =>
//   helpers.withMessage(({ $params }) => `The base price must be at least $${$params.min}, or free ($0).`, minValue(min));

// const sameAsZero = helpers.withMessage(({ $params }) => `The base price must be at least $5, or free ($0).`, sameAs(0));

// const customOr = (a: any, b: any) =>
//   helpers.withMessage((params: any) => `The base price must be at least $5, or free ($0).`, or(a, b));

// const requiredVisual = helpers.withMessage('Uploading an image is required to post a listing.', required);

// const listingValidations = computed(() => ({
//   listing: {
//     title: { required },
//     base_price: { required, minValue: customOr(minValueDollar(5), sameAsZero) },
//     description: { required },
//     usage_term: { required },
//     category: { required },
//     visuals: { requiredVisual, minLength },
//   },
// }));
// const v$ = useVuelidate(listingValidations, { listing });

const addTag = (newTag: any) => {
  listing.value.tags.push(newTag);
  tagList.value.push(newTag);
};

const lookupTags = async (search?: string) => {
  areTagsLoading.value = true;

  if (!search) {
    tagList.value = [];
  } else {
    const resp = await getTags(1, 4, search);

    tagList.value = map(resp.results, (tag) => tag.name);
  }

  areTagsLoading.value = false;
};
const lookupTagsDebounced = debounce(lookupTags, 500);

const visuals = computed(() => {
  return listing.value.visuals;
});

const isDarkMode = computed(() => {
  const { dark } = mainStore();
  return dark.value;
});

watch(isDarkMode, () => {
  rerenderEditorKey.value++;
});

const openVisualPreview = (imgUrl: string) => {
  selectedVisualImg.value = imgUrl;
  openVisualPreviewModal.value = true;
};

const closeVisualPreviewModal = () => {
  openVisualPreviewModal.value = false;
  selectedVisualImg.value = '';
};

const addVisual = (images: string[]) => {
  if (!listing.value.visuals) {
    listing.value.visuals = [];
  }
  if (images?.length) {
    listing.value.visuals = listing.value.visuals.concat(images);
  }
};

const removeVisual = (index: number) => {
  listing.value.visuals?.splice(index, 1);
};

const onPublish = async () => {
  const { createMarketplaceListing, updateListing } = marketplaceStore();
  // TODO: restore validation
  const isFormValid = true;
  // const isFormValid = await v$.value.$validate();

  if (!isFormValid) {
    await toast.show('Please review your form and try again', 'nonative', 'danger');
    return;
  }
  if (isGemMinimum.value) {
    await toast.show('Minimum of 200 gems required.', 'nonative', 'danger');
    return;
  }
  if (isPublishing.value) return;

  isPublishing.value = true;

  try {
    if (listing.value.base_price! > 0) {
      listing.value.base_price = listing.value.base_price! < 5 ? 5 : listing.value.base_price;
    }

    listing.value.title = truncate(listing.value.title, { length: 250, separator: /,? +/ });

    let response;
    if (!edit.value) {
      response = await createMarketplaceListing(listing.value);
    } else {
      const payload = {
        title: listing.value.title,
        base_price: listing.value.base_price,
        description: listing.value.description?.trim(),
        usage_term: listing.value.usage_term?.trim(),
        visuals: listing.value.visuals,
        category: listing.value.category,
        is_nsfw: listing.value.is_nsfw,
        id: listing.value.id,
        tags: listing.value.tags,
        currency_type: listing.value.currency_type,
      };

      response = await updateListing(payload);
    }
    isPublishing.value = false;
    const router = useRouter();
    await toast.show('Successfully saved.', 'nonative', 'success');
    router.push({ name: 'mp-listing-details', params: { slug: response.slug } });
  } catch (_err) {
    await toast.show('Some error has occurred. Please try again later.', 'nonative', 'danger');
    isPublishing.value = false;
  }
};

const toggleStatus = async () => {
  if (isStatusUpdating.value) return;
  isStatusUpdating.value = true;

  try {
    await updateMPListingStatus(listing.value.id!, !listing.value.is_active);
    toast.show(listing.value.is_active ? 'Listing paused!' : 'Listing resumed!', 'nonative', 'success');
    listing.value.is_active = !listing.value.is_active;
  } catch (_err: any) {
    if (_err.response?.status === 400 && _err.response?.data === 'Mature content cannot be published') {
      toast.show(
        'You cannot publish mature content until you mark yourself 18+ in content preferences!',
        'nonative',
        'danger'
      );
    } else {
      toast.show('Some error has occurred. Please try again later.', 'nonative', 'danger');
    }
  }

  isStatusUpdating.value = false;
};

const actionClicked = () => {
  isSectionCollapsed.value = !isSectionCollapsed.value;
};

const actionName = computed(() => {
  return edit.value ? 'Save' : 'Publish';
});

const listingId = computed(() => {
  return route.params.id;
});

onMounted(async () => {
  const { getListing } = marketplaceStore();
  lookupTags();

  if (listingId.value) {
    const listingDetails: MarketplaceListing = await getListing(listingId.value as string);
    edit.value = true;
    listing.value = {
      ...listing.value,
      ...listingDetails,
    };
  }
});
</script>

<style lang="sass" scoped>
.dark .a-input
  color: white !important
.gem-fee-content
  border-radius: 6px
  font-size: 14px
  padding: 0px 7px 0px 7px
  border: 2px solid #ae38e5
  color: #ae38e5
.toggle-icon
  font-weight: bold
  font-size: 12px
.header
  font-size: 16px
  font-weight: bold
.page
  overflow-x: hidden
.publish-btn
  width: 100px
  height: 30px
  font-size: 14px
.status-btn
  width: 150px
.visuals
  .content
    flex-wrap: wrap
    margin: -12px

  .visual
    background-size: cover
    width: 110px
    height: 110px
    &:first-of-type
      margin-left: 7px !important
.visual-image
  object-fit: cover
.wrap
  margin: 35px auto
  width: 240px
.valid
  font-size: 11px
.float-circle-btn
  background-color: rgba(0,0,0,0.5)
  --border-radius: 100% !important
  border-radius: 100% !important
  width: 28px
  height: 28px
  --padding: 0
  --padding-start: 0
  --padding-end: 0
  right: 0
  top: 0
  position: absolute
  z-index: 1
.title
  font-size: 26px
  font-weight: bold
  color: #214163
.sub-title
  font-size: 20px
  font-weight: bold
.error-msg
  color: var(--ion-color-danger) !important
</style>
