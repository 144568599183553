<template>
  <div class="d-flex" :class="isUser ? 'justify-content-end' : ''">
    <router-link
      v-if="
        (get(chatroom[index], 'user.username') !== get(chatroom[index - 1], 'user.username') &&
          !isUser &&
          isSameUserAndNextChar) ||
        (!isUser && isNotUserandLastIndex) ||
        (!isUser && isCharAfterUser) ||
        (!isUser && !isSameChar && get(chatroom[index], 'character')) ||
        (isNotUserandFirstindex && receiver.length <= 1) ||
        (!isUser && index == 0 && chatroom.length <= 1) ||
        (message.isReceiverAfterSender && !isUser)
      "
      :to="posterProfileLink"
      class="mr-2 mt-1"
      :class="{ disabled: linkDisabled }"
    >
      <CommenterImage
        :class="
          message.image_url
            ? 'set-image-top'
            : get(message.reply_to, 'image_url')
            ? 'set-reply-image'
            : message.reply_to
            ? 'set-reply-top'
            : ''
        "
        :src="
          !message.character
            ? resizeUpload(get(message.user, 'profile_picture_url'), '80x80')
            : resizeUpload(get(message.character, 'info.cropProfilePicture'), '80x80')
        "
        :ring="
          message.character
            ? get(message.character, 'profile_ring.image')
            : get(message.user, 'customize_profile.profile_ring.image')
        "
        :set-ring-position="true"
        :ringWidth="42"
        :top="-4"
        :left="-3"
        size="more-sm"
        :type="message.character ? 'character' : 'user'"
      />
    </router-link>
    <div class="flex-column" :style="{ maxWidth: setWidth(message) }">
      <div class="mb-1 mt-2" v-if="message.reply_to" style="font-size: 12px">
        Replied to
        {{
          get(message.reply_to, 'character')
            ? get(message.reply_to, 'character.info.name')
            : get(message.reply_to, 'user.username')
        }}
      </div>
      <div v-if="message.reply_to" class="content">
        <div class="info-content px-3 py-3" style="margin-bottom: -8px" :class="getReplyCard(index)">
          <div v-if="message.reply_to.text" class="title" :class="isUser ? 'text-white' : 'text-black'">
            {{ truncateText(stripHtmlTags(message.reply_to.text), 60) }}
          </div>
          <div v-else>
            <img loading="lazy" :src="resizeUpload(get(message.reply_to, 'image_url'), '190h')" class="reply-img" />
          </div>
        </div>
      </div>
      <div
        v-if="message.image_url"
        class="mx-auto clickable-item position-relative"
        :class="getImageCard(index)"
        @click="openModal"
      >
        <img loading="lazy" v-image class="img" :src="resizeUpload(message.image_url, '190h')" />
        <ion-button
          v-if="user.username !== message.user.username"
          fill="none"
          class="position-absolute one-button left"
          @click.stop="dropdownToggle($event, message)"
        >
          <i class="ti-more-alt menu-icon" />
        </ion-button>
      </div>
      <div
        v-if="!message.image_url"
        class="card"
        style="margin-bottom: 5px; width: auto"
        :class="getMessageCard(index)"
        @mouseover="isHoveringOver"
        @mouseleave="isNotHoveringOver"
        :style="user.username === message.user.username ? 'background: #17074C' : ''"
      >
        <div class="card-body d-flex px-3 py-2">
          <div class="w-100">
            <div class="pt-1">
              <div class="d-flex flex-column position-relative">
                <div
                  :style="user.username === message.user.username ? 'color: white' : ''"
                  class="type menu-drop-down-icon d-flex justify-content-between"
                >
                  <span :class="user.username === message.user.username ? 'user' : 'menu-icon'">{{
                    message.character ? 'character' : 'user'
                  }}</span>
                  <span></span>
                  <ion-button
                    color="dark"
                    class="clickable-item-hov menu-icon-btn"
                    v-if="!isUser && (isHovering || isDropDownToggle)"
                    @click="dropdownToggle($event, message)"
                  >
                    <i class="ti-more-alt menu-icon"
                  /></ion-button>
                </div>
                <div class="username-text">
                  <strong>
                    <router-link
                      class="clickable mr-2"
                      :class="{
                        'text-color-username':
                          usernameColor(get(message, 'user')) &&
                          !usernameColor(get(message, 'user')).startsWith('#') &&
                          !message.character,
                        disabled: linkDisabled,
                        'text-color':
                          (!usernameColor(get(message, 'user')) && user.username !== message.user.username) ||
                          (user.username !== message.user.username && !!message.character),
                        'bold-username': usernameColor(get(message, 'user')) && !message.character,
                      }"
                      :style="
                        !usernameColor(get(message, 'user')) || !!message.character
                          ? ''
                          : usernameColor(get(message, 'user')).startsWith('#')
                          ? `color: ${usernameColor(get(message, 'user'))} !important`
                          : `background-image: ${usernameColor(get(message, 'user'))} !important`
                      "
                      :to="posterProfileLink"
                    >
                      {{ !message.character ? message.user.username : get(message.character, 'info.name', 'Unknown') }}
                    </router-link>
                  </strong>
                </div>
              </div>
            </div>

            <div class="py-1 px-0 break-word">
              <span
                v-if="!message.image_url"
                v-html="sanitizeHtml((message.text || '').replace(/(?:\r\n|\r|\n)/g, '<br>'))"
                :class="isUser ? 'text-white ' : 'text-black text-color'"
              />
              <div v-else>{{ message.text }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div v-if="!isUser" class="mx-2 mt-4 clickable-item-hov">
      <div class="d-flex">
        <ion-icon :icon="arrowUndoOutline" />

       
      </div>
    </div> -->
    <router-link
      v-if="
        (get(chatroom[index], 'user.username') !== get(chatroom[index - 1], 'user.username') &&
          isUser &&
          isSameUserAndNextChar) ||
        (isUser && isUserandLastIndex) ||
        (isUser && isCharAfterUser) ||
        (isUser && !isSameChar && get(chatroom[index], 'character')) ||
        (isUserandFirstindex && sender.length <= 1) ||
        (isUser && index == 0 && chatroom.length <= 1) ||
        (message.isReceiverAfterSender && isUser)
      "
      :to="posterProfileLink"
      :class="{ disabled: linkDisabled }"
      class="ml-2 py-1 img-link"
    >
      <CommenterImage
        size="more-sm"
        :type="!message.character ? 'user' : 'character'"
        :src="
          resizeUpload(
            !message.character
              ? get(message.user, 'profile_picture_url', '/images/user.jpg')
              : get(message.character, 'info.cropProfilePicture', '/empty.png'),
            '80x80'
          )
        "
        :ring="
          message.character
            ? get(message.character, 'profile_ring.image')
            : get(message.user, 'customize_profile.profile_ring.image')
        "
        :set-ring-position="true"
        :ringWidth="42"
        :top="-4"
        :left="-3"
        :style="{ marginTop: get(message.text, 'length') > 90 ? '0px !important' : '' }"
        :class="message.image_url ? 'set-image-top' : message.reply_to ? 'set-reply-top' : ''"
        class="clickable"
      />
    </router-link>

    <image-preview-modal :image-url="message.image_url" :is-open="isOpened" @dismiss="closeImageModal" />
  </div>
</template>

<script lang="ts" setup>
import ImagePreviewModal from '../modals/ImagePreviewModal.vue';
import MessagePopover from '../popovers/MessagePopover.vue';
import CommenterImage from '@/shared/components/storage/CommenterImage.vue';
import ImagePopover from '@/shared/components/storage/popovers/ImagePopover.vue';
import { stripHtmlTags } from '@/shared/utils/string';
import { authStore } from '@/shared/pinia-store/auth';
import { popovers } from '@/shared/native/popovers';
import { sanitizeHtml } from '@/shared/utils/html';
import { resizeUpload } from '@/shared/utils/upload';
import { truncateText } from '@/shared/utils/string';
import { alertController } from '@ionic/vue';
import { toast } from '@/shared/native';
import { removeWorldMember, removeWorldchatMessage } from '@/shared/actions/worlds';
import { getWorldMembers } from '@/shared/actions/worlds';

const props = defineProps({
  message: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    default: 0,
  },
  sender: {
    type: Array,
    default: [],
  },
  receiver: {
    type: Array,
    default: [],
  },
  length: {
    type: Number,
  },
  chatroom: {
    type: Array,
    default: [],
  },
  currentMessageUserChar: {
    type: String,
  },
  worldStatus: {
    type: Object,
    required: true,
  },
  chatroomMembers: {
    type: Object,
    default: {},
  },
});

const currentUserChar = toRef(props, 'currentMessageUserChar');
const message = toRef(props, 'message');
const index = toRef(props, 'index');
const receiver = toRef(props, 'receiver');
const sender = toRef(props, 'sender');
const chatroom = toRef(props, 'chatroom') as any;
const emits = defineEmits(['reply' , 'remove']);
const isOpened = ref(false);
const isDropDownToggle = ref(false);
const isHovering = ref(false);
const { user } = authStore();
const worldStatus = toRef(props, 'worldStatus') as any;
const chatroomMembers = toRef(props, 'chatroomMembers') as any;
const route = useRoute();

const usernameColor = (user: any) => {
  return user?.customize_profile?.username_color || '';
};

const openModal = () => {
  isOpened.value = true;
};
const isNotUserandFirstindex = computed(() => {
  const rec = receiver?.value[0];
  const chat = chatroom?.value[index.value];
  return rec === chat && !isUser.value;
});

const isCharacterBeforeUser = computed(() => {
  if (get(chatroom.value[index.value - 1], 'character')) {
    if (!isCharacterAfterUser.value && isSameUser.value) {
      return true;
    }
  }
});

const dropdownToggle = async (ev: CustomEvent, msg: any) => {
  isDropDownToggle.value = true;
  const popover = !isEmpty(msg.image_url)
    ? await popovers.open(ev, ImagePopover, { image: msg, user: msg.user })
    : await popovers.open(ev, MessagePopover, { message: msg, worldStatus: worldStatus });
  try {
    const { data } = await popover.onDidDismiss();
    isDropDownToggle.value = false;
    if (data.value === 'reply') {
      reply();
    }
    if (data.value === 'remove-member') {
      const members = await worldMembers.value;
      const memberID = members.find((member: any) => member.user.id === msg.user.id).id;
      removeMember(memberID, msg.user.username);
    }
    if (data.value === 'ban-member') {
      const members = await worldMembers.value;
      const memberID = members.find((member: any) => member.user.id === msg.user.id).id;
      banMember(memberID, msg.user.username);
    }
    if(data.value === 'remove-message') {
      removeMessage(msg.id);
    }
  } catch (e) {}
};

const isSameChar = computed(() => {
  if (get(chatroom.value[index.value], 'character.id')) {
    return get(chatroom.value[index.value], 'character.id') === get(chatroom.value[index.value + 1], 'character.id');
  } else return false;
});

const worldMembers = computed(async () => {
  if (!chatroomMembers.value.world?.id) return [];

  try {
    const response = await getWorldMembers({ world_id: get(chatroomMembers.value, 'world.id', '')});
    return response || [];
  } catch (e) {
    await toast.show('Could not load user listing. Please try again.', 'nonative', 'danger');
    return [];
  }
});

const isSameUser = computed(() => {
  if (!get(chatroom.value[index.value], 'character')) {
    return true;
  }
});

const isCharacterAfterUser = computed(() => {
  if (!(get(chatroom.value[index.value], 'character.id') && get(chatroom.value[index.value + 1], 'character.id'))) {
    return true;
  }
});
const setWidth = (message: any) => {
  if (message.text) {
    return get(message.text, 'length') > 90 ? 'calc(100% - 80px)' : '';
  } else {
    return '';
  }
};
const isNotHoveringOver = () => {
  setTimeout(() => {
    isHovering.value = false;
  }, 300);
};
const isHoveringOver = () => {
  setTimeout(() => {
    isHovering.value = true;
  }, 250);
};

const singleChar = computed(() => {
  if (
    get(chatroom.value[index.value], 'character.id') !== get(chatroom.value[index.value + 1], 'character.id') &&
    get(chatroom.value[index.value], 'character.id') !== get(chatroom.value[index.value - 1], 'character.id')
  ) {
    return true;
  }
});
const isUserandFirstindex = computed(() => {
  if (index.value !== 0) {
    const send = sender.value.find((item) => item === chatroom.value[index.value]);
    const chat = chatroom?.value[index.value];

    return (
      send === chat && isUser.value && chatroom?.value[index.value - 1] === receiver?.value[receiver.value.length - 1]
    );
  } else {
    const chat = chatroom?.value[index.value];
    const send = sender?.value[0];
  }
});

const removeMember = async (id: string, name: string) => {
  try {
    const alert = await alertController.create({
      cssClass: '',
      header: 'Are you sure?',
      message: `Please confirm that you want to remove <b>${name}</b> from this world.`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'text-secondary',
          id: 'cancel-button',
        },
        {
          text: 'Confirm',
          id: 'confirm-button',
          role: 'ok',
          cssClass: 'text-danger',
        },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    if (role === 'ok') {
      await removeWorldMember(id);
      toast.show('Member removed successfully', 'nonative', 'success');
    }
  } catch (e) {
    toast.show('Encountered an error - please try again later.', 'nonative', 'danger');
  }
};

const banMember = async (id: string, name: string) => {
  try {
    const alert = await alertController.create({
      cssClass: '',
      header: 'Ban Member',
      message: `Please confirm that you want to ban <b>${name}</b> from this space.`,
      inputs: [
        {
          type: 'text',
          placeholder: 'Reason for ban (optional)'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'text-secondary',
          id: 'cancel-button',
        },
        {
          text: 'Ban Member',
          id: 'confirm-button',
          role: 'ok',
          cssClass: 'text-danger',
        },
      ],
    });
    await alert.present();
    const { role, data } = await alert.onDidDismiss();
    if (role === 'ok') {
      await removeWorldMember(id, {
        ban: true,
        ban_reason: data?.values?.[0] || ''
      });
      toast.show('Member banned successfully', 'nonative', 'success');
    }
  } catch (e) {
    toast.show('Encountered an error - please try again later.', 'nonative', 'danger');
  }
};

const removeMessage = async (id: string) => {
  try {
    const alert = await alertController.create({
      cssClass: '',
      header: 'Are you sure?',
      message: `Please confirm that you want to remove this message.`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'text-secondary',
          id: 'cancel-button',
        },
        {
          text: 'Confirm',
          id: 'confirm-button',
          role: 'ok',
          cssClass: 'text-danger',
        },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    if (role === 'ok') {
      await removeWorldchatMessage(route.params.id as string, id);
      emits('remove');
      toast.show('Message removed successfully', 'nonative', 'success');
    }
  } catch (e) {
    toast.show('Encountered an error - please try again later.', 'nonative', 'danger');
  }
};

const getMessageCard = (index: any) => {
  return (isUserandFirstindex.value && sender.value.length <= 1) ||
    (isUser.value && singleChar.value) ||
    (isUser.value && isNextUser.value && index == 0 && sender.value.length <= 1) ||
    (isUser.value && index == 0 && chatroom.value.length <= 1) ||
    (message.value.isSenderAfterReceiver && isUserandLastIndex.value) ||
    (isUser.value && message.value.isReceiverAfterSender && (isUserandFirstindex.value || index === 0)) ||
    (message.value.isSenderAfterReceiver && message.value.isReceiverAfterSender && isUser.value)
    ? 'msg-send'
    : (isNotUserandFirstindex.value && receiver.value.length <= 1) ||
      (!isUser.value && singleChar.value) ||
      (!isUser.value && isNextUser.value && index == 0 && receiver.value.length <= 1) ||
      (!isUser.value && index == 0 && chatroom.value.length <= 1) ||
      (message.value.isSenderAfterReceiver && isNotUserandLastIndex.value) ||
      (!isUser.value && message.value.isReceiverAfterSender && (isNotUserandFirstindex.value || index === 0)) ||
      (message.value.isSenderAfterReceiver && message.value.isReceiverAfterSender && !isUser.value) ||
      (!isUser.value &&
        !isEmpty(get(chatroom.value[index.value + 1], 'character')) &&
        get(chatroom.value[index.value], 'character.id') === get(chatroom.value[index.value + 1], 'character.id') &&
        index === 0)
    ? 'msg-text'
    : (isUser.value &&
        ((isUserandLastIndex.value && !isUserandFirstindex.value && !isSameUser.value) ||
          message.value.isReceiverAfterSender)) ||
      (isDifferentChar.value && isUser.value) ||
      (isUser.value && isCharAfterUser.value) ||
      (isCharacterAfterUser.value && isSameUser.value && isUser.value && !isNextUser.value)
    ? 'msg-send set-last-border'
    : (!isUser.value &&
        ((isNotUserandLastIndex.value && !isNotUserandFirstindex.value && !isSameUser.value) ||
          message.value.isReceiverAfterSender)) ||
      (isDifferentChar.value && !isUser.value) ||
      (!isUser.value && isCharAfterUser.value) ||
      (isCharacterAfterUser.value && isSameUser.value && !isUser.value && !isNextUser.value)
    ? 'mx-1 msg-text set-recevier-last-border'
    : isUser.value &&
      ((index === 0 && chatroom.value.length > 1) ||
        sender.value.length === 1 ||
        (isSameChar.value &&
          get(chatroom.value[index - 1], 'character.id') !== get(chatroom.value[index], 'character.id')) ||
        (isUser.value && isNextUser.value && !isBeforeUser.value) ||
        message.value.isSenderAfterReceiver ||
        isCharacterBeforeUser.value)
    ? 'msg-send first-message'
    : isUser.value &&
      !isUserandFirstindex.value &&
      !isUserandLastIndex.value &&
      sender.value.length >= 1 &&
      !isSameChar.value &&
      !isSameUser.value
    ? 'msg-send set-message-border'
    : (isUser.value && !isUserandFirstindex.value && !isUserandLastIndex.value && sender.value.length >= 1) ||
      (!isCharacterAfterUser.value && isSameUser.value && isUser.value) ||
      (isUser.value && isNextUser.value && isBeforeUser.value)
    ? 'msg-send set-middle-border'
    : (isNotUserandFirstindex.value && receiver.value.length > 1) ||
      (message.value.isSenderAfterReceiver && !isUser.value) ||
      (!isUser.value &&
        ((index === 0 && chatroom.value.length > 1) ||
          receiver.value.length === 1 ||
          (isSameChar.value &&
            get(chatroom.value[index - 1], 'character.id') !== get(chatroom.value[index], 'character.id')) ||
          (!isUser.value && isNextUser.value && !isBeforeUser.value) ||
          message.value.isSenderAfterReceiver ||
          isCharacterBeforeUser.value))
    ? 'msg-text set-receiver-border'
    : (!isUser.value && !isNotUserandFirstindex.value && receiver.value.length >= 1 && !isNotUserandLastIndex.value) ||
      (!isCharacterAfterUser.value && isSameUser.value && !isUser.value) ||
      (!isUser.value && isNextUser.value && isBeforeUser.value)
    ? 'msg-text set-receiver-middle-border'
    : 'msg-text';
};

// ... other computed properties and functions ...

const isCharacter = computed(() => {
  return message.value.character && message.value.character.id === currentUserChar.value;
});

const isNotSameUser = computed(() => {
  return (
    !isUser.value &&
    get(chatroom.value[index.value], 'user.username') !== get(chatroom.value[index.value - 1], 'user.username') &&
    get(chatroom.value[index.value], 'user.username') === get(chatroom.value[index.value + 1], 'user.username')
  );
});

const getImageCard = (index: any) => {
  return (isUser.value &&
    ((isUserandLastIndex.value && !isUserandFirstindex.value && !isSameUser.value) ||
      message.value.isReceiverAfterSender)) ||
    (isDifferentChar.value && isUser.value) ||
    (isUser.value && isCharAfterUser.value) ||
    (isCharacterAfterUser.value && isSameUser.value && isUser.value && !isNextUser.value)
    ? 'set-last-border'
    : isUser.value &&
      ((index === 0 && chatroom.value.length > 1) ||
        sender.value.length === 1 ||
        (isSameChar.value &&
          get(chatroom.value[index - 1], 'character.id') !== get(chatroom.value[index], 'character.id')) ||
        (isUser.value && isNextUser.value && !isBeforeUser.value) ||
        message.value.isSenderAfterReceiver ||
        isCharacterBeforeUser.value)
    ? 'first-message'
    : (isUser.value && !isUserandFirstindex.value && !isUserandLastIndex.value && sender.value.length >= 1) ||
      (!isCharacterAfterUser.value && isSameUser.value && isUser.value) ||
      (isUser.value && isNextUser.value && isBeforeUser.value)
    ? 'set-middle-border'
    : (!isUser.value &&
        ((isNotUserandLastIndex.value && !isNotUserandFirstindex.value && !isSameUser.value) ||
          message.value.isReceiverAfterSender)) ||
      (isDifferentChar.value && !isUser.value) ||
      (!isUser.value && isCharAfterUser.value) ||
      (isCharacterAfterUser.value && isSameUser.value && !isUser.value && !isNextUser.value)
    ? 'px-1 set-recevier-last-border'
    : (isNotUserandFirstindex.value && receiver.value.length > 1) ||
      (message.value.isSenderAfterReceiver && !isUser.value) ||
      (!isUser.value &&
        ((index === 0 && chatroom.value.length > 1) ||
          receiver.value.length === 1 ||
          (isSameChar.value &&
            get(chatroom.value[index - 1], 'character.id') !== get(chatroom.value[index], 'character.id')) ||
          (!isUser.value && isNextUser.value && !isBeforeUser.value) ||
          message.value.isSenderAfterReceiver ||
          isCharacterBeforeUser.value))
    ? 'set-receiver-border'
    : (!isUser.value && !isNotUserandFirstindex.value && receiver.value.length >= 1 && !isNotUserandLastIndex.value) ||
      (!isCharacterAfterUser.value && isSameUser.value && !isUser.value) ||
      (!isUser.value && isNextUser.value && isBeforeUser.value)
    ? 'set-receiver-middle-border'
    : '';
};

const isDifferentChar = computed(() => {
  if (get(chatroom.value[index.value], 'character.id') !== get(chatroom.value[index.value + 1], 'character.id')) {
    return true;
  }
});
const getReplyCard = (index: any) => {
  return (isUser.value &&
    ((isUserandLastIndex.value && !isUserandFirstindex.value && !isSameUser.value) ||
      message.value.isReceiverAfterSender)) ||
    (isDifferentChar.value && isUser.value) ||
    (isUser.value && isCharAfterUser.value) ||
    (isCharacterAfterUser.value && isSameUser.value && isUser.value && !isNextUser.value)
    ? 'set-last-border'
    : isUser.value &&
      ((index === 0 && chatroom.value.length > 1) ||
        sender.value.length === 1 ||
        (isSameChar.value &&
          get(chatroom.value[index - 1], 'character.id') !== get(chatroom.value[index], 'character.id')) ||
        (isUser.value && isNextUser.value && !isBeforeUser.value) ||
        message.value.isSenderAfterReceiver ||
        isCharacterBeforeUser.value)
    ? 'first-message'
    : (isUser.value && !isUserandFirstindex.value && !isUserandLastIndex.value && sender.value.length >= 1) ||
      (!isCharacterAfterUser.value && isSameUser.value && isUser.value) ||
      (isUser.value && isNextUser.value && isBeforeUser.value)
    ? 'set-middle-border'
    : (isNotUserandFirstindex.value && receiver.value.length > 1 && get(message.value, 'reply_to.image_url')) ||
      (message.value.isSenderAfterReceiver && !isUser.value && get(message.value, 'reply_to.image_url'))
    ? 'set-image-receiver-border'
    : (isNotUserandFirstindex.value && receiver.value.length > 1) ||
      (message.value.isSenderAfterReceiver &&
        !isUser.value &&
        get(chatroom.value[index.value], 'user.username') !== get(chatroom.value[index.value - 1], 'user.username') &&
        get(chatroom.value[index.value], 'user.username') === get(chatroom.value[index.value + 1], 'user.username'))
    ? 'set-receiver-border'
    : (!isUser.value &&
        ((isNotUserandLastIndex.value && !isNotUserandFirstindex.value) || message.value.isReceiverAfterSender)) ||
      (isDifferentChar.value && !isUser.value) ||
      (!isUser.value && isCharAfterUser.value) ||
      (isCharacterAfterUser.value && isSameUser.value && !isUser.value && !isNextUser.value)
    ? 'set-recevier-last-border'
    : (!isUser.value && !isNotUserandFirstindex.value && receiver.value.length >= 1 && !isNotUserandLastIndex.value) ||
      (!isCharacterAfterUser.value && isSameUser.value && !isUser.value) ||
      (!isUser.value && isNextUser.value && isBeforeUser.value)
    ? 'set-receiver-middle-border'
    : '';
  // (isUser && !isUserandFirstindex && !isUserandLastIndex && sender.length >= 1) ||
  //           (!isCharacterAfterUser && isSameUser && isUser) ||
  //           (isUser && isNextUser && isBeforeUser)
  //             ? 'set-left-margin'
  //             : !message.isReceiverAfterSender && !isUserandLastIndex && isUser
  //             ? 'set-margin'
  //             : !message.isReceiverAfterSender && !isUserandLastIndex && !isUser && !isNotUserandLastIndex
  //             ? 'set-receiver-reply-margin'
  //             : ''
};

const isNotUserandLastIndex = computed(() => {
  const rec = receiver?.value[receiver.value.length - 1];
  const chat = chatroom?.value[index.value];
  return rec === chat && !isUser.value;
});
const isCharAfterUser = computed(() => {
  if (isNextUser.value && isBeforeUser.value && get(chatroom.value[index.value + 1], 'character')) {
    return true;
  }
});

const isUserandLastIndex = computed(() => {
  const chat = chatroom?.value[index.value];
  const send = sender?.value[sender.value.length - 1];
  return isUser.value && send === chat;
});
const isBeforeUser = computed(() => {
  {
    if (
      !get(chatroom.value[index.value], 'character') &&
      get(chatroom.value[index.value], 'user.username') === get(chatroom.value[index.value - 1], 'user.username')
    ) {
      return true;
    }
  }
});
const isNextUser = computed(() => {
  {
    if (
      !get(chatroom.value[index.value], 'character') &&
      get(chatroom.value[index.value], 'user.username') === get(chatroom.value[index.value + 1], 'user.username')
    ) {
      return true;
    }
  }
});
const closeImageModal = () => {
  isOpened.value = false;
};

const linkDisabled = computed(() => {
  if (message.value?.character?.slug === '-') return true;
  return false;
});

const posterProfileLink = computed(() => {
  return !message.value.character
    ? { name: 'profile', params: { username: message.value.user.username } }
    : { name: 'character-profile-new', params: { slug: message.value.character.slug } };
});

const isSameUserAndNextChar = computed(() => {
  if (
    get(chatroom.value[index.value], 'user.username') !== get(chatroom.value[index.value - 1], 'user.username') &&
    get(chatroom.value[index.value], 'user.username') === get(chatroom.value[index.value + 1], 'user.username') &&
    get(chatroom.value[index.value], 'character.id') !== get(chatroom.value[index.value + 1], 'character.id') &&
    get(chatroom.value[index.value + 1], 'character')
  ) {
    return true;
  }
});
const isUser = computed(() => {
  return user.value.username === message.value.user.username;
});

const reply = () => {
  emits(
    'reply',
    message.value.id,
    get(message.value, 'user.username', ''),
    message.value.text,
    message.value.image_url
  );
};
</script>

<style lang="sass" scoped>
.username-text
  min-width: 80px
.menu-icon-btn
  width: 18px
  height: 18px
  --padding-start: 5px
  --padding-end: 5px
  position: absolute
  z-index: 1
  top: -5px
  right: -5px
.dark .menu-icon
  &:not(i)
    color: black
.one-button
  width: 20px
  height: 18px
  background-color: rgba(0, 0, 0, 0.5)
  color: #FFF
  --padding-start: 0
  --padding-end: 0
  border-radius: 13px
  font-size: 12px
  right: 8px
  top: 5px
  position: absolute
  pointer-events: all
.break-word
  word-break: break-word
.type
  font-size: 10px
  font-weight: bold
  margin-bottom: -2px
  text-transform: uppercase
.set-reply-top
  margin-top: 42px !important
.set-reply-image
  margin-top: 110px !important
.set-left-margin
  margin-right: 46px
.set-image-top
  margin-top: 110px !important
.content
  width: auto
  .info-content
    font-size: 14px
    background-color: rgb(100 100 100 / 43%)
    border-radius: 7px
.dark .text-color
  color: black !important
.reply
  background: #c6c8d6
  border-radius: 0
  margin-top: 0px !important
  margin-left: 0px
  margin-bottom: 0px !important
.set-top-border
  border-top-right-radius: 0 !important
.set-receiver-border
  border-bottom-left-radius: 1.75px !important
  margin-left: 49px !important
.set-image-receiver-border
  margin-left: 4px !important
  border-bottom-left-radius: 1.75px !important
.set-middle-border
  margin-right: 43px !important
  border-top-right-radius: 7px !important
  border-bottom-right-radius: 7px !important
.set-message-border
  border-bottom-right-radius: 1.75px !important

.set-receiver-middle-border
  margin-left: 49px !important
  border-top-left-radius: 7px !important
  border-bottom-left-radius: 7px !important

.set-last-border
  border-top-right-radius: 2.75px !important
.set-recevier-last-border
  border-top-left-radius: 2.75px !important
.set-receiver-reply-margin
  margin-left: 50px
.first-message
  border-bottom-right-radius: 1.75px !important
  margin-right: 43px !important

.set-user-margin
  margin-right: 67px !important
  border-top-right-radius: 0 !important
.set-margin
  margin-right: 46px !important
  margin-bottom: -8px !important
.msg-text
  width: calc(100% - 520px)
  background: #FFF
  border-radius: 20px

.card
  box-shadow: 0px 3px 4px 1px rgba(35, 14, 51, 0.1)

.msg-send
  width: calc(100% - 200px)
  background: var(--ion-color-primary)
  border-radius: 20px
.img
  width: 150px
  height: 150px
  object-fit: cover
  border: solid gray 0.1px
  border-radius: 10px
.reply-img
  width: 110px
  height: 100px
  object-fit: cover

.message-poster-img
  width: 35px
  height: 35px
  border-radius: 20px
  border: solid gray 0.1px
  object-fit: cover
  margin-top: -4px
.message-poster-receiver-img
  width: 35px
  height: 35px
  border-radius: 20px
  border: solid gray 0.1px
  object-fit: cover
  margin-top: -3px
.disabled
  opacity: 0.75
  pointer-events: none
.text-black, .type
  color: #214163
</style>
