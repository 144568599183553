import { sendAnalyticsEvent } from '@/shared/services/analytics';
import { blabReactionsNames, reactionsNames, worldReactionsNames } from '@/shared/statics/reactions';
import { chAxios } from '@/shared/lib/axios';

export async function createReactionOnCharacter({
  objectId = 'character',
  target,
  reaction,
  fromRoulette = false,
}: {
  objectId: string;
  target: string;
  reaction: string;
  fromRoulette: boolean;
}) {
  const url = `/reactions/characters/`;
  try {
    const { data } = await chAxios().post(url, {
      object_id: objectId,
      target,
      reaction,
      fromRoulette,
    });
    sendAnalyticsEvent('sent a reaction', {
      reaction: reactionsNames[data.reaction],
      entityType: 'character',
      entityId: objectId,
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function createReactionOnBlab({
  objectId = 'blab',
  target,
  reaction,
}: {
  objectId: string;
  target: string;
  reaction: string;
}) {
  const url = `/reactions/blabs/`;
  try {
    const { data } = await chAxios().post(url, {
      object_id: objectId,
      target,
      reaction,
    });

    sendAnalyticsEvent('sent a reaction', {
      reaction: blabReactionsNames[data.reaction],
      entityType: 'blab',
      entityId: objectId,
    });
    return data;
  } catch (e) {
    throw e;
  }
}
export async function createReactionOnComments({
  objectId = 'comment',
  target,
  reaction,
}: {
  objectId: string;
  target: string;
  reaction: string;
}) {
  const url = `/reactions/comments/`;
  try {
    const { data } = await chAxios().post(url, {
      object_id: objectId,
      target,
      reaction,
    });

    return data;
  } catch (e) {
    throw e;
  }
}export async function createReactionOnFanArts({
  objectId = 'fanwork',
  target,
  reaction,
}: {
  objectId: string;
  target: string;
  reaction: string;
}) {
  const url = `/reactions/fanart/`;
  try {
    const { data } = await chAxios().post(url, {
      object_id: objectId,
      target,
      reaction,
    });

    return data;
  } catch (e) {
    throw e;
  }
}

export async function createReactionOnBlabReplies({
  objectId = 'blab',
  target,
  reaction,
}: {
  objectId: string;
  target: string;
  reaction: string;
}) {
  const url = `/reactions/blabreplies/`;
  try {
    const { data } = await chAxios().post(url, {
      object_id: objectId,
      target,
      reaction,
    });

    sendAnalyticsEvent('sent a reaction', {
      reaction: blabReactionsNames[data.reaction],
      entityType: 'blab reply',
      entityId: objectId,
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function createReactionOnWorld({
  objectId = 'world',
  reaction,
  target,
}: {
  objectId: string;
  reaction: string;
  target: string;
}) {
  const url = `/reactions/worlds/`;
  try {
    const { data } = await chAxios().post(url, {
      object_id: objectId,
      reaction,
      target,
    });
    sendAnalyticsEvent('sent a reaction', {
      reaction: worldReactionsNames[data.reaction],
      entityType: 'world',
      entityId: objectId,
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function createReactionOnImage({
  objectId = 'image',
  target,
  reaction,
  fromRoulette = false,
}: {
  objectId: string;
  target: string;
  reaction: string;
  fromRoulette: boolean;
}) {
  const url = `/reactions/images/`;
  try {
    const { data } = await chAxios().post(url, {
      object_id: objectId,
      target,
      reaction,
      fromRoulette,
    });
    sendAnalyticsEvent('sent a reaction', {
      entityType: 'image',
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function createReactionOnTaskSubmission({
  objectId = 'siteeventtasksubmission',
  target,
  reaction,
}: {
  objectId: string;
  target: string;
  reaction: string;
}) {
  const url = `/reactions/task-submissions/`;
  try {
    const { data } = await chAxios().post(url, {
      object_id: objectId,
      target,
      reaction,
    });
    sendAnalyticsEvent('sent a reaction', {
      entityType: 'tasksubmission',
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateReactionOnWorld({ reactionId, reaction }: { reactionId: string; reaction: string }) {
  const url = `/reactions/worlds/${reactionId}/`;
  try {
    const { data } = await chAxios().patch(url, {
      reaction,
    });
    sendAnalyticsEvent('updated reaction', {
      reaction: worldReactionsNames[data.reaction],
      entityType: 'world',
      entityId: data.object_id,
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateReactionOnCharacter({ reactionId, reaction }: { reactionId: string; reaction: string }) {
  const url = `/reactions/characters/${reactionId}/`;
  try {
    const { data } = await chAxios().patch(url, {
      reaction,
    });
    sendAnalyticsEvent('updated reaction', {
      reaction: reactionsNames[data.reaction],
      entityType: 'character',
      entityId: data.object_id,
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateReactionOnBlab({ reactionId, reaction }: { reactionId: string; reaction: string }) {
  const url = `/reactions/blabs/${reactionId}/`;
  try {
    const { data } = await chAxios().patch(url, {
      reaction,
    });
    sendAnalyticsEvent('updated reaction', {
      reaction: blabReactionsNames[data.reaction],
      entityType: 'blab',
      entityId: data.object_id,
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateReactionOnBlabReply({ reactionId, reaction }: { reactionId: string; reaction: string }) {
  const url = `/reactions/blabreplies/${reactionId}/`;
  try {
    const { data } = await chAxios().patch(url, {
      reaction,
    });
    sendAnalyticsEvent('updated reaction', {
      reaction: blabReactionsNames[data.reaction],
      entityType: 'blab reply',
      entityId: data.object_id,
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateReactionOnImage({ reactionId, reaction }: { reactionId: string; reaction: string }) {
  const url = `/reactions/images/${reactionId}/`;

  try {
    const { data } = await chAxios().patch(url, {
      reaction,
    });
    sendAnalyticsEvent('updated reaction', {
      entityType: 'image',
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateReactionOnTaskSubmission({
  reactionId,
  reaction,
}: {
  reactionId: string;
  reaction: string;
}) {
  const url = `/reactions/task-submissions/${reactionId}/`;
  try {
    const { data } = await chAxios().patch(url, {
      reaction,
    });
    sendAnalyticsEvent('updated reaction', {
      reaction: reactionsNames[data.reaction],
      entityType: 'tasksubmission',
      entityId: data.object_id,
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function deleteReactionOnWorld({ reactionId }: { reactionId: string }) {
  const url = `/reactions/worlds/${reactionId}/`;
  try {
    const { data } = await chAxios().delete(url);
    sendAnalyticsEvent('deleted reaction', {
      entityType: 'world',
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function deleteReactionOnCharacter({ reactionId }: { reactionId: string }) {
  const url = `/reactions/characters/${reactionId}/`;
  try {
    const { data } = await chAxios().delete(url);
    sendAnalyticsEvent('deleted reaction', {
      entityType: 'character',
    });
    return data;
  } catch (e) {
    throw e;
  }
}
export async function deleteReactionOnComments({ reactionId }: { reactionId: string }) {
  const url = `/reactions/comments/${reactionId}/`;
  try {
    const { data } = await chAxios().delete(url);
    sendAnalyticsEvent('deleted reaction', {
      entityType: 'comments',
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function deleteReactionOnFanArts({ reactionId }: { reactionId: string }) {
  const url = `/reactions/fanart/${reactionId}/`;
  try {
    const { data } = await chAxios().delete(url);
    sendAnalyticsEvent('deleted reaction', {
      entityType: 'comments',
    });
    return data;
  } catch (e) {
    throw e;
  }
}
export async function deleteReactionOnImage({ reactionId }: { reactionId: string }) {
  const url = `/reactions/images/${reactionId}/`;
  try {
    const { data } = await chAxios().delete(url);
    sendAnalyticsEvent('deleted reaction', {
      entityType: 'image',
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function deleteReactionOnBlab({ reactionId }: { reactionId: string }) {
  const url = `/reactions/blabs/${reactionId}/`;
  try {
    const { data } = await chAxios().delete(url);
    sendAnalyticsEvent('deleted reaction', {
      entityType: 'blab',
    });
    return data;
  } catch (e) {
    throw e;
  }
}
export async function deleteReactionOnBlabReply({ reactionId }: { reactionId: string }) {
  const url = `/reactions/blabreplies/${reactionId}/`;
  try {
    const { data } = await chAxios().delete(url);
    sendAnalyticsEvent('deleted reaction', {
      entityType: 'blab reply',
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function deleteReactionOnTaskSubmission({ reactionId }: { reactionId: string }) {
  const url = `/reactions/task-submissions/${reactionId}/`;
  try {
    const { data } = await chAxios().delete(url);
    sendAnalyticsEvent('deleted reaction', {
      entityType: 'tasksubmission',
    });
    return data;
  } catch (e) {
    throw e;
  }
}
