<template>
  <div class="report-card no-select" v-if="report">
    <ion-badge
      v-if="report.status === 'reviewed'"
      color="primary"
      class="report-badge"
    >Reviewed</ion-badge>
    <ion-badge
      v-else-if="report.evaluation"
      :color="reportColor"
      class="report-badge"
    >{{report.evaluation}}</ion-badge>
    <div class="report-card__content">
      <div class="report-card__title mb-1">{{ report.reported_user?.username }}</div>
      <div class="report-card__description mb-1">
        <p class="text-gray">Report Category: {{ report.report_category?.title || '(No category)' }}</p>
        <p class="text-gray" v-if="report.reported_content?.text">Reported Message: {{ report.reported_content.text }}</p>
        Reporting user <span v-if="report.reported_by">(<a target="_blank" :href="userLink">{{report.reported_by?.username}}</a>)</span> said: {{ report.comments || '(No description)' }}
      </div>
      <div :title="`${formatDateTime(report.modified)} UTC`" class="my-1">
        <span class="text-gray">Last updated {{ formatTimeAgo(report.modified) }}</span>
      </div>
      <div v-if="report.reported_content_type === 'socialspacechat_message'" class="context-messages mt-2">
        <div class="context-title">Message Context:</div>
        <div class="message-list">
          <!-- Display context messages first -->
          <template v-if="contextMessages.length">
            <div v-for="message in contextMessages" :key="message.id" class="message-item">
              <div class="message-header">
                <span class="username">{{ message.user.username }}</span>
                <span class="timestamp">{{ formatTimeAgo(message.created) }}</span>
              </div>
              <div class="message-content">{{ message.content }}</div>
            </div>
          </template>
          
          <!-- Display the reported message -->
          <div v-if="report.reported_content" class="message-item reported-message">
            <div class="message-header">
              <span class="username">{{ report.reported_content.user?.username }}</span>
              <span class="timestamp">{{ formatTimeAgo(report.reported_content.created) }}</span>
              <span class="reported-badge">Reported Message</span>
            </div>
            <div class="message-content">{{ report.reported_content.text }}</div>
          </div>

          <!-- Show a message if no context is available -->
          <div v-if="!contextMessages.length && !report.reported_content" class="no-context-message">
            No message context available
          </div>
        </div>
      </div>
      <div v-if="report.content_deleted" class="content-status warning my-1">
        <ion-icon name="trash-outline"></ion-icon>
        Content has been deleted
      </div>
      <div v-if="report.content_privacy" class="content-status info my-1">
        <ion-icon name="eye-outline"></ion-icon>
        Current privacy: {{ formatPrivacy(report.reported_content?.privacy) }}
      </div>
      <div class="report-card__actions">
        <a 
          v-if="report.reported_content_link" 
          :href="report.reported_content_link" 
          target="_blank" 
          rel="noopener noreferrer" 
          @click.stop
        >
          <ion-button color="danger" class="nested-btn">
            <span class="no-select">View Reported Content</span>
          </ion-button>
        </a>
        <ion-button v-else color="danger" class="nested-btn" disabled>
          <span class="no-select">No Content Link Provided</span>
        </ion-button>
        <a 
          v-if="report.reported_content_admin_link" 
          :href="report.reported_content_admin_link" 
          target="_blank" 
          rel="noopener noreferrer" 
          @click.stop
        >
          <ion-button color="danger" class="nested-btn">
            <span class="no-select">View in Admin Panel</span>
          </ion-button>
        </a>
        <ion-button v-else disabled color="danger">
          <span class="no-select">No Content Link Provided</span>
        </ion-button>
        <ion-button @click.stop="openEvalModal" class="nested-btn no-select">Evaluate</ion-button>
        
        <!-- New moderation action buttons -->
        <ion-button 
          v-if="report.reported_content_type && canDelete" 
          @click.stop="confirmDelete" 
          color="danger" 
          class="nested-btn no-select"
          :disabled="isDeleting || !report.can_delete_content || report.reported_content?.deleted"
        >
          <ion-spinner v-if="isDeleting" name="crescent"></ion-spinner>
          <span v-else>{{ deleteButtonText }}</span>
        </ion-button>
        <ion-button 
          v-if="report.reported_content_type && canUpdatePrivacy" 
          @click.stop="openPrivacyModal" 
          color="warning" 
          class="nested-btn no-select"
          :disabled="isUpdatingPrivacy"
        >
          <ion-spinner v-if="isUpdatingPrivacy" name="crescent"></ion-spinner>
          <span v-else>Update Privacy</span>
        </ion-button>
      </div>
    </div>

    <!-- Evaluation Modal -->
    <ion-modal :is-open="showEvalModal" ref="modal" trigger="open-modal" @willDismiss="closeEvalModal">
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="evalReport" size="large" color="primary">
              Save
            </ion-button>
          </ion-buttons>
          <ion-buttons slot="end">
            <ion-button @click="closeEvalModal">
              <i slot="icon-only" name="close" class="ti-close" />
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">
        <ion-list>
          <!-- Remove context messages section -->
          <ion-item>
            <ion-label>Report Evaluation</ion-label>
            <ion-select v-model="report.evaluation">
              <ion-select-option value="no_issue">No Issue</ion-select-option>
              <ion-select-option value="mild">Mild</ion-select-option>
              <ion-select-option value="moderate">Moderate</ion-select-option>
              <ion-select-option value="bad">Bad</ion-select-option>
              <ion-select-option value="inexcusable">Inexcusable</ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item>
            <ion-textarea label="User report" :value="report.comments || 'No comment'" disabled></ion-textarea>
          </ion-item>
          <ion-item>
            <ion-textarea label="Admin comments" v-model="report.reviewer_comments" auto-grow></ion-textarea>
          </ion-item>
          <ion-item v-if="currentCategory === 'General'">
            <ion-label>Review Status</ion-label>
            <ion-select v-model="report.status">
              <ion-select-option value="pending">Pending</ion-select-option>
              <ion-select-option value="reviewed">Reviewed</ion-select-option>
            </ion-select>
          </ion-item>
          <div class="text-center mt-3">
            <ion-button @click="evalReport" size="large" color="primary">
              Save
            </ion-button>
          </div>
        </ion-list>
      </ion-content>
    </ion-modal>

    <!-- Privacy Modal -->
    <ion-modal :is-open="showPrivacyModal" ref="privacyModal" trigger="open-privacy-modal" @willDismiss="closePrivacyModal">
      <ion-header>
        <ion-toolbar>
          <ion-title>Update Privacy</ion-title>
          <ion-buttons slot="end">
            <ion-button @click="closePrivacyModal">
              <i slot="icon-only" name="close" class="ti-close" />
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">
        <ion-list>
          <ion-item>
            <ion-label>Current Privacy</ion-label>
            <ion-text color="medium">{{ formatPrivacy(report.reported_content?.privacy) || 'Not set' }}</ion-text>
          </ion-item>
          <ion-item>
            <ion-label>New Privacy Setting</ion-label>
            <ion-select v-model="selectedPrivacy" placeholder="Select new privacy">
              <ion-select-option value="unlisted">Unlisted</ion-select-option>
              <ion-select-option value="private">Private</ion-select-option>
            </ion-select>
          </ion-item>
          <div class="text-center mt-3">
            <ion-button 
              @click="updatePrivacy" 
              size="large" 
              color="primary"
              :disabled="!selectedPrivacy || isUpdatingPrivacy"
            >
              <ion-spinner v-if="isUpdatingPrivacy" name="crescent"></ion-spinner>
              <span v-else>Update Privacy</span>
            </ion-button>
          </div>
        </ion-list>
      </ion-content>
    </ion-modal>

    <!-- Delete Confirmation Alert -->
    <ion-alert
      :is-open="showDeleteAlert"
      header="Confirm Delete"
      message="Are you sure you want to delete this content? This action cannot be undone."
      :buttons="[
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            showDeleteAlert = false;
          },
        },
        {
          text: 'Delete',
          role: 'confirm',
          handler: () => {
            deleteContent();
          },
        },
      ]"
    ></ion-alert>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, toRef, PropType } from 'vue';
import { formatDateTime, formatTimeAgo } from '@/shared/utils/dateTime';
import { adminStore } from '@/shared/pinia-store/admin';
import { User, Report as BaseReport } from '@/shared/types/static-types';

interface ChatMessage {
  id: string;
  content: string;
  text?: string;
  created: string;
  user: User;
}

// Extend base report but override content_privacy type
type Report = Omit<BaseReport, 'content_privacy'> & {
  reported_content_type?: string;
  previous_messages?: ChatMessage[];
  reported_content?: {
    id: string;
    user?: User;
    content?: string;
    text?: string;
    created?: string;
    privacy?: string;
    deleted?: boolean;
  };
  content_deleted?: boolean;
  content_privacy?: string;
  can_delete_content?: boolean;
  evaluation?: string;
  status?: string;
  reviewer_comments?: string;
};

const props = defineProps({
  report: {
    type: Object as PropType<Report>,
    required: true
  }
});

const showEvalModal = ref(false);
const showPrivacyModal = ref(false);
const showDeleteAlert = ref(false);
const selectedPrivacy = ref('');
const isDeleting = ref(false);
const isUpdatingPrivacy = ref(false);

const report = toRef(props, 'report');

const contextMessages = computed(() => {
  if (props.report?.previous_messages?.length) {
    return props.report.previous_messages.map((msg: any) => ({
      id: msg.id,
      content: msg.text,
      created: msg.created,
      user: msg.user
    }));
  }
  return [];
});

const openEvalModal = () => {
  showEvalModal.value = true;
};

const closeEvalModal = () => {
  showEvalModal.value = false;
};

const isCharacterOrWorld = computed(() => {
  return report.value?.reported_content_type === 'character' || report.value?.reported_content_type === 'world';
});

const canUpdatePrivacy = computed(() => {
  const supportedTypes = ['character', 'world', 'blab', 'chatroom', 'socialspace'];
  return report.value?.reported_content_type && supportedTypes.includes(report.value.reported_content_type);
});

const categoryStr = (categoryId: string | undefined) => {
  const { categories } = adminStore();
  if (!categoryId) return '(No category)';
  return categories.value.find((c: any) => c.id === categoryId)?.title;
}

const { currentCategory } = adminStore();

const reportColor = computed(() => {
  if (report.value?.evaluation === 'no_issue') return 'medium';
  if (report.value?.evaluation === 'bad') return 'warning';
  if (report.value?.evaluation === 'inexcusable') return 'danger';
  if (report.value?.evaluation === 'mild') return 'secondary';
  if (report.value?.evaluation === 'moderate') return 'tertiary';
  return 'light';
});

const evalReport = () => {
  const { saveReport } = adminStore();
  saveReport(report.value);
  closeEvalModal();
};

const baseUrl = computed(() => {
  const {
    public: { hostUrl },
  } = useRuntimeConfig();
  return hostUrl;
});

const userLink = computed(() => {
  return `${baseUrl.value}/profile/${report.value?.reported_by?.username}`;
});

const formatPrivacy = (privacy: string | undefined) => {
  if (!privacy) return '';
  const privacyMap: Record<string, string> = {
    'private': 'Private',
    'unlisted': 'Unlisted',
    'public': 'Public'
  };
  return privacyMap[privacy] || privacy;
};

const openPrivacyModal = () => {
  selectedPrivacy.value = 'P';
  showPrivacyModal.value = true;
};

const closePrivacyModal = () => {
  showPrivacyModal.value = false;
  selectedPrivacy.value = '';
};

const updatePrivacy = async () => {
  if (!report.value?.id || !selectedPrivacy.value) return;
  isUpdatingPrivacy.value = true;
  try {
    const { updateReportedContentPrivacy } = adminStore();
    const privacy = selectedPrivacy.value as 'private' | 'unlisted';
    await updateReportedContentPrivacy(report.value.id, privacy);
    closePrivacyModal();
  } finally {
    isUpdatingPrivacy.value = false;
  }
};

const confirmDelete = () => {
  showDeleteAlert.value = true;
};

const deleteContent = async () => {
  if (!report.value?.id) return;
  isDeleting.value = true;
  try {
    const { deleteReportedContent } = adminStore();
    await deleteReportedContent(report.value.id);
    showDeleteAlert.value = false;
  } finally {
    isDeleting.value = false;
  }
};

const canDelete = computed(() => {
  const deletableTypes = [
    'character', 
    'world', 
    'blab', 
    'comment', 
    'chatroom',
    'socialspace',
    'socialspacechat_message',
    'raffle'
  ];
  return report.value?.reported_content_type && 
         deletableTypes.includes(report.value.reported_content_type);
});

const deleteButtonText = computed(() => {
  if (report.value?.reported_content?.deleted) {
    return 'Content Deleted';
  }
  if (!report.value?.can_delete_content) {
    return 'Cannot Delete Content';
  }
  return 'Delete Content';
});

onUnmounted(() => {
  closeEvalModal();
  closePrivacyModal();
});
</script>

<style lang="sass" scoped>
.report-card
  position: relative
  padding: 1rem
  border: 1px solid #ccc
  border-radius: 0.5rem
  margin-bottom: 1rem
  background-color: #f9f9f9
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1)
  display: flex
  justify-content: space-between
  align-items: center
  .report-card__content
    flex: 1
    .report-card__title
      font-weight: bold
    .report-card__description
      font-size: 1rem
  .report-card__actions
    display: flex
    gap: 1rem
    flex-wrap: wrap
    margin-top: 1rem

.content-status
  display: flex
  align-items: center
  gap: 0.5rem
  padding: 0.25rem 0.5rem
  border-radius: 0.25rem
  font-size: 0.875rem
  &.warning
    background-color: rgba(var(--ion-color-danger-rgb), 0.1)
    color: var(--ion-color-danger)
  &.info
    background-color: rgba(var(--ion-color-primary-rgb), 0.1)
    color: var(--ion-color-primary)

    flex-wrap: wrap
    margin-top: 1rem

.content-status
  display: flex
  align-items: center
  gap: 0.5rem
  padding: 0.25rem 0.5rem
  border-radius: 0.25rem
  font-size: 0.875rem
  &.warning
    background-color: rgba(var(--ion-color-danger-rgb), 0.1)
    color: var(--ion-color-danger)
  &.info
    background-color: rgba(var(--ion-color-primary-rgb), 0.1)
    color: var(--ion-color-primary)

.report-badge
  top: 5px
  right: 5px
  --border-radius: 10px
  position: absolute

ion-spinner
  width: 1rem
  height: 1rem

.context-messages
  background: #f5f5f5
  border-radius: 8px
  padding: 12px
  margin-bottom: 16px

.context-title
  font-weight: bold
  margin-bottom: 8px
  color: #666

.message-list
  max-height: 300px
  overflow-y: auto
  border: 1px solid #ddd
  border-radius: 4px
  padding: 8px

.message-item
  padding: 8px
  border-bottom: 1px solid #eee
  &:last-child
    border-bottom: none
  &.reported-message
    background-color: #fff3f3
    border-left: 3px solid #ff4444
    margin: 8px 0
    position: relative

.message-header
  display: flex
  justify-content: space-between
  margin-bottom: 4px
  align-items: center
  .username
    font-weight: bold
    color: #444
  .timestamp
    color: #888
    font-size: 0.9em
  .reported-badge
    background: #ff4444
    color: white
    padding: 2px 6px
    border-radius: 4px
    font-size: 0.8em
    margin-left: 8px

.message-content
  white-space: pre-wrap
  word-break: break-word
  color: #333
  line-height: 1.4

.no-context-message
  text-align: center
  color: #666
  padding: 16px
  font-style: italic
  background: #f9f9f9
  border-radius: 4px
  margin: 8px 0

.debug-info, .debug-message-info
  background: #f0f0f0
  padding: 8px
  margin: 8px 0
  border-radius: 4px
  font-family: monospace
  pre
    margin: 4px 0
    white-space: pre-wrap
    word-break: break-word

.context-messages
  width: 100%
  padding: 10px 0

.context-header
  font-weight: bold
  margin-bottom: 10px
  color: #666

.message-item
  padding: 8px
  margin: 4px 0
  border-radius: 8px
  background: #f5f5f5

  &.reported-message
    background: #fff3f3
    border: 1px solid #ffcdd2

.message-user
  font-weight: bold
  margin-bottom: 4px

.message-content
  margin-bottom: 4px

.message-time
  font-size: 0.8em
  color: #666
</style>
