import a from 'anchorme';
import { sanitizeUrl } from '@braintree/sanitize-url';

import constants from '@/shared/statics/constants';
import { sanitizeHtml } from './html';

export function stripHtmlTags(value: string = '') {
  const noTags = (value || '').replace(/(<([^>]+)>)/gi, '');
  return (noTags + '')
    .replaceAll('&nbsp;', '')
    .replaceAll('&amp;', '')
    .replaceAll('&rsquo;', '')
    .replace(/&#\d+;/gm, (s) => String.fromCharCode((s.match(/\d+/gm) || [])[0] as any));
}

export function addTrackerToURL(url: string, source = '') {
  // if (!source) return url
  // let u = new Url(url)
  // u.query.utm_source = source
  // u.query.utm_medium = 'web'
  // u.query.utm_name = 'website_share'
  // return u.toString()
}

export function toTitleCase(str: string) {
  // https://stackoverflow.com/a/46959528/1692741
  return (str || '').replace(/(^|\s)\S/g, function (t) {
    return t.toUpperCase();
  });
}

export function truncateEasy(str: string, index = 30) {
  if (!str) return '';
  if (str.length <= index) return str;
  return str.slice(0, index) + '...';
}

export function linkify(input: string = '') {
  const options = {
    truncate: 20,
    emails: false,
    ips: false,
    files: false,
    attributes: {
      target: '_blank',
      rel: 'noopener noreferrer nofollow',
    },
  };
  const linked = a({ input, options });
  return linked;
}

export function linkifyAndTagUsers(input: string = '') {
  const options = {
    truncate: 20,
    emails: false,
    ips: false,
    files: false,
    attributes: {
      target: '_blank',
      rel: 'noopener noreferrer nofollow',
    },
  };
  const linked = a({ input, options });

  return sanitizeHtml(linkTaggedUsers(linked));
}

function linkTaggedUsers(str: string) {
  // TODO link @username to the profiles
  return str.replace(constants.tagUsernameRegex, (m) => {
    if (m.length > 1) {
      const url = `/profile/${m.trim().slice(1)}`;
      return `<a href="${url}" target="_blank" rel="noopener noreferrer nofollow">${m}</a>`;
    } else return m;
  });
}

export function generateRandomID() {
  // not a real guid but paired with a date for near-certain uniqueness
  const guidstr = _guid();
  const datestr = +new Date();
  return guidstr + '-' + datestr;
}

function _s4() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}

function _guid() {
  return _s4() + _s4() + '-' + _s4() + '-' + _s4() + '-' + _s4() + '-' + _s4() + _s4() + _s4();
}

export function identifyFileType(file64: string) {
  // magic numbers below
  const jpeg = '/9j/'; // hex: ffd8ff
  const png = 'iVBORw'; // hex: 89504e47
  const gif = 'R0lG'; // hex: 47494638 (truncated)
  const header = file64.split(',')[1].slice(0, 8);
  if (header.startsWith(jpeg)) return 'jpeg'; // 'image/jpg' is not official MIME
  if (header.startsWith(png)) return 'png';
  if (header.startsWith(gif)) return 'gif';
  return '';
}

export function emailStrCheck(email: string): boolean { 
  if (!email?.trim()) return false;
  const ind1 = email.indexOf('@');
  return ind1 > 0 && ind1 < email.length - 1;
}

export function getTopPriorityBadge(badges: string[]) {
  if (!badges) return '';
  let i = 0;
  let badge = '';
  for (; i < constants.badgePriorityList.length; i++) {
    badge = constants.badgePriorityList[i];
    if (badges.includes(badge)) return badge;
  }
  return '';
}

// TODO :Types
// https://stackoverflow.com/questions/18749591/encode-html-entities-in-javascript/39243641#39243641
const escapeChars: any = {
  '<': 'lt',
  '>': 'gt',
  '"': 'quot',
  '&': 'amp',
  "'": '#39',
};

let regexString = '[';
for (const key in escapeChars) {
  regexString += key;
}
regexString += ']';

const escapeRegex = new RegExp(regexString, 'g');

export function escapeHTML(str: string) {
  return str.replace(escapeRegex, function (m) {
    return '&' + escapeChars[m] + ';';
  });
}

const htmlEntities: any = {
  nbsp: ' ',
  lt: '<',
  gt: '>',
  quot: '"',
  amp: '&',
  apos: "'",
};

export function unescapeHTML(str: string) {
  if (!str) return '';
  return str.replace(/&([^;]+);/g, function (entity, entityCode) {
    let match;

    if (entityCode in htmlEntities) {
      return htmlEntities[entityCode];
      /* eslint no-cond-assign: 0 */
    } else if ((match = entityCode.match(/^#x([\da-fA-F]+)$/))) {
      return String.fromCharCode(parseInt(match[1], 16));
      /* eslint no-cond-assign: 0 */
    } else if ((match = entityCode.match(/^#(\d+)$/))) {
      return String.fromCharCode(~~match[1]);
    } else {
      return entity;
    }
  });
}

export function oauthUrlTokens(url: string) {
  const splittedUrl = url.split('?');
  const tokens = splittedUrl[1];
  const [token0, token1] = tokens.split('&');
  return {
    oauth_token: token0.split('=')[1],
    oauth_verifier: token1.split('=')[1],
  };
}

export function capitalizeFirstLetter(string: string) {
  const usedString = string || '';
  return usedString.charAt(0).toUpperCase() + usedString.slice(1);
}

export function capitalizeFirstLetterInPhrase(phrase: string) {
  return phrase
    .split(' ')
    .map((item) => capitalizeFirstLetter(item))
    .join(' ');
}

export const truncateText = (text = '', stop: number = 25, clamp?: string) => {
  const trimmedText = text.replace(/\s\s+/g, '');
  return trimmedText && trimmedText.slice(0, stop) + (stop < trimmedText.length ? clamp || '...' : '');
};

export function getClickableLink(link: string) {
  return link.startsWith('http://') || link.startsWith('https://') ? sanitizeUrl(link) : sanitizeUrl(`https://${link}`);
}

export function extractEmoji(str: string = '', count: number = 1) {
  return str
    .match(/(\p{Emoji_Presentation}|\p{Extended_Pictographic})/gu)
    ?.slice(0, count)
    .join('');
  // WARNING: This will also extract the following characters: 123456789#*
}

export function fixHtml(html: string) {
  const document = useDocument();
  const div = document.value?.createElement('div');
  if (!div) return '';
  div.innerHTML = html;
  return div.innerHTML;
}

export const wordCutoff = (s: string) => {
  if (!s) return '';
  try {
    s = s.substring(0, s.lastIndexOf(' '));
    return s;
  } catch (e) {
    return s;
  }
};

export const removePrefix = (s: string, prefix: string) => {
  if (s.startsWith(prefix)) {
    return s.slice(prefix.length);
  } else {
    return s;
  }
};

export const extractYoutubeID = (url: string) => {
  try {
    let i;
    let r;
    const rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/;
    r = url.match(rx);
    return r?.[1];
  } catch (e) {
    return '';
  }
};

export const isYoutubeVideoLinkValid = (url: string) => {
  return extractYoutubeID(url)?.length === 11;
};

export const toTitleSpaceCase = (str: string = '') => {
  if (str === 'MBTI') return str;
  return toTitleCase(str.replace(/([A-Z])/g, ' $1')).replace(/\( /g, '(').replace(/\/ /g, '/');
};

export const isValidMusicURL = (url: string): boolean => {
  const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
  const spotifyRegex = /^(https?:\/\/)?(open\.spotify\.com)\/.+$/;
  const soundCloudRegex = /^(https?:\/\/)?(www\.|on\.)?(soundcloud\.com)\/.+$/;
  const playlistRegex = /(youtube\.com\/playlist)/;
  if (playlistRegex.test(url)) {
    return false;
  }
  return youtubeRegex.test(url) || spotifyRegex.test(url) || soundCloudRegex.test(url);
};
