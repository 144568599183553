<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ion-header translucent>
      <ion-toolbar>
        <div class="d-flex justify-content-between align-items-center">
          <div />
          <div>{{ get(sticker, 'title') }}</div>
          <div>
            <ion-buttons slot="end">
              <ion-button class="close-button" @click="dismissModal">
                <i class="ti-close" />
              </ion-button>
            </ion-buttons>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>
    <div class="modal-inner-content">
      <div class="d-flex justify-content-center align-items-center p-3">
        <div class="h-100 w-100 position-relative">
          <div class="d-flex justify-content-center align-items-center w-100 image-wrapper">
            <img :src="get(sticker, 'image', '')" class="image-contain" />
          </div>
        </div>
      </div>
    </div>
    <ion-footer translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-center text-center align-items-center p-2">
          <ion-button color="transparent" class="purchase-btn" @click="purchaseSticker">
            <ChLoading size="sm" v-if="loading" class="spinner" />
            <div v-else class="text-black d-flex align-items-center">
              Buy for
              <div class="mx-1 token">
                <CurrencyImage :image="'/char.jpg'" :type="get(sticker, 'currency_type')" :img-width="20" />
                <inline-svg :src="displayImage" class="svg" />
              </div>

              <strong>{{ get(sticker, 'amount') }}</strong>
            </div>
          </ion-button>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-modal>
</template>

<script lang="ts" setup>
import { useVirtualItemCurrencyImage } from '~/composables/useVirtualItemCurrencyImage';

const props = defineProps({
  sticker: {
    type: Object,
    default: {},
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
});
const loading = ref(false);
const sticker = toRef(props, 'sticker');
const emits = defineEmits(['close', 'purchase']);
const displayImage = computed(() => {
  if (sticker.value) {
    const { imageFromItemsCurrency } = useVirtualItemCurrencyImage(sticker.value);
    return imageFromItemsCurrency.value;
  }
});

const purchaseSticker = async () => {
  loading.value = true;
  emits('purchase', sticker.value);

  loading.value = false;
};

const dismissModal = () => {
  emits('close');
};
</script>

<style lang="sass" scoped>
.image-wrapper
 height: calc(47vh - 80px)
 max-height: calc(47vh - 80px)
.svg
  width: 20px
  height: 20px
.purchase-btn
  border: 2px solid #e6e6e6
  border-radius: 20px
  color: #214163
.dark .toolbar
  --background: #17074c !important
.image-contain
  max-height: 100%
  height: auto
  width: auto !important
  max-width: 100% !important
  object-fit: cover


.modal-inner-content
  background: var(--ion-background-color, #fff)
  max-height: 42vh
  min-height: 42vh
  max-height: 42dvh
  min-height: 42dvh
  overflow: hidden
  overflow-x: hidden

.close-button
  width: 50px

.modal-big
  --width: 600px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
</style>
