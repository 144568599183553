<template>
  <div class="modal-inner-content">
    <div class="px-3 py-3">
      <ChLoading size="sm" class="mx-auto" v-if="loading" />
      <ComplimentBombVerticalCard
        v-else
        v-for="(gift, index) in giftCbData"
        :key="index"
        :svg-icon="get(gift, 'svgIcon')"
        :title="gift.title"
        :description="gift.text"
        :extraText="get(gift, 'extraText')"
        :type="get(gift, 'value')"
        :info="gift.info"
        :selectedInventoryItem="selectedInventoryItem"
        :show-badge="gift.showBadge"
        :displayBorder="true"
        @click="actionClicked(gift, index)"
        :class="{
          'mb-2 clickable-item-hov': giftCbData.length > 1,
        }"
      >
        <template #cbimage v-if="!isEmpty(animation)">
          <img loading="lazy" v-character-card-image :src="animation" class="animation" />
        </template>
      </ComplimentBombVerticalCard>

      <bomb-owned-badge v-if="showOuterBadge" :count="totalCount" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import ComplimentBombVerticalCard from '@/shared/components/ComplimentBombVerticalCard.vue';
import BombOwnedBadge from '@/shared/pages/compliment-bombs/components/ComplimentBombOwnedBadge.vue';
const props = defineProps({
  giftCbData: { type: Array, default: () => [] },
  showOuterBadge: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  animation: { type: String, default: null },
  selectedInventoryItem: { type: Object, default: () => {} },
});

const giftCbData: any = toRef(props, 'giftCbData');
const selectedInventoryItem: any = toRef(props, 'selectedInventoryItem');
const emits = defineEmits(['gifted']);
const totalCount = computed(() => {
  return get(selectedInventoryItem.value, 'amount');
});

const actionClicked = (gift: any, index: any) => {
  if (gift.count === 0) return;
  emits('gifted', index);
};
</script>
<style lang="sass" scoped>
.animation
  max-width:130px !important
  height: auto

.modal-inner-content
  background: #fff
  max-height: calc(85dvh - 45px)
  max-height: calc(85vh - 45px)
  overflow: auto
  overflow-x: hidden
</style>
