<template>
  <div class="clear-bg">
    <ion-page class="page scrollable no-padding">
      <div id="home-top" />
      <Announcement class="mb-3 mt-0 mt-md-n3" />

      <div class="quests-card position-relative pb-1">
        <home-quests-tabs
          :total-quests="totalQuests"
          :total-raffles="totalRaffles"
          :tabs="tabs"
          :new-counts="rafflesAndEventsCount"
          :quizzes-count="quizzesCount || 0"
          :quiz-notification-count="quizNotifsCount"
          :active-index="tabIndex"
          @tab-changed="tabChanged"
          @newFlag="newFlag"
        />

        <div
          v-if="noCharacters && currentTab === 'quizzes'"
          class="disabled-quizzes w-100 flex-column position-absolute d-flex align-items-center justify-content-center"
        >
          <div class="p-3 d-flex flex-column align-items-center justify-content-center text-center">
            Unlock Quizzes by Creating a Character
            <router-link :to="{ name: 'character-creator' }">
              <ion-button>Create character</ion-button>
            </router-link>
          </div>
        </div>
        <div
          v-if="disabledForNewUser && disabledRemainingMs > 0 && currentTab === 'events'"
          class="disabled-events position-absolute d-flex align-items-center justify-content-center"
        >
          <vue-countdown v-slot="{ days, hours, minutes, seconds }" :time="disabledRemainingMs">
            <span>
              <span class="no-select">Unlocks in </span>
              <span class="timer">
                <span class="countdown">
                  <span v-if="days" class="no-select">{{ days }}d </span
                  ><span v-if="hours" class="no-select">{{ hours }}h </span
                  ><span v-if="!days" class="no-select">{{ minutes }}m </span
                  ><span v-if="!days && !hours" class="no-select">{{ seconds }}s</span>
                </span>
              </span>
            </span>
          </vue-countdown>
        </div>
        <horizontal-scroll-raffle-card
          v-if="currentTab === 'raffles'"
          class="px-2"
          :raffles="raffles"
          :loading="isRaffleLoading"
          :raffle-entries="raffleEntries"
          :see-all-link="{ name: 'raffles', query: { tab: 'all' } }"
          :total-count="totalRaffles"
        />
        <horizontal-event-tasks
          :class="{
            hide: currentTab === 'raffles' || currentTab === 'quizzes' || currentTab === 'social',
          }"
          class="px-2"
          :see-all-link="{ name: 'events' }"
          :quests="quests"
          :loading-event="isEventLoading"
          :current-tab="currentTab"
          :total-count="totalQuests"
          @refresh="fetchHomeEventTasks"
        ></horizontal-event-tasks>

        <div v-if="currentTab === 'social'" class="px-2">
          <horizontal-compliment-bombs-card
            :complimentBombChars="complimentBombChars"
            :inventory="inventory"
            :cb-daily-limit="cbDailyLimit"
            :ff-daily-limit="ffDailyLimit"
            :complimentCharacters="complimentCharacters"
            :gift-inventory="giftInventory"
            :loading="isCompBombLoading"
            :is-cb-loader="isLoadingCbLimit"
            @updated="fetchInventoryAndChars"
            @fetch="fetchCbCharsAndCbLimit"
            @gifted="fetchInventoryDetails"
          />
        </div>

        <div v-if="currentTab === 'quizzes'">
          <div v-if="isQuizzesLoader" class="loading mx-2 d-flex justify-content-center align-items-center mt-2">
            <ion-skeleton-text
              v-for="(_, key) of [0, 1, 2, 3, 4, 5]"
              :key="key"
              :animated="true"
              style="width: 100%; height: 190px"
            />
          </div>
          <div v-else>
            <home-horizontal-quizzes-card
              class="px-2"
              :quizzes-count="quizzesCount || 0"
              :quizzes="mergedQuizzes"
              @fetch="fetchMoreQuizzes"
              @skip="fetchNowNotifications"
              :quizNotifsData="quizNotifsData"
              :see-all-link="{ name: 'quizzes' }"
            />
          </div>
        </div>
      </div>

      <top-banner class="mt-3" />

      <FeedPreview :version="2" />
      <thankyou-message-modal
        :is-open="isOpenThankyouModal"
        @close="closeThankyouModal"
        @open="closeAndOpenPrfRingModal"
        :is-virtual-item="isVirtualItem"
      />

      <ComplimentBombsGiftedModal
        :is-open="isOpenGiftModal"
        :loading="userLoading"
        :username="receivedUser.username"
        :section-description="`You can use it from your <strong>Inventory</strong> or in the <strong>Compliment Bomb section</strong> of the site!`"
        :button-text="'Say Thanks'"
        :button-icon="heartOutline"
        :animation="'/bombanimation.gif'"
        @open="closeGiftModalAndOpenFeedBackModal"
        @close="closeCbGiftModal"
      />
      <VirtualItemsGiftedModal
        :is-open="isOpenGiftVirtualItemModal"
        :user="receivedUser"
        @open="closeGiftVirtualItemModalAndOpenFeedBackModal"
        @close="closeVirtualItemsGiftModal"
      />
    </ion-page>
  </div>
</template>

<script setup lang="ts">
import TopBanner from './TopBanner.vue';
import Announcement from './Announcement.vue';
import { SiteEventTask, Paging, Tab, Raffle } from '@/shared/types/static-types';
import { heartOutline } from 'ionicons/icons';
import { getActiveEventTasks, getEventTasksCount } from '@/shared/actions/events';
import { COMPLIMENT_BOMB } from '@/shared/statics/constants';
import { homeQuestsTabs } from '@/shared/statics/tabs';
import { getRaffles, getRaffleWinners, getRafflesEntryStatus, getRafflesCount } from '@/shared/actions/raffles';
import { getQuizzes } from '@/shared/actions/quizzes';
import { getUserProfileById } from '@/shared/actions/users';
import { fetchNotifications } from '@/shared/actions/notifications';
import { featureFlags } from '@/shared/config/feature-flags';
import { authStore } from '@/shared/pinia-store/auth';
import FeedPreview from '@/shared/pages/feed/FeedPreview.vue';
import HorizontalEventTasks from './HorizontalEventTasks.vue';
import HorizontalScrollRaffleCard from './HorizontalScrollRaffleCard.vue';
import HomeHorizontalQuizzesCard from './HomeHorizontalQuizzesCard.vue';
import HomeQuestsTabs from './HomeQuestsTabs.vue';
import HorizontalComplimentBombsCard from './HorizontalSocialCard.vue';
import thankyouMessageModal from '@/shared/modals/ComplimentBombThankyouMessageModal.vue';
import ComplimentBombsGiftedModal from '@/shared/modals/ComplimentBombsSelectedAndGiftedModal.vue';
import {
  getComplimentBombDailyLimit,
  getCurrentlyBombedCharacters,
  getFanartFlareDailyLimit,
  getInventoryDetails,
} from '@/shared/actions/complimentBomb';
import { onboardingStore } from '@/shared/pinia-store/onboarding';
import VirtualItemsGiftedModal from '~/shared/modals/VirtualItemsGiftedModal.vue';

const { isNewUser } = onboardingStore();
const raffles = ref([]) as Ref<Raffle[]>;
const totalRaffles = ref(0);
const raffleEntries = ref<any>({});
const raffleWinners = ref([]);
const quizzes = ref([]);
const quizzesCount: any = ref('');
const quizNotifsData = ref([]);
const isQuizLoading = ref(true);
const isQuizzesLoader = ref(true);
const currentTab = ref('');
const quests = ref([]) as Ref<SiteEventTask[]>;
const totalQuests = ref(0);
const rafflesAndEventsCount = ref({});
const isOpenGiftModal = ref(false);
const isOpenGiftVirtualItemModal = ref(false);
const isOpenThankyouModal = ref(false);
const isVirtualItem = ref(false);

const { disabledForNewUser, disabledRemainingMs } = onboardingStore();

const tabs = computed(() => {
  return homeQuestsTabs
    .filter((tab) => tab.value !== 'quizzes' || (tab.value === 'quizzes' && featureFlags.quizzes))
    .filter((tab) => tab.value !== 'social' || (tab.value === 'social' && featureFlags.complimentBombs));
});

const { userHasCharacters } = authStore();

const noCharacters = computed(() => !isQuizLoading.value && !userHasCharacters.value);
const complimentCharacters = ref([]);
const complimentBombChars: any = ref([]);
const nowNotifsPage = ref(1);
const nowNotifsPaging = ref(null) as Ref<Paging | null>;
const quizNotifsCount = ref(0);
const inventory = ref({});
const giftInventory = ref({});
const cbDailyLimit = ref({});
const ffDailyLimit = ref({});
const isCompBombLoading = ref(true);
const receivedUser = ref<any>({});
const userLoading = ref(true);
const router = useRouter();
const route = useRoute();
const isLoadingCbLimit = ref(false);
const isEventLoading = ref(true);
const isRaffleLoading = ref(true);
const fetchConsumableInventoryDetails = async () => {
  const resp = await getInventoryDetails({ type: 'consumable', object: COMPLIMENT_BOMB });
  inventory.value = resp[0];
};

const fetchGiftInventoryDetails = async () => {
  const resp = await getInventoryDetails({ type: 'gift', object: COMPLIMENT_BOMB });
  giftInventory.value = resp[0];
};

const closeAndOpenPrfRingModal = () => {
  isOpenThankyouModal.value = false;
  router.replace({
    name: 'profile',
    params: { username: user.value.username },
    query: { itemId: get(route.query, 'itemId') },
  });
};

const fetchComplimentBombDailyLimit = async () => {
  isLoadingCbLimit.value = true;
  const resp = await getComplimentBombDailyLimit();
  const resp1 = await getFanartFlareDailyLimit();

  cbDailyLimit.value = resp;
  ffDailyLimit.value = resp1;
  isLoadingCbLimit.value = false;
};

const fetchInventoryAndChars = () => {
  fetchConsumableInventoryDetails();
  fetchCurrentlyBombedCharacters();
  fetchComplimentBombDailyLimit();
};

const fetchCbCharsAndCbLimit = () => {
  fetchComplimentBombDailyLimit();
  fetchCurrentlyBombedCharacters();
};

const fetchInventoryDetails = async (val: any) => {
  val === 'gift' ? await fetchGiftInventoryDetails() : await fetchConsumableInventoryDetails();
};

const fetchMoreQuizzes = () => {
  if (nowNotifsPaging.value) {
    fetchNowNotifications(nowNotifsPage.value + 1);
  }
};

const { user, getUserFreebieDetails, usersFreebie, loadCurrencyBoxImage } = authStore();

const getCurrentTab = () => {
  featureFlags.complimentBombs ? (currentTab.value = 'social') : (currentTab.value = 'events');
};

const eventsIndex = indexOf(
  tabs.value,
  find(tabs.value, (tab) => tab.value === 'events')
);

const tabIndex = ref(0);

const getTabIndex = () => {
  const tIndex = indexOf(
    tabs.value,
    find(tabs.value, (tab) => tab.value === currentTab.value)
  );
  if (tIndex !== -1) {
    tabIndex.value = tIndex;
  }

  tabIndex.value = tIndex;
  currentTab.value = tabs.value[tabIndex.value].value;
};

const tabChanged = ({ value }: Tab) => {
  currentTab.value = value;
  getTabIndex();
};

const newFlag = (obj: any) => {
  if (obj.raffles === false) {
    raffles.value.map((raffle: any) => (raffle.is_new = obj.raffles));
  } else if (obj.events === false) {
    quests.value.map((quest: any) => (quest.is_new = obj.events));
  }
};

const closeGiftModalAndOpenFeedBackModal = () => {
  isOpenGiftModal.value = false;
  isOpenThankyouModal.value = true;
};
const closeGiftVirtualItemModalAndOpenFeedBackModal = () => {
  isOpenGiftVirtualItemModal.value = false;
  isOpenThankyouModal.value = true;
  isVirtualItem.value = true;
};

const closeCbGiftModal = () => {
  isOpenGiftModal.value = false;
  router.push({ name: 'home' });
};

const closeVirtualItemsGiftModal = () => {
  isOpenGiftVirtualItemModal.value = false;
  router.push({ name: 'home' });
};

const fetchWinners = async () => {
  raffleWinners.value = await getRaffleWinners();
};

const fetchCurrentlyBombedCharacters = async () => {
  const resp = await getCurrentlyBombedCharacters();
  complimentBombChars.value = resp;
  complimentCharacters.value = resp.filter((char: any) => !char.complimented_by_me && char.user !== user.value.id);
  if (currentTab.value === 'social' && isEmpty(complimentBombChars.value)) {
    currentTab.value = 'events';
    getTabIndex();
    isCompBombLoading.value = false;
  } else {
    isCompBombLoading.value = false;
  }
};

const mergedQuizzes = computed(() => {
  const arrayA = quizNotifsData.value.map((item: any) => ({ ...item, type: 'now' }));
  const arrayB = quizzes.value.map((item: any) => ({ ...item, type: 'quiz' }));
  return [...arrayA, ...arrayB];
});

const fetchRafflesEntries = async (results: [], reset = false) => {
  const resp = await getRafflesEntryStatus(map(results, 'id'));
  raffleEntries.value = reset ? keyBy(resp, 'raffle') : { ...raffleEntries.value, ...keyBy(resp, 'raffle') };
};

const fetchRafflesAndEventsCount = async () => {
  const resp = await getRafflesCount();
  const resp1 = await getEventTasksCount();
  rafflesAndEventsCount.value = { raffles: resp.new_count, events: resp1.new_count };
};

const fetchRaffles = async () => {
  const { results, ...pagingRes } = await getRaffles({ page: 1, ordering: 'end_date', page_size: 10 });
  fetchRafflesEntries(results);
  raffles.value = results;
  totalRaffles.value = pagingRes.count;
  isRaffleLoading.value = false;
};

const closeThankyouModal = () => {
  isOpenThankyouModal.value = false;
  router.replace({ name: 'home' });
};

const fetchHomeEventTasks = async () => {
  const data = await getActiveEventTasks();
  quests.value = data.results;
  totalQuests.value = data.count;
  // reorder quests, pushing the completed ones to the right
  quests.value.sort((a, b) => {
    if (a.user_submission_count && !b.user_submission_count) return 1;
    if (!a.user_submission_count && b.user_submission_count) return -1;
    return 0;
  });
  isEventLoading.value = false;
};

const fetchQuizzes = async () => {
  const resp = await getQuizzes({ ordering: `-avg_submission_day`, page: 1, page_size: 15 });
  quizzes.value = resp.results;
  quizzesCount.value = resp.count;
  isQuizzesLoader.value = false;
};

const fetchNowNotifications = async (offset = 1) => {
  const { results } = await fetchNotifications(offset, 4, false, 'include_now');
  quizNotifsData.value = offset === 1 ? results?.results : quizNotifsData.value.concat(results?.results);
  nowNotifsPage.value = offset;
  nowNotifsPaging.value = results?.next;
  quizNotifsCount.value = results?.count;
  isQuizLoading.value = false;
};

const fetchUserById = async () => {
  const userId = String(get(route.query, 'userId' as string));
  const resp = await getUserProfileById(userId);
  receivedUser.value = resp;
  userLoading.value = false;
};

onMounted(async () => {
  getCurrentTab();
  getTabIndex();
  tabIndex.value = isNewUser.value ? eventsIndex : 0;
  currentTab.value = isNewUser.value ? 'events' : 'social';
  const { updateTempSocial } = authStore();

  if (featureFlags.complimentBombs) await fetchConsumableInventoryDetails();
  await fetchRafflesAndEventsCount();
  if (featureFlags.currency) await getUserFreebieDetails();
  fetchWinners();
  if (featureFlags.quizzes) fetchNowNotifications();
  if (featureFlags.complimentBombs) {
    fetchCbCharsAndCbLimit();
    await fetchGiftInventoryDetails();
    updateTempSocial({
      fanArtCount: 1,
      complimentCount: 1,
      tempAvailableCompliment: null,
      tempAvailableFanArt: null,
      totalFanArtCount: null,
      totalComplimentedCount: null,
    });
  }

  if (featureFlags.quizzes) fetchQuizzes();
  if (featureFlags.currency) await loadCurrencyBoxImage();

  fetchHomeEventTasks();
  fetchRaffles();
  if (get(route.query, 'giftId') && get(route.query, 'userId')) {
    isOpenGiftModal.value = true;
    fetchUserById();
  }
  if (get(route.query, 'id') && get(route.query, 'userId') && get(route.query, 'itemId')) {
    isOpenGiftVirtualItemModal.value = true;
    fetchUserById();
  }
});
</script>

<style lang="sass" scoped>
.disabled-quizzes, .disabled-events
  background: rgba(209, 171, 245, 0.8)
  z-index: 1000
  width: 100%
  border-radius: 0 0 8px 8px
  height: calc(100% - 41px)
  color: rgb(103, 28, 164)
  font-size: 30px
  font-weight: bold
  @media(max-width:700px)
    height: calc(100% - 41px)
  .timer
    color: #FFF
.see-all-text
  font-size: 14px
  background: #7050B7 !important
  --background: #7050B7 !important
  color: white
  text-decoration: underline
  white-space: break-spaces
  height: 115px
  border-radius: 10px
.loading
  grid-gap: 6px
  ion-skeleton-text
    border-radius: 10px
.clear-bg
  background: transparent

.auto-scroll-container
  max-width: 100%
  overflow: hidden
  position: relative
  border-radius: 10px
  border: solid #f4e9b0 2px
  background: linear-gradient(to right, rgb(254 230 57) 9%, rgb(242 172 48) 56%, rgb(239 158 45) 98%, rgb(237 145 42) 100%)
.auto-scroll-content
  white-space: nowrap
  overflow: hidden
  display: inline-block
  animation: auto-scroll-content 40s linear infinite

  & p
    display: inline-block
    margin-right: 15px
    margin-top: 15px
@keyframes auto-scroll-content
  0%
    transform: translate(0, 0)

  100%
    transform: translate(-80%, 0)
.hide
  display: none

.v-enter-active,
.v-leave-active
  transition: opacity 1s ease

.v-enter-from,
.v-leave-to
  opacity: 0
.top-nav
  display: grid
  position: relative
  gap: 15px 10px
  max-width: 100%
  margin-top: 12px
  grid-template-columns: repeat(4, 1fr)
.top-nav-btn
  color: white
  position: relative
  border-radius: 10px
  background-color: #8928C4
  padding: 0.25rem 0.15rem
  height: 42px
  line-height: 18px
  text-align: center
  font-size: 16px
  span
    user-select: none !important
.hcarousel
  margin-top: 1.5rem
  margin-bottom: 1.5rem
@media (max-width: 767px)
  .top-nav
    display: grid
    position: relative
    gap: 5px 5px
    max-width: 100%
    margin-top: 12px
    grid-template-columns: repeat(2, 1fr)

  .hcarousel
    margin-top: 0

.section-spacer-bottom
  margin-bottom: 42px !important
.section-spacer-top
  margin-top: 12px !important
.nav-new
  top: -10px
  right: 4px
  border-radius: 8px
  z-index: 1
  position: absolute

.quests-card
  border: 3px solid #7050B7
  border-radius:10px
  margin-top: -2px
  height: 258px

.page
  padding: 36px 24px !important
  @media(max-width: 600px)
    padding: 12px !important
.infinite-home
  --background: transparent
  height: calc(100% - 58px - 40px)
  overflow: auto
  &::-webkit-scrollbar
    display: none
</style>
