<template>
  <ion-page
    id="entire-page"
    class="scrollable customize-character-profile-page position-relative"
    :style="displayCustomizeSection ? 'padding: 13px 0 !important' : 'padding-bottom: 12px'"
  >
    <div class="section">
      <div class="p-3 d-flex align-items-center lines">
        <inline-svg src="/theme-page-bg.svg" class="mr-1 icon" />
        <div class="title">Whole Page Background</div>
      </div>
      <ThemeCustomizeMobileBg
        class="p-3"
        :isBold="true"
        :fontSize="subFontSize"
        :pageOptions="[{ abbr: 'page', name: 'Color' }]"
      />
      <div v-if="featureFlags.backgroundOverlay">
        <hr class="mx-3" />
        <CustomizationProfileAnimation
          :character="character"
          :display-title="true"
          :fontSize="subFontSize"
          :setMargin="setMargin"
          @tryOn="emits('tryOn')"
        />
      </div>
    </div>
    <div class="mt-2 section" v-for="(section, index) in themeSection" :key="index">
      <div class="d-flex align-items-center p-3 lines">
        <inline-svg :src="section.iconSrc" height="30" width="30" class="mr-1 icon" />
        <div class="title">{{ section.title }}</div>
      </div>
      <div class="p-3">
        <Header
          v-if="section.value === 'topsection' && displayCustomizeSection"
          :character="character"
          :is-draft="isDraft"
          :isMobCustomize="true"
          :customize="currentTemplate"
        />
        <div
          v-else-if="section.value === 'infoSections' && displayCustomizeSection"
          :style="infoSecStyle"
          class="rest-top py-2"
        >
          <div class="d-flex flex-column align-items-center p-2">
            <div class="info-sec w-100 d-flex flex-column align-items-start" style="height: auto">
              <div class="info-title">Section title</div>
              <div class="text">Section Default text</div>
            </div>
          </div>
        </div>
        <div class="p-2 mt-2 container w-100">
          <div class="d-flex align-items-center">
            <inline-svg src="/palette-primary.svg" class="mr-1 icon" />
            <div class="sub-title">Colors:</div>
          </div>
          <ThemeCustomizeMobileBg :pageOptions="section.pageOptions" fontSize="16px" />
        </div>

        <ThemeStyles :sections="section.sections" />
        <ThemeTextSection
          fontSize="18px"
          :text-options="section.textOptions"
          :displayContainer="displayContainer"
          :isSectionColor="true"
        />
      </div>
    </div>
  </ion-page>
</template>

<script lang="ts" setup>
import { characterStylizeStore } from '~/shared/pinia-store/character-stylize';
import ThemeCustomizeMobileBg from '~/shared/components/customize-bar/themes/theme-customize-mobile-bg.vue';
import ThemeStyles from '~/shared/components/customize-bar/themes/theme-styles.vue';
import ThemeTextSection from '~/shared/components/customize-bar/themes/theme-text-section.vue';
import Header from './components/Header.vue';
import CustomizationProfileAnimation from '~/shared/components/customize-bar/CustomizationProfileAnimation.vue';
import { characterProfileStore } from '~/shared/pinia-store/character-profile';
import { mainStore } from '~/shared/pinia-store/main';
import { featureFlags } from '~/shared/config/feature-flags';
const { currentTemplate } = characterStylizeStore();
const props = defineProps({
  fontSize: {
    type: String,
    default: '22px',
  },
  subFontSize: {
    type: String,
    default: '20px',
  },
  setMargin: {
    type: Boolean,
    default: false,
  },
  displayContainer: {
    type: Boolean,
    default: false,
  },
  displayCustomizeSection: {
    type: Boolean,
    default: false,
  },
});
const route = useRoute();
const { dark } = mainStore();
const emits = defineEmits(['tryOn']);
const { character } = characterProfileStore();

const topSection = computed(() => {
  return [
    {
      name: 'profilePic',
      icon: '/person-circle-outline.svg',
      title: 'Profile Pic',
      borders: [
        { label: 'None', value: 'none', style: {} },
        { label: 'Solid', value: 'solid', style: { borderStyle: 'solid', borderColor: '#eaecf6' } },
        { label: 'Dotted', value: 'dotted', style: { borderStyle: 'dotted', borderColor: '#7a7e9b' } },
        {
          label: 'Dashed',
          value: 'dashed',
          style: { borderStyle: 'dashed', borderColor: '#7a7e9b', marginRight: '4px' },
        },
      ],
      shadows: [
        { label: 'None', value: 'none' },
        { label: 'A little', value: 'alittle' },
        { label: 'A lot', value: 'alot' },
      ],
    },
    {
      name: 'topsection',
      icon: '/theme-style-icon.svg',
      title: 'Style',
      borders: [
        { label: 'None', value: 'none', style: {} },
        { label: 'Solid', value: 'solid', style: {} },
        { label: 'Dotted', value: 'dotted', style: { borderStyle: 'dotted' } },
        { label: 'Dashed', value: 'dashed', style: { borderStyle: 'dashed', marginRight: '4px' } },
      ],
      shadows: [
        { label: 'None', value: 'none' },
        { label: 'A little', value: 'alittle' },
        { label: 'A lot', value: 'alot' },
      ],
      edges: [
        { label: 'Sharp', value: 'sharp', radius: '0px' },
        { label: 'Round', value: 'round', radius: '6px' },
        { label: 'Very Round', value: 'veryround', radius: '20px' },
      ],
    },
  ];
});
const infoSection = computed(() => {
  return [
    {
      name: 'infoSections',
      icon: '/theme-style-icon.svg',
      title: 'Style',
      borders: [
        { label: 'None', value: 'none', style: {} },
        { label: 'Solid', value: 'solid', style: {} },
        { label: 'Dotted', value: 'dotted', style: { borderStyle: 'dotted' } },
        { label: 'Dashed', value: 'dashed', style: { borderStyle: 'dashed', marginRight: '4px' } },
      ],
      shadows: [
        { label: 'None', value: 'none' },
        { label: 'A little', value: 'alittle' },
        { label: 'A lot', value: 'alot' },
      ],
      edges: [
        { label: 'Sharp', value: 'sharp', radius: '0px' },
        { label: 'Round', value: 'round', radius: '6px' },
        { label: 'Very Round', value: 'veryround', radius: '20px' },
      ],
    },
  ];
});
const themeSection = computed(() => {
  return [
    {
      title: 'Top Section',
      value: 'topsection',
      iconSrc: '/top-section-icon.svg',
      textOptions: [
        { abbr: 'charName', name: 'Character Name' },
        { abbr: 'quote', name: 'Quote' },
      ],
      pageOptions: [
        { abbr: 'topSection', name: 'Background:' },
        { abbr: 'buttons', name: 'Buttons:' },
      ],
      sections: topSection.value,
    },
    {
      title: 'Info Sections',
      value: 'infoSections',
      iconSrc: '/info-icon.svg',
      textOptions: [
        { abbr: 'titles', name: 'Titles' },
        { abbr: 'default', name: 'Default Fonts' },
      ],
      pageOptions: [{ abbr: 'infoSection', name: 'Info Sections' }],
      sections: infoSection.value,
    },
  ];
});
const getCharNameFont = computed(() => {
  return get(currentTemplate.value, 'data.theme.text.charName.font', 'Roboto');
});

const infoSecStyle = computed(() => {
  const theme = get(currentTemplate.value, 'data.theme', {});
  const edgeMap = { sharp: '0px', round: '10px', veryround: '20px' };

  return {
    background: theme.backgrounds?.infoSection,
    border: `3px ${theme.styles?.infoSections?.border || 'none'} ${theme.styles?.infoSections?.color || ''} !important`,
    'box-shadow':
      theme.styles?.infoSections?.shadow === 'alittle'
        ? '0 2px 13px rgb(0 0 0 / 15%)'
        : theme.styles?.infoSections?.shadow === 'alot'
        ? '3px 2px 20px #635d5d'
        : '0px 0px 0px',
    'border-radius': edgeMap[theme.styles?.sectionEdges] || '20px',
  };
});
const titlesTextColor = computed(() => {
  return get(currentTemplate.value, 'data.theme.text.titles.color');
});

const titlesTextFont = computed(() => {
  return get(currentTemplate.value, 'data.theme.text.titles.font', 'Roboto');
});

const titlesTextBold = computed(() => {
  return get(currentTemplate.value, 'data.theme.text.titles.bold') ? 'bold' : 'normal';
});

const titlesTextItalic = computed(() => {
  return get(currentTemplate.value, 'data.theme.text.titles.italics') ? 'italic' : 'normal';
});

const defaultTextColor = computed(() => {
  const defaultClr = dark.value ? '#FFFFFF' : '#000000';
  return `${get(currentTemplate.value, 'data.theme.text.default.color', defaultClr)}`;
});
const defaultTextFont = computed(() => {
  return get(currentTemplate.value, 'data.theme.text.default.font', 'Roboto');
});

const defaultTextBold = computed(() => {
  return get(currentTemplate.value, 'data.theme.text.default.bold') ? '800' : '100';
});

const defaultTextItalic = computed(() => {
  return get(currentTemplate.value, 'data.theme.text.default.italics') ? 'italic' : 'normal';
});

const isDraft = computed(() => route.name === 'character-draft-stylize');
</script>

<style lang="sass" scoped>
.lines
  border-bottom: 2px solid #eaecf6
.change-font
  font-family: v-bind(getCharNameFont)
.container
  border: 2px solid #eaecf6
  border-radius:12px
.section
  background: white
  border-radius: 16px
.sub-title
  font-size: 18px
  font-weight: bolder
  color: #214163
.title
  font-size: v-bind(fontSize)
  font-weight: bolder
  color: #214163
  @media(max-width: 335px)
    font-size: 1rem !important
.dark .title
  color: #214163 !important
.info-title
  font-size: 22px
  color: v-bind(titlesTextColor) !important
  font-weight: v-bind(titlesTextBold)
  font-style: v-bind(titlesTextItalic)
  font-family: v-bind(titlesTextFont)

.text
  font-size: 16px
  color: v-bind(defaultTextColor) !important
  font-weight: v-bind(defaultTextBold)
  font-style: v-bind(defaultTextItalic)
  font-family: v-bind(defaultTextFont)

.icon
  width: 26px
  height: 26px
.customize-character-profile-page
  overflow: hidden


.top-sec
  border-radius: 9px

.info-sec
  border-radius: 9px
  height: 30px

.small-temp-char-button
  overflow: hidden
  height: 16px
  --border-radius: 0px
  margin-top: 0px

.rest-top
  text-align: center
  border-radius: 12px

.cover
  height: 40px
  border-top-left-radius: 9px
  border-top-right-radius: 9px
</style>
