<template>
  <div>
    <ion-segment
      scrollable
      mode="md"
      style="gap: 8px; overflow: auto hidden"
      class="tabs compact no-shadow bg-transparent customize-tabs"
      :value="activeIndex"
      @ion-change="segmentChanged($event)"
    >
      <ion-segment-button
        v-for="(tab, index) of tabs"
        :key="index"
        class="bg-transparent"
        :class="{ 'quizzes-modal': isModal, 'inventory-modal': isInventory }"
        :value="index"
      >
        <div>
          <ion-label style="color: #5d5c5c; font-size: 14px">{{ tab.name }}</ion-label>
        </div>
      </ion-segment-button>
    </ion-segment>
  </div>
</template>

<script lang="ts" setup>
import { Tab } from '@/shared/types/static-types';

const props = defineProps({
  tabs: {
    type: Array<Tab>,
    default: [] as Tab[],
  },
  activeIndex: {
    type: Number,
    default: 0,
  },
  isModal: {
    type: Boolean,
    default: false,
  },
  isInventory: {
    type: Boolean,
    default: false,
  },
});

const tabs = toRef(props, 'tabs');
const isModal = toRef(props, 'isModal');
const activeIndex = toRef(props, 'activeIndex');
const emits = defineEmits(['update:activeIndex', 'tab-changed']);
const tabColor = computed(() => {
  return isModal.value ? 'var(--ion-color-primary)' : '#214163';
});
const segmentChanged = ($event: CustomEvent) => {
  const tabIndex = $event.detail.value as number;
  const tab = tabs.value[tabIndex];

  emits('update:activeIndex', tabIndex);
  emits('tab-changed', tab);
};
</script>
<style scoped lang="sass">
.inventory-modal
  max-height: 30px !important
  min-height: 30px !important
  height: 30px !important
ion-segment-button
  --indicator-color: transparent
  --background-checked:v-bind(tabColor)
  border-radius: 10px
  border: 2px solid #214163
  color: #5d5c5c
  max-width: 100%
  border: 2px solid
  max-height: 40px
  min-height: 40px

.quizzes-modal
  max-height: 33px !important
  min-height: 33px !important
  height: 33px !important
  border-radius: 20px !important

// .shrink
//   flex: none !important

.segment-button-checked
  background-color: var(--background-checked) !important
  border: 0px solid !important
  *
    color: white !important
</style>
