<template>
  <div>
    <div>
      <div :style="`background: ${bg} !important`" class="wrapper layout-wrapper h-100">
        <ClientOnly>
          <nav-bar class="fixed-mobile" />
        </ClientOnly>
        <div :style="prfBackgroundImageStyle">
          <div id="main-content" class="w-100 no-auth-main-content" @scroll="handleScroll" :style="bgOverlay">
            <div class="content" :style="route.name === 'event-details-preview' ? `background: ${background}` : ''">
              <slot class="router" />
              <ClientOnly>
                <BottomFooter
                  v-if="!isShortHeight && (!keyboardOpen || !isLessThan570px) && route.name !== 'event-details-preview'"
                  :style="`background: ${background}`"
                  class="footer"
                />
              </ClientOnly>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import NavBar from '@/shared/components/nav-bar/NavBar.vue';
import BottomFooter from '@/shared/components/footer.vue';
import { mainStore } from '../pinia-store/main';

const { backgroundImageStyle, animation, bgOverlayMode } = useBackgroundOverlay();
const { prfBackgroundImageStyle, image } = useBackgroundImage();
const { isShortHeight } = useWindowSize();
const { keyboardOpen } = mainStore();
const { isLessThan570px } = useWindowSize();
const { bg: b, isCharacterProfile } = useCharPageColors();

const bg = computed(() => b.value.replace(' !important', ''));
const route = useRoute();
useCanonicalUrl();

const background = computed(() => {
  return isDisplay.value ? '' : bg.value;
});
const handleScroll = () => {
  const eventScroll = document.querySelector('.event-detail-preview') as any;
  const scrollableElement = document.querySelector('.no-auth-main-content') as any;
  if (!eventScroll) return;
  const maxHeight = isLessThan570px.value ? 200 : 500;
  const minHeight = isLessThan570px.value ? 130 : 250;
  const scrollLimit = 500;
  const scrollY = scrollableElement.scrollTop;
  let newHeight = maxHeight - (scrollY / scrollLimit) * (maxHeight - minHeight);

  if (newHeight < minHeight) {
    newHeight = minHeight;
  }

  eventScroll.style.height = `${newHeight}px`;
};

const isDisplay = computed(() => {
  return ((route.name === 'profile' || isCharacterProfile.value) && (!isEmpty(image.value) || !isEmpty(animation.value)));
});

const bgOverlay = computed(() => {
  return isDisplay.value ? backgroundImageStyle.value : {};
});

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>

<style scoped lang="sass">
.wrapper
  background: v-bind(bg) !important
.layout-wrapper
  background: v-bind(bg) !important
.force-full-height

#main-content
  height: calc(var(--doc-height) - var(--default-nav-bar-height) - var(--safe-ios-margin) - var(--safe-ios-margin)/3)
  flex-grow: 1
  flex-shrink: 0
  background: v-bind(background) !important
  background-size: 100% auto !important
  background-repeat: v-bind(bgOverlayMode) !important
  overflow-y: auto
  overflow-x: hidden
  .content
    width: 100%
    height: 100%
    ::v-deep
      .ion-page.page
        min-height: 100%
        overflow: visible
        margin: auto
        position: relative !important
        max-width: 690px
</style>
