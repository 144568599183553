<template>
  <div class="badge-container">
    <div class="d-flex align-items-center justify-content-between">
      <div class="owned-text">Owned:</div>
      <div style="padding: 0px 7px 0px 7px">{{ count }}</div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import constants from '@/shared/statics/constants';
const props = defineProps({
  count: { type: Number, default: 0 },
  type: { type: String, default: '' },
  info: { type: String, default: '' },
});
const inventories = constants.inventories;
const isGift = computed(() => props.type === inventories.gift);
</script>
<style scoped lang="sass">
.owned-text
  background: white
  border-bottom-left-radius: 20px
  border-top-left-radius: 20px
  padding: 4px 9px 4px 9px
.dark .owned-text
  background: #17074c
.badge-container
  background: rgb(249, 249, 255)
  padding: 4px
  border-radius: 20px
.dark .badge-container
  background: #214163
.badge
  padding: 6px 9px
  background: white
  color: #214163
  &.gift-badge
    background: #fa6c91
    font-size: 11px
    border-radius: 7px
    padding: 3px 6px 3px 6px !important
    margin-top: 0.25rem
    color: white
  &.count-badge
    border-radius: 7px
    font-size: 12px
    padding: 2px 3px 2px 2px !important
    color: var(--ion-color-primary)
    margin-left: auto !important
</style>
