<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ion-header translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-between align-items-center my-2">
          <div></div>
          <div class="title">Inventory</div>
          <ion-buttons slot="end">
            <ion-button class="close-button" @click="dismissModal()">
              <i class="ti-close" />
            </ion-button>
          </ion-buttons>
        </div>
        <inventoryTabs
          class="px-3 pb-3"
          :tabs="tabs"
          :active-index="tabIndex"
          :is-inventory="true"
          @tab-changed="tabChanged"
        />
      </ion-toolbar>
    </ion-header>

    <div class="modal-inner-content">
      <div class="p-3 text-black" v-if="currentTab === 'items'">
        <div class="my-1">Click on an item card to know more about and/or use it</div>
        <div class="user-compliment p-3 mb-2" v-for="(inv, index) in cbInventories" :key="index">
          <div class="compliment-bomb-section">
            <div class="compliment-bomb-content position-relative">
              <div
                class="position-absolute d-flex info-icon align-items-center justify-content-center clickable-item-hov"
                :class="{ 'info-cons-icon': inv.value === inventoryTypes.consumable }"
                @click="openInfoDetails(inv)"
              >
                <i class="ti-info-alt" />
              </div>
              <inline-svg class="comp-bomb-icon" :src="inv.svg" />
              <ion-badge class="compliment-bomb-badge mx-1">x{{ inv.amount }}</ion-badge>
            </div>

            <div class="compliment-text-content text-black">
              <compliment-bomb-character-selection
                v-if="inv.value === inventoryTypes.consumable"
                @updated="emits('updated')"
                :inventories="inventories"
                :selectedInventoryItem="selectedItem"
                :class="{ 'hide-cursor': get(inv, 'amount') === 0 }"
              >
                <ion-button class="use-compliment-btn" :disabled="get(inv, 'amount') === 0" @click="action(inv)"
                  >Use</ion-button
                >
              </compliment-bomb-character-selection>

              <div
                class="d-flex align-items-center mx-auto"
                :class="{ 'set-direction': inv.value === inventoryTypes.gift }"
              >
                <div
                  class="clickable-item-hov"
                  :class="{ 'mt-1': inv.value === inventoryTypes.gift }"
                  @click="openGetMoreModal(inv)"
                >
                  Get More
                </div>

                <div
                  class="mx-2"
                  style="font-size: 22px"
                  v-if="isGiftAndConsAmount && inv.value === inventoryTypes.consumable"
                >
                  |
                </div>
                <GiftComplimentBomb
                  :inventories="inventories"
                  :selected-inventory-item="selectedItem"
                  :class="{ 'is-disabled hide-cursor': inv.value === inventoryTypes.gift && get(inv, 'amount') <= 0 }"
                  @updated="fetchInventoryDetails"
                >
                  <div
                    class="clickable-item-hov"
                    v-if="inv.value === inventoryTypes.consumable && isGiftAndConsAmount"
                    @click="action(inv)"
                  >
                    <i class="ti-gift gift-icon" /> Gift!
                  </div>
                  <div v-else-if="inv.value === inventoryTypes.gift">
                    <ion-button class="use-compliment-btn" :disabled="get(inv, 'amount') <= 0" @click="action(inv)">
                      <div class="d-flex align-items-center">
                        <i class="ti-gift gift-icon mr-1" />

                        <div class="mt-1">Gift!</div>
                      </div>
                    </ion-button>
                  </div>
                </GiftComplimentBomb>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="p-3 text-black">
        <div class="d-flex align-items-center mb-2">
          <inline-svg src="/icons/complimentBomb-consumable.svg" height="35" width="35" />

          <span class="item-title">Compliment Bomb</span>
        </div>
        <div class="sub-title">My Characters Who Got Bombed</div>
        <ChLoading size="md" class="mx-auto" v-if="currUserloading" />
        <div class="py-2" v-else>
          <div v-if="!isEmpty(otherUsersBombedChars)">
            <compliment-bomb-vertical-card
              v-for="(character, index) in otherUsersBombedChars"
              :key="index"
              :character="character"
              :title="title(character, 'bombed')"
              @close="dismissModal"
            />
          </div>
          <div v-else class="no-data">No Character Bombed yet!</div>
        </div>
        <div class="sub-title">Characters I've Bombed</div>
        <ChLoading size="md" class="mx-auto" v-if="loading" />
        <div v-else>
          <Grid v-if="!isEmpty(myBombedChars)" :scrollSm="false" :sm="3" :lg="5" :md="4">
            <CharacterCard
              v-for="character in myBombedChars"
              :key="`char-${character.id}`"
              class="grid-item"
              :character="character"
              :compliment-bomb-hub="true"
              @close="dismissModal"
            />
          </Grid>
          <div v-else class="no-data">No Character Bombed yet!</div>
        </div>
        <hr />
        <div class="d-flex align-items-center mb-2">
          <inline-svg src="/icons/fanartFlare-consumable.svg" height="35" width="35" />

          <span class="item-title">FanArt Flare</span>
        </div>
        <div class="sub-title">My Characters Who Got Flared</div>
        <ChLoading size="md" class="mx-auto" v-if="currUserloading" />
        <div class="py-2" v-else>
          <div v-if="!isEmpty(otherUsersBombedCharsArtFlared)">
            <compliment-bomb-vertical-card
              v-for="(character, index) in otherUsersBombedCharsArtFlared"
              :key="index"
              :character="character"
              :title="title(character, 'flared')"
              @close="dismissModal"
            />
          </div>
          <div v-else class="no-data">No Character Flared yet!</div>
        </div>
        <div class="sub-title">Characters I've Flashed</div>
        <ChLoading size="md" class="mx-auto" v-if="loading" />
        <div v-else>
          <Grid v-if="!isEmpty(myBombedCharsArtFlared)" :scrollSm="false" :sm="3" :lg="5" :md="4">
            <CharacterCard
              v-for="character in myBombedCharsArtFlared"
              :key="`char-${character.id}`"
              class="grid-item"
              :character="character"
              :compliment-bomb-hub="true"
              @close="dismissModal"
            />
          </Grid>
          <div v-else class="no-data">No Character Flared yet!</div>
        </div>
      </div>
    </div>
    <ion-footer translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-center text-center align-items-center my-2">
          <ion-button class="get-more-btn" @click="openGetMoreItemsModal">
            <inline-svg src="/icons/add-square.svg" class="mr-1" />
            Get More Items</ion-button
          >
        </div>
      </ion-toolbar>
    </ion-footer>
    <SocialItemsModal
      :is-open="isOpenSocialModal"
      :showOwnedBadge="false"
      :purchase-more="true"
      :inventories="inventories"
      @close="closeSocialItemsModal"
    />
    <compliment-bomb-use-and-purchase-modal
      :is-open="isOpenPurchaseBombModal"
      :inventory="inventory"
      :purchase-more="true"
      :selectedInventoryItem="selectedItem"
      @close="closePurchaseModal"
      @purchase="closeModal"
    />

    <gift-compliment-bomb-modal
      :is-open="isOpenGiftComplimentBombModal"
      modal-title="Info"
      :show-footer="false"
      :selectedInventoryItem="selectedItem"
      @close="isOpenGiftComplimentBombModal = false"
    />
  </ion-modal>
</template>

<script lang="ts" setup>
import ComplimentBombVerticalCard from '@/shared/components/ComplimentBombVerticalCard.vue';
import ComplimentBombCharacterSelection from '@/shared/pages/compliment-bombs/components/ComplimentBombCharacterSelection.vue';
import ComplimentBombUseAndPurchaseModal from './ComplimentBombUseAndPurchaseModal.vue';
import Grid from '@/shared/components/storage/Grid.vue';
import SocialItemsModal from '~/shared/modals/SocialItemsModal.vue';
import GiftComplimentBombModal from '@/shared/modals/GiftComplimentBombModal.vue';
import inventoryTabs from '@/shared/pages/quizzes/components/quizzes-tabs.vue';
import CharacterCard from '@/shared/components/EventCharacterCard.vue';
import { getUserComplimentBombedChars } from '@/shared/actions/complimentBomb';
import constants, { COMPLIMENT_BOMB, FANART_FLARE } from '@/shared/statics/constants';
import GiftComplimentBomb from '@/shared/pages/compliment-bombs/components/GiftComplimentBomb.vue';
import { inventoryHomeTabs } from '../statics/tabs';
import { Tab } from '../types/static-types';

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
  inventories: {
    type: Object,
    default: {},
  },
});
const emits = defineEmits(['close', 'view', 'updated', 'gifted']);
const isOpen = toRef(props, 'isOpen');
const isOpenPurchaseBombModal = ref(false);
const isOpenGiftComplimentBombModal = ref(false);
const inventoryTypes = constants.inventories;
const charCbType = constants.charComplimentbombType;
const myBombedChars: any = ref([]);
const myBombedCharsArtFlared: any = ref([]);
const otherUsersBombedChars: any = ref([]);
const otherUsersBombedCharsArtFlared: any = ref([]);
const loading = ref(false);
const currUserloading = ref(false);
const tempInvType = ref({});
const tabs = ref(inventoryHomeTabs);
const selectedItem = ref({});
const currentTab = ref('items');
const tabIndex = ref(0);
const isOpenSocialModal = ref(false);
const inventories = toRef(props, 'inventories');
const inventory = computed(() => inventories.value?.compliment_bomb_consumable || {});
const giftInventory = computed(() => inventories.value?.compliment_bomb_gift || {});
const cbInventories = computed(() => {
  return Object.keys(inventories.value).map((key) => {
    let svg;
    let title;

    if (key === 'compliment_bomb_consumable') {
      svg = '/compliment-bomb.svg';
      title = 'Compliment Bomb';
    } else if (key === 'compliment_bomb_gift') {
      svg = '/wrapped-cbomb.svg';
      title = 'Gift Compliment Bomb';
    } else if (key === 'fanart_flare_consumable') {
      svg = '/fanart-flare.svg';
      title = 'FanArt Flare';
    } else if (key === 'fanart_flare_gift') {
      svg = '/wrapped-fanart-flare.svg';
      title = 'Gift FanArt Flare';
    } else if (!svg) {
      svg = '';
      title = '';
    }

    return {
      svg: svg,
      amount: get(inventories.value[key], 'amount', 0),
      value: get(inventories.value[key], 'type', ''),
      key: key,
      type: get(inventories.value[key], 'object', ''),
      title: title,
    };
  });
});

const action = (item: any) => {
  selectedItem.value = item;
};

const getTabIndex = () => {
  const tIndex = indexOf(
    tabs.value,
    find(tabs.value, (tab) => tab.value === currentTab.value)
  );
  if (tIndex !== -1) {
    tabIndex.value = tIndex;
  }
};

const closeSocialItemsModal = () => {
  isOpenSocialModal.value = false;
  dismissModal();
};
const fetchCbChars = async (bombType: any, type: any) => {
  const resp = await getUserComplimentBombedChars({ bomb_type: bombType, type });
  return resp;
};

const openGetMoreItemsModal = () => {
  isOpenSocialModal.value = true;
};

const fetchInventoryDetails = (val: any) => {
  tempInvType.value = val;
  emits('gifted', tempInvType.value);
};

const tabChanged = ({ value }: Tab) => {
  currentTab.value = value;
  getTabIndex();
};

const fetchCurrUserCb = async () => {
  currUserloading.value = true;
  const resp = await fetchCbChars(charCbType.bombedByMe, COMPLIMENT_BOMB);

  myBombedChars.value = resp;
  currUserloading.value = false;
};

const fetchCurrUserFf = async () => {
  currUserloading.value = true;
  const resp = await fetchCbChars(charCbType.bombedByMe, FANART_FLARE);
  myBombedCharsArtFlared.value = resp;
  currUserloading.value = false;
};

const fetchOtherUsersCb = async () => {
  loading.value = true;
  const resp = await fetchCbChars(charCbType.bombedByOthers, COMPLIMENT_BOMB);
  otherUsersBombedChars.value = resp;
  loading.value = false;
};

const fetchOtherUsersFf = async () => {
  loading.value = true;
  const resp = await fetchCbChars(charCbType.bombedByOthers, FANART_FLARE);
  otherUsersBombedCharsArtFlared.value = resp;
  loading.value = false;
};

const openInfoDetails = async (val: any) => {
  selectedItem.value = val;
  isOpenGiftComplimentBombModal.value = true;
};
const dismissModal = () => {
  emits('close');
};

watch(isOpen, async () => {
  if (isOpen.value) {
    await fetchCurrUserCb();
    await fetchCurrUserFf();
    await fetchOtherUsersFf();
    await fetchOtherUsersCb();
    getTabIndex();
  }
});

const isGiftAndConsAmount = computed(() => {
  if (get(inventory.value, 'amount', 0) <= 0 && get(giftInventory.value, 'amount', 0) <= 0) return false;
  else return true;
});

const openGetMoreModal = (val: any) => {
  if (val) selectedItem.value = val;
  isOpenPurchaseBombModal.value = true;
};

const closePurchaseModal = () => {
  isOpenPurchaseBombModal.value = false;
};

const closeModal = () => {
  closePurchaseModal();
  dismissModal();
};

const title = (character: any, type: any) => {
  return `
  <span  class="clickable-item-hov">${get(
    character,
    'info.name',
    'unknown'
  )}</span> <span class="text-black" style="pointer-events: none">is being currently ${type}</span>`;
};
</script>
<style lang="sass" scoped>
.get-more-btn
  --background: linear-gradient(0deg, #A83CFF, #A83CFF),linear-gradient(90deg, rgba(126, 224, 255, 0.4) 0%, rgba(95, 169, 238, 0) 100%)
.get-more-btn::part(native)
  color: white
.info-cons-icon
  top: 8px !important
  left: 6px !important
.info-icon
  width: 20px
  height: 20px
  color: #FFF
  top: 6px
  left: 2px
  background: #0A0928E5
  font-size: 12px
  border-radius: 12px
.set-direction
  flex-direction: column-reverse
.hide-cursor
  pointer-events: none !important
.gift-icon
  font-weight: bold
.compliment-bomb-content
  align-items: center
  display: flex
  @media(max-width:299px)
    margin-left: auto !important
    margin-right: auto !important
.compliment-text-content
  font-weight: bold
  color: #214163
  display: flex
  flex-wrap: wrap
  flex-direction: column
  margin-left: auto !important
  @media(max-width:299px)
    width: 100%

.grid-item
  @media(min-width:1200px)
    width: calc(20% - 5px) !important
    min-width: calc(20% - 5px) !important
.compliment-bomb-section
  align-items: center
  display: flex
  @media(max-width:299px)
    flex-wrap: wrap
.use-compliment-btn
  width: 180px
  max-width: 100%
  min-width: 100%
  font-size: 18px
  font-weight: bold
  @media(max-width:500px)
    width:140px

.compliment-bomb-badge
  background: white
  font-weight: bold
  display: flex
  border: 1px solid #EAECF6
  height: 26px
  width: 50px
  align-items: center
  justify-content: center
  min-width: 50px
  max-width: 50px
  color: #AE38E5
  border-radius: 9px
  font-size: 15px
.comp-bomb-icon
  width: 71px
  height: 71px
  @media(max-width:340px)
    width: 50px
    height: 50px
.dark .toolbar
  --background: #17074c !important
.modal-inner-content
  background: var(--ion-background-color, #fff)
  max-height: 70vh
  max-height: 70dvh
  overflow-x: hidden
  overflow-y: auto
.modal-big
  --width: 550px
  --height: auto
  --border-radius: 10px
  @media(max-width: 576px)
    --width: 100%
.close-button
  width: 50px
.sub-title
  font-size: 18px
  font-weight: bold
.item-title
  font: bolder 22px "Roboto Slab", sans-serif
  -webkit-text-fill-color: #214163
.dark .item-title
  font: bolder 22px "Roboto Slab", sans-serif
  -webkit-text-fill-color: #fff
.title
  color: #214163
  font-size: 18px
  font-weight: bold

.user-compliment
  border-radius: 10px
  border: 2px solid #cfcaca
</style>
