<template>
  <ion-modal
    class="modal-cb"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ModalHeader
      :modal-title="modalTitle"
      :showGoBackButton="showGoBackButton"
      :showCloseButton="true"
      @close="dismissModal"
    />
    <modal-compliment-bomb-content
      :giftCbData="updatedCbomb"
      :selectedInventoryItem="selectedInventoryItem"
      :show-outer-badge="true"
    />

    <ion-footer translucent>
      <ion-toolbar class="toolbar">
        <div
          class="d-flex align-items-center"
          :class="
            selectedInventoryItem.value === inventoryTypes.consumable
              ? 'justify-content-between '
              : 'justify-content-center'
          "
          v-if="get(selectedInventoryItem, 'amount') > 0 && !purchaseMore"
        >
          <GiftComplimentBomb
            v-if="isEmpty(character)"
            :inventories="inventories"
            :selectedInventoryItem="selectedInventoryItem"
            @updated="fetchInventoryDetails"
          >
            <ion-button fill="clear" class="gift-btn">
              <ion-icon :icon="giftOutline" class="icon" />
              <span class="mx-1">{{
                `Send as gift ${selectedInventoryItem.value === inventoryTypes.consumable ? 'instead!' : ''}`
              }}</span>
            </ion-button>
          </GiftComplimentBomb>

          <ion-button
            v-if="selectedInventoryItem.value === inventoryTypes.consumable"
            class="use-btn"
            :class="{ 'set-padding': !isEmpty(character) }"
            @click="emits('use')"
            >Use</ion-button
          >
        </div>
        <div v-else class="d-flex justify-content-center align-items-center text-center">
          <ion-button class="token-btn" @click="buyComplimentBomb">
            <img class="token-icon mr-1" src="/quiz_token.png" />
            Buy</ion-button
          >
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-modal>
</template>

<script lang="ts" setup>
import { giftOutline } from 'ionicons/icons';
import ModalHeader from './ModalComponents/ModalHeader.vue';
import constants from '@/shared/statics/constants';
import ModalComplimentBombContent from './ModalComponents/ModalComplimentBombContent.vue';
import GiftComplimentBomb from '@/shared/pages/compliment-bombs/components/GiftComplimentBomb.vue';
const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
  character: {
    type: Object,
    default: {},
  },
  inventories: {
    type: Object,
    default: () => {},
  },
  showGoBackButton: {
    type: Boolean,
    default: true,
  },
  purchaseMore: {
    type: Boolean,
    default: false,
  },
  selectedInventoryItem: {
    type: Object,
    default: () => {},
  },
});
const selectedInventoryItem = toRef(props, 'selectedInventoryItem');
const emits = defineEmits(['close', 'purchase', 'gifted', 'sent', 'updated', 'use', 'buy']);
const isOpen = toRef(props, 'isOpen');
const inventoryTypes = constants.inventories;
const inventories = toRef(props, 'inventories');
const svgIcon = ref('/compliment-bomb.svg');
const router = useRouter();
const purchaseMore: any = toRef(props, 'purchaseMore');
const tempInvType = ref('');
const character = toRef(props, 'character');
const totalCount = computed(() => {
  return get(selectedInventoryItem.value, 'amount');
});

const isGiftCountOrPurchaseMore = computed(() => {
  return get(selectedInventoryItem.value, 'amount') > 0 && !purchaseMore.value;
});

const modalTitle = computed(() => {
  return isGiftCountOrPurchaseMore.value
    ? `${
        get(selectedInventoryItem.value, 'type') === 'fanart_flare'
          ? `${get(selectedInventoryItem.value, 'value') === 'gift' ? 'Gift FanArt Flare' : 'FanArt Flare'}`
          : `${get(selectedInventoryItem.value, 'value') === 'gift' ? 'Gift Compliment Bomb' : 'Compliment Bomb'}`
      } ${isEmpty(character.value) ? '' : character.value.info.name}`
    : `Buy a ${get(selectedInventoryItem.value, 'type') === 'fanart_flare' ? 'FanArt Flare' : 'Compliment Bomb'}`;
});
const description = computed(() => {
  return get(selectedInventoryItem.value, 'value') === 'gift'
    ? `Share one with a friend, <strong>because you can't use these for yourself</strong>`
    : `<strong>Use on ${
        isEmpty(character.value) ? 'any Character on the site' : `${character.value.info.name}.`
      }  </strong> ${
        isEmpty(character.value) ? '(even yours). The Character' : character.value.info.name
      }  will then be <strong>targeted to a wave of ${
        get(selectedInventoryItem.value, 'type') === 'fanart_flare' ? 'fanart' : 'compliments'
      }</strong> from other users.`;
});

const sectionTitle = computed(() => {
  return isGiftCountOrPurchaseMore.value
    ? `TARGET ${isEmpty(character.value) ? ' A CHARACTER' : character.value.info.name} FOR ${
        get(selectedInventoryItem.value, 'type') === 'fanart_flare' ? 'FANART' : 'COMPLIMENTS'
      }`
    : `${
        get(selectedInventoryItem.value, 'type') === 'fanart_flare'
          ? `${get(selectedInventoryItem.value, 'value') === 'gift' ? 'Gift FanArt Flare' : 'FanArt Flare'}`
          : `${get(selectedInventoryItem.value, 'value') === 'gift' ? 'Gift Compliment Bomb' : 'Compliment Bomb'}`
      }`;
});

const fetchInventoryDetails = (val: any) => {
  tempInvType.value = val;
  emits('gifted', tempInvType.value);
};

const subTitle = computed(() => {
  if (get(selectedInventoryItem.value, 'amount') <= 0 || purchaseMore.value)
    return `<strong>Target a Character for ${
      get(selectedInventoryItem.value, 'type') === 'fanart_flare' ? 'Fanart' : 'Compliments'
    }</strong>`;
  else '';
});

const dismissModal = (val: any) => {
  emits('close', val);
};

const updatedCbomb = computed(() => {
  return [
    {
      svgIcon: get(selectedInventoryItem.value, 'svg') || svgIcon.value,
      title: sectionTitle.value,
      text: description.value,
      extraText: subTitle.value,
      count: totalCount.value,
      showBadge: false,
    },
  ];
});

const buyComplimentBomb = () => {
  purchaseMore.value ? emits('purchase') : dismissModal('');
  emits('buy');

  router.push({ name: 'shop' });
};
</script>
<style lang="sass" scoped>
.icon
  font-weight: bold
.token-icon
  width: 20px
  height: 20px
.token-btn
  --background: linear-gradient(to right, rgb(215 27 217), rgb(180 51 225))
  border: 3px solid #214062
  border-radius: 10px
  font-size: 17px
  font-weight: bold
  @media(min-width:550px)
    width: 20%
.use-btn
  --background: var(--ion-color-primary)
  margin-left: auto
  font-weight: bolder
  font-size: 14px
  padding-right: 14px
.use-btn::part(native)
  color: white !important
.set-padding
  padding-right: 0px !important
.gift-btn
  --color: #00b4c5
  text-decoration: underline
  font-size: 14px
.dark .toolbar
  --background: #17074c !important

.modal-inner-cb-content
  background: #fff
  max-height: 70vh
  max-height: 70dvh
  overflow: auto
  overflow-x: hidden

.modal-cb
  --width: 450px
  --height: auto
  --border-radius: 10px
  @media(max-width: 470px)
    --width: 100%
.close-button
  width: 50px
.title
  color: #214163
  font-size: 20px
  font-weight: bold
  text-align: center
  align-items: center
</style>
