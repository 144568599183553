import { computed } from 'vue';
import { get } from 'lodash-es';
import { authStore } from '@/shared/pinia-store/auth';
import { characterStylizeStore } from '@/shared/pinia-store/character-stylize';
import { featureFlags } from '~/shared/config/feature-flags';
import { profileStore } from '~/shared/pinia-store/profile';

export const useBackgroundImage = (): any => {
  const { userTryOnBgImage } = authStore();
  const { currentTemplate } = characterStylizeStore();
  const { currentProfile } = profileStore();
  const route = useRoute();
  const imageMode = ref('');
  const isImageApplied = computed(() => {
    return (
      [
        'character-profile-draft-new',
        'character-profile-new',
        'character-draft-stylize',
        'character-stylize',
        'profile',
      ].includes(route.name as string) && !isEmpty(userTryOnBgImage.value)
    );
  });
  const isCharProfile = computed(() => {
    return ['character-profile-draft-new', 'character-profile-new'].includes(route.name as string);
  });

  const isCharStylize = computed(() => {
    return ['character-draft-stylize', 'character-stylize'].includes(route.name as string);
  });

  const image = computed(() => {
    if (featureFlags.backgroundImage) {
      if (isImageApplied.value) {
        return get(userTryOnBgImage.value, 'image');
      } else {
        return route.name === 'profile'
          ? get(currentProfile.value, 'customize_profile.background_image.image')
          : isCharStylize.value || isCharProfile.value
          ? get(currentTemplate.value, 'data.background_image.image')
          : null;
      }
    }
  });

  const mode = computed(() => {
    if (featureFlags.backgroundImage) {
      if (isImageApplied.value) {
        imageMode.value = get(userTryOnBgImage.value, 'image_mode');
      } else {
        imageMode.value =
          route.name === 'profile'
            ? get(currentProfile.value, 'customize_profile.background_image.image_mode')
            : get(currentTemplate.value, 'data.background_image.image_mode');
      }

      return imageMode.value === 'scale' ? 'no-repeat' : 'repeat';
    }
  });

  const prfBackgroundImageStyle = computed(() => {
    const backgroundImage = `url(${image.value}) !important`;
    const backgroundRepeat = `${mode.value} !important`;
    if (mode.value === 'repeat') {
      return {
        backgroundImage: backgroundImage,
        backgroundRepeat: backgroundRepeat,
      };
    } else {
      return {
        backgroundImage: backgroundImage,
        backgroundRepeat: backgroundRepeat,
        backgroundSize: 'cover !important',
      };
    }
  });

  return {
    prfBackgroundImageStyle,
    mode,
    image,
  };
};
