<template>
  <div>
    <scroller
      ref="scrollerRef"
      :scroll-size="300"
      :threshold="20"
      :no-gradient-background="true"
      @resize="recalcScroll"
    >
      <ion-skeleton-text v-if="loading" :animated="true" class="comp-bomb-field mt-2" />
      <div v-else>
        <div class="compliment-bombs-card d-flex align-items-center">
          <div class="d-flex flex-column">
            <div class="inventory-container px-1 pb-1">
              <div
                class="d-flex justify-content-between align-items-center clickable-item-hov"
                @click="onClickInventory"
              >
                <span class="sub-text">Inventory</span>
                <div><i class="ti-angle-right back-icon" /></div>
              </div>

              <div class="inventory-container" v-for="(item, index) in invItems" :key="index">
                <compliment-bomb-character-selection
                  @updated="updatedGiftCharSelection"
                  @gifted="fetchInventoryDetails"
                  :inventories="inventories"
                  :displayUseModal="true"
                  :showGoBackButton="false"
                  :selectedInventoryItem="selectedInventoryItem"
                  :class="{ 'is-disabled': get(item, 'amount') === 0 }"
                >
                  <div
                    class="d-flex justify-content-between align-items-center px-2 position-relative clickable-item-hov"
                    @click="action(item)"
                  >
                    <i class="ti-info-alt info-icon" @click.stop="openInfoDetails(item)" />
                    <div class="d-flex align-items-center">
                      <inline-svg :src="item.svg" height="22" width="22" />
                      <i v-if="get(item, 'value') === 'gift'" class="ti-gift gift-icon" />
                    </div>
                    <div class="amount">{{ item.amount }}</div>
                  </div>
                </compliment-bomb-character-selection>
              </div>
            </div>
            <ion-button fill="clear" class="featured-char" @click="featuredCharacterInSocial">
              <span class="mx-1">Get Featured Here!</span>
            </ion-button>
          </div>
          <compliment-bombs-card
            v-for="(character, index) in complimentBombChars"
            :key="index"
            :cb-daily-limit="cbDailyLimit"
            :ff-daily-limit="ffDailyLimit"
            :cbRewards="cbRewards"
            :complimentCharacters="complimentCharacters"
            :character="character"
            :is-cb-loader="isCbLoader"
            v-show="!isEmpty(complimentBombChars)"
            @fetch="fetchCbLimit"
            @updated="cBombUpdated"
          />
        </div>

        <div v-if="isEmpty(complimentBombChars)" class="no-data py-5 text-black">No Characters Featured Yet!</div>
      </div>
    </scroller>

    <InventoryModal
      :is-open="isOpenCompBombHubsModal"
      :inventories="inventories"
      @close="closeCbombHubModal"
      @updated="emits('updated')"
      @gifted="fetchInventoryDetails"
    />
    <SocialItemsModal
      :is-open="isOpenSocialModal"
      @updated="emits('updated')"
      @gifted="fetchInventoryDetails"
      :inventories="inventories"
      @close="isOpenSocialModal = false"
    />
    <gift-compliment-bomb-modal
      :is-open="isOpenGiftComplimentBombModal"
      modal-title="Info"
      :show-footer="false"
      :selectedInventoryItem="selectedInventoryItem"
      @close="isOpenGiftComplimentBombModal = false"
    />
  </div>
</template>

<script lang="ts" setup>
import { sparklesOutline } from 'ionicons/icons';
import ComplimentBombsCard from '@/shared/components/ComplimentBombsCard.vue';
import InventoryModal from '~/shared/modals/InventoryModal.vue';
import ComplimentBombCharacterSelection from '@/shared/pages/compliment-bombs/components/ComplimentBombCharacterSelection.vue';
import constants from '~/shared/statics/constants';
import GiftComplimentBombModal from '@/shared/modals/GiftComplimentBombModal.vue';
import SocialItemsModal from '~/shared/modals/SocialItemsModal.vue';
const props = defineProps({
  complimentBombChars: { type: Array, default: () => [] },
  isCbLoader: { type: Boolean, default: false },
  inventories: { type: Object, default: () => {} },
  complimentCharacters: { type: Array, default: () => [] },
  cbDailyLimit: { type: Object, default: () => {} },
  ffDailyLimit: { type: Object, default: () => {} },
  loading: { type: Boolean, default: false },
  cbRewards: { type: Array, default: () => [] },
});

const router = useRouter();
const isOpenCompBombHubsModal = ref(false);
const complimentBombChars = toRef(props, 'complimentBombChars');
const scrollerRef: Ref = ref(null);

const inventories = toRef(props, 'inventories');

const tempInvType = ref({});
const isOpenSocialModal = ref(false);
const isOpenGiftComplimentBombModal = ref(false);
const selectedInventoryItem = ref({});
const emits: any = defineEmits(['updated', 'fetch', 'gifted', 'cbupdated']);
const inventoryItems = constants.inventories;
const socialItems = constants.socialItems;
const recalcScroll = () => {
  setTimeout(() => {
    try {
      if (scrollerRef.value) (scrollerRef.value as any).calculateScroll();
    } catch (e) {}
  }, 150);
};
watch(
  complimentBombChars,
  () => {
    recalcScroll();
  },
  { deep: true }
);

const action = (item: any) => {
  selectedInventoryItem.value = item;
};
const updatedGiftCharSelection = (val: any) => {
  emits('updated', val);
};

const cBombUpdated = (val: any) => {
  emits('cbupdated', val);
};
const invItems = computed(() => {
  return Object.keys(inventories.value).map((key) => {
    let svg;
    let title;

    Object.keys(socialItems).forEach((socialKey) => {
      Object.keys(inventoryItems).forEach((inventoryKey) => {
        if (key === `${socialItems[socialKey]}_${inventoryItems[inventoryKey]}`) {
          svg = `/icons/${socialKey}-${inventoryItems[inventoryKey]}.svg`;
          title = socialKey === 'complimentBomb' ? 'Compliment Bomb' : 'FanArt Flare';
        }
      });
    });

    return {
      svg,
      amount: get(inventories.value[key], 'amount', 0),
      value: get(inventories.value[key], 'type', ''),
      key: key,
      type: get(inventories.value[key], 'object', ''),
      title,
    };
  });
});
const onClickInventory = () => {
  router.replace({ name: 'home', query: { modal: 'compliment-bomb-hub' } });
  isOpenCompBombHubsModal.value = true;
};

const featuredCharacterInSocial = () => {
  isOpenSocialModal.value = true;
};

const fetchInventoryDetails = (val: any) => {
  tempInvType.value = val;
  emits('gifted', tempInvType.value);
};

const closeCbombHubModal = () => {
  isOpenCompBombHubsModal.value = false;
  router.push({ name: 'home' });
};
const fetchCbLimit = (boolean = false) => {
  emits('fetch', boolean);
};

const openInfoDetails = async (val: any) => {
  selectedInventoryItem.value = val;
  isOpenGiftComplimentBombModal.value = true;
};
onMounted(() => {
  if (router.currentRoute.value?.query?.modal) {
    isOpenCompBombHubsModal.value = true;
  }
});
</script>
<style lang="sass" scoped>
.gift-icon
  font-size: 10px
  background: #ff6e94
  padding: 3px
  margin-left: 2px
  border-radius: 3px
  color: white
.amount
  color: var(--ion-color-primary)
.dark .amount
  color: #fff
.sub-text
  color: #9874DB
  font-weight: bold
  font-size: 14px

.back-icon
  font-size: 11px
  color: #9874DB
  font-weight: bold
.info-icon
  position: absolute
  pointer-events: all
  top: 3px
  color: #7050B7
  left: 3px
  font-size: 9px
.dark .info-icon
  color: #fff
.inventory-container
  border: 2px solid #EAECF6
  border-radius: 10px
.buttons
  @media(max-width:375px)
    flex-wrap: wrap

.comp-bomb-field
  width: 100%
  height: 194px
.compliment-bombs-card
  gap: 5px
  margin-top: 1rem
  margin-bottom: 0.5rem
.icon
  font-size: 15px
.featured-char
  font-size: 12px
  height: 34px
  color: #fff
  font-weight: bolder
  text-decoration: underline
  border: 2px solid #A83CFF
  border-radius: 5px
  background: #A83CFF
  width: 125px
  min-width: 125px
  max-width: 125px
  @media(max-width:375px)
    font-size: 10px
    width: 110px
    min-width: 110px
    max-width: 110px
</style>
