<template>
  <div class="try-on-bottom" :class="{ 'try-on-content': !isCustomizeProfile }">
    <div class="d-flex align-items-center justify-content-between bg-content">
      <div class="d-flex align-items-center text">
        <ion-button class="go-back-btn mr-2" @click="goBack" v-if="!isMobSmallScreen"
          ><i class="ti-arrow-left"
        /></ion-button>
        <div>
          Trying on:
          <strong class="ml-1"> {{ get(userTryOnAnimation, 'title') }}</strong>
        </div>
      </div>
      <div />
      <div class="d-flex align-items-center" :class="{ 'w-100': isMobSmallScreen }">
        <ion-button class="go-back-btn mr-2" v-if="isMobSmallScreen" @click="goBack"
          ><i class="ti-arrow-left mr-2" />Exit</ion-button
        >
        <ion-button
          :class="userTryOnAnimation.is_bought ? 'save-btn' : 'purchase-btn'"
          :disabled="loading && userTryOnAnimation.is_bought"
          @click="action"
        >
          <div v-if="get(userTryOnAnimation, 'is_bought')">
            <ChLoading size="sm" v-if="loading" class="spinner" />
            <span v-else>Save</span>
          </div>
          <div v-else class="d-flex align-items-center">
            Buy for
            <div class="mx-1 token">
              <CurrencyImage :image="'/char.jpg'" :type="get(userTryOnAnimation, 'currency_type')" :img-width="20" />
              <inline-svg :src="imageFromItemsCurrency" class="svg" />
            </div>

            <strong>{{ get(userTryOnAnimation, 'amount') }}</strong>
          </div>
        </ion-button>
      </div>
    </div>
    <RingBearerPurchaseModal
      :is-open="isOpenRingModal"
      @close="isOpenRingModal = false"
      @purchase="closeRingModal"
      :item="userTryOnAnimation"
    />

    <RingBearerPurchaseModal
      :is-open="isOpenRingConfirmationModal"
      :item="userTryOnAnimation"
      @updated="closePurchaseRingModal"
      :isPurchased="isPurchased"
    />
  </div>
</template>
<script lang="ts" setup>
import { authStore } from '../pinia-store/auth';
import NotEnoughCoinsPopover from '@/shared/pages/store/popovers/NotEnoughCoinsPopover.vue';
import { popovers } from '@/shared/native/popovers';
import RingBearerPurchaseModal from '@/shared/modals/ItemsPurchaseModal.vue';
import { useVirtualItemCurrencyImage } from '~/composables/useVirtualItemCurrencyImage';
import { characterStylizeStore } from '../pinia-store/character-stylize';
import { profileStore } from '../pinia-store/profile';
const { userTryOnAnimation, updateProfileTryOnAnimation } = authStore();
const { isCustomizeProfile } = useCharPageColors();
const { imageFromItemsCurrency } = useVirtualItemCurrencyImage(userTryOnAnimation.value);
const emits = defineEmits(['close', 'save']);
const { usersCurrency } = authStore();
const isOpenRingModal = ref(false);
const { isMobSmallScreen } = useWindowSize();
const { postUserProfileVirtualItem } = profileStore();
const { changeCharacterCustomizationData } = characterStylizeStore();
const isOpenRingConfirmationModal = ref(false);
const isPurchased = ref(false);
const loading = ref(false);
const goBack = () => {
  emits('close');
  updateProfileTryOnAnimation({});
};
const closeRingModal = () => {
  isOpenRingModal.value = false;
  setTimeout(() => {
    isPurchased.value = true;
    isOpenRingConfirmationModal.value = true;
  }, 100);
};
const closePurchaseRingModal = () => {
  isOpenRingConfirmationModal.value = false;
  setTimeout(() => {
    updateProfileTryOnAnimation({});
  }, 500);
};
const openPopover = (ev: MouseEvent) => {
  popovers.open(ev, NotEnoughCoinsPopover, { currencyType: get(userTryOnAnimation.value, 'currency_type') });
};
const currencyAmount = computed(() => {
  return get(userTryOnAnimation.value.currency_type) === 'gem' ? usersCurrency.value.gems : usersCurrency.value.coins;
});
const action = (ev: MouseEvent) => {
  if (get(userTryOnAnimation.value, 'is_bought')) {
    save();
  } else if (currencyAmount.value >= userTryOnAnimation.value.amount) {
    isOpenRingModal.value = true;
  } else {
    openPopover(ev);
  }
};

const save = async () => {
  loading.value = true;
  const payload = isCustomizeProfile.value
    ? {
        id: userTryOnAnimation.value.id,
        image: userTryOnAnimation.value.image,
        extra: !isEmpty(get(userTryOnAnimation.value, 'extra')) ? userTryOnAnimation.value.extra : { bgRepeat: true },
      }
    : { id: userTryOnAnimation.value.id };

  if (isCustomizeProfile.value) {
    changeCharacterCustomizationData('background_overlay', payload);
  } else {
    await postUserProfileVirtualItem(payload, 'Background Overlay');
  }

  setTimeout(() => {
    updateProfileTryOnAnimation({});
    emits('save');
    loading.value = false;
  }, 2000);
};
</script>
<style scoped lang="sass">
.save-btn
  --background: #7050B7
  --border-radius: 14px
  @media(max-width: 500px)
    width: 100%

.bg-content
  @media(max-width: 500px)
    flex-wrap: wrap
.token
  height: 20px
.svg
  width: 20px
  height: 20px
.dark .purchase-btn
  color: #214163
.purchase-btn
  border: 2px solid #e6e6e6
  border-radius: 20px
  color: #214163
  --background: transparent
  @media(max-width: 500px)
    width: 100%
.go-back-btn
  --background: #431C89
  --border-radius: 20px
  @media(max-width: 500px)
    width: 65%
.text
  color: #431C89 !important
.try-on-bottom
  width: 40%
  background: white
  height: 60px
  padding: 12px
  z-index: 1000
  position: absolute
  bottom: 0
  left: 50%
  transform: translate(-50%, 0)
  border-top-left-radius: 12px
  border-top-right-radius: 12px
  box-shadow: 0px -7px 8px -2px rgba(35, 14, 51, 0.1)
  @media(max-width: 1034px)
    width: 100% !important
  @media(max-width: 500px)
    height: auto
.try-on-content
  @media(max-width: 1034px)
    position: relative !important
    height: auto !important
</style>
