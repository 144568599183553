<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ion-header translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-between align-items-center p-2">
          <div class="blab-card d-flex align-items-center">
            <ion-buttons slot="start">
              <ion-button @click="closeModal">
                <i class="ti-close icon clickable-item-hov" style="color: #214163" />
              </ion-button>
            </ion-buttons>
            <CommenterImage
              size="md"
              :src="
                inActionBlab.character
                  ? resizeUpload(get(inActionBlab, 'character.info.cropProfilePicture'))
                  : resizeUpload(get(inActionBlab, 'user.profile_picture_url'))
              "
              :ring="
                inActionBlab.character
                  ? get(inActionBlab, 'character.profile_ring.image')
                  : get(inActionBlab, 'user.customize_profile.profile_ring.image')
              "
              :ringWidth="60"
              :top="-5"
            />

            <div class="ml-2 flex-grow-1">
              <div class="d-flex flex-column">
                <div class="comment-type">
                  {{ inActionBlab.character ? toUpper('character') : toUpper('user') }}
                </div>
                <small v-if="!inActionBlab.character" @click.stop="openProfile">
                  <router-link
                    v-if="get(inActionBlab, 'user.username')"
                    :class="{
                      'text-color-username':
                        usernameColor(inActionBlab?.user) && !usernameColor(inActionBlab?.user).startsWith('#'),
                      'bold-username': usernameColor(inActionBlab?.user),
                    }"
                    :style="
                      !usernameColor(inActionBlab?.user)
                        ? ''
                        : usernameColor(inActionBlab?.user).startsWith('#')
                        ? `color: ${usernameColor(inActionBlab?.user)} !important`
                        : `background-image: ${usernameColor(inActionBlab?.user)} !important`
                    "
                    :to="{ name: 'profile', params: { username: get(inActionBlab, 'user.username') } }"
                    @click="closeModal"
                  >
                    <div class="d-flex align-items-center small-text small-user-text">
                      <div class="mr-1" style="font-size: 16px">@{{ inActionBlab.user?.username }}</div>
                      <ProfileBadge
                        :badges="get(inActionBlab?.user, 'customize_profile.badges') || []"
                        :show-small-badge="true"
                      />
                    </div>
                  </router-link>
                </small>
                <small v-else class="small-text">
                  <router-link
                    v-if="inActionBlab?.character?.slug"
                    :to="{
                      name: 'character-profile-new',
                      params: { slug: inActionBlab?.character?.slug },
                    }"
                    @click.stop="openProfile"
                  >
                    {{ get(inActionBlab.character, 'info.name') }}
                  </router-link></small
                >

                <small v-if="blabid" class="position-relative small-timer-text"
                  ><router-link
                    class="router-link clickable-item-hov text-dark"
                    :to="{ name: 'post-details', params: { id: blabid } }"
                    @click="openDetailsPage"
                    >{{ formatTimeAgo(inActionBlab.created) }}</router-link
                  ></small
                >
                <!-- <small v-if="inActionBlab.location" class="ml-2">
                  <i class="ti-location-pin icon" />
                  {{ inActionBlab.location }}</small
                > -->
              </div>
            </div>
          </div>
          <div></div>
          <ion-button v-if="blabid" class="header-popover-button d-block" @click.prevent="menuDropdownToggle"
            ><i class="ti-more-alt"
          /></ion-button>
        </div>
      </ion-toolbar>
    </ion-header>

    <div class="modal-inner-content h-100">
      <div v-if="isLoading" class="d-flex align-items-center justify-content-center w-100" style="height: 50vh">
        <ChLoading size="lg" />
      </div>

      <div v-else class="body-content" :class="{ max: isReplyingGlobal }">
        <div class="px-4">
          <div class="d-flex align-items-center mt-3">
            <div class="name text-black">{{ inActionBlab.title }}</div>
            <MatureTag class="ml-2" size="md" v-if="isNsfw" />
          </div>
          <div
            v-if="inActionBlab.description"
            class="description blab-description mt-3"
            v-html="sanitizedDescription"
          />

          <div class="mt-2 clickable-item">
            <ion-badge v-for="tag in inActionBlab.tags" :key="tag" color="medium ml-1" @click="openTagSearch(tag)"
              >#{{ tag }}</ion-badge
            >
          </div>

          <div class="mb-2">
            <post-featured-chars
              v-if="isMobSmallScreen && get(inActionBlab, 'featured_characters.length')"
              :characters="inActionBlab.featured_characters"
              @close="closeModal"
            />
          </div>
          <!-- 
        <div v-if="reactions?.total_count">
          <div class="bg-primary p-1 d-inline-block mr-1" style="border-radius: 50px; height: 24px; width: 24px">
            <i style="color: white" class="ti-thumb-up" />
          </div>
          <span class="reaction-count">{{ reactions?.total_count || 0 }}</span>
        </div> -->

          <post-featured-chars
            v-if="!isMobSmallScreen && get(inActionBlab, 'featured_characters.length')"
            :characters="inActionBlab.featured_characters"
            @close="closeModal"
          />
        </div>
        <hr style="box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.3)" />

        <div class="post-actions d-flex justify-content-end align-items-center px-4">
          <div class="d-flex justify-content-center clickable-item mob-actions-pd">
            <Reaction
              type="post"
              :reactions="reactions"
              :user-reaction="userReaction"
              @changed="(reaction) => reactionChanged(reaction.key, inActionBlab, reaction.isInstant)"
            >
              <ion-button
                color="transparent"
                class="inline-button icon-button w-100 on-hover px-1 px-sm-3 px-md-5"
                :class="!!userReaction && userReaction.reaction ? 'text-secondary' : 'text-light'"
              >
                <div class="d-flex align-items-center justify-content-center">
                  <!-- <i
                    :class="!!userReaction && userReaction.reaction ? 'text-secondary' : 'text-light'"
                    class="ti-thumb-up mr-1 icon-ion"
                  /> -->
                  <img
                    src="/liked.png"
                    style="width: 16px; height: 16px"
                    class="mr-1"
                    :class="!!userReaction && userReaction.reaction ? 'text-secondary' : 'text-light'"
                  />
                  <span
                    class="reaction-count"
                    :class="!!userReaction && userReaction.reaction ? 'text-secondary' : ''"
                    style="color: #a7aabe"
                    >{{
                      reactions?.total_count > 99 ? `${reactions?.total_count}k` : reactions?.total_count || 0
                    }}</span
                  >
                </div>
              </ion-button>
            </Reaction>
          </div>

          <div
            class="d-flex align-items-center justify-content-center clickable-item px-1 px-sm-3 px-md-5 mob-actions-pd"
            @click.prevent="openComments"
          >
            <ion-button color="transparent" class="inline-button icon-button w-100 on-hover text-light">
              <img src="/msg.png" style="width: 20px; height: 20px" class="mr-1" />
              <span style="color: #a7aabe">
                {{ blabRepliesCount > 99 ? `${blabRepliesCount}k` : blabRepliesCount }}
              </span>
            </ion-button>
          </div>

          <!-- <div
            class="d-flex align-items-center justify-content-center clickable-item px-1 px-sm-3 px-md-5 mob-actions-pd"
          >
            <ion-button
              color="transparent"
              class="inline-button icon-button w-100 on-hover text-light"
              @click="() => (openBookmark = true)"
            >
              <div class="d-flex align-items-center justify-content-center">
                <div style="margin-top: -5px !important">
                  <bookmark-picker
                    v-if="blabid && isAuthenticated"
                    :object-id="blabid"
                    content-type="blab"
                    class="d-inline-block"
                    :open-bookmark="openBookmark"
                    :show-text="true"
                    :icon-color="'grey'"
                    @closeBookmarkModal="() => (openBookmark = false)"
                  />
                </div>
              </div>
            </ion-button>
          </div> -->
        </div>

        <hr />

        <!-- <div class="name mt-2 pb-4" style="font-weight: bold; font-size: 18px">Replies ({{ replyCount || 0 }})</div> -->

        <div v-if="!pinnedRepliesLoading">
          <template v-if="get(pinnedReplies, 'length')">
            <div class="d-flex align-items-center justify-content-between px-4">
              <div id="post-pinned-replies" class="name my-3" style="font-weight: bold; font-size: 18px">
                Pinned Replies ({{ pinnedReplies.length || 0 }})
              </div>
              <ion-button :color="buttonColor" class="reorder-button" @click="toggleReorder">
                {{ buttonContent }}
              </ion-button>
            </div>

            <Sortable
              tag="div"
              :list="pinnedReplies"
              item-key="id"
              handle="#blab-drag-handle"
              :disabled="!isReordering"
              @change="dragSunshineMsg"
            >
              <template #item="{ element, index }">
                <blab-sun-shine-messages
                  :message="element"
                  :blab="inActionBlab.id"
                  :maindetails="true"
                  :sunshine="pinnedReplies"
                  :sunindex="index"
                  :nestedReply="nestedReply"
                  :replies-count="get(sunshineparentrepliesCounts, `${element.reply.id}.child_count`) || 0"
                  class="my-2 px-4"
                  @deleted="hideBlabSunShineReply"
                  @afterReaction="(reactionResp: any) => onReactionSunShine(index, reactionResp)"
                  @unshine="() => onUnpinned()"
                  @count="fetchSunshineParentRepliesCount"
                />
                <hr v-if="index < pinnedReplies.length - 1" class="my-3" />
              </template>
            </Sortable>
          </template>

          <div id="post-replies" class="name my-3 px-4" style="font-weight: bold; font-size: 18px">
            {{ get(pinnedReplies, 'length') ? 'Other Replies' : 'Replies' }}
            <template v-if="replyCount">({{ replyCount || 0 }})</template>
          </div>
          <div class="blab-replies">
            <div v-if="blabReplyMessage && blabReplyMessage.length">
              <div v-for="(message, index) of blabReplyMessage" :key="message.id" class="my-2">
                <blab-message
                  :message="message"
                  :blab="inActionBlab.id"
                  :maindetails="true"
                  :nestedReply="nestedReply"
                  :replies-count="get(parentrepliesCounts, `${message.id}.child_count`) || 0"
                  @afterReaction="(reactionResp: any) => onReaction(index, reactionResp)"
                  @posted="fetchBlabCountReplies"
                  @deleted="hideBlabReply"
                  @count="fetchParentRepliesCount"
                  @pinned="(res: any) => onPinned(index, res)"
                  class="px-4"
                />
                <hr v-if="index < blabReplyMessage.length - 1" class="my-3" />
              </div>
              <div
                v-if="nextPageExists && !repliesLoading"
                class="clickable-item-hov text-center"
                @click="requestLoadMore"
              >
                View Previous Replies
              </div>
            </div>

            <div v-else-if="!repliesLoading" class="ml-auto d-flex justify-content-center">
              <div>No replies yet! Be the first to respond!</div>
            </div>

            <div v-if="repliesLoading" class="d-flex justify-content-center">
              <ChLoading size="lg" />
            </div>
          </div>
        </div>
        <div v-else class="d-flex justify-content-center">
          <ChLoading size="lg" />
        </div>
      </div>
    </div>
    <ion-footer translucent v-if="!isReplyingGlobal && isAuthenticated">
      <ion-toolbar class="toolbar">
        <div class="p-3" ref="postReplyElm">
          <post-blab
            :blab="inActionBlab.id"
            :is-open="isOpeningCommentTextField"
            :show-input="isClickedInputTextField"
            :commentInputField="isOpeningCommentTextField"
            @count="fetchBlabCountReplies"
            @open="openReplies"
            @clicked="onClickReplyAsUserField"
          ></post-blab>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-modal>
</template>

<script lang="ts">
import { Prop, Watch, Options, Vue } from 'vue-property-decorator';
import MatureTag from '@/shared/components/MatureTag.vue';
import { Action, Getter } from 's-vuex-class';
import { chatboxEllipsesOutline, arrowRedoOutline } from 'ionicons/icons';
import namespace from '@/shared/store/namespace';
import { formatTimeAgo } from '@/shared/utils/dateTime';
import {
  getBlabReplies,
  getCountofBlabReplies,
  getCountofReplies,
  updateSunShineOrder,
  getSunShineBlabReply,
} from '@/shared/actions/blabs';
import { Blab, Paging } from '@/shared/types/static-types';
import { stripHtmlTags } from '@/shared/utils/string';
import PostBlab from '@/shared/pages/blabs/components/PostBlab.vue';
import BlabMessage from '@/shared/pages/blabs/components/BlabMessage.vue';
import Reaction from '@/shared/components/Reaction/index.vue';
import BlabSunShineMessages from '@/shared/pages/blabs/components/BlabSunShineMessages.vue';
import BookmarkPicker from '@/shared/components/BookmarkPicker.vue';
import PostMenuPopover from '@/shared/components/PostMenuPopover.vue';
import store from '@/shared/store';
import { authStore } from '@/shared/pinia-store/auth';
import { sanitizeHtml } from '@/shared/utils/html';
import { resizeUpload } from '@/shared/utils/upload';
import PostFeaturedChars from '@/shared/components/PostFeaturedChars.vue';
import CommenterImage from '@/shared/components/storage/CommenterImage.vue';
import ProfileBadge from '@/shared/pages/profile/components/ProfileBadge.vue';
import { popovers } from '@/shared/native';
import { uiStore } from '../pinia-store/ui';

@Options({
  name: 'BlabDetailsModal',
  components: {
    Reaction,
    PostBlab,
    BlabMessage,
    BlabSunShineMessages,
    BookmarkPicker,
    PostFeaturedChars,
    CommenterImage,
    MatureTag,
    ProfileBadge,
  },
})
export default class BlabDetailsModal extends Vue {
  @Prop({ default: () => 'Post', required: false }) modalTitle!: string;
  @Prop({ default: false }) isOpen!: boolean;
  @Prop({}) blabid!: string;

  public get isReplyingGlobal() {
    const { isReplying } = uiStore();
    return isReplying.value;
  }

  public isReordering = false;
  public isClickedInputTextField = false;
  public isOpeningCommentTextField = false;

  public toggleReorder() {
    this.isReordering = !this.isReordering;
  }

  public get buttonContent() {
    return this.isReordering ? 'Done' : 'Reorder';
  }

  public get buttonColor() {
    return this.isReordering ? 'success' : 'primary';
  }

  public first = first;
  public nestedReply = {};
  formatTimeAgo = formatTimeAgo;
  resizeUpload = resizeUpload;
  public get tinyMCEKey() {
    const {
      public: { tinyMCEKey },
    } = useRuntimeConfig();
    return tinyMCEKey;
  }

  stripHtmlTags = stripHtmlTags;
  get = get;

  public windowHeight = '0px';
  public isReplying = false;
  public isOpeningReply = false;
  public chatboxEllipsesOutline = chatboxEllipsesOutline;
  public arrowRedoOutline = arrowRedoOutline;
  public openBookmark = false;

  @Getter('inActionBlab', { namespace: namespace.BlabsModule })
  public inActionBlab!: Blab;

  public get isNsfw() {
    return this.inActionBlab.is_nsfw;
  }

  public get authToken() {
    const { authToken } = authStore();
    return authToken.value;
  }

  @Action('getBlab', { namespace: namespace.BlabsModule })
  public getBlab!: any;

  public get user() {
    const { user } = authStore();
    return user.value;
  }

  public usernameColor = (user: any) => {
    return user?.customize_profile?.username_color || '';
  };

  public get lastPostAs() {
    const { lastPostAs } = authStore();
    return lastPostAs.value;
  }

  public handleResize() {
    this.windowHeight = `${window.innerHeight}px`;
  }

  mounted() {
    window.addEventListener('resize', this.handleResize);
  }
  public get placeholder() {
    return `Reply as ${this.user.username}`;
  }

  wsConnection: WebSocket | null = null;
  public pinnedRepliesLoading = false;
  public repliesLoading = false;
  public blabReplyMessage: { id: string; message: string; isNew?: boolean }[] = [];
  public pinnedReplies: any = [];
  shineIds: any = {};
  parentrepliesCounts = {};
  sunshineparentrepliesCounts = {};
  reorderSunshineReply: any = [];
  public blabRepliesCount = 0;
  public paging: Paging | null = null;
  public blabrepliesid: string[] | undefined = [];
  public isLoading = true;
  isOpeningSunshine = true;
  public isOpenComments = false;
  public currOffset = 0;
  public repliesPageLimit = 10;
  public postReplyHeight = '0px';

  public get windowWidth() {
    const { width } = useWindowSize();
    return width.value;
  }

  public get isAuthenticated() {
    const { isAuthenticated } = authStore();
    return isAuthenticated.value;
  }

  public get sanitizedDescription() {
    return sanitizeHtml(this.inActionBlab.description + '&nbsp;');
  }

  @Watch('isOpen')
  openBlabDetails(val: boolean) {
    if (val) {
      this.resetData();
      this.getBlabDetails();
      this.hookBlabMessageWS();
      this.postReplyHeight = this.$refs.postReplyElm ? `${get(this.$refs.postReplyElm, 'offsetHeight') || 0}px` : '0px';
    }
  }

  public get replyBoxId() {
    return `${this.isLoading}-${get(this.lastPostAs, 'id') || 'nonepa'}-${this.isOpen}-${get(this.inActionBlab, 'id')}`;
  }

  @Watch('replyBoxId')
  isReplyBoxUpdated() {
    setTimeout(() => {
      this.postReplyHeight = this.$refs.postReplyElm ? `${get(this.$refs.postReplyElm, 'offsetHeight') || 0}px` : '0px';
    }, 100);
  }

  public get postReplyHeightComp() {
    return get(this.$refs.postReplyElm, 'offsetHeight')
      ? `${get(this.$refs.postReplyElm, 'offsetHeight') || 0}px`
      : '0px';
  }

  public openTagSearch(tagText: any) {
    const router = useRouter();
    this.closeModal();
    setTimeout(() =>
      router.push({
        name: 'search',
        query: { term: `#${tagText}`, tab: 'all' },
      })
    );
  }

  @Watch('blabReplyMessage')
  getrepliesCount() {
    this.fetchParentRepliesCount();
  }

  @Watch('pinnedReplies')
  getsunshinerepliesCount() {
    this.fetchSunshineParentRepliesCount();
  }

  public resetData() {
    this.isLoading = true;
    this.isOpeningCommentTextField = false;
    this.isOpeningReply = false;
    this.isClickedInputTextField = true;
    this.blabReplyMessage = [];
    this.paging = null;
    this.pinnedReplies = [];
    this.isOpeningSunshine = true;
  }

  public openComments() {
    this.isOpenComments = true;
    setTimeout(() => {
      this.isOpenComments = false;
    }, 10);
  }

  public async fetchBlabCountReplies() {
    this.blabRepliesCount = get(await getCountofBlabReplies([this.inActionBlab.id]), '0.blab_replies_count') || 0;
  }

  public openReplies() {
    this.isOpeningSunshine = false;
    this.isOpeningReply = true;
  }
  public onClickReplyAsUserField() {
    this.isClickedInputTextField = false;
    this.isOpeningCommentTextField = true;
  }

  public openSunshine() {
    this.isOpeningReply = false;
    this.isOpeningSunshine = true;
  }

  public hideBlabReply(id: string) {
    this.blabReplyMessage = this.blabReplyMessage.filter((msg: any) => msg.id !== id);
  }

  public hideBlabSunShineReply(id: string) {
    this.pinnedReplies = this.pinnedReplies.filter((msg: any) => msg.id !== id);
  }

  public async fetchPostReplies(offset = 0) {
    this.repliesLoading = true;

    const { results, ...paging } = await getBlabReplies(this.inActionBlab.id, offset, this.repliesPageLimit, null, {
      sunshined_reply_ids: map(this.pinnedReplies, (pin: any) => pin.reply.id).join(',') || null,
      ordering: '-created',
    });
    this.blabReplyMessage = !offset ? results : this.blabReplyMessage.concat(results);

    this.paging = paging;
    this.currOffset = offset;

    this.repliesLoading = false;
  }

  public async requestLoadMore(ev: MouseEvent) {
    if (!this.paging?.next) {
      (ev?.target as any).complete();
    } else {
      await this.fetchPostReplies(this.currOffset + this.repliesPageLimit);
    }
  }

  public openProfile() {
    this.$emit('profile', true);
    this.closeModal();
    if (!this.inActionBlab.character) {
      this.$router.push({ name: 'profile', params: { username: get(this.inActionBlab, 'user.username') } });
    } else {
      this.$router.push({ name: 'character-profile-new', params: { slug: this.inActionBlab.character.slug } });
    }
  }

  public openDetailsPage() {
    this.$emit('details', this.blabid);
  }

  public get isBlabOwner() {
    try {
      return this.inActionBlab.user!.username === this.user.username;
    } catch (error) {
      return false;
    }
  }

  public get replyCount() {
    return this.blabRepliesCount - get(this.pinnedReplies, 'length', 0);
  }

  public get truncatedOwnerName() {
    return truncate(
      get(this.inActionBlab, 'character')
        ? get(this.inActionBlab.character, 'info.name')
        : this.inActionBlab.user?.username,
      { length: 25, omission: '' }
    );
  }

  public onPinned(replyIndex: number, details: any) {
    const splicedRep = this.blabReplyMessage.splice(replyIndex, 1);
    this.pinnedReplies.push({
      reply: { ...splicedRep[0], sunshined_reply: true },
      order_num: details.order_num,
      blab: details.blab,
    });
    this.currOffset -= 1;
  }

  public async onUnpinned() {
    await this.fetchPinnedReplies(true, true);
  }

  public async dragSunshineMsg() {
    this.pinnedReplies.forEach((field: any, index: any) => {
      this.reorderSunshineReply[index] = {
        reply: field.reply.id,
        order_num: field.order_num,
      };
    });

    this.reorderSunshineReply.forEach((field: any, index: any) => (field.order_num = index));

    const payload = {
      blab: this.blabid,
      reply_order: this.reorderSunshineReply,
    };
    await updateSunShineOrder(payload);
  }

  public onReaction(replyIndex: number, reaction: any) {
    this.blabReplyMessage[replyIndex] = {
      ...this.blabReplyMessage[replyIndex],
      ...reaction.updatedReactionsData,
    };
  }

  public onReactionSunShine(index: string, reaction: any) {
    this.pinnedReplies[index].reply = {
      ...this.pinnedReplies[index].reply,
      ...reaction.updatedReactionsData,
    };
  }

  public async fetchPinnedReplies(inclOtherReplies = true, scrollToReplies = false) {
    this.pinnedRepliesLoading = true;
    this.pinnedReplies = await getSunShineBlabReply([this.inActionBlab.id]);

    if (scrollToReplies) {
      this.$nextTick(() => {
        const document = useDocument();
        if (get(this.pinnedReplies, 'length')) {
          document.value?.getElementById(`post-pinned-replies`)?.scrollIntoView({ block: 'start' });
        } else {
          document.value?.getElementById(`post-replies`)?.scrollIntoView({ block: 'start' });
        }
      });
    }

    if (inclOtherReplies) this.fetchPostReplies();
    this.pinnedRepliesLoading = false;
  }

  public async fetchParentRepliesCount() {
    this.parentrepliesCounts = {};
    const resp = await getCountofReplies(map(this.blabReplyMessage, 'id'));
    this.parentrepliesCounts = keyBy(resp, 'parent_reply_id');
  }

  public async fetchSunshineParentRepliesCount() {
    this.sunshineparentrepliesCounts = {};
    const resp = await getCountofReplies(map(this.pinnedReplies, 'reply.id'));
    this.sunshineparentrepliesCounts = keyBy(resp, 'parent_reply_id');
  }

  public postReply() {
    this.isReplying = !this.isReplying;
  }

  public get userReaction() {
    if (this.inActionBlab.user_reaction) {
      return this.inActionBlab.user_reaction;
    }
  }

  public get reactions() {
    const { reaction_counts: counts } = this.inActionBlab || {};

    return counts;
  }

  public async reactionChanged(reaction: string, currBlab: any, isInstant = false) {
    const reactionResp = await store.dispatch('BlabsModule/react', {
      reaction,
      blab: currBlab,
      isInstant,
    });
    this.$emit('afterReaction', reactionResp);
  }

  public get nextPageExists() {
    return !!this.paging?.next;
  }

  public async getBlabDetails() {
    this.isLoading = true;
    try {
      await this.getBlab(this.blabid);
      this.fetchBlabCountReplies();
    } catch (_err) {}

    this.isLoading = false;
    this.fetchPinnedReplies();
  }

  public async menuDropdownToggle(ev: CustomEvent) {
    const popover = await popovers.open(ev, PostMenuPopover, {
      post: this.inActionBlab,
    });
    const { data } = await popover.onDidDismiss();
    if (data === 'editPost') {
      this.$emit('edit', true, this.blabid);
    }
  }

  public get isMobSmallScreen() {
    return this.windowWidth <= 540;
  }

  public dismissModal(val: any) {
    const { setIsReplying } = uiStore();
    setIsReplying(false);
    this.$emit('dismissModal', val);

    this.resetData();
  }

  public closeModal() {
    this.$emit('closeModal');
    this.$emit('dismissModal');
    this.resetData();
  }

  public hookBlabMessageWS() {
    const {
      public: { wsUrl },
    } = useRuntimeConfig();

    if (this.wsConnection) {
      try {
        this.wsConnection.close();
      } catch (e) {
        // Handle if needed
      }
      this.wsConnection = null;
    }

    const connection = new WebSocket(`${wsUrl}/ws/blabreplies/${this.blabid}/?token=${this.authToken}`);
    connection.onmessage = (event) => {
      const data = JSON.parse(event.data).message;

      if (data.action === 'create') {
        const newReply = { ...data.message, isNew: false };
        const isMyComment = get(newReply, 'user.id') === this.user.id;
        if (isMyComment && !data.message.parent_reply) {
          newReply.isNew = true;
          setTimeout(() => {
            const reply = this.blabReplyMessage.find((msg: any) => msg.id === newReply.id);

            reply && (reply.isNew = false);
          }, 5000);
        } else if (isMyComment) {
          this.nestedReply = newReply;
        }

        if (!data.message.parent_reply) {
          this.blabReplyMessage.unshift(newReply);
        }
        this.blabRepliesCount += 1;
        this.currOffset += 1;

        if (isMyComment) {
          this.$nextTick(() => {
            const document = useDocument();
            document.value
              .getElementById(`post-reply-${newReply.id}`)
              ?.scrollIntoView({ behavior: 'auto', block: 'nearest' });
            document.value?.querySelector('.sections')?.scrollIntoView();
          });
        }
      }
    };
    connection.onerror = () => {};
    this.wsConnection = connection;
  }

  beforeUnmount() {
    if (this.wsConnection) {
      try {
        this.wsConnection.close();
      } catch (e) {
        // Handle if needed
      }
      this.wsConnection = null;
    }
  }
}
</script>

<style lang="sass" scoped>
.small-text
  @media(min-width: 500px)
   position: relative
   top: -4px
.small-user-text
  @media(min-width: 500px)
   left: -1px
.small-timer-text
  @media(min-width: 500px)
   top: -7px
.comment-type
  font-weight: bold
  font-size: 14px
  color: var(--ion-color-primary)
.dark .comment-type
  color: #FFFFFF
.modal-header
  padding-top: calc(var(--safe-ios-margin) * 0.75)
.w-80
  width: 80% !important
.modal-inner-content
  background: var(--ion-background-color, #fff)
  max-height: 100vh
  max-height: 100dvh
  overflow: auto
  overflow-x: hidden

.blab-card
  img
    width: 50px
    height: 50px
    border-radius: 25px
    border: solid gray 0.1px
    object-fit: cover
    .description
      font-size: 13px
    .icon-button
      color: #ae38e5
      font-size: 20px

    .post-actions
      max-width: 200px
.reaction-count
  font-size: 16px
.icon-ion
  font-size: 25px
  color: grey

.share-icon
  font-size: 20px

.card-bottom-section
  padding-left: 80px
  padding-right: 80px
  @media(max-width: 600px)
    padding: 0px

.icon-edit
  font-size: 20px

.text
  font-size: 16px
  font-weight: bold

.active
  color: black
  font-weight: bold
.normal
  color: grey
.name
  font-size: 20px
  font-weight: bold

.modal-big
  --width: 900px
  @media(min-width: 750px)
    --height: 90%
  @media(max-width: 900px)
    --width: 100%
    .name
      font-size: 20px
      cursor: pointer
    .name:hover
      opacity: 0.7

.comment-icon
  font-size: 25px
  color: grey
  margin-top: 3px

.on-hover:hover
  background: #f3f3f3
  border-radius: 4px

.dark .on-hover:hover
  background: #444 !important

.mob-actions-pd
  @media(max-width: 700px)
    padding-left: 0px
    padding-right: 0px

.dark .text-light
  color: white

.bg-primary
  background: var(--ion-color-primary) !important

.header-popover-button
  bottom: 0.5rem
  height: 20px
  width: 34px
  --border-radius: 16px
  --background: #7050B7
  gap: 10px
  i
    font-size: 16px
    color: #ffffff
</style>
