<template>
  <div v-if="route.name !== 'preset-creator'" class="secondary bg-light">
    <div class="max-width d-flex align-center pl-0 pr-3 px-md-0">
      <div class="d-block align-items-center justify-content-center h-100">
        <AutosaveIndicator
          v-if="editorType"
          class="ind"
          horizontal
          update-color="#999"
          :is-dirty="isDirty"
          :is-saving="isSaving"
          :modified="obj?.modified"
          :autosave-enabled="autosaveEnabled"
          :set-autosave-enabled="setAutosaveEnabled"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import AutosaveIndicator from '@/shared/components/AutosaveIndicator.vue';
import { characterEditorStore } from '@/shared/pinia-store/character-editor';
import { storyWorldCreatorStore } from '@/shared/pinia-store/storyworld-creator';
import { socialSpaceCreatorStore } from '@/shared/pinia-store/social-space-creator';
import { useRoute } from 'vue-router';

const route = useRoute();

const props = defineProps({
  type: {
    type: String,
    default: '',
  },
  isTemplateCreation: {
    type: Boolean,
    default: false,
  },
});
const editorType = toRef(props, 'type');
const {
  autosaveEnabled: cAutosaveEnabled,
  setAutosaveEnabled: cSetAutosaveEnabled,
  isDirty: cIsDirty,
  isSaving: cIsSaving,
  character,
} = characterEditorStore();
const {
  autosaveEnabled: wAutosaveEnabled,
  setAutosaveEnabled: wSetAutosaveEnabled,
  isDirty: wIsDirty,
  isSaving: wIsSaving,
  currentStoryWorld,
} = storyWorldCreatorStore();
const {
  autosaveEnabled: ssAutosaveEnabled,
  setAutosaveEnabled: ssSetAutosaveEnabled,
  isDirty: ssIsDirty,
  isSaving: ssIsSaving,
  socialSpace,
} = socialSpaceCreatorStore();

const autosaveEnabled = computed(() => {
  if (editorType.value === 'character') return cAutosaveEnabled.value;
  if (editorType.value === 'world') return wAutosaveEnabled.value;
  if (editorType.value === 'socialSpace') return ssAutosaveEnabled.value;
});
const setAutosaveEnabled = computed(() => {
  if (editorType.value === 'character') return cSetAutosaveEnabled;
  if (editorType.value === 'world') return wSetAutosaveEnabled;
  if (editorType.value === 'socialSpace') return ssSetAutosaveEnabled;
});
const isDirty = computed(() => {
  if (editorType.value === 'character') return cIsDirty.value;
  if (editorType.value === 'world') return wIsDirty.value;
  if (editorType.value === 'socialSpace') return ssIsDirty.value;
});
const isSaving = computed(() => {
  if (editorType.value === 'character') return cIsSaving.value;
  if (editorType.value === 'world') return wIsSaving.value;
  if (editorType.value === 'socialSpace') return ssIsSaving.value;
});
const obj = computed(() => {
  if (editorType.value === 'character') return character.value;
  if (editorType.value === 'world') return currentStoryWorld.value;
  if (editorType.value === 'socialSpace') return socialSpace.value;
});
</script>

<style scoped lang="sass">
ion-menu-toggle
  display: flex
  align-items: center
.ind
  margin-top: 10px
  margin-left: 5px
@media(max-width: 1320px)
  .left-icon
    display: block !important
.left-icon
  display: none
  color: #ae38e5
  --padding-start: 0
  --padding-end: 0
  margin: 0
  height: 100% !important
@media(max-width: 570px)
  .secondary
    height: 40px !important
  .right-icon
    height: 30px !important
    min-width: 30px !important
  .ind
    margin-top: 3px
    margin-left: 10px
.secondary
  height: 50px
  background: #faf5ff
  width: 100%
  box-shadow: 0px 2px 13px -7px rgba(0,0,0,0.75)
  z-index: 1
  .max-width
    max-width: 650px
    margin: auto
    height: 100%
    width: 100%
  .center-icon
    color: #ae38e5
    font-weight: bold
  .right-icon
    cursor: pointer
    height: 35px
    min-width: 35px
    background-color: #ae38e5 !important
    color: #FFF
    background-size: cover
    background-position: center
    border-radius: 8px
</style>