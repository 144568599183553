<template>
  <div class="inline-gallery mx-n2 mb-n3">
    <div class="loading-overlay" :class="{ visible: isUploading }">
      <div class="d-flex inner-overlay">
        <div class="loading-indicator">
          <i class="ti-reload spin" />
        </div>
        <strong>Uploading...</strong>
      </div>
    </div>

    <div v-if="isEditor && !collections?.length" class="top-bar">
      <div class="msg pt-3 pl-2">You can bulk upload by selecting multiple image files from your device.</div>
      <UploadForm
        :is-multiple="true"
        :save-watermark="true"
        :full-image-info="true"
        :allowGif="true"
        :used-key="usedKey"
        class="my-2 upload-button ml-2"
        @storageFullError="closeAndOpenStorageModal"
        @uploaded="addImages"
        @loading="setUploading"
      >
        <ion-custom-button :disabled="isUploading" :loading="isUploading" class="upload-button no-select">
          <i class="ti-plus mr-2" />
          Add
        </ion-custom-button>
      </UploadForm>
    </div>
    <div v-if="!collections?.length" class="no-data">No albums yet</div>
    <div v-else-if="collections?.length" class="collections">
      <scroller :scroll-size="300" class="w-100" :threshold="10">
        <InlineGalleryCollections class="d-flex" :is-editor="isEditor" :customize="customize" :disabled="isUploading" />
      </scroller>
      <div class="mt-2">
        <!-- A text field that allows the user to edit the current collection name-->
        <ion-label v-if="isEditor && currentImageCollection" class="title">{{ labelText }}</ion-label>
        <ion-input
          v-if="isEditor && currentImageCollection"
          class="c-input one-input name-input mt-1"
          placeholder="Images"
          :value="currentImageCollectionName"
          @input="updateCurrentImageCollectionName"
        />
      </div>
    </div>
    <div
      v-if="isEditor && collections?.length && currentImageCollection && !isCurrentImageCollectionReadOnly"
      class="images-actions"
    >
      <div class="left">
        <ion-button class="watermark-add" :disabled="!visibleImagesCount" @click="addWatermarks">
          <!-- Show icon instead of text on mobile-->
          <inline-svg src="/icons/watermark-on.svg" width="18px" height="22px" />
          <span class="ml-1 mobile-only no-select">All</span>
          <span class="ml-1 desktop-only no-select">Watermark All</span>
        </ion-button>
        <ion-button class="watermark-remove no-select" :disabled="!visibleImagesCount" @click="removeWatermarks">
          <inline-svg src="/icons/watermark-off.svg" width="18px" height="22px" />
          <span class="ml-1 mobile-only no-select">Clear</span>
          <span class="ml-1 desktop-only no-select">Clear All</span>
        </ion-button>
        <ion-button
          class="no-select mv"
          :color="reorderEnabled ? 'secondary' : 'primary'"
          v-if="visibleImagesCount"
          @click="toggleReorder"
        >
          <i class="ti-move" />
          <span class="ml-1 mobile-only no-select">Move</span>
          <span class="ml-1 desktop-only no-select">Move</span>
        </ion-button>
      </div>
      <div class="right">
        <UploadForm
          :is-multiple="true"
          :save-watermark="true"
          :allowGif="true"
          :full-image-info="true"
          :used-key="usedKey"
          @storageFullError="closeAndOpenStorageModal"
          class="upload-button"
          @uploaded="addImages"
          @loading="setUploading"
        >
          <ion-button :disabled="isUploading" :loading="isUploading" class="upload-button">
            <i class="ti-plus mr-1" />
            Add
          </ion-button>
        </UploadForm>
      </div>
    </div>
    <div class="images-wrapper">
      <div v-if="!isEditor && currentImageCollection" class="album-name-custom mb-2 mt-4">
        {{ currentImageCollection ? currentImageCollection?.name : 'All Images' }}
      </div>
      <p v-if="isCurrentImageCollectionReadOnly" class="small text-black">This album is read-only</p>
      <div v-if="currentImageCollection" class="py-1 position-relative spacing">
        <InlineGalleryImages
          :is-editor="isEditor"
          :customize="customize"
          :disabled="isUploading"
          :reordering="reorderEnabled"
          @openEditModal="openEditImageModal"
          @touched="emitTouched"
        />
      </div>
    </div>
    <ImageStorageSpaceFullModal :is-open="isOpenStorageFullModal" @close="isOpenStorageFullModal = false" />
    <EditImageDetailsModal
      v-if="isEditor"
      :image-id="editImageModalId"
      :is-open="editImageModalIsOpen && !!editImageModalId"
      @dismissModal="closeEditImageModal"
    />
    <VisualPreviewModal
      :is-open="isViewImageModalOpen"
      :image-id="currentImageId"
      :image-url="get(currentImageData, 'image') || ''"
      :caption="get(currentImageData, 'description') || ''"
      :source="get(currentImageData, 'source') || ''"
      :source-user="get(currentImageData, 'source_user') || null"
      :all-images="currentImages"
      :image="currentImageData"
      :active-index="currentIndex"
      @updatedImage="updatedImage"
      @dismissModal="closeVisualPreviewModal"
      @onReaction="(reactionResp: any) => onVisualReaction(reactionResp)"
      @updated="setModalPage"
      @loadmore="loadMoreImages"
    />
  </div>
</template>

<script lang="ts" setup>
import { alertController } from '@ionic/vue';
import InlineGalleryImages from './InlineGalleryImages.vue';
import InlineGalleryCollections from './InlineGalleryCollections.vue';
import EditImageDetailsModal from './EditImageDetailsModal.vue';
import ImageStorageSpaceFullModal from '@/shared/modals/ImageStorageSpaceFullModal.vue';
import UploadForm from '@/shared/components/upload-form.vue';
import { imageStore } from '@/shared/pinia-store/images';
import { toast } from '@/shared/native';
import { Image } from '@/shared/types/static-types';

import { mainStore } from '@/shared/pinia-store/main';
import VisualPreviewModal from '@/shared/modals/VisualPreviewModal.vue';

import { getImageStorage } from '@/shared/actions/imagesCollections';

import { unSpoilImage } from '@/shared/actions/imagesCollections';
import { authStore } from '@/shared/pinia-store/auth';

const { isAuthenticated, user } = authStore();

const {
  updateImageCollectionLocal,
  addImagesToImageCollectionLocal,
  setImages,
  setWatermarkImages,
  closeViewImageModal,
  replaceReactionLocal,
  setModalVisibleImageIndex,
  updateImageLocal,
  reset
} = imageStore();

const emits = defineEmits(['touched', 'mounted']);
const props = defineProps({
  header: {
    type: String,
    default: '',
  },
  isEditor: {
    type: Boolean,
    default: false,
  },
  parentType: {
    type: String,
    default: '',
  },
  parentId: {
    type: String,
    default: '',
  },
  customize: {
    type: Object,
    default: () => ({}),
  },
  usedKey: {
    type: String,
    default: '',
  },
});
const { isTempDefault } = useCustTempDefault(props);
const isEditor = toRef(props, 'isEditor');
const usedKey = toRef(props, 'usedKey');
const customize = toRef(props, 'customize');
const editImageModalIsOpen = ref(false);
const editImageModalId = ref('' as string);
const isUploading = ref(false);
const isOpenStorageFullModal = ref(false);
const isWatermarking = ref(false);
const removingWatermarks = ref(false);
const reorderEnabled = ref(false);

const isViewImageModalOpen = computed(() => {
  const { viewImageModalOpen } = imageStore();
  return viewImageModalOpen.value;
});

const isDark = computed(() => {
  const { dark } = mainStore();
  return dark.value;
});

const emitTouched = () => emits('touched');

const infoSectionColor = computed(() => {
  const defaultClr = isDark.value ? '#17074C' : '#E6E6E6';
  return isTempDefault.value ? defaultClr : get(customize.value, 'data.theme.backgrounds.infoSection') || defaultClr;
});

const titlesTextColor = computed(() => {
  return isTempDefault.value && isDark.value ? '#FFFFFF' : get(customize.value, 'data.theme.text.titles.color');
});

const titlesTextFont = computed(() => {
  return isTempDefault.value ? 'Roboto' : get(customize.value, 'data.theme.text.titles.font') || 'Roboto';
});

const titlesTextBold = computed(() => {
  return get(customize.value, 'data.theme.text.titles.bold') ? 'bold' : 'normal';
});

const titlesTextItalic = computed(() => {
  return get(customize.value, 'data.theme.text.titles.italics') ? 'italic' : 'normal';
});

const collections = computed(() => {
  const { activeImageCollections } = imageStore();
  return activeImageCollections.value;
});

const currentImageCollection = computed(() => {
  const { visibleImageCollection } = imageStore();
  return visibleImageCollection.value;
});

const isCurrentImageCollectionReadOnly = computed(() => {
  const { isVisibleImageCollectionReadOnly } = imageStore();
  return isVisibleImageCollectionReadOnly.value;
});

const currentImageCollectionName = computed(() => {
  const { visibleImageCollection } = imageStore();
  return visibleImageCollection.value?.name;
});

const visibleImages = computed(() => {
  const { visibleImageCollectionImages } = imageStore();
  return visibleImageCollectionImages.value;
});

const visibleImagesCount = computed(() => {
  const { visibleImageCollectionImages } = imageStore();
  return visibleImageCollectionImages.value?.length || 0;
});

const updateCurrentImageCollectionName = (e: any) => {
  updateImageCollectionLocal({
    id_: currentImageCollection.value!.id_!,
    name: e.target?.value,
  });
  emitTouched();
};

const labelText = computed(() => {
  if (isEditor.value) return 'Album Name';
  return currentImageCollectionName.value || 'Images';
});

const setUploading = (val: boolean) => {
  isUploading.value = val;
};

const toggleReorder = () => {
  reorderEnabled.value = !reorderEnabled.value;
};

const updatedImage = async (image: any) => {
  if (!image?.spoiled_images.user) {
    if (isAuthenticated.value && image?.user !== user.value.id) {
      await unSpoilImage({ image_id: image.id });
    }
    updateImageLocal({ ...image, is_spoiled: false });
  }
};

const addImages = (images: Image | Image[]) => {
  if (!Array.isArray(images)) {
    setImages([images]);
    addImagesToImageCollectionLocal({ imageIds: [images.id!] });
    emitTouched();
    return;
  }
  if (!images || !images.length) return;
  setImages(images);
  addImagesToImageCollectionLocal({ imageIds: images.map((image) => image.id as string) });
  emitTouched();
};

const addWatermarks = async () => {
  try {
    const alert = await alertController.create({
      cssClass: '',
      header: 'Are you sure?',
      message: `You are adding watermarks on all images in this album.`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'text-secondary',
          id: 'cancel-button',
        },
        {
          text: 'Confirm',
          id: 'confirm-button',
          role: 'ok',
          cssClass: 'text-secondary',
        },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    if (role === 'ok') {
      isWatermarking.value = true;
      removingWatermarks.value = false;
      const imageIds = map(visibleImages.value, 'id').filter(Boolean) as string[];
      await setWatermarkImages({ imageIds, watermarking: true });
    }
  } catch (e) {
    toast.show('Watermarking failed or canceled', 'nonative', 'danger');
  }
  isWatermarking.value = removingWatermarks.value = false;
};
const removeWatermarks = async () => {
  try {
    const alert = await alertController.create({
      cssClass: '',
      header: 'Are you sure?',
      message: `You are removing watermarks on all images in this album.`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'text-secondary',
          id: 'cancel-button',
        },
        {
          text: 'Confirm',
          id: 'confirm-button',
          role: 'ok',
          cssClass: 'text-secondary',
        },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    if (role === 'ok') {
      isWatermarking.value = false;
      removingWatermarks.value = true;
      const imageIds = map(visibleImages.value, 'id');
      await setWatermarkImages({ imageIds, watermarking: false });
    }
  } catch (e) {
    toast.show('Watermark removal failed or canceled', 'nonative', 'danger');
  }
  isWatermarking.value = removingWatermarks.value = false;
};

const closeAndOpenStorageModal = () => {
  isOpenStorageFullModal.value = true;
};
const openEditImageModal = (imageId: string) => {
  if (!isEditor.value) return;
  editImageModalIsOpen.value = true;
  editImageModalId.value = imageId;
};
const closeEditImageModal = () => {
  editImageModalIsOpen.value = false;
  setTimeout(() => {
    editImageModalId.value = '';
  }, 250);
};
const closeVisualPreviewModal = () => {
  closeViewImageModal();
};
const onVisualReaction = (reactionResp: any) => {
  replaceReactionLocal(reactionResp);
};
const currentImages = computed(() => {
  const { visibleImageCollectionImages } = imageStore();
  return visibleImageCollectionImages.value;
});
const currentIndex = computed(() => {
  const { modalImageIndex } = imageStore();
  return modalImageIndex.value;
});
const currentImageId = computed(() => {
  const { visibleImageCollectionImages, modalImageIndex } = imageStore();
  return visibleImageCollectionImages.value?.[modalImageIndex.value]?.id;
});
const currentImageData = computed(() => {
  const { visibleImageCollectionImages, modalImageIndex } = imageStore();
  return visibleImageCollectionImages.value?.[modalImageIndex.value];
});
const setModalPage = (pg: number) => {
  setModalVisibleImageIndex(pg);
};
const loadMoreImages = () => {};

onMounted(() => {
  emits('mounted');
});
onBeforeUnmount(() => {
  if (!isEditor.value) reset();
});
</script>

<style lang="sass" scoped>
.inline-gallery
  position: relative
  min-height: 150px
.watermark-add
  --background: #7050B7 !important
  --padding-top: 0.5rem !important
  --padding-bottom: 0.5rem !important
  color: white
  font-size: 12px
.watermark-remove
  --background: #A7AABE !important
  --padding-top: 0.5rem !important
  --padding-bottom: 0.5rem !important
  color: #41476C
  font-size: 12px
.fade-top
  position: absolute
  top: 270px
  left: 0
  right: 20px
  height: 30px
  z-index: 1
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, v-bind(infoSectionColor) 100%)
.fade-bottom
  position: absolute
  bottom: 0
  left: 0
  right: 20px
  height: 30px
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, v-bind(infoSectionColor) 100%)
.name-input
  border-radius: 8px
.msg
  font-size: 14px
  font-weight: 400
.album-name-custom
  font-size: 18px
  font-weight: bold
  line-height: 1.25em
  color: v-bind(titlesTextColor) !important
  font-weight: v-bind(titlesTextBold)
  font-style: v-bind(titlesTextItalic)
  font-family: v-bind(titlesTextFont)
  &.dark
    color: v-bind(titlesTextColor) !important
.view-btn
  text-transform: unset
  width: 150px
  --border-radius: 10px
.sub-title
  font-size: 20px
  font-weight: bold
.edit-btn
  text-transform: unset
  width: 100px
  width: 150px !important
  height: 30px !important
  ::v-deep
    .c-button
      height: 30px !important
      text-transform: unset
      font-size: 15px !important
.upload-button
  width: 100px
  max-width: 100px
  border-radius: 10px
  ::v-deep
    width: 65px !important
    max-width: 65px !important
    min-height: 38px !important
    --border-radius: 10px !important
    text-transform: unset
.bottom-bar
  font-size: 14px
  font-weight: 400
.no-data
  color: #000
  opacity: 0.6 !important
.images-actions
  display: flex
  justify-content: space-between
  align-items: flex-start
  margin-top: 1rem
  margin-bottom: 1rem
  .left
    display: flex
    align-items: flex-start
  .right
    display: flex
    align-items: center
    .upload-button
      margin-right: 1rem
      width: 150px
      max-width: 120px
      ::v-deep
        --border-radius: 8px !important
        .c-button
          font-size: 15px !important
          text-transform: unset
.loading-overlay
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  background: rgba(0, 0, 0, 0.4)
  display: flex
  justify-content: center
  align-items: flex-start
  z-index: -1
  cursor: not-allowed
  border-radius: 20px
  opacity: 0
  transition: opacity 0.3s ease-in-out, z-index 0.05s step-end 0.08s
  &.visible
    opacity: 1
    z-index: 10
  .inner-overlay
    background: rgba(255, 255, 255, 1.0)
    padding: 1rem
    border-radius: 0.5rem
    display: flex
    justify-content: top
    align-items: center
    flex-direction: column
    margin-top: 45px
  .loading-indicator
    animation: spin 3s linear infinite
    margin: 0.75rem
    i
      font-size: 2em
      color: #50c8ff !important
@keyframes spin
  0%
    transform: rotate(360deg)
  100%
    transform: rotate(0deg)
.mobile-only
  display: none !important
.desktop-only
  display: block
@media(max-width: 500px)
  .desktop-only
    display: none !important
  .mobile-only
    display: block !important
  .upload-button
    width: 65px !important
    max-width: 65px !important
    min-height: 38px !important
    --border-radius: 10px !important
    text-transform: unset
.ti-move, .ti-plus
  font-size: 16px
  margin-top: 3px
  margin-bottom: 3px
.mv
  --padding-top: 0.5rem !important
  --padding-bottom: 0.5rem !important
  font-size: 12px
</style>
