

export const useSocialInventoryTimer = (resetTimer: any, lastCompliment: any) => {
  const timeRemaining = computed(() => {
    const current = new Date();
    const currentTime = new Date(current).getTime()
    
    const lastComplimented= new Date(lastCompliment).getTime()
    const resetTimerInMinutes = resetTimer * 1000 * 60 ;
    const timerDiffMinutes = ((lastComplimented + resetTimerInMinutes) - currentTime);
   

    return timerDiffMinutes > 0 ? timerDiffMinutes : 0;
  });

  return { timeRemaining };
};
