<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="isVirtualItem ? false : true"
    :show-backdrop="true"
    @didDismiss="closeModal"
  >
    <ion-header translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-between align-items-center px-3 py-2">
          <div></div>
          <div><div class="title">Choose a Thank You Message</div></div>
          <div>
            <ion-buttons slot="end" v-if="!isVirtualItem">
              <ion-button @click="dismissModal">
                <i class="ti-close" />
              </ion-button>
            </ion-buttons>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>

    <div class="modal-inner-content" :style="isVirtualItem ? 'background: white' : ''">
      <div class="px-3 py-2">
        <div
          class="d-flex flex-column px-2 py-2 mb-1 clickable-item-hov"
          style="border: 2px solid gray; border-radius: 10px"
          v-for="(msg, index) in messages"
          :key="index"
          @click="actionClicked(msg, index)"
          :class="{ 'message-bg-color': selectedIndex === index }"
        >
          {{ msg }}
        </div>
      </div>
    </div>
    <ion-footer translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-center text-center align-items-center">
          <ion-button @click="submit" :disabled="isEmpty(selectedMessage)">
            <inline-svg class="mr-1" src="/send.svg" />
            <span class="sender">Send</span></ion-button
          >
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-modal>
</template>

<script lang="ts" setup>
import constants from '@/shared/statics/constants';
import { giftRespond, getGiftRespondStatus } from '@/shared/actions/complimentBomb';
import { giftItemRespond } from '../actions/virtualItems';
import { receiveGemsResponse } from '../actions/users';
import { toast } from '~/shared/native/toast';
const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
  isVirtualItem: {
    type: Boolean,
    default: false,
  },
  isGiftGems: {
    type: Boolean,
    default: false,
  },
  giftId: {
    type: String,
    default: '',
  },
});
const emits = defineEmits(['close', 'open']);
const route = useRoute();
const messages = constants.thankYouMessages;
const isOpen = toRef(props, 'isOpen');
const selectedIndex = ref(null);
const selectedMessage = ref('');
const giftStatus: any = ref({});
const giftId = toRef(props, 'giftId') as any;
const isVirtualItem = toRef(props, 'isVirtualItem');
const isGiftGems = toRef(props, 'isGiftGems');
const fetchGiftRespondStatus = async () => {
  const id = isVirtualItem.value ? get(route.query, 'id') : giftId.value;
  const resp = await getGiftRespondStatus(id);
  giftStatus.value = resp;
};
watch(isOpen, () => {
  if (isOpen.value && !isGiftGems.value) {
    fetchGiftRespondStatus();
  }
});
const dismissModal = () => {
  isVirtualItem.value ? emits('open') : emits('close');
};
const closeModal = () => {
  if (isVirtualItem.value) return;
  else emits('close');
};
const submit = async () => {
  const gift = isGiftGems.value
    ? get(route.query, 'userId')
    : isVirtualItem.value
    ? get(route.query, 'id')
    : giftId.value;

  const payload = {
    gift: gift,
    response: selectedMessage.value,
  };
  const payload1 = {
    to_user: gift,
    response: selectedMessage.value,
    notif_id: get(route.query, 'notifId'),
  };
  isGiftGems.value
    ? await receiveGemsResponse(payload1)
    : isVirtualItem.value
    ? await giftItemRespond(payload)
    : await giftRespond(payload);
  if (isGiftGems.value) {
    toast.show('Thank you message sent successfully', 'nonative', 'success');
  }
  dismissModal();
};
const actionClicked = (val: any, index: any) => {
  selectedMessage.value = val;
  selectedIndex.value = index;
};
</script>
<style lang="sass" scoped>
.dark .sender
  color: #fff
.message-bg-color
  background: #4a9fc3
  color: white
  border: none !important
.dark .toolbar
  --background: #17074c !important

.modal-inner-content
  background: var(--ion-background-color, #f2f2f2)
  max-height: 60vh
  overflow-x: hidden
  overflow-y: auto

.modal-big
  --width: 450px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
.close-button
  width: 50px
.title
  color: #214163
  font-size: 20px
  font-weight: bold
  text-align: center
  align-items: center
.sub-title
  color: #214163
  font-size: 18px
  font-weight: bold
</style>
