<template>
  <div class="footer-wrapper text-center">
    <div
      class="footer-content d-flex align-items-center justify-content-center flex-column"
      :style="
        isCharacterProfile
          ? { color: text, fill: text }
          : { color: 'var(--ion-color-primary)', fill: 'var(--ion-color-primary)' }
      "
    >
      <div class="sns d-flex">
        <a rel="nofollow" class="mr-3" href="https://www.tumblr.com/blog/characterhub-com" target="_blank"
          ><i class="ti-tumblr-alt"
        /></a>
        <a rel="nofollow" class="mr-3" href="https://twitter.com/characterhub" target="_blank"
          ><i class="ti-twitter-alt"
        /></a>
        <a rel="nofollow" class="mr-3" href="https://www.instagram.com/characterhub/" target="_blank"
          ><i class="ti-instagram"
        /></a>
        <a rel="nofollow" class="mr-3" href="https://www.facebook.com/characterhub" target="_blank"
          ><i class="ti-facebook"
        /></a>
        <a
          class="mr-3 d-flex align-items-center justify-content-center tiktok-link"
          href="https://www.tiktok.com/@character.hub"
          rel="nofollow"
          target="_blank"
        >
          <inline-svg class="footer-tiktok" src="/icons/tiktok.svg" width="22px" height="26px" />
        </a>
        <a rel="nofollow" href="https://www.youtube.com/@characterhub" target="_blank"><i class="ti-youtube" /></a>
      </div>
      <div class="pages">
        <router-link class="link" to="/terms">Terms</router-link>
        |
        <router-link class="link" to="/privacy">Privacy</router-link>
        |
        <a
          href="https://docs.google.com/document/d/1znu1meYCKeQ5X6pTKDLd6t-f8IjVovpR8zUlAl_2eEc/view"
          class="link"
          target="_blank"
          to="/terms"
          >FAQ</a
        >
        <span v-if="!isMobile">
          |
          <router-link class="link" to="/cookies">Cookies</router-link>
        </span>

        |
        <router-link class="link" to="/dmca">DMCA</router-link>
        |
        <a class="link" href="https://characterhub.com/blog" target="_blank">Blog</a>
        |
        <a class="link" href="https://characterhub.com/original-character" target="_blank">OCs</a>
        |
        <a class="link" href="https://characterhub.com/original-character-art" target="_blank">OC Art</a>
        |
        <a class="link" href="https://characterhub.com/blog/character-name-generator" target="_blank">OC Generator</a>
      </div>
      <div class="credits mt-1">
        © 2025 UsePencil, Inc. All Rights Reserved.<span v-if="!isMobile">&nbsp;Ver 1.0{{ appVer }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useMobile } from '~/apps/mobile/composables/useMobile';

const { text, isCharacterProfile } = useCharPageColors();
const { appVer } = useAppVer();

const { isMobile } = useMobile();
</script>

<style scoped lang="sass">
.footer-content
  z-index: 1
  height: calc(70px + var(--safe-ios-margin)/3)
  min-height: calc(70px + var(--safe-ios-margin)/3)
  padding-bottom: calc(var(--safe-ios-margin)/3)
  font-size: 11px
  width: 100%
  position: relative
  *
    color: inherit
    fill: inherit
.sns
  font-size: 22px
.tiktok-link
  width: 22px
  height: 26px
</style>
