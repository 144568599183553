<template>
  <ion-modal
    class="modal-with-content"
    :is-open="isOpen"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="close"
  >
    <ion-content no-bounce>
      <ion-button class="clickable-item-hov top-right" color="transparent" @click.prevent="close">
        <i class="ti-close" color="medium" />
      </ion-button>
      <div class="flex header items-center justify-between">
        <div class="text-xl font-bold">Add to Bookmarks</div>
        <div>
          <ion-button class="clickable-item-hov" color="dark" @click="makeNewCollection">
            Create New Collection
          </ion-button>
        </div>
      </div>
      <div class="collections-container mt-4">
        <div
          v-for="(c, index) in collections"
          :key="c.id"
          class="collection-card relative border my-2 rounded-lg shadow-sm flex flex-col"
          :class="{ 'selected-card': c.context_bookmarked }"
          @click="toggleBookmark(null, index)"
        >
          <div class="card-content mt-4 relative">
            <div class="flex items-center gap-4 w-full">
              <span v-if="!c.isEditing" class="text-sm font-bold ml-1 truncate w-full">{{ c.name }}</span>
              <input
                v-else
                v-model="c.name"
                class="text-sm input-box font-bold border-b border-gray-300 w-full px-1 outline-none"
                @click.stop
                @blur="saveName(c)"
                @keyup.enter="saveName(c)"
              />
              <i class="ti ti-pencil w-4 h-4 cursor-pointer mr-2" @click.stop="editName(c)" />
            </div>
          </div>
          <input
            :id="c.id"
            type="checkbox"
            class="select-box"
            :disabled="c.isEditing"
            :checked="c.context_bookmarked"
          />

          <div class="drop-down" @click.stop.prevent="toggleDropDown(c.id)">
            <i class="ti-more-alt" />
          </div>
          <div v-if="activeDropdownId === c.id" class="drop-down-content">
            <div class="drop-down-item" @click.stop.prevent="confirmDelete(c)">Delete Bookmark</div>
          </div>
        </div>
      </div>
      <div class="flex justify-center gap-4">
        <div v-if="paging.next" class="clickable-item-hov text-center" @click="nextPage">Load More</div>
        <button class="close-bottom" @click="close">Close</button>
      </div>
    </ion-content>
  </ion-modal>
</template>

<script lang="ts" setup>
import { BookmarkCollection, Paging } from '@/shared/types/static-types';
import {
  getBookmarkCollections,
  bookmark,
  unbookmark,
  createBookmarkCollection,
  updateBookmarkCollection,
  deleteCollection,
} from '@/shared/actions/bookmarks';
import { getNextPage } from '@/shared/helpers/pagination';
import constants from '@/shared/statics/constants';
import { toast } from '@/shared/native';
import { authStore } from '@/shared/pinia-store/auth';
import { alertController } from '@ionic/vue';

const { isAuthenticated } = authStore();
const activeDropdownId = ref<string | null>(null);

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
  objectId: {
    type: String,
    required: true,
  },
  contentType: {
    type: String,
    required: true,
    validator: (prop: string) => constants.bookmarkableTypes.includes(prop),
  },
});

const emits = defineEmits(['close', 'changeStatus']);

const collections = ref([]) as Ref<(BookmarkCollection & { isEditing?: boolean })[]>;
const working = ref(false) as Ref<boolean>;
const paging = ref({
  next: null,
  previous: null,
  count: 0,
  page: 1,
}) as Ref<{ next: string | null; previous: string | null; count: number; page: number }>;

const close = () => {
  emits('close');
};

const changeStatus = (isBookmarked: boolean) => {
  emits('changeStatus', isBookmarked);
};

const fetchBookmarkCollections = async () => {
  if (!isAuthenticated.value) return;
  const data = await getBookmarkCollections(1, props.objectId);
  collections.value = data.results.map((c) => ({ ...c, isEditing: false }));
  paging.value = data;
};

const nextPage = async () => {
  const data = await getNextPage(paging.value as Paging);
  collections.value = [...collections.value, ...data.results];
  paging.value = data;
};

watch(
  () => props.objectId,
  (newVal: string) => {
    if (newVal) fetchBookmarkCollections();
  }
);

onMounted(() => {
  if (props.objectId) fetchBookmarkCollections();
});

const toggleBookmark = async (_: any, index: number) => {
  working.value = true;
  const bookmarkCollection: BookmarkCollection = collections.value[index];
  const isBookmarking = !bookmarkCollection.context_bookmarked; // if true, bookmark; if false, unbookmark
  bookmarkCollection.context_bookmarked = !bookmarkCollection.context_bookmarked;
  try {
    if (isBookmarking) {
      await bookmark({
        collection: bookmarkCollection.id,
        id: props.objectId,
        type: props.contentType,
      });
      changeStatus(true);
    } else {
      const count = await unbookmark({ collection: bookmarkCollection.id, id: props.objectId });
      if (count === 0) {
        changeStatus(false);
      } else {
        changeStatus(true);
      }
    }
  } catch (e) {
    // Do nothing
    bookmarkCollection.context_bookmarked = !bookmarkCollection.context_bookmarked;
  } finally {
    toast.show(isBookmarking ? 'Bookmarked' : 'Bookmark Removed', 'nonative', 'primary');
    working.value = false;
  }
  working.value = false;
};

const makeNewCollection = async () => {
  working.value = true;
  try {
    const newCollection = await createBookmarkCollection({ name: 'Bookmarks' });
    collections.value = [...collections.value, { ...newCollection, isEditing: false }];
  } catch (e) {
    await toast.show(`Error creating a new bookmark collection, please try again.`, 'nonative', 'danger');
  } finally {
    working.value = false;
  }
};

const toggleDropDown = (collectionId: string) => {
  activeDropdownId.value = activeDropdownId.value === collectionId ? null : collectionId;
};

const editName = (collection: BookmarkCollection & { isEditing?: boolean }) => {
  collection.isEditing = true;
};

const saveName = async (collection: BookmarkCollection & { isEditing?: boolean }) => {
  if (!collection.name!.trim()) {
    await toast.show('Collection name cannot be empty.', 'nonative', 'danger');
    collection.isEditing = false;
    return;
  }

  try {
    const payload = {
      name: collection.name,
    };
    await updateBookmarkCollection(collection.id, payload);
    collection.isEditing = false;
    await toast.show('Collection name updated successfully.', 'nonative', 'success');
  } catch (e) {
    await toast.show('Failed to update the collection name.', 'nonative', 'danger');
  }
};

const confirmDelete = async (collection: BookmarkCollection & { isEditing?: boolean }) => {
  try {
    const alert = await alertController.create({
      cssClass: '',
      header: 'Are you sure?',
      message: `Please confirm that you want to delete this bookmark list.`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'text-secondary',
          id: 'cancel-button',
        },
        {
          text: 'Confirm',
          id: 'confirm-button',
          role: 'ok',
          cssClass: 'text-secondary',
        },
      ],
    });

    await alert.present();
    const { role } = await alert.onDidDismiss();

    if (role === 'ok') {
      working.value = true;

      try {
        await deleteCollection(collection.id); // Call delete API
        collections.value = collections.value.filter((c) => c.id !== collection.id);
        await toast.show('Bookmarks deleted', 'nonative', 'primary');
      } catch (e) {
        await toast.show('Encountered an error - please try again later.', 'nonative', 'danger');
      } finally {
        working.value = false;
      }
    }
  } catch (e) {
    await toast.show('Failed to display delete confirmation. Please try again.', 'nonative', 'danger');
  }
};
</script>

<style lang="sass" scoped>
.panel
  font-size: 22px
  border-bottom: 2px solid rgba(33, 65, 99, .2)
  padding-bottom: 6px
  font-weight: bold
  margin: 17px 0
.badge-modal-container
  background-color: white
  width: 100%
.collection-img
  width: 80px
  height: 80px
  border-radius: 8px
  object-fit: cover
  margin-bottom: 20px
.top-right
  color: black
  position: absolute
  top: 0.5rem
  right: 0.5rem
.clickable-item-hov
  background: none
.close-bottom
  position: absolute
  bottom: 0.5rem
  width: 200px
  left: 0
  right: 0
  margin-left: auto
  margin-right: auto
  border-radius: 12px
  border: 1px solid var(--ion-color-primary)
  color: var(--ion-color-tertiary)
.coll-row
  padding: 0.25rem
  margin: 0.5rem
.collections-container
  margin: 0 auto
  padding: 0 20%
  overflow-y: scroll
  max-height: 70%
.card-content
  padding: 0.5rem
  border-bottom: 1px solid #e2e8f0
  cursor: pointer
  &:hover
    background-color: var(--ion-color-light)
  .dark &:hover
    background-color: #17073d
  .selected-card
    background-color: #f7fafc
  .select-box
    position: absolute
    top: 2px
    right: 2px
.select-box
  position: absolute
  top: 2px
  left: 4px
.drop-down
  position: absolute
  top: 2px
  right: 4px
  cursor: pointer
.header
  margin-top: 3rem
  padding: 0 1rem
.ti-pencil
  color: var(--ion-color-primary)
.drop-down-content
  position: absolute
  right: 0
  top: 16px
  background: white
  border: 1px solid #e2e8f0
  border-radius: 0.375rem
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1)
  z-index: 10
.dark .drop-down-content
  background: #17073d
  border: 1px solid #e2e8f0
  z-index: 10
.drop-down-item
  padding: 0.5rem 1rem
  cursor: pointer
  white-space: nowrap
.dark .drop-down-content:hover
  background-color: var(--ion-color-primary)
.drop-down-content:hover
  background-color: var(--ion-color-light)
.dark .input-box
  background: var(--ion-color-base)
.dark .ti-pencil 
  color: white
.gap-4
  gap: 1rem
</style>
