<template>
  <div :class="containerClasses">
    <div class="d-flex align-items-center" :class="{ 'mb-2': hasDescription }">
      <div class="d-flex align-items-center justify-content-center mr-3" :class="{ 'character-image': hasCharacter }">
        <template v-if="svgIcon">
          <inline-svg class="heart-icon" :src="svgIcon" />
        </template>
        <slot name="cbimage" v-else>
          <img loading="lazy" v-character-card-image :src="imageSource" :class="imageClasses" />
        </slot>
      </div>

      <div
        class="d-flex flex-column flex-grow-1"
        :style="smallscreen ? 'max-width:calc(100% - 106px)' : ''"
        :class="{ 'reverse-direction-badge': showBadge && isEmpty(type) }"
      >
        <BombOwnedBadge v-if="showBadge" :count="count" :type="type" :info="info" />

        <div class="title mt-1">
          <router-link
            v-if="hasCharacter"
            :to="{ name: 'character-profile-new', params: { slug: character.slug } }"
            class="clickable-item-hov"
            @click="emitClose"
          >
            <div v-html="title" />
          </router-link>
          <span v-else-if="get(dailyLimit, 'available_compliments') > 0 && !isBonusRewarded">{{ title }}</span>
        </div>
        <div v-if="!isEmpty(extraText)" class="mt-2 text-black" v-html="extraText" />

        <div
          v-if="
            (isBonusRewarded && timeRemaining) ||
            (showMissionDesc && isDisplayReward && get(dailyLimit, 'done_in_time') >= get(dailyLimit, 'default_limit'))
          "
        >
          Come back to
          {{ (socialItemType || get(selectedInventoryItem, 'type')) === COMPLIMENT_BOMB ? 'Compliment' : 'Draw' }} more
          in
          <strong style="color: var(--ion-color-primary)">
            <vue-countdown v-slot="{ hours, minutes, seconds }" :time="timeRemaining">
              <span v-if="hours">{{ hours < 10 ? `0${hours}` : hours }}:</span>
              <span v-if="minutes">{{ minutes < 10 ? `0${minutes}` : minutes }}:</span>
              <span>{{ seconds < 10 ? `0${seconds}` : seconds }}!</span>
            </vue-countdown>
          </strong>
        </div>
        <div
          v-else-if="
            showMissionDesc && isDisplayReward && get(dailyLimit, 'done_in_time') <= get(dailyLimit, 'default_limit')
          "
        ></div>
        <div v-else-if="showMissionDesc">
          <div v-if="get(dailyLimit, 'available_compliments') === 0">
            Come back
            {{
              isNull(get(dailyLimit, 'last_rewarded'))
                ? 'later'
                : lastRewardTimer
                ? `to ${socialItemType === COMPLIMENT_BOMB ? 'compliment' : 'draw'} more in`
                : 'again later'
            }}
            <strong
              style="color: var(--ion-color-primary)"
              v-if="!isEmpty(get(dailyLimit, 'last_rewarded')) && lastRewardTimer"
            >
              <vue-countdown v-slot="{ hours, minutes, seconds }" :time="lastRewardTimer">
                <span v-if="hours">{{ hours < 10 ? `0${hours}` : hours }}:</span>
                <span v-if="minutes">{{ minutes < 10 ? `0${minutes}` : minutes }}:</span>
                <span>{{ seconds < 10 ? `0${seconds}` : seconds }}</span>
              </vue-countdown>
            </strong>
            for a chance at another bonus
          </div>
          <div v-else>
            <div v-if="lastRewardTimer && !isEmpty(get(dailyLimit, 'last_rewarded'))">
              <span> {{ socialItemType === COMPLIMENT_BOMB ? 'Compliment ' : 'Draw' }} more in </span>
              <strong style="color: var(--ion-color-primary)">
                <vue-countdown v-slot="{ hours, minutes, seconds }" :time="lastRewardTimer">
                  <span v-if="hours">{{ hours < 10 ? `0${hours}` : hours }}:</span>
                  <span v-if="minutes">{{ minutes < 10 ? `0${minutes}` : minutes }}:</span>
                  <span>{{ seconds < 10 ? `0${seconds}` : seconds }}</span>
                </vue-countdown>
              </strong>
              for a chance at another bonus
            </div>
            <div v-else-if="isNull(get(dailyLimit, 'last_rewarded'))">Complete your character set to earn bonus</div>
            <div v-else>
              {{ socialItemType === COMPLIMENT_BOMB ? 'Compliment ' : 'Draw' }} more to earn rewards and get a change to
              win a bonus
            </div>
          </div>
        </div>
        <div class="text-black mt-1" v-else style="font-size: 14px" v-html="description" />
        <div v-if="isDisplayReward && !isBonusRewarded">All Caught Up</div>
        <div class="mission-reward my-1" v-if="isDisplayReward && totalComplimentCount">
          <div class="text-black">Reward:</div>
          <div>
            <span class="text-black"
              ><inline-svg height="20" width="20" src="/hubuck.svg" /> {{ reward * totalComplimentCount }}
            </span>
            <span class="mx-1" v-if="isBonusRewarded">+</span>
            <span style="color: #ff9c33" v-if="isBonusRewarded">
              <inline-svg height="20" width="20" src="/hubuck.svg" /> {{ extraBonus }}
              <span>completion bonus</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { mainStore } from '@/shared/pinia-store/main';
import BombOwnedBadge from '@/shared/pages/compliment-bombs/components/ComplimentBombOwnedBadge.vue';
import { COMPLIMENT_BOMB, FANART_FLARE } from '../statics/constants';

const props = defineProps({
  character: { type: Object, default: {} },
  title: { type: String, default: '' },
  svgIcon: { type: String, default: '' },
  boldText: { type: String, default: '' },
  description: { type: String, default: '' },
  extraText: { type: String, default: '' },
  isSelected: { type: Boolean, default: false },
  count: { type: Number, default: 0 },
  type: { type: String, default: '' },
  info: { type: String, default: '' },
  showBadge: { type: Boolean, default: false },
  isDisplayReward: { type: Boolean, default: false },
  extraBonus: { type: Number, default: 0 },
  reward: { type: Number, default: 0 },
  isBonusRewarded: { type: Boolean, default: false },
  totalComplimentCount: { type: Number, default: 0 },
  displayBorder: { type: Boolean, default: false },
  selectedInventoryItem: { type: Object, default: () => {} },
  socialItemType: { type: String, default: '' },
  dailyLimit: { type: Object, default: () => {} },
  showMissionDesc: { type: Boolean, default: false },
  remainingEndTime: { type: Number, default: 0 },
  currBombedCharsData: { type: Object, default: {} },
});
const emits = defineEmits(['close']);
const { width } = useWindowSize();
const character = toRef(props, 'character');
const info = toRef(props, 'info');
const dailyLimit = toRef(props, 'dailyLimit');
const hasCharacter = computed(() => !isEmpty(character.value));
const hasDescription = computed(() => !!props.description);
const displayBorder = toRef(props, 'displayBorder');
const currBombedCharsData = toRef(props, 'currBombedCharsData');
const imageSource = computed(() => {
  return get(character.value, 'info.cropProfilePicture');
});
const imageClasses = computed(() => {
  return !isEmpty(get(character.value, 'info.cropProfilePicture')) ? 'char-prof-img' : 'char-small-img';
});
const containerClasses = computed(() => {
  if (isEmpty(info.value)) {
    return {
      'comp-bomb-card p-2': !hasCharacter.value && !displayBorder.value,
      active: props.isSelected,
    };
  }
});

const isNotComplimentedAndFlared = computed(() => {
  return (
    (get(currBombedCharsData.value, 'compliment_bombed_type') === COMPLIMENT_BOMB &&
      !get(currBombedCharsData.value, 'complimented_char_by_me')) ||
    (get(currBombedCharsData.value, 'compliment_bombed_type') === FANART_FLARE &&
      !get(currBombedCharsData.value, 'complimented_fanart_by_me'))
  );
});

const timeRemaining = computed(() => {
  if (dailyLimit.value) {
    return get(dailyLimit.value, 'reset_time') * 1000 * 60;
  }
});

const lastRewardTimer = computed(() => {
  if (dailyLimit.value) {
    const current = new Date();
    const currentTime = new Date(current).getTime();

    const lastRewardedTime = new Date(get(dailyLimit.value, 'last_rewarded')).getTime();
    const resetTime = 86400000;
    const timePassed = currentTime - lastRewardedTime;

    const remainingTime = timePassed < resetTime ? resetTime - timePassed : 0;

    return remainingTime;
  }
});

const timer = computed(() => {
  return 24 * 60 * 60 * 1000;
});

const smallscreen = computed(() => width.value <= 275);

const emitClose = () => emits('close');
</script>
<style lang="sass" scoped>
.reverse-direction-badge
  flex-direction: column-reverse !important
.active
  border: 2px solid var(--ion-color-primary) !important
.comp-bomb-card
  border-radius: 10px
  border: 2px solid #EAECF6
.heart-icon
  width: 90px
  height: 90px
.yay-img
  max-width: 135px
  max-height: auto
  @media(max-width:500px)
    max-width: 100px
.title
  color: #214163
  font-weight: bold
  font-size: 18px
.character-image
  width: 95px
  height: 100px
  min-width: 95px
  border-radius: 12px
  overflow: hidden
  background: #4a9fc3
  box-shadow: 0px 2px 4px 1px rgba(35, 14, 51, 0.19)
  border-radius: 12px
.char-small-img
  width: 77% !important
  height: 69% !important
.char-prof-img
  width: 100% !important
  height: 100% !important
</style>
