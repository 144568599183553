<template>
  <ion-modal
    class="gift-modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ModalHeader :modal-title="title" :showCloseButton="true" @close="dismissModal" />

    <ModalComplimentBombContent
      :showOuterBadge="true"
      :selectedInventoryItem="selectedInventoryItem"
      :giftCbData="updatedGiftInventory"
      @gifted="selected"
    />

    <ModalFooter v-if="showFooter">
      <div class="d-flex justify-content-center text-center align-items-center">
        <ion-button class="gift-button" :disabled="isTotalCountZero" @click="openModal">
          <ion-icon :icon="giftOutline" class="gift-icon mx-1" />
          Gift
        </ion-button>
      </div>
    </ModalFooter>
  </ion-modal>
</template>

<script lang="ts" setup>
import { giftOutline } from 'ionicons/icons';
import ModalHeader from './ModalComponents/ModalHeader.vue';
import ModalFooter from './ModalComponents/ModalFooter.vue';
import ModalComplimentBombContent from './ModalComponents/ModalComplimentBombContent.vue';

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
  modalTitle: {
    type: String,
    default: '',
  },
  inventories: {
    type: Object,
    default: () => {},
  },
  showFooter: {
    type: Boolean,
    default: false,
  },

  selectedInventoryItem: {
    type: Object,
    default: () => {},
  },
});
const emits = defineEmits(['close', 'updated', 'open']);
const isOpen = toRef(props, 'isOpen');

const selectedIndex = ref(0);

const modalTitle = toRef(props, 'modalTitle');
const selectedInventoryItem = toRef(props, 'selectedInventoryItem');
watch(isOpen, () => {
  selectedIndex.value = 0;
  //title.value = modalTitle.value;
});
const isTotalCountZero = computed(() => {
  return get(selectedInventoryItem.value, 'amount') <= 0;
});

const openModal = () => {
  emits('open');
};

const title = computed(() => {
  return modalTitle.value
    ? modalTitle.value
    : get(selectedInventoryItem.value, 'type') === 'fanart_flare'
    ? 'GIFT FANART FLARE'
    : `GIFT A COMPLIMENT BOMB`;
});

const description = computed(() => {
  return `<strong>Once gifted, </strong> your friend can <strong>${
    get(selectedInventoryItem.value, 'type') === 'fanart_flare' ? 'flare' : 'bomb'
  }</strong> one of <strong>their Characters</strong> and get  ${
    get(selectedInventoryItem.value, 'type') === 'fanart_flare' ? 'FanArt' : 'Compliment Bombed'
  }</strong> by the <strong>entire community!</strong>.`;
});

const sectionTitle = computed(() => {
  return modalTitle.value ? '' : `READY TO BE GIFTED`;
});

const updatedGiftInventory = computed(() => {
  return [
    {
      svgIcon: get(selectedInventoryItem.value, 'svg'),
      title: sectionTitle.value,
      text: description.value,
    },
  ];
});

const dismissModal = () => {
  emits('close');
};

const selected = (val: any) => {
  selectedIndex.value = val;
};
</script>
<style lang="sass" scoped>
.gift-button::part(native)
  color: #fff
.hide-cursor
  pointer-events: none !important

.dark .toolbar
  --background: #17074c !important

.gift-modal-big
  --width: 400px
  --height: auto
  --border-radius: 10px
  @media(max-width: 576px)
    --width: 100%
.close-button
  width: 50px
.title
  color: #214163
  font-size: 20px
  font-weight: bold
  text-align: center
  align-items: center
</style>
