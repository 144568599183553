<template>
  <div>
    <EventCard
      :task="task"
      v-for="task in submissionTasks"
      :hideHasSubmitted="hideHasSubmitted"
      :isQuestModal="isQuestModal"
      :selected-task="selectedtaskId === task.id"
      :currentTab="currentTab"
      @click="selectedTask(task)"
    />
    <div v-if="!isQuestModal && isAuthenticated && submittedTasks && submittedTasks.length">
      <div v-if="currentTab !== 'submission'">
        <div class="text-center mx-auto sub-txt">Done</div>
        <hr class="submission-line" />
      </div>
      <EventCard :task="task" v-for="task in submittedTasks" :hideHasSubmitted="hideHasSubmitted" />
    </div>

    <!-- <div v-if="!submissionTasks.length" class="expired text-center mt-3">End of list</div> -->
  </div>
</template>

<script lang="ts" setup>
import { authStore } from '~/shared/pinia-store/auth';
import EventCard from './EventCardPreview.vue';

const props = defineProps({
  submissionTasks: {
    type: Array,
    default: () => [],
  },
  submittedTasks: {
    type: Array,
    default: () => [],
  },
  hideHasSubmitted: {
    type: Boolean,
    default: false,
  },
  isQuestModal: {
    type: Boolean,
    default: false,
  },
  currentTab: {
    type: String,
    default: '',
  },
});
const submissionTasks: any = toRef(props, 'submissionTasks');
const selectedtaskId = ref('');
const isQuestModal = toRef(props, 'isQuestModal');
const emits = defineEmits(['selected']);
const { isAuthenticated } = authStore();
const hideHasSubmitted: any = toRef(props, 'hideHasSubmitted');
const selectedTask = (task: any) => {
  if (isQuestModal.value) {
    selectedtaskId.value = task.id;
    emits('selected', selectedtaskId.value);
  }
};
</script>

<style lang="sass" scoped>
.submission-line
  background: #5BD502 !important
  height: 3px
  margin: 0px !important
.sub-txt
  color: #5BD502
  font-size: 20px
  font-weight: bold
a:hover
  opacity: 0.7
.expired
  color: gray !important
</style>
