<template>
  <ion-page class="page scrollable">
    <div>
      <div class="title text-center p-3">{{ edit ? 'Edit Bookmark List' : 'Create a Bookmark List' }}</div>
      <div class="heading text-center">
        Organize your favorite things with bookmarks! Start a new list here, then bookmark away!
      </div>

      <div class="section mt-3">
        <div class="sub-title">Key info</div>

        <div class="label mb-1 mt-3">Bookmark List Name</div>
        <ion-input v-model="collection.name" class="c-input" placeholder="Enter name" />

        <div class="label mb-1 mt-3">Description</div>
        <Editor
          :key="rerenderEditorKey"
          v-model="collection.description"
          :api-key="`${tinyMCEKey}`"
          :init="{
            height: 500,
            relative_urls: false,
            menu: {
              insert: {
                title: 'Insert',
                items: 'image link media inserttable | charmap emoticons hr  | insertdatetime',
              },
            },
            menubar: 'edit view insert format tools table help',
            plugins:
              'quickbars advlist autolink lists link image charmap preview anchor searchreplace visualblocks code insertdatetime media table code help wordcount',
            toolbar:
              'undo redo | bold italic backcolor | blocks | fontfamily |  \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat | help',
            contextmenu: false,
            quickbars_insert_toolbar: false,
            quickbars_selection_toolbar: 'bold italic underline strikethrough link',
          }"
          placeholder="Write a brief description about your folder!"
        />
      </div>

      <div class="section d-flex align-items-center mt-3">
        <div class="label mb-1">Folder Image:</div>

        <img
          v-if="collection.image_url && collection.cropped_img_url"
          class="clickable-item-hov image mx-2"
          :src="resizeUpload(collection.cropped_img_url, '190w')"
          @click="openCropPreview(collection.image_url)"
        />

        <UploadForm class="mx-2" @uploaded="changeImage" />
      </div>

      <div class="section d-flex align-items-center mt-3">
        <div class="label">Folder Cover Image</div>
        <img
          v-if="collection.banner_img_url"
          class="banner-image mx-2"
          :src="resizeUpload(collection.banner_img_url, '190w')"
        />
        <UploadForm class="mx-2" @uploaded="changeCover" />
      </div>

      <div class="mt-3 section p-2 rounded-xl">
        <div class="font-bold">Privacy</div>
        <ion-radio-group v-model="collection.privacy" class="d-flex flex-column ml-2" mode="md">
          <div class="d-flex align-items-center mt-3">
            <ion-radio value="M" class="mr-1 clickable-item-hov" />
            <div class="d-flex flex-column">
              <div class="bold clickable-item-hov" @click="setPrivacy('M')">Private</div>
              <div class="msg">
                🔒 Upload for personal use. Only you will be able to view these bookmarks.
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center mt-4">
            <ion-radio value="U" class="mr-1 clickable-item-hov" />
            <div class="d-flex flex-column">
              <div class="bold clickable-item-hov" @click="setPrivacy('U')">Unlisted</div>
              <div class="msg">🔗 Only you and those with the link will be able to view these bookmarks.</div>
            </div>
          </div>
          <div class="d-flex align-items-center mt-4">
            <ion-radio value="P" class="mr-1 clickable-item-hov" />
            <div class="d-flex flex-column">
              <div class="bold clickable-item-hov" @click="setPrivacy('P')">Public</div>
              <div class="msg">🌍 Everyone will be able to view these bookmarks.</div>
            </div>
          </div>
        </ion-radio-group>
      </div>

      <div class="w-100 d-flex">
        <ion-button class="mx-auto mt-4 publish-btn" :disabled="!isValid || isPublishing" @click="publish">
          <ChLoading size="sm" v-if="isPublishing" class="spinner" />
          <span v-else>Save</span>
        </ion-button>
      </div>
      <div v-if="collection.id" class="d-flex justify-content-center mt-4">
        <span class="delete-btn" @click="deleteCurrentCollection">Delete</span>
      </div>
    </div>

    <crop-image-modal
      :image-url="cropImage"
      :is-open="openCropperView"
      @dismiss-modal="closeCropPreview"
      @crop-done="cropProfilePicture"
    />
  </ion-page>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { alertController } from '@ionic/vue';
import { Watch } from 'vue-property-decorator';
import { BookmarkCollection } from '@/shared/types/static-types';
import UploadForm from '@/shared/components/upload-form.vue';
import { toast } from '@/shared/native';
import {
  createBookmarkCollection,
  updateBookmarkCollection,
  getBookmarkCollection,
  deleteCollection,
} from '@/shared/actions/bookmarks';
import { authStore } from '@/shared/pinia-store/auth';
import { mainStore } from '@/shared/pinia-store/main';
import { upload } from '@/shared/services/upload';
import { resizeUpload } from '@/shared/utils/upload';

@Options({
  name: 'CreateBookmarkCollectionPage',
  components: {
    UploadForm,
  },
})
export default class CreateBookmarkCollectionPage extends Vue {
  public edit = false;
  public loading = true;
  public isPublishing = false;
  public get tinyMCEKey() {
    const {
      public: { tinyMCEKey },
    } = useRuntimeConfig();

    return tinyMCEKey;
  }

  public cropImage? = '';
  public openCropperView = false;
  get = get;
  resizeUpload = resizeUpload;
  public collection: BookmarkCollection = {
    id: '',
    name: '',
    slug: '',
    description: '',
    image_url: '',
    cropped_img_url: '',
    banner_img_url: '',
    privacy: 'M',
  };

  public setPrivacy(value: string) {
    this.collection.privacy = value;
  }

  public get user() {
    const { user } = authStore();
    return user.value;
  }

  public rerenderEditorKey = 0;

  public get dark() {
    const { dark } = mainStore();
    return dark.value;
  }

  @Watch('dark')
  public rerenderTextarea() {
    this.rerenderEditorKey = this.rerenderEditorKey + 1;
  }

  public changeImage(image: string) {
    if (typeof image !== 'string') return;
    this.collection.image_url = image;
    this.collection.cropped_img_url = image;
  }

  public changeCover(image: string) {
    if (typeof image !== 'string') return;
    this.collection.banner_img_url = image;
  }

  public async deleteCurrentCollection() {
    try {
      const alert = await alertController.create({
        cssClass: '',
        header: 'Are you sure?',
        message: `Please confirm that you want to delete this bookmark list.`,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'text-secondary',
            id: 'cancel-button',
          },
          {
            text: 'Confirm',
            id: 'confirm-button',
            role: 'ok',
            cssClass: 'text-secondary',
          },
        ],
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      const router = useRouter();
      if (role === 'ok') {
        await deleteCollection(this.collection.id);
        await toast.show('Bookmarks deleted', 'nonative', 'primary');
        router.push({ name: 'my-creations', query: { tab: 'bookmarks' } });
      }
    } catch (e) {
      toast.show('Encountered an error - please try again later.', 'nonative', 'danger');
    }
  }

  public async publish() {
    const router = useRouter();
    this.isPublishing = true;
    let response;
    try {
      if (!this.edit) {
        response = await createBookmarkCollection({
          name: this.collection.name,
          description: this.collection.description,
          image_url: this.collection.image_url,
          cropped_img_url: this.collection.cropped_img_url,
          banner_img_url: this.collection.banner_img_url,
          privacy: this.collection.privacy,
        });
      } else {
        const payload = {
          name: this.collection.name,
          description: this.collection.description,
          image_url: this.collection.image_url,
          cropped_img_url: this.collection.cropped_img_url,
          banner_img_url: this.collection.banner_img_url,
          privacy: this.collection.privacy,
        };

        response = await updateBookmarkCollection(this.collection.id, payload);
      }
      router.push({ name: 'bookmark-collection-details', params: { slug: response.slug } });

      this.isPublishing = false;
      await toast.show('Successfully saved.', 'nonative', 'success');
    } catch (_err) {
      await toast.show('Some error has occurred. Please try again.', 'nonative', 'danger');
      this.isPublishing = false;
    }
  }

  public get isValid() {
    return !!this.collection.name;
  }

  public openCropPreview(imgUrl?: string) {
    this.cropImage = imgUrl;
    this.openCropperView = true;
  }

  public closeCropPreview() {
    this.openCropperView = false;
    this.cropImage = '';
  }

  public async cropProfilePicture(image: Blob) {
    const url = await upload(image);
    this.collection.cropped_img_url = url;
    this.openCropperView = false;
    this.cropImage = '';
  }

  public get collectionId() {
    const router = useRouter();
    return router.currentRoute.value.params.id;
  }

  public async mounted() {
    if (this.collectionId) {
      const router = useRouter();
      const collection_details: BookmarkCollection = await getBookmarkCollection(
        String(router.currentRoute.value.params.id)
      );
      this.collection = collection_details;
      this.edit = true;
    }
  }
}
</script>
<style lang="sass" scoped>
.image
  width: 90px
  height: 90px
  border-radius: 45px
  object-fit: cover
.heading
  font-size: 14px
  font-weight: bold
  color: grey
.banner-image
  width: 90px
  height: 90px
  object-fit: cover

.title
  font-size: 26px
  font-weight: bold
  color: #214163
.sub-title
  font-size: 20px
  font-weight: bold

.publish-btn
  width: 100px
  .spinner
    width: 15px
    height: 15px

.delete-btn
  background-color: transparent
  color: gray
  cursor: pointer
  padding: 0 0.5rem 0.5rem 0.5rem
</style>
