<template>
  <!-- This component doesn't render anything -->
</template>

<script lang="ts" setup>
import { authStore } from '@/shared/pinia-store/auth';

const rampComponentLoaded = ref(false);
const route = useRoute();
const { isAuthenticated } = authStore();

onMounted(() => {
  const {
    public: { playwirePubId, playwireWebsiteId },
  } = useRuntimeConfig();

  if (!playwirePubId || !playwireWebsiteId) {
    return;
  }

  if (!rampComponentLoaded.value) {
    rampComponentLoaded.value = true;
    (window as any).ramp = (window as any).ramp || {};
    (window as any).ramp.que = (window as any).ramp.que || [];
    (window as any).ramp.passiveMode = true;

    const configScript = document.createElement('script');
    configScript.src = `https://cdn.intergient.com/${playwirePubId}/${playwireWebsiteId}/ramp.js`;
    document.body.appendChild(configScript);

    configScript.onload = () => {
      (window as any).ramp.que.push(async () => {
        try {
          await (window as any).ramp.setPath('logged-in-aib');
        } catch (error) {
          console.error('Error setting path for ramp', error);
        }
        (window as any).ramp.spaNewPage('logged-in-aib');
      });
    };
  }
});

// watch(
//   () => route.path,
//   (newPath) => {
//     (window as any).ramp.que.push(() => {
//       (window as any).ramp.setPath('logged-in-aib');
//       (window as any).ramp.spaNewPage('logged-in-aib');
//     });
//   }
// );
</script>
