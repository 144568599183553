import { Raffle } from '@/shared/types/static-types';
import { chAxios } from '@/shared/lib/axios';
import { sendAnalyticsEvent } from '@/shared/services/analytics';

export async function postRaffle(payload: {}) {
  const url = `/raffles/`;
  try {
    const { data } = await chAxios().post(url, payload);
    sendAnalyticsEvent('Created raffle');
    return data;
  } catch (e) {
    throw e;
  }
}
export async function getRafflesEntryStatus(ids: string[] | undefined = []) {
  const url = `/raffles/entries/user/`;
  try {
    const { data } = await chAxios().post(url, { raffle_ids: ids });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getRafflesCount() {
  const url = `/raffles/new-raffles-count/`;
  try {
    const { data } = await chAxios().get(url);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function postThankyouRaffleNote(payload: {}) {
  const url = `/raffles/entries/say-thanks/`;
  try {
    const { data } = await chAxios().post(url, payload);
    sendAnalyticsEvent('Submitted thank you on raffle');
    return data;
  } catch (e) {
    throw e;
  }
}

export async function entryInGiveaway(payload: {}) {
  const url = `/raffles/entries/`;
  try {
    const { data } = await chAxios().post(url, payload);
    sendAnalyticsEvent('Entered raffle');
    return data;
  } catch (e) {
    throw e;
  }
}

export async function submitRaffleSocialBonus(payload: {}) {
  const url = `/raffles/entries/social-bonus/`;
  try {
    const { data } = await chAxios().post(url, payload);
    sendAnalyticsEvent('Earned raffle bonus entry', { type: 'social' });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateRaffle(payload: {}, id: string) {
  const url = `/raffles/${id}/`;
  try {
    const { data } = await chAxios().patch(url, payload);
    sendAnalyticsEvent('Updated Raffle');
    return data;
  } catch (e) {
    throw e;
  }
}
export async function getRaffleById(id: string): Promise<Raffle> {
  const url = `/raffles/`;
  try {
    const { data } = await chAxios().get(url, { params: { id } });
    return data;
  } catch (e) {
    throw e;
  }
}
export async function getManageRaffles(tab: string, page?: number, pageSize?: number) {
  const url = `/raffles/manage/`;
  try {
    const { data } = await chAxios().get(url, { params: { tab, page, page_size: pageSize } });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getRaffleEntryStatus(id: string) {
  const url = `/raffles/entries/user-status/?raffle_id=${id}`;
  try {
    const { data } = await chAxios().get(url);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getRaffleBonusEntries(id: string) {
  const url = `/raffles/entries/user-bonus-earned/?raffle_id=${id}`;
  try {
    const { data } = await chAxios().get(url);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getRaffleFeedBackNotes(id: string) {
  const url = `/raffles/entries/thanks-detail/?raffle_id=${id}`;
  try {
    const { data } = await chAxios().get(url);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getRaffleWinners() {
  const url = `/raffles/raffle-winners/`;
  try {
    const { data } = await chAxios().get(url);
    return data;
  } catch (e) {
    throw e;
  }
}
export async function getRaffleWinnersAndRunnerUps(id: string) {
  const url = `/raffles/${id}/raffle-winners-and-runnersups/`;
  try {
    const { data } = await chAxios().get(url);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getRaffleEntriesCount(id: string) {
  const url = `/raffles/entries/entries-count/?raffle_id=${id}`;
  try {
    const { data } = await chAxios().get(url);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getRaffleThankYouCounts(id: string) {
  const url = `/raffles/entries/thanks-count/?raffle_id=${id}`;
  try {
    const { data } = await chAxios().get(url);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getRaffles(params?: {
  category?: string;
  is_featured?: Boolean;
  page?: number;
  user?: string;
  ordering?: string;
  page_size?: number;
}) {
  const url = `/raffles/`;
  try {
    const { data } = await chAxios().get(url, { params });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getUserRaffles(user_id: any) {
  const url = `/raffles/from-user/?user=${user_id}`;
  try {
    const { data } = await chAxios().get(url);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getRaffleFromSlug(slug: string) {
  const url = `/raffles/from-slug/`;
  try {
    const { data } = await chAxios().get(url, { params: { slug } });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function deleteRaffle(id: string) {
  const url = `/raffles/${id}/`;
  await chAxios().delete(url);
}