<template>
  <div v-if="currentReport">
    <span class="text-blue clickable-item-hov" @click.prevent="close">
      Back
    </span>
    <h6 class="title mt-3">Review Report</h6>
    <ion-button color="danger" @click="ban(3)"><span class="no-select">3 day ban</span></ion-button>
    <ion-button color="danger" @click="ban(7)"><span class="no-select">7 day ban</span></ion-button>
    <ion-button color="danger" @click="ban(30)"><span class="no-select">30 day ban</span></ion-button>
    <ion-button color="danger" @click="ban(0)"><span class="no-select">Perma ban</span></ion-button>
    <div class="mt-3">
      <label for="ban-reason">Reason for Ban&nbsp;</label>
      <input id="ban-reason" v-model="banReason" type="text" placeholder="Enter reason here" size="40">
    </div>
    <div>
      <div class="title mt-3">@{{ currentReport?.reported_user?.username }}</div>
      <div class="report-card">
        <div class="report-card__content">
          {{ formatDateTime(currentReport.modified) }} UTC
          <div class="report-card__title">{{ categoryStr(currentReport?.report_category) }}</div>
          <div class="report-card__description">{{ currentReport.comments || '(No description)' }}</div>
        </div>
        <a :href="currentReport.reported_content_link" target="_blank" rel="noopener noreferrer">
          <ion-button color="danger">
            <span class="no-select">View Reported Content</span>
          </ion-button>
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { adminStore } from '@/shared/pinia-store/admin';
import { banUser, unbanUser } from '@/shared/actions/admin';
import { toast } from '@/shared/native';
import { formatDateTime } from '~/shared/utils/dateTime';

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
  report: {
    type: String,
    required: true,
  },
});

const emits = defineEmits(['close']);
const reportId = toRef(props, 'report');
const banReason = ref('');
const close = () => {
  emits('close');
};

const currentReport = computed(() => {
  const { getReportById } = adminStore();
  return getReportById.value(reportId.value);
});
const categoryStr = (categoryId: string | undefined) => {
  const { categories } = adminStore();
  if (!categoryId) return '(No category)';
  return categories.value.find((c: any) => c.id === categoryId)?.title;
}
const ban = (days: number) => {
  if (!currentReport.value) return;
  if (!banReason.value?.trim()) {
    toast.show('Please enter a reason for the ban', 'nonative', 'danger');
  }
  // confirm first
  const confirmBan = confirm(
    `Are you sure you want to ban ${currentReport.value.reported_user?.username} for ${days ? days : '999999'} day(s)?`
    );
  if (!confirmBan) {
    toast.show('No action taken', 'nonative', 'primary');
    return;
  }
  banUser(currentReport.value.reported_user.id, banReason.value, days);
  toast.show('User has been banned', 'nonative', 'danger');
}
</script>

<style lang="sass" scoped>
.close-button
  width: 50px
.no-space
  margin-left: 0
  margin-right: 0
  margin-bottom: 0

.no-space [class*='col-']:not(:first-child),
.no-space [class*='col-']:not(:last-child)
  padding-right: 0
  padding-left: 0
.see-all-btn
  --border-radius: 10px
  height: 30px
.row
  margin-top: 10px !important

.one-character
  img
    min-width: 90px
    width: 90px !important
    height: 90px !important
    border-radius: 45px
    border: solid gray 0.1px
    object-fit: cover
    .name
      font-size: 18px
      font-weight: bold
      color: #737373
      cursor: pointer
    .name:hover
      opacity: 0.7

.title
  font-weight: bold
  font-size: 22px
.modal-big
  --width: 700px
  --height: auto
  @media(max-width: 576px)
    --width: 100%

.inner-content
  background: var(--ion-background-color, #fff)
  max-height: 95vh
  overflow: auto
</style>
