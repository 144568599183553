<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ion-header translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-between align-items-center px-3">
          <div></div>
          <div>
            <div class="title">
              {{
                get(limit, 'available_compliments') === 0 && isNull(get(limit, 'last_rewarded'))
                  ? `No Charater Available come back later!`
                  : get(limit, 'available_compliments') === 0
                  ? `You've completed your ${
                      socialItemType === COMPLIMENT_BOMB ? 'compliments' : ' fanart '
                    } for today!`
                  : isMissionSuccessful
                  ? 'Mission Successful'
                  : `${socialItemType === COMPLIMENT_BOMB ? 'Compliments' : 'FanArt Flare'}`
              }}
            </div>
          </div>
          <div>
            <ion-buttons slot="end">
              <ion-button @click="dismissModal">
                <i class="ti-close" />
              </ion-button>
            </ion-buttons>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>

    <div class="modal-inner-content">
      <div class="px-3 py-3">
        <ChLoading size="lg" v-if="loading" class="mx-auto" />
        <div v-else>
          <compliment-bomb-vertical-card
            :title="title"
            :isDisplayReward="isDisplayReward"
            :showMissionDesc="true"
            :dailyLimit="limit"
            :extra-bonus="bonus"
            :reward="reward"
            :currBombedCharsData="currBombedCharsData"
            :totalComplimentCount="isBonusRewarded || totalComplimentCount ? totalComplimentCount : count"
            :isBonusRewarded="isBonusRewarded"
            :socialItemType="socialItemType"
            :remainingEndTime="remainingEndTime"
          >
            <template #cbimage>
              <img loading="lazy" v-character-card-image :src="yayAnimation" class="yay-img" />
            </template>
          </compliment-bomb-vertical-card>

          <div class="d-flex align-items-center py-3">
            <div class="w-100 char-text">Wanna shower a Character with love?</div>
            <div class="w-100">
              <ion-button class="buy-compliment-button" @click="isOpenPurchaseBombModal = true">
                <inline-svg src="/compliment-bomb.svg" />
                Buy a {{ socialItemType === COMPLIMENT_BOMB ? 'Compliment Bomb' : 'FanArt Flare' }} for yourself or a
                friend!
              </ion-button>
            </div>
          </div>
          <ComplimentVoteTabs class="my-2" :tabs="tabs" :active-index="tabIndex" @tab-changed="tabChanged" />

          <div class="sub-title text-black mb-3">
            Vote On The Best {{ socialItemType === COMPLIMENT_BOMB ? 'Compliments' : 'FanArt!' }}
          </div>
          <div>
            <ChLoading size="lg" v-if="loading" class="mx-auto" />
            <div v-else>
              <div v-if="socialItemType === COMPLIMENT_BOMB">
                <compliments-vote-card
                  v-for="(compliment, index) in complimentCharacters"
                  :key="index"
                  :compliment="compliment"
                />
              </div>
              <div v-else>
                <Grid :xl="2" :lg="2" :md="2" :sm="2" :scrollSm="false">
                  <FanArtFlareVoteCard
                    class="grid-item"
                    v-for="(fanart, index) in complimentCharacters"
                    :fanart="fanart"
                    :key="index"
                  />
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ion-footer translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-center text-center align-items-center">
          <ion-button class="done-btn" @click="dismissModal">Done</ion-button>
        </div>
      </ion-toolbar>
    </ion-footer>

    <compliment-bomb-use-and-purchase-modal
      :is-open="isOpenPurchaseBombModal"
      :purchase-more="true"
      @close="closePurchaseModal"
      @purchase="closeModal"
    />
  </ion-modal>
</template>

<script lang="ts" setup>
import ComplimentBombVerticalCard from '@/shared/components/ComplimentBombVerticalCard.vue';
import ComplimentsVoteCard from '@/shared/components/ComplimentsVoteCard.vue';
import { getComplimentBombsList, getFanArtFlareList } from '@/shared/actions/complimentBomb';
import ComplimentBombUseAndPurchaseModal from './ComplimentBombUseAndPurchaseModal.vue';
import FanArtFlareVoteCard from '@/shared/components/FanArtFlareVoteCard.vue';
import Grid from '@/shared/components/storage/Grid.vue';
import { mainStore } from '@/shared/pinia-store/main';
import ComplimentVoteTabs from '@/shared/pages/compliment-bombs/components/ComplimentBombCharactersTabs.vue';
import { complimentTabs } from '@/shared/statics/tabs';
import { Tab } from '@/shared/types/static-types';
import { useSocialInventoryTimer } from '~/composables/useSocialInventoryTimer';
import { COMPLIMENT_BOMB } from '../statics/constants';
const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
  count: {
    type: Number,
  },
  limit: {
    type: Object,
    default: {},
  },
  isMissionCompleted: {
    type: Boolean,
    default: false,
  },
  isDisplayReward: {
    type: Boolean,
    default: false,
  },
  cbRewards: {
    type: Array,
  },
  totalComplimentCount: {
    type: Number,
  },
  isBonusRewarded: {
    type: Boolean,
    default: false,
  },
  socialItemType: {
    type: String,
    default: '',
  },
  currBombedCharsData: {
    type: Object,
    default: () => {},
  },
});
const emits = defineEmits(['close']);
const complimentCharacters = ref([]);
const isOpen = toRef(props, 'isOpen');
const isOpenPurchaseBombModal = ref(false);
const loading = ref(true);
const limit = toRef(props, 'limit');
const count = toRef(props, 'count');
const tabIndex = ref(0);

const remainingEndTime: any = ref(null);
const currentTab = ref('new');
const isMissionCompleted = toRef(props, 'isMissionCompleted');
const cbRewards = toRef(props, 'cbRewards');
const socialItemType = toRef(props, 'socialItemType');
const { dark } = mainStore();
const title = computed(() => {
  return 'NICE GOING!';
});

const tabs = computed(() => {
  return complimentTabs.map((item: any) => {
    return {
      ...item,
      name: item.name.replace('{social_type}', socialItemType.value === COMPLIMENT_BOMB ? 'Compliments' : 'FanArt'),
    };
  });
});

const yayAnimation = computed(() => (dark.value ? '/animation_darkmode.gif' : '/animation.gif'));
const getTabIndex = () => {
  const tIndex = indexOf(
    tabs.value,
    find(tabs.value, (tab) => tab.value === currentTab.value)
  );
  if (tIndex !== -1) {
    tabIndex.value = tIndex;
  }
};

const tabChanged = ({ value }: Tab) => {
  currentTab.value = value;
  getTabIndex();
  fetchListOfSocialItems();
};

const isMissionSuccessful = computed(() => {
  return (
    limit.value.done_in_time >= limit.value.default_limit ||
    isMissionCompleted.value ||
    limit.value.available_compliments === 0 ||
    isNull(count.value)
  );
});

const bonus = computed(() => {
  if (cbRewards.value) {
    const ffindex = cbRewards.value.findIndex((item: any) => get(item, 'abbr') === 'fanart-flare-set-completion') || 0;
    const ccindex =
      cbRewards.value.findIndex((item: any) => get(item, 'abbr') === 'compliment-bomb-set-completion') || 0;
    return socialItemType.value === COMPLIMENT_BOMB
      ? get(cbRewards.value[ccindex], 'max_curr_amount')
      : get(cbRewards.value[ffindex], 'max_curr_amount');
  }
});

const reward = computed(() => {
  if (cbRewards.value) {
    const ffindex =
      cbRewards.value.findIndex((item: any) => get(item, 'abbr') === 'fanart-flare-individual-item-completion') || 0;
    const ccindex =
      cbRewards.value.findIndex((item: any) => get(item, 'abbr') === 'compliment-bomb-individual-item-completion') || 0;
    return socialItemType.value === COMPLIMENT_BOMB
      ? get(cbRewards.value[ccindex], 'max_curr_amount')
      : get(cbRewards.value[ffindex], 'max_curr_amount');
  }
});

const fetchListOfSocialItems = async () => {
  loading.value = true;
  const val = currentTab.value === 'top' ? currentTab.value : '-created';
  const resp =
    socialItemType.value === COMPLIMENT_BOMB
      ? await getComplimentBombsList({ ordering: val })
      : await getFanArtFlareList({ ordering: val });
  complimentCharacters.value = resp.results;
  loading.value = false;
};

watch(isOpen, () => {
  if (isOpen.value) {
    fetchListOfSocialItems();
    getTabIndex();
    const tempType =
      socialItemType.value === COMPLIMENT_BOMB
        ? get(limit.value, 'last_action_taken')
        : get(limit.value, 'last_commented');
    const { timeRemaining } = useSocialInventoryTimer(get(limit.value, 'reset_time'), tempType);
    remainingEndTime.value = timeRemaining.value;
  }
});

const dismissModal = (val?: any) => {
  complimentCharacters.value = [];
  currentTab.value = 'new';
  emits('close', val);
};

const closePurchaseModal = () => {
  isOpenPurchaseBombModal.value = false;
};

const closeModal = () => {
  closePurchaseModal();
  dismissModal();
};
</script>
<style lang="sass" scoped>
.ion-modal
  ::v-deep .modal-inner-content
    background: #F3F3F3 !important

.dark .done-btn::part(native)
  color: #fff !important
.yay-img
  max-width: 135px
  max-height: auto
.char-text
  font-size: 15px
  @media(max-width:400px)
    font-size: 84% !important
.buy-compliment-button
  white-space: break-spaces
  height: 66px
  font-weight: bold
  font-size: 15px
  @media(max-width:400px)
    font-size: 84% !important
.skip
  font-weight: bold
  text-decoration: underline
.compliment-button
  width: 100%
  height: 40px
.selected-char-bar
  background: #e6e6e6
  width: 100%
  border-radius: 20px
  padding-bottom: 0.4rem
  padding-top: 0.4rem
.bar-color-changed
  background: #00b4c5 !important
.character-bar
  border: 2px solid
  border-radius: 20px
  height: 24px
  width: 100%
.total-cha-section
  white-space: nowrap
.character-text-section
  font-weight: bold
  @media(max-width:360px)
    font-size: 14px
.dark .toolbar
  --background: #17074c !important

.modal-inner-content
  background: var(--ion-background-color, #fff)
  max-height: 60vh
  overflow-x: hidden
  overflow-y: auto

.modal-big
  --width: 600px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
.close-button
  width: 50px
.title
  color: #214163
  font-size: 20px
  font-weight: bold
  text-align: center
  align-items: center
.sub-title
  color: #214163
  font-size: 18px
  font-weight: bold
  text-align: center
  align-items: center
.char-compliment-bombed
  border-radius: 10px
  border: 2px solid #cfcaca
</style>
