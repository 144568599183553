<template>
  <div>
    <div class="title">{{ submissionTitle }}</div>

    <div v-if="loader" class="loader ml-auto">
      <ion-skeleton-text animated />
    </div>
    <CurrencyBar
      v-else
      :setNoPadding="true"
      :coins="get(usersCurrency, 'coins', 0)"
      :gems="get(usersCurrency, 'gems')"
      :earned="reward"
    />
    <div class="submission-reward my-2 position-relative">
      <div class="reward">Reward:</div>
      <div class="d-flex align-items-center justify-content-center text-center">
        <inline-svg height="30" width="30" class="mr-1" :src="currency" />
        <span class="coins">{{ reward }}</span>
      </div>
    </div>
    <div class="d-flex total-char-section mt-1">
      <div class="d-flex align-items-center character-text-section">
        Characters
        {{ count }}/{{ totalInSet }}
      </div>
      <div class="px-1 py-1 mx-1 character-bar">
        <div class="d-flex" style="gap: 2px">
          <div
            v-for="(character, index) in totalInSet"
            :key="index"
            class="selected-char-bar"
            :class="{
              'bar-color-changed': index < count,
            }"
          ></div>
        </div>
      </div>
    </div>
    <div class="text-center set mt-2">Complete the set for a bonus reward!</div>
  </div>
</template>
<script lang="ts" setup>
import CurrencyBar from '@/shared/pages/store/CurrencyBar.vue';
import { authStore } from '../pinia-store/auth';
const { usersCurrency, getUsersCurrency } = authStore();
const props = defineProps({
  submissionTitle: { type: String, default: () => '' },
  count: { type: Number, default: 0 },
  reward: { type: Number, default: 200 },
  currency: { type: String, default: '/hubuck.svg' },
  totalInSet: { type: Number, default: 0 },
});

const reward = toRef(props, 'reward');
const loader = ref(true);

watch(
  reward,
  async () => {
    if (reward.value) {
      await getUsersCurrency();

      loader.value = false;
    }
  },
  { immediate: true }
);
</script>
<style lang="sass" scoped>
.loader
  width: 50%
  max-width: 100%
  height: 35px
.coins
  font: bolder 20px "Roboto Slab", sans-serif
  -webkit-text-fill-color: #214163
.dark .coins
  font: bolder 20px "Roboto Slab", sans-serif
  -webkit-text-fill-color: #fff
.reward
  font-size: 10px
  position: relative
  left: -11px
.set
  font-size: 14px
  color: #FF9C33
  font-weight: bold
.submission-reward
  border-radius: 9px
  height: 58px
  border: 1px solid #EAECF7
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center


.title
  display: flex
  justify-content: center
  align-items: center
  font-weight: bolder
  text-align: center
  font: bolder 20px "Roboto Slab", sans-serif
  -webkit-text-fill-color: #214163

.dark .title
  font: bolder 20px "Roboto Slab", sans-serif !important
  -webkit-text-fill-color: #fff !important

.selected-char-bar
  background: #e6e6e6
  width: 100%
  border-radius: 20px
  padding-bottom: 0.4rem
  padding-top: 0.4rem
.bar-color-changed
  background: #00b4c5 !important
.character-bar
  border: 2px solid
  border-radius: 20px
  height: 24px
  width: 100%
.total-char-section
  white-space: nowrap
  color: #00b4c5
.character-text-section
  font-weight: bold
  @media(max-width:360px)
    font-size: 14px
</style>
