<template>
  <div class="d-flex flex-column">
    <div v-for="item in questions" :key="item.id" class="m-content content ion-card-padding">
      <strong style="font-weight: bold !important">{{
        item.extra.selected_option.value === 'CUSTOM' ? item?.title : stripHtmlTags(item.extra.selected_option.title)
      }}</strong>
      <div
        v-for="answer in item?.options || []"
        :key="answer.id"
        class="answer my-2 p-2"
        :class="
          item.answer === answer.id
            ? isTempDefault
              ? 'bg-primary'
              : 'answer-pressed'
            : item.answer || userCharacter || route.name === 'home'
            ? ''
            : 'clickable-item answer-hover'
        "
        @click.prevent="
          () => (item.answer || userCharacter || route.name === 'home' ? null : submitAnswer(item.id, answer.id))
        "
      >
        <div class="d-flex">
          <div class="d-flex flex-wrap">
            {{ answer.title }}
          </div>
          <div class="d-flex align-items-center mr-3 ml-auto">
            <ion-icon
              v-if="userCharacter || (item.answer && (item.answer === answer.id || answer.is_correct))"
              style="font-size: 23px"
              class="mr-2"
              :icon="answer.is_correct ? checkmarkCircleOutline : closeCircleOutline"
            />
            <span v-if="userCharacter">
              {{ item.answer_count ? Math.round((answer.select_count / item.answer_count) * 100) : 0 }}%
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { checkmarkCircleOutline, closeCircleOutline } from 'ionicons/icons';
import { mainStore } from '@/shared/pinia-store/main';
import { stripHtmlTags } from '@/shared/utils/string';
import {
  getCharacterTriviaQuestions,
  submitQuestion,
  getUserCharacter,
  getTriviaQuestionsStats,
} from '@/shared/actions/characters';
import { characterProfileStore } from '@/shared/pinia-store/character-profile';
import { authStore } from '@/shared/pinia-store/auth';
const { character } = characterProfileStore();
const { isAuthenticated } = authStore();

const props = defineProps({
  customize: {
    type: Object,
    default: () => ({}),
  },
});

const customize = toRef(props, 'customize');

const route = useRoute();
const questions: any = ref([]);
const userCharacter = ref(false);

const { isTempDefault } = useCustTempDefault(props);

const isdark = computed(() => {
  const { dark } = mainStore();
  return dark.value;
});

const infoSectionColor = computed(() => {
  const sectionColor = get(customize.value, 'data.theme.backgrounds.infoSection');
  return isTempDefault.value ? 'inherit' : sectionColor;
});
const pageColor = computed(() => {
  const sectionColor = get(customize.value, 'data.theme.backgrounds.page');
  return isTempDefault.value && isdark.value ? 'white' : isTempDefault.value && !isdark.value ? 'black' : sectionColor;
});
const defaultTextColor = computed(() => {
  const defaultClr = isdark.value ? '#FFFFFF' : '#000000';
  return isTempDefault.value ? defaultClr : `${get(customize.value, 'data.theme.text.default.color', defaultClr)}`;
});

const defaultBgColor = computed(() => {
  return isdark.value ? 'var(--ion-color-secondary)' : 'var(--ion-color-primary)';
});

const defaultTextFont = computed(() => {
  return isTempDefault.value ? 'Roboto' : get(customize.value, 'data.theme.text.default.font', 'Roboto');
});
const defaultTextBold = computed(() => {
  return isTempDefault.value && !get(route.query, 'tempId')
    ? 'normal'
    : get(customize.value, 'data.theme.text.default.bold')
    ? '800'
    : 'normal';
});

const params = computed(() => {
  return {
    ...(route.name === 'character-editor' && {
      character_id: route.params.id ? route.params.id.toString() : character.value?.id?.toString()!,
    }),
    ...((route.name === 'character-profile-new' || route.name === 'character-stylize') && {
      character_id: route.params.id ? route.params.id.toString() : character.value?.id?.toString()!,
    }),

    ...((route.name === 'draft-character-editor' ||
      route.name === 'character-profile-draft-new' ||
      route.name === 'preset-editor' ||
      route.name === 'character-creator') && {
      char_draft_id: route.name === 'character-creator' ? route.query.presetId : route.params.id.toString(),
    }),
    ...(route.name === 'character-creator' && {
      character_id: route.name === 'character-creator' ? route.query.templateCharId : route.params.id.toString(),
    }),
    ...(route.name === 'home' && {
      character_id: character.value?.id?.toString()!,
    }),
  };
});

const defaultTextItalic = computed(() => {
  return get(customize.value, 'data.theme.text.default.italics') ? 'italic' : 'normal';
});

const submitAnswer = async (question = '', answer = '') => {
  const res = await submitQuestion({ sectionquestion: question, answer });

  questions.value = questions.value.map((item: any) => {
    if (item.id === res.sectionquestion) {
      return {
        ...item,
        answer: res.answer,
      };
    } else {
      return item;
    }
  });
};

const fetchUserChar = async () => {
  const res = await getUserCharacter(params.value);

  userCharacter.value = res.is_user_character;
};

const fetchQuestions = async () => {
  let res;
  if (userCharacter.value) {
    res = await getTriviaQuestionsStats(params.value);
  } else {
    res = await getCharacterTriviaQuestions(params.value);
  }
  questions.value = character.value?.info?.triviaConfig
    ? sortBy(
        res.filter((item: any) => item.options.length),
        (item: any) => character.value?.info?.triviaConfig.indexOf(item.extra.ques_id)
      )
    : [];
};

onMounted(async () => {
  if (isAuthenticated.value) await fetchUserChar();
  fetchQuestions();
});
</script>

<style lang="sass" scoped>
.ion-card-padding
  padding: 8px !important
  margin-top: 8px !important
  margin-bottom: 8px !important

.ios .ion-card-padding
  padding: 8px !important
  margin-top: 8px !important
  margin-bottom: 8px !important

.circle
  position: absolute
  width: 18px
  height: 18px
  background-color: v-bind(defaultTextColor)
  left: -25px
  top: 50%
  transform: translateY(-50%)
  border-radius: 50%

.dark .content
  background: inherit !important
  color: v-bind(defaultTextColor) !important
  font-weight: v-bind(defaultTextBold) !important
  font-style: v-bind(defaultTextItalic) !important
  font-family: v-bind(defaultTextFont) !important

.content
  background: inherit !important
  ::v-deep
    *
      font-weight: v-bind(defaultTextBold) !important
      font-style: v-bind(defaultTextItalic) !important
      font-family: v-bind(defaultTextFont) !important

.m-content
  color: v-bind(defaultTextColor)
.dark .m-content
  color: v-bind(defaultTextColor)

.answer
  background: v-bind(infoSectionColor) !important
  border: 1px solid v-bind(pageColor) !important
  color: v-bind(defaultTextColor) !important
  border-radius: 12px

.dark .answer
  background: v-bind(infoSectionColor) !important
  border: 1px solid v-bind(pageColor) !important
  color: v-bind(defaultTextColor) !important
  border-radius: 12px

.answer-pressed
  background: v-bind(defaultTextColor) !important
  color: v-bind(infoSectionColor) !important

.answer-hover:hover
  opacity: 0.2

.bg-primary
  background: v-bind(defaultBgColor) !important
  color: v-bind(defaultTextColor) !important

.dark .bg-primary
  background: v-bind(defaultBgColor) !important
  color: v-bind(defaultTextColor) !important
</style>
