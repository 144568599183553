import { computed } from 'vue';
import { get } from 'lodash-es';
import { authStore } from '@/shared/pinia-store/auth';
import { characterStylizeStore } from '@/shared/pinia-store/character-stylize';
import { featureFlags } from '~/shared/config/feature-flags';
import { profileStore } from '~/shared/pinia-store/profile';

export const useBackgroundOverlay = () => {
  const { userTryOnAnimation } = authStore();
  const { currentTemplate } = characterStylizeStore();
  const { currentProfile } = profileStore();
  const route = useRoute();

  const isOverlayApplied = computed(() => {
    return (
      [
        'character-profile-draft-new',
        'character-profile-new',
        'character-draft-stylize',
        'character-stylize',
        'profile',
        'shop',
      ].includes(route.name as string) && !isEmpty(userTryOnAnimation.value)
    );
  });
  const isCharProfile = computed(() => {
    return ['character-profile-draft-new', 'character-profile-new'].includes(route.name as string);
  });

  const isCharStylize = computed(() => {
    return ['character-draft-stylize', 'character-stylize'].includes(route.name as string);
  });

  const isProfile = computed(() => {
    return route.name === 'profile';
  });
  const animation = computed(() => {
    if (featureFlags.backgroundOverlay) {
      if (isOverlayApplied.value) return get(userTryOnAnimation.value, 'image');
      else
        return route.name === 'profile'
          ? get(currentProfile.value, 'customize_profile.background_overlay.image')
          : isCharStylize.value || isCharProfile.value
          ? get(currentTemplate.value, 'data.background_overlay.image')
          : null;
    }
  });

  const bgOverlayMode = computed(() => {
    if ((isProfile.value || isCharProfile.value) && !isEmpty(animation.value)) {
      return get(userTryOnAnimation.value, 'extra.bgRepeat') ||
        (isProfile.value
          ? get(currentProfile.value, 'background_overlay.extra.bgRepeat')
          : get(currentTemplate.value, 'data.background_overlay.extra.bgRepeat'))
        ? 'repeat'
        : 'no-repeat';
    }
  });

  const backgroundImageStyle = computed(() => {
    return {
      backgroundImage: animation.value ? `url(${animation.value}) !important` : '',
    };
  });

  return {
    backgroundImageStyle,
    animation,
    bgOverlayMode,
  };
};
