<template>
  <div>
    <div id="chsgi" class="scrollable my-3">
      <div class="mobile-only offset" :class="{ off: !topRowReactionsOpen }"></div>
      <Sortable
        :id="`images_${currentCollectionId}`"
        :key="forcererenderKey"
        class="images grid-container"
        item-key="id"
        tag="div"
        :list="images"
        :options="sortableOptions"
        :class="{ 'is-disabled': route.name === 'home' }"
        :disabled="!isEditor || isReadOnlyCollection"
        @end="onEndMove"
        @clone="onClone"
        @move="onMove"
      >
        <template #item="{ element, index }">
          <CharacterOutfitsImage
            v-if="!element?.loader"
            :id="element.id"
            :is-editor="isEditor"
            :index="index"
            :image="element"
            :used-key="usedKey"
            :is-watermark-loading="isWatermarkLoading"
            :watermark-load-text="watermarkLoadText"
            :customize="customize"
            :edit-image-modal-is-open="editImageModalIsOpen"
            :disabled="isReadOnlyCollection"
            :reordering="isReordering"
            @updatedImage="updatedImage"
            @addImage="addImage"
            @hideReactions="toggleReactionBump(false)"
            @showReactions="toggleReactionBump(true)"
            @touched="emitTouched"
            @deleteImage="deleteImage"
            @watermark="setWatermarkImages"
            @openEditModal="openEditImageModal"
            @updatedImageReaction="updatedImageReaction"
            @uploadingImage="uploadingImage"
          />
          <div v-else class="">
            <ion-skeleton-text animated class="small-image" />
          </div>
        </template>
      </Sortable>
    </div>

    <EditImageSectionDetailsModal
      :is-editor="isEditor"
      :image="editImage"
      :used-key="usedKey"
      :customize="customize"
      :is-watermark-loading="isWatermarkLoading"
      :is-open="editImageModalIsOpen && !!editImage?.id"
      :uploading="isUploading"
      @imageWatermark="watermarkImages"
      @updatedImage="updatedImage"
      @dismissModal="closeEditImageModal"
    />

    <VisualPreviewModal
      :is-open="isViewImageModalOpen"
      :image-id="selectedVisualImgId"
      :image-url="selectedVisualImg"
      :caption="selectedVisualCaption"
      :source="selectedVisualSource"
      :source-user="get(currentImage, 'source_user') || null"
      :all-images="images"
      :image="currentImage"
      :used-key="usedKey"
      :active-index="currentIndex"
      @updatedImage="updatedImage"
      @dismissModal="closeVisualPreviewModal"
      @onReaction="(reaction) => onVisualReaction(reaction)"
      @updated="openVisualPreview"
    />
  </div>
</template>

<script lang="ts" setup>
import CharacterOutfitsImage from './CharacterOutfitsImage.vue';
import EditImageSectionDetailsModal from './EditImageSectionDetailsModal.vue';
import { imageStore } from '@/shared/pinia-store/images';
import { characterProfileStore } from '@/shared/pinia-store/character-profile';
import { characterEditorStore } from '@/shared/pinia-store/character-editor';
import { mainStore } from '@/shared/pinia-store/main';
import { authStore } from '@/shared/pinia-store/auth';
import { isTouchScreen } from '@/shared/utils/ui';
import { getImages, bulkUpdateWatermarks, unSpoilImage } from '@/shared/actions/imagesCollections';
import VisualPreviewModal from '@/shared/modals/VisualPreviewModal.vue';

const {
  charOutfits,
  addOutfits,
  editImages,
  charOutfitsIds,
  charLifeStagesIds,
  charInventoryIds,
  charFavIds,
  charLifeStages,
  charInventory,
  charFav,
} = characterEditorStore();
const { character } = characterProfileStore();

const props = defineProps({
  usedKey: {
    type: String,
    default: '',
  },
  isEditor: {
    type: Boolean,
    default: true,
  },
  customize: {
    type: Object,
    default: () => ({}),
  },
  addedImages: {
    type: Array,
    default: () => [Image],
  },
  watermarkAll: {
    type: Boolean,
    default: false,
  },
  loaders: {
    type: Array,
    default: () => [],
  },
  isComplimentBomb: {
    type: Boolean,
    default: false,
  },
  reordering: {
    type: Boolean,
    default: false,
  },
});
const route = useRoute();
const isWatermarkLoading = ref(false);
const watermarkLoadText = ref('');
const { isTempDefault } = useCustTempDefault(props);
const clonedElement = ref(null) as any;
const forcererenderKey = ref(0);
const emits = defineEmits(['touched', 'openEditModal', 'uploadingImage', 'removeLoader']);
const isEditor = toRef(props, 'isEditor');
const editImageModalIsOpen = ref(false);
const isViewImageModalOpen = ref(false);
const isReordering = toRef(props, 'reordering');
const currentIndex = ref(0);
const selectedVisualImg = ref('');
const selectedVisualImgId = ref('');
const selectedVisualCaption = ref('');
const selectedVisualSource = ref('');
const { isAuthenticated, user } = authStore();

const editImage: any = ref({});
const currentImage: any = ref({});

const sortableOptions = {
  group: {
    name: 'imgmove',
    pull: true,
    put: false,
  },
  preventOnFilter: false,
  filter: '.drag-prio',
  draggable: '.inner-i-d',
  fallbackOnBody: true,
  forceAutoScrollFallback: true,
  animation: 50,
  delay: isTouchScreen() === false ? 0 : 130,
  touchStartThreshold: 5,
  fallbackTolerance: 5,
  chosenClass: 'held',
  swapThreshold: 1,
  scrollSpeed: 15,
};

const images: any = ref([]);
const customize = toRef(props, 'customize');
const addedImages = toRef(props, 'addedImages');
const watermarkAll = toRef(props, 'watermarkAll');
const usedKey = toRef(props, 'usedKey');
const loaders = toRef(props, 'loaders');
const isUploading = ref(false);
const isComplimentBomb = toRef(props, 'isComplimentBomb');

const topRowReactionsOpen = ref(false);
const emitTouched = () => emits('touched');

watch(watermarkAll, () => {
  const items = images.value.filter((image: any) => image.image);
  const ids = items.map((image: any) => image.id);
  setWatermarkImages({ imageIds: ids, watermarking: watermarkAll.value });
});

const uploadingImage = (uploading: boolean) => {
  isUploading.value = uploading;
};

const updatedImageReaction = (img: any) => {
  if (isEditor.value) return;
  images.value = images.value.map((image: any) => {
    if (image.id === img.id) {
      if (isViewImageModalOpen.value) {
        editImage.value = img;
        currentImage.value = img;
      }
      return img;
    } else {
      return image;
    }
  });
};

const openVisualPreview = (index: number) => {
  const img: any = images.value[index];
  currentImage.value = img;
  selectedVisualImg.value = img.image;
  selectedVisualImgId.value = img ? img.id || '' : '';
  selectedVisualCaption.value = img ? img.description || '' : '';
  selectedVisualSource.value = img ? img.source || '' : '';
  currentIndex.value = index;
};

const onVisualReaction = (reaction: any) => {
  updatedImageReaction({
    ...currentImage.value,
    user_reaction: reaction.reactResp.newReaction,
    reaction_counts: reaction.reactResp.updatedReactionsData.reaction_counts,
  });
};

const addImage = (img: any) => {
  if (isEditor.value) {
    images.value = images.value.map((image: any) => {
      if (image.id === img.id) {
        return { ...image, image: img.image };
      } else {
        return image;
      }
    });
    editImages([img], usedKey.value);
  }
};

const updatedImage = async (img: any) => {
  if (!isEditor.value) {
    if (isAuthenticated.value && img?.user !== user.value.id) {
      await unSpoilImage({ image_id: img.id });
    }
    images.value = images.value.map((image: any) => {
      if (image.id === img.id) {
        currentImage.value = img;
        return img;
      } else {
        return image;
      }
    });
    editImages([img], usedKey.value);
  } else if (isEditor.value) {
    images.value = images.value.map((image: any) => {
      if (image.id === img.id) {
        if (editImageModalIsOpen.value) {
          editImage.value = img;
        }
        return img;
      } else {
        return image;
      }
    });
    editImages([img], usedKey.value);
  }
};

const watermarkImages = () => {
  setWatermarkImages({ imageIds: [editImage.value?.id!], watermarking: !editImage.value.is_watermarked });
};

const setWatermarkImages = async (payload: { imageIds: string[]; watermarking: boolean }) => {
  const { imageIds, watermarking } = payload;
  isWatermarkLoading.value = true;
  try {
    watermarkLoadText.value = watermarking
      ? 'Hubert is painting on a watermark. Give it a few seconds!'
      : 'Hubert is removing the watermark. Give it a few seconds!';
    const resp = await bulkUpdateWatermarks({ image_ids: imageIds, is_watermarked: watermarking });
    resp.forEach((img: any) => {
      images.value = images.value.map((image: any) => {
        if (image.id === img.id) {
          if (editImageModalIsOpen.value) {
            editImage.value = { ...image, image: img.image, is_watermarked: img.is_watermarked };
          }
          return { ...image, image: img.image, is_watermarked: img.is_watermarked };
        } else {
          return image;
        }
      });
    });
    editImages(resp, usedKey.value);
  } catch (e) {
  } finally {
    nextTick(() => {
      forcererenderKey.value++;
    });
    isWatermarkLoading.value = false;
    watermarkLoadText.value = '';
  }
};

watch(loaders.value, (val) => {
  if (val) {
    if (loaders.value.length) {
      if (images.value.length) {
        images.value = images.value.filter((item: any) => {
          return !item.loader;
        });
      }
      images.value = images.value.concat(loaders.value);
    }
  }
});

watch(addedImages, (val) => {
  if (val) {
    if (loaders.value.length) {
      if (images.value.slice(-1)[0]?.loader) images.value.pop();
    }
    if (images.value.length) {
      images.value = images.value.concat(addedImages.value);
      if (!loaders.value.length)
        images.value = images.value.filter((item: any) => {
          return !item.loader;
        });
    } else {
      images.value = addedImages.value;
    }
    if (images.value.length > 2) {
      const doc = document?.getElementById(`i_${images.value.slice(-2)[0]?.id}`);
      doc?.scrollIntoView({ behavior: 'smooth' });
    }
    emits('removeLoader');
  }
});

const currentCollectionId = computed(() => {
  const { visibleImageCollectionId } = imageStore();
  return visibleImageCollectionId.value;
});

const isDark = computed(() => {
  const { dark } = mainStore();
  return dark.value;
});

const isReadOnlyCollection = computed(() => {
  const { isVisibleImageCollectionReadOnly } = imageStore();
  return isVisibleImageCollectionReadOnly.value;
});

const infoSectionColor = computed(() => {
  const defaultClr = isDark.value ? '#17074C' : '#E6E6E6';
  return isTempDefault.value ? defaultClr : get(customize.value, 'data.theme.backgrounds.infoSection') || defaultClr;
});

const deleteImage = (image: any) => {
  const index = images.value.findIndex((img: any) => img.id === image.id);
  images.value.splice(index, 1);
  addOutfits(images.value, true, usedKey.value);
};

const openEditImageModal = (image: any) => {
  editImage.value = image;
  if (isEditor.value) {
    editImageModalIsOpen.value = true;
  } else {
    const index = images.value.findIndex((img: any) => img.id === image.id);
    currentIndex.value = index;
    selectedVisualImg.value = editImage.value?.image || '';
    selectedVisualImgId.value = editImage.value ? editImage.value.id || '' : '';
    selectedVisualCaption.value = editImage.value ? editImage.value.description || '' : '';
    selectedVisualSource.value = editImage.value ? editImage.value.source || '' : '';
    isViewImageModalOpen.value = true;
  }
};

const closeVisualPreviewModal = () => {
  isViewImageModalOpen.value = false;
};

const closeEditImageModal = () => {
  editImageModalIsOpen.value = false;
  setTimeout(() => {
    editImage.value = {};
  }, 250);
};

const onMove = (e: any) => {
  const event = e;
  const movingIntoCollection = event.to?.id?.startsWith('ih');
  if (isReadOnlyCollection.value) {
    event.preventDefault();
    event.stopPropagation();
    return false;
  }
  if (movingIntoCollection) {
    const { visibleImageCollectionId } = imageStore();
    if (visibleImageCollectionId.value === event.to.id.replace('ih_', '')) {
      return false;
    }
  }
};
const onClone = (e: any) => {
  clonedElement.value = e.item;
};
const onEndMove = (e: any) => {
  images.value.splice(e.newIndex, 0, images.value.splice(e.oldIndex, 1)[0]);
  addOutfits(images.value, true, usedKey.value);

  nextTick(() => {
    forcererenderKey.value++;
  });
};
const toggleReactionBump = (open: boolean) => {
  // if current screen width is over 500px do not do anything
  if (window.innerWidth > 500) return;
  topRowReactionsOpen.value = open;
  document.getElementById('chsgi')?.scrollTo(0, 0);
};

const fetchImagesByIds = async () => {
  let ids: any = [];
  let sectionKey: string = '';
  if (usedKey.value === 'outfits') {
    sectionKey = 'outfit_ids';
    ids = charOutfitsIds?.value || character?.value?.info?.outfit_ids || [];
  } else if (usedKey.value === 'favorites') {
    sectionKey = 'favorites_ids';
    ids = charFavIds.value || character.value?.info?.favorites_ids || [];
  } else if (usedKey.value === 'inventory') {
    sectionKey = 'inventory_ids';
    ids = charInventoryIds.value || character.value?.info?.inventory_ids || [];
  } else {
    sectionKey = 'life_stages_ids';
    ids = charLifeStagesIds.value || character.value?.info?.life_stages_ids || [];
  }
  const res = await getImages({
    section: sectionKey,
    ...(route.name === 'character-editor' && {
      character_id: route.params.id ? route.params.id.toString() : character.value?.id?.toString()!,
    }),
    ...((route.name === 'character-profile-new' || route.name === 'character-stylize') && {
      character_id: route.params.id ? route.params.id.toString() : character.value?.id?.toString()!,
    }),
    ...(route.name === 'character-creator' && {
      character_id: route.name === 'character-creator' ? route.query.templateCharId : route.params.id.toString(),
    }),
    ...((route.name === 'draft-character-editor' ||
      route.name === 'character-profile-draft-new' ||
      route.name === 'preset-editor' ||
      route.name === 'character-creator') && {
      char_draft_id: route.name === 'character-creator' ? route.query.presetId : route.params.id.toString(),
    }),
    ...(route.name === 'home' &&
      isComplimentBomb.value && {
        character_id: character.value?.id?.toString()!,
      }),
  });
  const elements: any = [];
  for (let index = 0; index < ids.length; index++) {
    res.forEach((image: any) => {
      if (image.id === ids[index]) {
        if (!isEmpty(image)) {
          elements.push(image);
        }
      }
    });
  }
  if (
    route.name === 'character-editor' ||
    route.name === 'draft-character-editor' ||
    route.name === 'character-creator'
  ) {
    await addOutfits(elements, true, usedKey.value);
    if (usedKey.value === 'outfits') {
      images.value = charOutfits.value;
    } else if (usedKey.value === 'favorites') {
      images.value = charFav.value;
    } else if (usedKey.value === 'inventory') {
      images.value = charInventory.value;
    } else {
      images.value = charLifeStages.value;
    }
  } else {
    images.value = elements;
  }
};

onMounted(async () => {
  if (route.params.id || character.value?.id || route.query.presetId || route.query.templateCharId) {
    await fetchImagesByIds();
  }
});
</script>
<style scoped lang="sass">
@media (max-width: 500px)
  .grid-container
    grid-gap: 0.25rem !important
    padding: 1px !important

.grid-container
  grid-template-columns: repeat(2, minmax(0, 1fr))
  grid-template-rows: auto
  grid-gap: 0rem 0.5rem
  display: grid
  padding-bottom: 1rem
  padding-top: 1rem
  padding: 2px
  width: 100%
.fade-top
  position: absolute
  top: 0
  left: 0
  right: 0
  height: 30px
  z-index: 2
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, v-bind(infoSectionColor) 100%)
  pointer-events: none
.fade-bottom
  position: absolute
  bottom: 0
  left: 0
  right: 0
  height: 30px
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, v-bind(infoSectionColor) 100%)
  z-index: 2
  pointer-events: none
.scrollable
  position: relative
  max-height: min(80vh, 600px)
  overflow-y: auto
  overflow-x: hidden
  max-width: 100%
.mobile-only
  display: none
@media(max-width: 500px)
  .scrollable
    max-height: min(57vh, 375px)
  .mobile-only
    display: block
.offset
  transition: height 150ms ease
  height: 70px
  width: 1px
  display: block
.off
  height: 1px
  overflow: hidden
.held
  outline: 2px solid rgba(174, 56, 229, 0.3) !important
  border-radius: 10px
  opacity: 0.8
  top: 10px
  left: 10px

.small-image
  height: 220px
</style>
