<template>
  <div
    id="page"
    class="character-profile-header position-relative"
    :style="{ border: topsectionColor }"
    :class="routeName ? 'set-header-margin' : ''"
  >
    <div id="topSection" class="position-relative" v-if="isDisplayCoverSlider">
      <ClientOnly>
        <swiper
          :modules="[SwiperPagination]"
          loop
          :navigation="{ enabled: true, nextEl: '#nextpg', prevEl: '#prevpg' }"
          :pagination="{ clickable: true }"
          class="swiper-base"
          @swiper="onSwiperInstance"
        >
          <swiper-slide v-for="(visual, index) in coverSlider" :key="index">
            <div class="cover position-relative">
              <img loading="lazy" :src="resizeUpload(visual, '800w')" class="image" />
            </div>
          </swiper-slide>
        </swiper>
        <div class="navbtns">
          <div id="prevpg" class="d-flex-column clickable-area mx-2" @click="prevPage">
            <i class="ti-angle-left" />
          </div>
          <div id="nextpg" class="d-flex-column clickable-area mx-2" @click="nextPage">
            <i class="ti-angle-right" />
          </div>
        </div>
      </ClientOnly>
    </div>
    <div id="topSection" v-else class="cover" :style="isMobCustomize ? 'height:150px' : ''">
      <MatureTag class="mat-tag" v-if="isNsfw" size="md" />

      <img v-if="cover" loading="lazy" :src="resizeUpload(cover, '800w')" class="image" />
    </div>
    <div class="position-relative">
      <div class="profile-ring prf position-absolute" v-if="featureFlags.profileRing && !isMobCustomize">
        <img :src="profilePictureRing" class="ring-image" />
      </div>
      <div
        class="profile-picture position-relative"
        :style="{ border: profileColor }"
        :class="{ 'cust-profile-pic': isMobCustomize }"
      >
        <img loading="lazy" v-image :src="resizeUpload(profilePicture, '190h')" class="image" />
      </div>
    </div>
    <div class="rest position-relative">
      <div id="charName" class="name d-flex justify-content-center" :class="{ 'cust-char-name': isMobCustomize }">
        <div class="change-font">
          <span v-if="character?.privacy === 'M'">🔒&nbsp;</span><span v-if="character?.privacy === 'U'">🔗&nbsp;</span
          >{{ name }}
        </div>
        <ion-badge v-if="isDraft" class="ml-2 badge" color="secondary">Draft</ion-badge>
      </div>

      <div v-if="isCharacterOwner && !isComplimentBomb && !isMobCustomize" class="">
        <div id="buttons" class="button-container mt-4" :class="{ 'is-disabled': isComplimentBomb }">
          <ion-button
            class="edit-button"
            :class="{ hide: route.name === 'character-draft-stylize' || route.name === 'character-stylize' }"
            @click="editCharacter"
          >
            <inline-svg class="icon mr-1" src="/icons/pencil.svg" height="16px" width="16px" />
            <div class="edit-text">Edit Information</div>
          </ion-button>
          <ion-button
            class="stylize-button"
            :class="{
              hide: route.name === 'character-draft-stylize' || route.name === 'character-stylize',
              'change-background': !charStatus,
            }"
            @click="dropDownPrfSubMenuToggle"
          >
            <div class="d-flex">
              <i v-if="!charStatus" class="ti-lock icon" />
              <inline-svg class="icon mr-1" src="/icons/brush.svg" height="16px" width="16px" />
              <div class="edit-text">Customize Profile</div>
            </div>
          </ion-button>
        </div>
      </div>
      <div v-if="quote" id="quote" class="quote" :class="{ 'cust-char-name': isMobCustomize }">"{{ quote }}"</div>
      <div v-if="tags.length" class="tags mx-auto d-flex justify-content-center flex-wrap">
        <div
          v-for="(tag, index) of tags"
          :key="index"
          class="tag d-flex mt-1 justify-content-center align-items-center"
          @click="openTagSearch(tag)"
        >
          #{{ truncateText(tag, 20, '') }}
        </div>
      </div>
      <div
        class="button-container container-column"
        :class="{
          'hide-cursor': routeName,
          'mt-4': !isMobCustomize,
          'my-2 btn-container-size': isMobCustomize,
          'is-disabled': isComplimentBomb,
        }"
      >
        <div class="button-row">
          <SubmitFanWork
            v-if="get(user, 'is_email_verified', true)"
            :submitted-for-id="id"
            submitted-for-type="character"
            :disabled="(isCharacterOwner && !routeName) || !isAuthenticated"
            class="w-50"
          >
            <ion-button
              class="small-button w-100"
              :disabled="(isCharacterOwner && !routeName) || !isAuthenticated || isCharacterRoute"
            >
              <inline-svg height="24px" class="fan-icon mr-1" src="/icons/menu-fanwork.svg" />
              <span class="btn-text">Send Fanart</span>
            </ion-button>
          </SubmitFanWork>
          <ion-button
            v-else
            class="small-button w-100"
            :disabled="(isCharacterOwner && !routeName) || !isAuthenticated"
            @click="openVerificationModal"
          >
            <inline-svg height="24px" class="fan-icon" src="/icons/menu-fanwork.svg" />
          </ion-button>
          <ion-button
            v-if="get(user, 'is_email_verified', true)"
            class="small-button rolechat"
            :disabled="
              isRCProfStatusLoading || (isCharacterOwner && !routeName) || !isAuthenticated || isCharacterRoute || isRoleplayDisabled
            "
            @click.prevent="requestRoleplay"
          >
            <inline-svg height="25px" width="24px" src="/icons/pr-icon.svg" class="mr-1 fan-icon" />
            <span class="btn-text">Roleplay</span>
          </ion-button>
          <ion-button
            v-else
            class="small-button rolechat"
            :disabled="
              isRCProfStatusLoading || (isCharacterOwner && !routeName) || !isAuthenticated || isCharacterRoute || isRoleplayDisabled
            "
            @click="openVerificationModal"
          >
            <inline-svg height="28px" width="27px" src="/icons/pr-icon.svg" class="mr-1 fan-icon" />
            <span class="btn-text">Roleplay</span>
          </ion-button>
        </div>
        <div class="button-row-2" :style="{ display: isMobCustomize ? 'none' : '' }">
          <ion-button
            v-if="get(user, 'is_email_verified', true)"
            class="wide-button mx-0"
            :disabled="(isCharacterOwner && !routeName) || !isAuthenticated || isCharacterRoute"
            :color="isStatusChecked ? 'secondary' : ''"
            @click="dropdownToggle"
            >{{ userFollowStatus }}</ion-button
          >
          <ion-button
            v-else
            class="wide-button mx-0"
            :disabled="(isCharacterOwner && !routeName) || !isAuthenticated || isCharacterRoute"
            :color="isStatusChecked ? 'secondary' : ''"
            @click="openVerificationModal"
            >{{ userFollowStatus }}</ion-button
          >
          <div class="bookmark">
            <reaction
              :disabled="isDraft || !isAuthenticated || isCharacterRoute"
              :reactions="mutatedReactionsData.reaction_counts"
              :user-reaction="mutatedReactionsData.user_reaction"
              class="reaction"
              :right-indent-popup="true"
              @changed="(reaction) => reactionChanged(reaction.key, reaction.isInstant)"
            >
              <ion-button
                :color="!!mutatedReactionsData?.user_reaction?.reaction ? 'secondary' : ''"
                :disabled="isDraft || !isAuthenticated"
                class="w-100 small-button inside-reaction"
              >
                <svg
                  v-if="!mutatedReactionsData?.user_reaction"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    transform="scale(2)"
                    d="M3.61328 9.97918H4.54245L5.51328 10.375H8.81328C9.11005 10.3753 9.39625 10.2648 9.61588 10.0652C9.83551 9.86565 9.97276 9.59129 10.0008 9.29584L10.3674 5.49168C10.3674 5.43334 10.3674 5.37501 10.3674 5.31668C10.3674 5.07577 10.2717 4.84473 10.1014 4.67439C9.93106 4.50404 9.70002 4.40834 9.45911 4.40834H7.51328L7.72995 3.95418C7.90069 3.59933 7.989 3.21047 7.98828 2.81668C7.98828 2.66019 7.95746 2.50523 7.89757 2.36065C7.83768 2.21607 7.74991 2.0847 7.63925 1.97404C7.52859 1.86339 7.39722 1.77561 7.25265 1.71572C7.10807 1.65583 6.95311 1.62501 6.79661 1.62501C6.69195 1.62446 6.58821 1.64467 6.49141 1.68447C6.39461 1.72426 6.30665 1.78286 6.23264 1.85687C6.15863 1.93088 6.10003 2.01884 6.06024 2.11564C6.02044 2.21244 6.00023 2.31618 6.00078 2.42084C6.00025 2.97273 5.81713 3.50893 5.47995 3.94584L4.50911 5.19584H3.61328"
                    :stroke="textConverter"
                    stroke-width="1"
                    stroke-miterlimit="10"
                  />
                  <path
                    transform="scale(2)"
                    d="M3.6125 4.80859H1.625V10.3753H3.6125V4.80859Z"
                    :stroke="textConverter"
                    stroke-width="1"
                    stroke-miterlimit="10"
                  />
                </svg>
                <span v-else class="emoji">{{ extractedEmojiFromReaction }}</span>
              </ion-button>
            </reaction>
          </div>
          <div class="bookmark">
            <bookmark-picker
              :object-id="id || ''"
              content-type="character"
              in-button
              :disabled="!isAuthenticated || isCharacterRoute"
              :text-color="textConverter"
            />
          </div>
        </div>
      </div>
      <div class="owner-container" :style="isMobCustomize ? 'display:none !important' : ''">
        <div v-if="originalCreator" :class="{ 'hidden-username': ongoingOnboarding && isStepActive(3) }">
          <div>
            <div class="owner mr-2">Owned by</div>
            <router-link
              :class="{
                'text-color-username': authorUsernameColor && !authorUsernameColor.startsWith('#'),
                'bold-username': authorUsernameColor,
              }"
              :style="
                !authorUsernameColor
                  ? ''
                  : authorUsernameColor.startsWith('#')
                  ? `color: ${authorUsernameColor} !important`
                  : `background-image: ${authorUsernameColor} !important`
              "
              :to="{ name: 'profile', params: { username: authorUsername } }"
              class="clickable-item-hov secondary"
              >@{{ authorUsername }}</router-link
            >
          </div>
          <div class="owner mt-2 mr-2">Created by</div>
          <span v-if="originalCreatorIsUsername"
            ><router-link
              :to="{ name: 'profile', params: { username: originalCreator.split(' ')[0].substring(1) } }"
              class="clickable-item-hov secondary"
            >
              {{ originalCreator }}
            </router-link></span
          >
          <span v-else-if="originalCreatorIsUrl"
            ><a :href="cleanedCreatorLink" rel="nofollow" target="_blank" class="link clickable-item-hov secondary"
              >Created externally <i class="msg ti-link secondary" /></a
          ></span>
          <a rel="nofollow" v-else @click.prevent
            ><span class="link clickable-item-hov secondary" @click="doOpenOwnershipModal"
              >See Creator History <i class="msg ti-info-alt secondary" /></span
          ></a>
        </div>
        <div v-else :class="{ 'hidden-username': ongoingOnboarding && isStepActive(3) }">
          <div class="owner creator mb-2">Created and owned by</div>
          <br />
          <router-link
            :class="{
              'text-color-username': authorUsernameColor && !authorUsernameColor.startsWith('#'),
              'hide-cursor': routeName,
              'bold-username': authorUsernameColor,
            }"
            :style="
              !authorUsernameColor
                ? ''
                : authorUsernameColor.startsWith('#')
                ? `color: ${authorUsernameColor} !important`
                : `background-image: ${authorUsernameColor} !important`
            "
            :to="{ name: 'profile', params: { username: authorUsername } }"
            class="clickable-item-hov secondary ml-2 d-flex align-items-center"
          >
            <span class="mr-1">{{ authorUsername }}</span>

            <ProfileBadge :badges="badges" :show-small-badge="true"
          /></router-link>
        </div>
        <div
          v-if="character?.is_fanchar || character?.is_fanchar_spinoff"
          class="fanmade-disclaimer position-relative d-inline-block mb-4 mt-2 p-2"
        >
          <div class="d-flex align-items-left mb-1">
            <FanmadeTag class="mr-1" :isFanChar="character?.is_fanchar" :isSpinoff="character?.is_fanchar_spinoff" />
            <strong class="mr-2">Disclaimer</strong>
          </div>
          <span v-if="character?.is_fanchar_spinoff"
            >This is a parody character based on a character from popular media. User does not claim ownership of the
            original character or idea.</span
          >
          <span v-else
            >This is a fan character. User doesn't claim ownership of the fandom or media this character may be based on
            or take place in.</span
          >
        </div>
      </div>
      <ion-button
        v-if="!isDraft"
        :style="isMobCustomize ? 'display:none !important' : ''"
        class="header-popover-button d-block"
        @click="profileDropdownToggle"
        :class="{ 'is-disabled': isStepActive(3) && ongoingOnboarding }"
        ><i class="ti-more-alt"
      /></ion-button>
    </div>
    <request-rolechat-modal
      :character="character"
      :is-open="openRolechatReqModal"
      @dismiss-modal="closeRolechatReqModal"
    />
    <rolechat-profile-form-modal
      :is-open="isRCProfileFormOpen"
      @save-dismiss="closeSaveRCPProfileForm"
      @dismiss-modal="closeRCProfileForm"
    />
    <lock-customize-feature-modal
      :is-open="isLockStylizeOpen"
      :chars-count="charsCount"
      @dismiss-modal="closeLockStylizeCharacterModal"
    />
    <stylize-character-modal
      :id="character?.id"
      :is-open="isStylizeModalOpen"
      :customize="customize"
      @close="closeStylizeCharacterModal"
      @fetch="fetchEntityData"
      @applied="appliedModal"
    />
    <transfer-character-modal
      :is-open="openTransferModal"
      :character="character"
      :pending-request="pendingTransferCharacterRequest"
      @close="closeTransferModal"
    />
    <character-ownership-modal :is-open="openOwnershipModal" :character="character" @close="closeOwnershipModal" />
    <VerificationModal :is-open="verificationModal" @dismiss-modal="closeVerificationModal" />

    <compliment-bomb-use-and-purchase-modal
      :is-open="isOpenComplimentBombModal"
      :inventory="inventory"
      :character="character"
      @close="closeCbModal"
      @updated="closeCbAndFetchDetails"
    />
    <CharacterProfileRingModal
      :character="character"
      :isDraft="!!isDraft"
      :is-open="isOpenProfileRingModal"
      @close="isOpenProfileRingModal = false"
      @updated="emits('fetchCharacter')"
    />
  </div>
</template>

<script lang="ts" setup>
import { alertController } from '@ionic/vue';
import isURL from 'validator/es/lib/isURL';
import ProfilePopover from '../popovers/ProfilePopover.vue';
import Reaction from '@/shared/components/Reaction/index.vue';
import { reactionsNames } from '@/shared/statics/reactions';
import { Character, CharacterTransferRequest } from '@/shared/types/static-types';
import { characterProfileStore } from '@/shared/pinia-store/character-profile';
import BookmarkPicker from '@/shared/components/BookmarkPicker.vue';
import { popovers, toast } from '@/shared/native';
import FollowPopover from '@/shared/components/nav-bar/popovers/follow-popover.vue';
import RequestRolechatModal from '@/shared/modals/RequestRolechatModal.vue';
import RolechatProfileFormModal from '@/shared/modals/RolechatProfileFormModal.vue';
import LockCustomizeFeatureModal from '@/shared/modals/LockCustomizeFeatureModal.vue';
import TransferCharacterModal from '@/shared/modals/TransferCharacterModal.vue';
import CharacterOwnershipModal from '@/shared/modals/CharacterOwnershipModal.vue';
import { follow, getStatusOfFollowing, unfollowUser, updateFollowType } from '@/shared/actions/follow';
import MatureTag from '@/shared/components/MatureTag.vue';
import SubmitFanWork from '@/shared/components/FanWork/SubmitFanWork.vue';
import { getRolechatProfileStatus } from '@/shared/actions/rolechats';
import { extractEmoji, truncateText } from '@/shared/utils/string';
import StylizeCharacterModal from '@/shared/modals/StylizeCharacterModal.vue';
import { characterStylizeStore } from '@/shared/pinia-store/character-stylize';
import constants from '@/shared/statics/constants';
import { authStore } from '@/shared/pinia-store/auth';
import { mainStore } from '@/shared/pinia-store/main';
import { textColorChanged } from '@/shared/utils/textConverter';
import { resizeUpload } from '@/shared/utils/upload';
import FanmadeTag from '@/shared/components/FanmadeTag.vue';
import VerificationModal from '@/shared/modals/VerificationModal.vue';
import ComplimentBombUseAndPurchaseModal from '@/shared/modals/ComplimentBombUseAndPurchaseModal.vue';
import ProfileBadge from '@/shared/pages/profile/components/ProfileBadge.vue';
import { react } from '@/shared/helpers/characters';
import CharacterPrfPopover from '@/shared/components/nav-bar/popovers/character-prf-sub-popover.vue';
import CharacterProfileRingModal from '@/shared/modals/CharacterProfileRingModal.vue';
import { featureFlags } from '@/shared/config/feature-flags';
import { menuController } from '@ionic/vue';
import { coverPreferences } from '@/shared/statics/constants';
import { onboardingStore } from '~/shared/pinia-store/onboarding';
const { subscriptionLevel } = authStore();
const { ownershipHistory } = characterProfileStore();
const {
  clearAll,
  template: userTemplates,
  deleteAppliedCustomizationTemp,
  deletingAppliedCustomizationTemplate,
} = characterStylizeStore();
const { user, isAuthenticated } = authStore();
const isOpenProfileRingModal = ref(false);
const { isStepActive, ongoingOnboarding } = onboardingStore();
const extractedEmojiFromReaction = computed(() => extractEmoji(userReactionText.value));
const isOpenComplimentBombModal = ref(false);
const router = useRouter();
const route = useRoute();
const props = defineProps({
  character: {
    type: Object as PropType<Character>,
    default: {},
  },
  isDraft: {
    type: Boolean as PropType<Boolean>,
    default: false,
  },
  isComplimentBomb: {
    type: Boolean as PropType<Boolean>,
    default: false,
  },
  customize: {
    type: Object,
    default: {},
  },
  isMobCustomize: {
    type: Boolean,
    default: false,
  },
  charStatus: {
    type: Boolean,
    default: false,
  },
  charsCount: {
    type: Number,
    default: 0,
  },
  allowComplimentBomb: {
    type: Boolean,
    default: false,
  },
  inventory: {
    type: Object,
    default: {},
  },
});

const { isTempDefault } = useCustTempDefault(props);

const charStatus = toRef(props, 'charStatus');
const customize = toRef(props, 'customize');
const character = toRef(props, 'character');
const isDraft = toRef(props, 'isDraft');
const followType = ref('');
const swiperRef = ref(null);
const allowComplimentBomb = toRef(props, 'allowComplimentBomb');
const previouscolor = ref('');
const followStatus = ref({} as any);
const inventory = toRef(props, 'inventory');
const isStatusChecked = ref(false);
const unfollow = ref(false);
const pendingTransferCharacterRequest = ref(null as CharacterTransferRequest | null);
const isStylizeModalOpen = ref(false);
const emits = defineEmits(['close', 'fetch', 'updated', 'fetchCharacter']);
const { width } = useWindowSize();

const coverSlider = computed(() => {
  return character.value?.info?.sliding_cover_images;
});
const openTagSearch = (tagText: string) => {
  const hashText = '#';
  tagText = hashText.concat(tagText);
  router.push({ name: 'search', query: { term: tagText, tab: 'all' } });
};

const mutatedReactionsData = ref(pick(character.value, ['reaction_counts', 'user_reaction']));

const isdark = computed(() => {
  const { dark } = mainStore();
  return dark.value;
});

const isRoleplayDisabled = computed(() => {
  return !character.value?.allow_roleplay_req;
});

const verificationModal = ref(false);

const openVerificationModal = () => {
  verificationModal.value = true;
};

const closeVerificationModal = () => {
  verificationModal.value = false;
};

const isNsfw = computed(() => character.value?.is_nsfw);

onMounted(async () => {
  if (deletingAppliedCustomizationTemplate.value) isStylizeModalOpen.value = true;
  setTimeout(() => {
    deleteAppliedCustomizationTemp(false);
  }, 50);
  if (!isCharacterOwner.value && isAuthenticated.value) getFollowStatus();
});

const originalCreatorIsUsername = computed(() => {
  if (!originalCreator.value?.trim()) return false;
  const s = originalCreator.value || '';
  const match = s.match(constants.tagUsernameRegex);
  const username = match && match[0];
  return username?.length === s.length;
});

const routeName = computed(() => {
  return route.name === 'character-stylize' || route.name === 'character-draft-stylize';
});
const originalCreatorIsUrl = computed(() => {
  if (!originalCreator.value?.trim()) return false;
  return isURL(originalCreator.value.trim());
});
const coverPreference = computed(() => {
  return character?.value?.info?.cover_preference || '';
});

const cleanedCreatorLink = computed(() => {
  if (!originalCreatorIsUrl.value) return '';
  if (!originalCreator.value!.includes('://')) {
    return `https://${originalCreator.value!.trim()}`;
  }
  return originalCreator.value!.trim();
});
const dropdownToggle = async (ev: CustomEvent) => {
  if (route.name !== 'character-stylize') {
    const popover = await popovers.open(ev, FollowPopover, {
      status: isStatusChecked.value,
      followType: followStatus.value?.type,
    });
    const { data } = await popover.onDidDismiss();
    if (data?.value) followType.value = data.value;
  }
};

const dropDownPrfSubMenuToggle = async (ev: CustomEvent) => {
  const popover = await popovers.open(ev, CharacterPrfPopover, { isDraft: isDraft.value });
  const { data } = await popover.onDidDismiss();
  if (data === 'open') {
    isOpenProfileRingModal.value = true;
  } else if (data === 'customize') {
    stylizeCharacter();
  }
};

const profileDropdownToggle = async (ev: CustomEvent) => {
  if (!(route.name === 'character-stylize' || route.name === 'character-draft-stylize')) {
    const popover = await popovers.open(ev, ProfilePopover, {
      character: character.value,
      isCharacterOwner: isCharacterOwner.value,
      isComplimentBomb: allowComplimentBomb.value,
    });
    const { data } = await popover.onDidDismiss();
    if (data === 'transferCharacter') {
      openTransferModal.value = true;
    } else if (data === 'characterOwnership') {
      openOwnershipModal.value = true;
    } else if (data === 'complimentBomb') {
      isOpenComplimentBombModal.value = true;
    }
  }
};

const doOpenOwnershipModal = () => {
  openOwnershipModal.value = true;
};
const userFollowStatus = computed(() => {
  return followStatus.value.type === 'superfollow'
    ? 'Superfollowing'
    : followStatus.value.type === 'follow'
    ? 'Following'
    : followStatus.value.type === 'litefollow'
    ? 'Litefollowing'
    : 'Follow';
});

const onSwiperInstance = (v: any) => {
  swiperRef.value = v;
};

const doFollow = async () => {
  if (followType.value === 'unfollow') return;
  else {
    const response = await follow('character', id.value, followType.value);
    followStatus.value = response;
    isStatusChecked.value = true;
    if (unfollow.value) {
      unfollow.value = false;
    }
  }
};

const getButtonsColor = computed(() => {
  return isTempDefault.value ? '#AE38E5' : get(customize.value, 'data.theme.backgrounds.buttons') || '#AE38E5';
});

const getCustomizeButtonsColor = computed(() => {
  return isTempDefault.value
    ? 'linear-gradient(270deg, rgb(174 56 229) 3.33%, rgb(222 24 215) 100%)'
    : get(customize.value, 'data.theme.backgrounds.buttons');
});

const profileColor = computed(() => {
  const defaultClr = isdark.value ? '#010123' : '#FFFFFF';
  const border = get(customize.value, 'data.theme.styles.profilePic.border', 'none');
  const color = isTempDefault.value
    ? defaultClr
    : get(customize.value, 'data.theme.styles.profilePic.color', defaultClr);
  return `8px ${border} ${color} !important`;
});
const topsectionColor = computed(() => {
  const border = get(customize.value, 'data.theme.styles.topsection.border', 'none');
  const color = get(customize.value, 'data.theme.styles.topsection.color');
  return `3px ${border} ${color} !important`;
});

const getPopoverColor = computed(() => {
  return isTempDefault.value && get(customize.value, 'data.theme.backgrounds.buttons') === '#AE38E5'
    ? '#989aa2'
    : get(customize.value, 'data.theme.backgrounds.buttons');
});
const getCoverColor = computed(() => {
  return isTempDefault.value && isdark.value
    ? ' #17074C'
    : isTempDefault.value
    ? '#E6E6E6'
    : cover.value
    ? get(customize.value, 'data.theme.backgrounds.topSection')
    : get(customize.value, 'data.theme.backgrounds.buttons');
});
const textConverter = computed(() => {
  return getButtonsColor.value ? textColorChanged(getButtonsColor.value) : '#ae38e5';
});
const updatedFollow = async () => {
  if (followType.value === 'unfollow') {
    isStatusChecked.value = false;
    await unfollowUser(followStatus.value.id);
    followStatus.value = {};
    unfollow.value = true;
  } else {
    if (!followType.value) return;
    await updateFollowType(followStatus.value.id, {
      type: followType.value,
    });
    followStatus.value.type = followType.value;
    isStatusChecked.value = true;
  }
};

const fetchEntityData = () => {
  isStylizeModalOpen.value = false;
  emits('fetch');
};

const getFollowStatus = async () => {
  const resp = await getStatusOfFollowing(id.value, 'character');
  followStatus.value = resp;
  if (!isEmpty(followStatus.value.type)) {
    isStatusChecked.value = true;
  }
};

watch(followType, () => {
  followStatus.value.type && !unfollow.value
    ? updatedFollow()
    : (followType.value && followType.value.length) || unfollow.value
    ? doFollow()
    : '';
});

const isCharacterOwner = computed(() => {
  try {
    return character.value?.author?.username === user.value?.username;
  } catch (error) {
    return false;
  }
});

watch(character, () => {
  if (!isCharacterOwner.value && isAuthenticated.value) getFollowStatus();
});
const nextPage = () => {
  swiperRef.value?.slideNext();
};

const prevPage = () => {
  swiperRef.value?.slidePrev();
};

const closeLockStylizeCharacterModal = () => {
  isLockStylizeOpen.value = false;
};
const closeStylizeCharacterModal = (isVal: any) => {
  isStylizeModalOpen.value = false;
  if (isVal) emits('fetch');
};
const id = computed(() => {
  return character?.value?.id;
});

const isEmptyTopSection = (value: any) => {
  if (isTempDefault.value && get(customize.value, 'data.theme.backgrounds.topSection') === '#E6E6E6') {
    return value === 'istopbg' &&
      isdark.value &&
      (route.name === 'character-profile-new' || route.name === 'character-profile-draft-new')
      ? '#17074C'
      : value === 'isownertext' &&
        isdark.value &&
        (route.name === 'character-profile-new' || route.name === 'character-profile-draft-new')
      ? '#fff'
      : value === 'istagtext' &&
        isdark.value &&
        (route.name === 'character-profile-new' || route.name === 'character-profile-draft-new')
      ? '#fff'
      : value === 'istagtext'
      ? '#000'
      : get(customize.value, 'data.theme.backgrounds.topSection');
  } else {
    return get(customize.value, 'data.theme.backgrounds.topSection');
  }
};
const setTopSectionBackground = computed(() => {
  const topSecBg = isEmptyTopSection('istopbg');
  return topSecBg;
});

const setOwnerText = computed(() => {
  const topSecBg = isEmptyTopSection('isownertext');
  return topSecBg;
});

const getTagTextColor = computed(() => {
  const tagtext = isEmptyTopSection('istagtext');
  return tagtext;
});

const badges = computed(() => {
  return get(character?.value?.author?.customize_profile, 'badges') || [];
});
const authorUsername = computed(() => {
  return character?.value?.author?.username || 'user';
});

const authorUsernameColor = computed(() => {
  return character?.value?.author?.customize_profile?.username_color || '';
});

const cover = computed(() => {
  return character?.value?.info?.cover;
});

const profilePicture = computed(() => {
  return character?.value?.info?.cropProfilePicture;
});

const profilePictureRing = computed(() => {
  return character?.value?.profile_ring?.image;
});

const name = computed(() => {
  return character?.value?.info?.name;
});

const quote = computed(() => {
  return character?.value?.info?.quote;
});
const getCharNameFont = computed(() => {
  return isTempDefault.value ? 'Roboto' : get(customize.value, 'data.theme.text.charName.font') || 'Roboto';
});

const getQuoteFont = computed(() => {
  return isTempDefault.value ? 'Roboto' : get(customize.value, 'data.theme.text.quote.font') || 'Roboto';
});
const getCharNameBold = computed(() => {
  return get(customize.value, 'data.theme.text.charName.bold') ? '800' : '100';
});
const getQuoteBold = computed(() => {
  return get(customize.value, 'data.theme.text.quote.bold') ? '800' : '100';
});
const getDefaultBold = computed(() => {
  return get(customize.value, 'data.theme.text.default.bold') ? '800' : '100';
});
const getQuoteItalic = computed(() => {
  return get(customize.value, 'data.theme.text.quote.italics') ? 'italic' : 'normal';
});

const topsectionEdges = computed(() => {
  if (get(customize.value, 'data.theme.styles.topsection.edges') === 'sharp') return '0px';
  else if (get(customize.value, 'data.theme.styles.topsection.edges') === 'round') return '10px';
  return '20px';
});
const topsectionShadow = computed(() => {
  return get(customize.value, 'data.theme.styles.topsection.shadow') === 'none'
    ? '0px 0px 0px'
    : get(customize.value, 'data.theme.styles.topsection.shadow') === 'alittle'
    ? '0 2px 13px rgb(0 0 0 / 15%)'
    : '3px 2px 20px #635d5d';
});

const closeCbModal = (val: any) => {
  if (get(val, 'detail.role') === 'backdrop' && !isEmpty(val)) {
    closeCbAndFetchDetails();
  }

  isOpenComplimentBombModal.value = false;
};

const closeCbAndFetchDetails = () => {
  isOpenComplimentBombModal.value = false;
  emits('updated');
};

const getDefaultItalic = computed(() => {
  return get(customize.value, 'data.theme.text.default.italics') ? 'italic' : 'normal';
});
const getCharNameItalic = computed(() => {
  return get(customize.value, 'data.theme.text.charName.italics') ? 'italic' : 'normal';
});

const isEmptyCharColor = (value: any) => {
  const currentColor = get(customize.value, 'data.theme.text.charName.color');
  if (isTempDefault.value && currentColor === '#000000') {
    return value === 'istag'
      ? '#181c3c'
      : value === 'ischar' &&
        (route.name === 'character-profile-new' || route.name === 'character-profile-draft-new') &&
        isdark.value
      ? '#FFFFFF'
      : value === 'istext' &&
        (route.name === 'character-profile-new' || route.name === 'character-profile-draft-new') &&
        isdark.value
      ? 'var(--ion-color-secondary)'
      : value === 'istext' && isEmpty(previouscolor.value)
      ? '#AE38E5'
      : value === 'ischartag' &&
        (!isdark.value || route.name === 'character-stylize' || route.name === 'character-draft-stylize')
      ? '#fff'
      : get(customize.value, 'data.theme.text.charName.color');
  } else {
    previouscolor.value = currentColor;
    return get(customize.value, 'data.theme.text.charName.color');
  }
};
const getOwnerColor = computed(() => {
  const textOwner = isEmptyCharColor('istext');
  return textOwner;
});

const getOwnerTagColor = computed(() => {
  const owner = isEmptyCharColor('istag');
  return owner;
});
const getCharNameColor = computed(() => {
  const char = isEmptyCharColor('ischar');
  return char;
});
const disclaimerBackgroundColor = computed(() => {
  return isdark.value ? 'rgb(24, 28, 60)' : '#ccc';
});
const disclaimerTextColor = computed(() => {
  return isdark.value ? '#ffffff' : '#000000';
});

const getUnderLine = computed(() => {
  return isTempDefault.value && !get(route.query, 'tempId') ? 'none' : 'underline';
});

const getQuoteColor = computed(() => {
  return isTempDefault.value && isdark.value ? '#FFFFFF' : get(customize.value, 'data.theme.text.quote.color');
});

const getTagColor = computed(() => {
  const chartag = isEmptyCharColor('ischartag');
  return chartag;
});
const getDefaultFont = computed(() => {
  return isTempDefault.value ? 'Roboto' : get(customize.value, 'data.theme.text.default.font') || 'Roboto';
});
const tags = computed(() => {
  return character?.value?.tags || [];
});

const reactions = computed(() => {
  return character?.value?.reaction_counts || [];
});
const appliedModal = () => {
  closeStylizeCharacterModal('');
  router.push({ name: 'character-stylize', params: { id: get(character.value, 'id') } });
};

const userReactionText = computed(() => {
  return reactionsNames[mutatedReactionsData.value?.user_reaction?.reaction || ''];
});

const originalCreator = computed(() => {
  if (character.value?.original_creator && character.value?.original_creator !== '-')
    return character.value?.original_creator;
  if (ownershipHistory.value?.length) {
    if (ownershipHistory.value?.[0]?.note && ownershipHistory.value[0].note !== '-')
      return ownershipHistory.value?.[0]?.note;
    if (ownershipHistory.value?.[0]?.user === character.value?.author?.username) return '';
    return `@${ownershipHistory.value?.[0]?.user}`;
  }
  return character.value?.original_creator ? `@${character.value?.original_creator}` : null;
});

const editCharacter = () => {
  const name = isDraft.value ? 'draft-character-editor' : 'character-editor';
  router.push({ name, params: { id: id.value } });
};

const reactionChanged = async (reaction: any, isInstant = false) => {
  const reactionResp = await react(
    reaction,
    character.value,
    mutatedReactionsData.value.user_reaction,
    mutatedReactionsData.value.reaction_counts,
    false,
    isInstant
  );

  mutatedReactionsData.value = {
    reaction_counts: reactionResp.reaction_counts,
    user_reaction: reactionResp.user_reaction,
  };
};

const isCharacterRoute = computed(() => {
  return route.path.includes('/customize/character/');
});

const isRCProfStatusLoading = ref(false);
const isRCProfileFormOpen = ref(false);
const openRolechatReqModal = ref(false);
const isLockStylizeOpen = ref(false);
const openTransferModal = ref(false);
const openOwnershipModal = ref(false);

const requestRoleplay = async () => {
  if (route.name !== 'character-stylize') {
    isRCProfStatusLoading.value = true;
    try {
      const resp = await getRolechatProfileStatus();
      if (!get(resp, 'profile_exists', false)) {
        const alert = await alertController.create({
          cssClass: 'welcome-rc-alert',
          header: 'WELCOME TO ROLEPLAY CHAT',
          message: `Roleplay chats let you explore your characters in new ways! Some ground rules:
        <ul>
        <li>Please treat other community members with the upmost respect and be a good partner.</li>
        <li>Being a good partner means listening to what they say, staying within the other person’s boundaries.</li>
        <li>Abuse of these rules will not be tolerated and can lead to immediate bans.</li>
        </ul>`,
          buttons: [
            {
              text: 'Disagree',
              id: 'notconfirm-button',
              role: 'notok',
              cssClass: 'mx-auto text-danger',
            },
            {
              text: 'Agree',
              id: 'confirm-button',
              role: 'ok',
              cssClass: 'mx-auto',
            },
          ],
          backdropDismiss: false,
        });
        await alert.present();

        const { role } = await alert.onDidDismiss();

        if (role === 'ok') {
          openRCProfileForm();
        }
      } else {
        isRCProfStatusLoading.value = false;
        openRolechatReqModal.value = true;
      }
    } catch (_err) {
      await toast.show('Some error has occurred. Please try again later.', 'nonative', 'danger');
      router.push({ name: 'home' });
    }
    isRCProfStatusLoading.value = false;
  }
};

const openRCProfileForm = () => {
  isRCProfileFormOpen.value = true;
};
const closeRCProfileForm = () => {
  if (isRCProfileFormOpen.value) {
    isRCProfileFormOpen.value = false;
    openRolechatReqModal.value = false;
  }
};
const closeSaveRCPProfileForm = () => {
  isRCProfileFormOpen.value = false;
  openRolechatReqModal.value = true;
};
const stylizeCharacter = async () => {
  if (charStatus.value) {
    if (!isTempDefault.value || !get(userTemplates.value, 'length')) {
      clearAll();
      const routeName = ['character-profile-draft-new', 'char-draft-details'].includes(route.name as string)
        ? 'character-draft-stylize'
        : 'character-stylize';
      router.push({ name: routeName, params: { id: id.value } });
      if (width.value < 1185) await menuController.open('end');
    } else {
      isStylizeModalOpen.value = true;
    }
  } else {
    isLockStylizeOpen.value = true;
  }
};

const closeRolechatReqModal = () => {
  openRolechatReqModal.value = false;
};

const profileShadow = computed(() => {
  if (get(customize.value, 'data.theme.styles.profilePic.shadow') === 'none') return '0px 0px 0px';
  else if (get(customize.value, 'data.theme.styles.profilePic.shadow') === 'alittle') return '0px 3px 7px';
  else return '0px 10px 10px';
});

const closeTransferModal = (req?: CharacterTransferRequest) => {
  openTransferModal.value = false;
  if (req) {
    pendingTransferCharacterRequest.value = req;
  }
};
const isDisplayCoverSlider = computed(() => {
  return (
    !isEmpty(coverSlider.value) && coverPreference.value === coverPreferences.MULTIPLE && subscriptionLevel.value > 0
  );
});

const closeOwnershipModal = () => {
  openOwnershipModal.value = false;
};
</script>

<style lang="sass" scoped>
.hidden-username
  pointer-events: none
.navbtns
  display: flex
  position: absolute
  justify-content: space-between
  align-items: center
  height: 100% !important
  width: 100% !important
  top: 0
  bottom: 0
  z-index: 11
  pointer-events: none
  .clickable-area
    // full height of the enclosing div
    height: 2rem
    width: 2rem
    border-radius: 50%
    background-color: v-bind(getButtonsColor) !important
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
    pointer-events: all
    color: white
    &:hover
      color: var(--ion-color-secondary)
  i
    pointer-events: none
    font-size: 1rem
@media (hover: none)
  .clickable-area
    &:hover
      color: white !important
.setborder
  border: 10px solid white
.fan-icon
  color: v-bind(text) !important
  ::v-deep
    path
      stroke: v-bind(textConverter) !important
    #fill-only
      fill: v-bind(textConverter) !important
      stroke: unset !important
.mat-tag
  position: absolute
  top: 10px
  left: 10px
.set-space
  @media(max-width:345px)
    flex-direction: column !important
.hide
  display: none !important
.hide-cursor
  pointer-events: none !important
.set-header-margin
  margin-top: 0px
.change-background
  --background: #979797 !important
.change-font
  font-family: v-bind(getCharNameFont)

.stylize-button, .edit-button
  --background: v-bind(getCustomizeButtonsColor)
  width: 82%
  height: 40px
  color: v-bind(textConverter)
  border: none
  border-radius: 8px
  text-transform: unset
  font-size: 16px
  margin: 0
  --border-radius: 8px
  .edit-text
    line-height: 15px
    font-weight: bold
  :deep(.icon)
    path
      stroke: v-bind(textConverter)
  @media(max-width:500px)
    height: 32px
    font-size: 12px
    :deep(.icon)
      height: 14px
.button-container
  margin: 0 auto
  display: flex
  grid-gap: 6px
  align-items: center
  max-width: 400px
  &.container-column
    flex-direction: column !important
  ::v-deep
    --ion-color-secondary: #328FD2
    --ion-color-secondary-rgb: 80,200,255
    --ion-color-secondary-contrast: #ffffff
    --ion-color-secondary-contrast-rgb: 255,255,255
    --ion-color-secondary-shade: #2d81bd
    --ion-color-secondary-tint: #479ad7
.btn-container-size
  max-width: 305px !important
.wide-button
  width: 64%
  height: 40px
  --background: v-bind(getButtonsColor) !important
  color: v-bind(textConverter)
  border: none
  border-radius: 8px
  margin-bottom: 20px
  text-transform: unset
  font-size: 18px
  --border-radius: 8px
.button-row
  display: flex
  justify-content: space-between
  width: 100%
  grid-gap: 6px
.button-row-2
  display: flex
  justify-content: space-between
  width: 100%
  grid-gap: 3px

.small-button
  overflow: hidden
  display: inline-block !important
  height: 40px
  --background: v-bind(getButtonsColor) !important
  color: #fff
  font-size: 16px
  border: none
  border-radius: 8px
  --border-radius: 8px
  margin: 0
.rolechat
  min-width: 50%
  width: 50%
.reaction
  width: 92%
  display: inline-block !important
  ::v-deep
    .like-btn
      width: 100% !important
    ion-button
      --border-radius: 8px
.bookmark
  display: inline-block !important
  flex: 1
  min-width: 15%
  :deep(.icon)
    path
      stroke: v-bind(textConverter)
  :deep
    ion-button
      height: 40px !important
      --border-radius: 8px
      width: 92%
      --padding-start: 0 !important
      --padding-end: 0 !important
      padding-left: 0 !important
      padding-right: 0 !important
      --background: v-bind(getButtonsColor)

@media(max-width: 500px)
  .character-profile-header
    .profile-picture
      width: 140px !important
      height: 140px !important
      margin: -70px auto 0 auto !important
      box-shadow: v-bind(topsectionShadow)
    .profile-ring
      width: 175px !important
      z-index: 3
      display: flex
      align-items: center
      justify-content: center
      &.prf
        top: 0px
        right: 0px
        left: 0px
        bottom: 0px
        margin: auto
    .ring-image
      width: 100% !important

    .cover
      height: 190px !important

    .tags
      width: 90% !important
.character-profile-header
  color: black
  border-radius: 20px
  overflow: hidden
  box-shadow: v-bind(topsectionShadow)
  border-radius: v-bind(topsectionEdges)
  :deep(ion-button)
    margin: 0 !important
  .badge
    height: 20px
  .profile-ring
    width: 240px
    z-index: 3
    display: flex
    align-items: center
    justify-content: center
    &.prf
      top: 0px
      right: 0px
      left: 0px
      bottom: 0px
      margin: auto
  .ring-image
    width: 100%
  .profile-picture
    width: 191px
    height: 191px
    border-radius: 100px
    overflow: hidden
    margin: -96px auto 0 auto
    box-shadow: v-bind(profileShadow)
    z-index: 1
    .image
      object-fit: cover
      width: 100%
      height: 100%
  .cust-profile-pic
      width: 170px !important
      height: 170px !important
  .cover
    height: 241px
    overflow: hidden
    .image
      object-fit: cover
      width: 100%
      height: 100%
  .rest
    text-align: center
    background: v-bind(setTopSectionBackground)
    background-color: v-bind(setTopSectionBackground)
    margin-top: -95px
    padding: 95px 18px 12px 18px
  .edit-character
    margin-top: -95px
    width: calc(100% - 24px)
    .button
      text-transform: unset
      font-size: 14px
      font-weight: 500
      --border-radius: 10px
  .quote
    margin-top: 24px
    font-weight: v-bind(getQuoteBold)
    font-size: 16px
    font-style: v-bind(getQuoteItalic)
    font-family: v-bind(getQuoteFont)
    color: v-bind(getQuoteColor)
  .name
    margin-top: 24px
    font-size: 24px
    font-weight: v-bind(getCharNameBold)
    font-style: v-bind(getCharNameItalic)
    color: v-bind(getCharNameColor)
  .cust-char-name
    margin-top: 3px !important
  .tags
    margin-top: 20px
    width: 80%
    .tag
      background-color: v-bind(getTagColor)
      border-radius: 5px
      color: v-bind(getTagTextColor)
      font-family: v-bind(getDefaultFont)
      font-weight: v-bind(getDefaultBold)
      font-style: v-bind(getDefaultItalic)
      height: 20px
      margin: 0 2.5px
      font-size: 10px
      padding: 0 8px
      cursor: pointer
.cover
  background-color: v-bind(getCoverColor)
.dark .cover
  background-color: v-bind(getCoverColor)
.dark .rest
  background: v-bind(setTopSectionBackground) !important
  background-color: v-bind(setTopSectionBackground) !important
.dark .profile-picture
  box-shadow: v-bind(profileShadow) !important

.emoji
  font-size: 20px !important
.set-margin
  margin-bottom: 28px
  margin-top: 11px
  justify-content: space-between !important
  @media(max-width: 1460px)
    margin-top: 4px !important
.owner
  background-color: v-bind(getOwnerTagColor)
  color: v-bind(setOwnerText) !important
  border-radius: 6px
  color: white
  width: 90px
  text-align: center
  min-height: 1.5rem
  padding-top: 0.2rem
  display: inline-block
  user-select: none !important

.fanmade-disclaimer
  background-color: v-bind(disclaimerBackgroundColor)
  color: v-bind(disclaimerTextColor)
  border-radius: 10px
  min-height: 1.5rem
  padding-top: 0.2rem
  display: inline-block
  user-select: none !important

.dark .fanmade-disclaimer
  background-color: v-bind(disclaimerBackgroundColor)
  color: v-bind(disclaimerTextColor)

.creator
  width: 160px !important
.owner-container
  font-size: 0.9rem
  color: black
  margin-top: 24px
  text-align: start

.header-popover-button
  position: absolute
  right: 1rem
  bottom: 0.5rem
  height: 24px
  width: 48px
  --border-radius: 16px
  --background: v-bind(getPopoverColor)
  gap: 10px
  i
    font-size: 1.2rem
    color: v-bind(textConverter)
.no-select
  user-select: none !important
.dark .secondary
  color: v-bind(getOwnerColor) !important
.secondary
  color: v-bind(getOwnerColor)
  text-decoration: v-bind(getUnderLine) !important
.btn-text
  color: v-bind(textConverter)
</style>
