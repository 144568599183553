<template>
  <div v-if="ready" class="text world-editor-select">
    <ion-select
      v-model="mutatedWorlds"
      mode="md"
      placeholder="Link a new Story World as a location for this Story World."
      :multiple="true"
      :interface-options="customOptions"
      class="bg-white select pr-2"
    >
      <ion-select-option v-for="world in allWorlds" :key="world.id" :value="world.id">
        {{ world.name }}
        <span v-if="world.privacy === 'M'">🔒</span>
        <span v-else-if="world.privacy === 'U'">🔗</span>
      </ion-select-option>
    </ion-select>
  </div>
  <div>
    <ion-reorder-group v-if="ready" :disabled="false" @ionItemReorder="handleReorder($event)">
      <div v-for="(element, index) of mutatedWorlds" :key="index" class="d-flex align-items-center mt-2 one-row">
        <ion-reorder slot="end" class="reorder-desktop">
          <i id="about-drag-handle" class="ti-menu clickable-item-hov mx-2 about-drag-icon" />
        </ion-reorder>

        <div class="locations-card px-2 py-2">{{ name(element) }}</div>

        <ion-button class="inline-button" color="transparent" @click="deleteLocationsField(index)">
          <i class="ti-trash" />
        </ion-button>
      </div>
    </ion-reorder-group>
  </div>
</template>

<script lang="ts" setup>
import { storyWorldCreatorStore } from '@/shared/pinia-store/storyworld-creator';
import { getUserStoryWorld } from '@/shared/actions/worlds';
import { authStore } from '@/shared/pinia-store/auth';

const { user } = authStore();

const { changeInfoKey, storyworld } = storyWorldCreatorStore();

const props = defineProps({
  usedKey: {
    type: String,
    default: '',
  },
  value: {
    type: Array,
    default: [],
  },
});

const ready = ref(true);
const usedKey = toRef(props, 'usedKey');

const allWorlds = ref([] as any[]);
const customOptions = ref({});
const handleReorder = (ev: CustomEvent) => {
  const { from, to } = ev.detail;
  mutatedWorlds.value!.splice(to, 0, mutatedWorlds.value!.splice(from, 1)[0]);
  ev.detail.complete();
  ev.stopPropagation();
  ready.value = false;
  nextTick(() => {
    ready.value = true;
  });
};

const mutate = () => {
  const locations = mutatedWorlds.value!.filter((field: any) => field);
  changeInfoKey(usedKey.value, locations);

  ready.value = false;
  nextTick(() => {
    ready.value = true;
  });
};
const name = (element: any) => {
  if (element?.info?.name) return element.info.name;
  if (!isEmpty(mutatedWorlds.value)) {
    const data = allWorlds.value.find((el: any) => el.id === element);
    return get(data, 'name', '');
  }
};
const fetchStoryWorldLocationsText = () => {
  customOptions.value = isEmpty(allWorlds.value)
    ? {
        header: 'Add new Locations by creating more Story Worlds!',
        message: `Say you're building a world called 'Wizarding World'. If you make another world named 'Hogwarts', you can add it as a Location in 'Wizarding World'. It's like making 'Hogwarts' a part of your 'Wizarding World'!`,

        translucent: true,
      }
    : {};
};
const fetchStoryWorlds = async () => {
  try {
    const response = await getUserStoryWorld(user.value.id, 'story', 1, 1000);
    allWorlds.value = response.results;
    if (storyworld.value?.id) {
      allWorlds.value = allWorlds.value.filter((el: any) => el.id !== storyworld.value!.id);
    }
    fetchStoryWorldLocationsText();
  } catch (_e) {}
};

onMounted(async () => {
  await fetchStoryWorlds();

  ready.value = false;
  nextTick(() => {
    ready.value = true;
  });
});

const mutatedWorlds = computed({
  get() {
    return storyworld.value?.subworlds;
  },
  set(val) {
    if (!isEmpty(allWorlds.value)) changeInfoKey('subworlds', val);
  },
});

const deleteLocationsField = (index: number) => {
  mutatedWorlds.value?.splice(index, 1);
  mutate();
};
</script>

<style lang="sass" scoped>
.locations-card
  overflow: unset
  border-radius: 10px
  background: #f2f2f2
  color: #45b6f5
  width: calc(100% - 53px)
.dark .locations-card
  background: #11022A
.title
  font-size: 16px
  font-weight: bold
.select
  border-radius: 10px
  padding: 0 10px
  font-weight: normal
.dark .select
  color: white !important
.one-input
  margin-bottom: 10px
  border-radius: 10px !important
  ::v-deep
    input, textarea
      border: 0 !important
    textarea
      padding: 12px
</style>
