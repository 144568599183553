<template>
  <ion-modal
    class="gift-modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ModalHeader :modal-title="title" :showGoBackButton="true" :showCloseButton="true" @close="dismissModal" />

    <div class="modal-inner-content p-3">
      <compliment-bomb-character-selection
        @updated="emits('updated')"
        @purchase="dismissModal"
        @buy="dismissModal"
        @gifted="giftedItems"
        :display-use-modal="true"
        :purchaseMore="purchaseMore"
        :inventories="inventories"
        :selectedInventoryItem="selectedSocialItem"
        class="d-flex align-items-center flex-column"
        style="gap: 10px"
      >
        <div
          class="container badge clickable-item-hov"
          v-for="(item, index) in socialItems"
          @click="selected(item, index)"
          :class="{ active: selectedIndex === index }"
        >
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center justify-content-center mx-3">
              <inline-svg class="icon" :src="item.svg" />
            </div>

            <div class="d-flex flex-column flex-grow-1">
              <div class="title mt-1">
                <span class="uppercase"> {{ socialPack[item.type] }}</span>
              </div>
              <div class="text-black" v-html="subTitle(item)" />

              <div class="text-black" style="font-size: 14px" v-html="description(item)" />
            </div>
          </div>
          <div
            class="d-flex px-2 pb-2 align-items-center"
            :class="showOwnedBadge ? 'justify-content-between' : 'justify-content-center'"
          >
            <div class="badge p-2" v-if="showOwnedBadge">
              <span class="owned text-black">Owned:</span> <span class="amount">x{{ item.amount }}</span>
            </div>
            <div>
              <ion-button class="token-btn" @click.stop="buyComplimentBomb">
                <img class="token-icon mr-1" src="/quiz_token.png" />
                Buy</ion-button
              >
            </div>
          </div>
        </div>
      </compliment-bomb-character-selection>
    </div>
  </ion-modal>
</template>

<script lang="ts" setup>
import constants from '../statics/constants';
import ComplimentBombCharacterSelection from '@/shared/pages/compliment-bombs/components/ComplimentBombCharacterSelection.vue';
import ModalHeader from './ModalComponents/ModalHeader.vue';

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
  showOwnedBadge: {
    type: Boolean,
    default: true,
  },
  inventories: {
    type: Object,
    default: () => {},
  },
  purchaseMore: {
    type: Boolean,
    default: false,
  },
});
const socialPack = constants.socialInventoryItems;
const emits = defineEmits(['close', 'updated', 'open', 'gifted']);
const isOpen = toRef(props, 'isOpen');
const selectedIndex = ref(null);
const selectedSocialItem = ref({});
const inventories: any = toRef(props, 'inventories');
const router = useRouter();
const title = computed(() => {
  return 'Buy Social Items';
});

const subTitle = (value: any) => {
  return `<strong>Target a Character for ${get(value, 'type') === 'fanart_flare' ? 'Fanart' : 'Compliments'}</strong>`;
};

const giftedItems = (value: any) => {
  emits('gifted', value);
};

const description = (value: any) => {
  return `<strong>Use on
    any Character on the site
  </strong> (even yours). The Character will then be <strong>targeted to a wave of ${
    get(value, 'type') === 'fanart_flare' ? 'fanart' : 'compliments'
  }</strong> from other users.`;
};

const socialItems = computed(() => {
  return Object.entries(inventories.value)
    .filter(([val1, val2]): any => get(val2, 'type') !== 'gift')
    .map(([key, value]: [string, any]) => {
      let svg;
      let title;

      if (key === 'compliment_bomb_consumable') {
        svg = '/compliment-bomb.svg';
        title = 'Compliment Bomb';
      } else if (key === 'fanart_flare_consumable') {
        svg = '/fanart-flare.svg';
        title = 'Fanart Flare';
      } else if (!svg) {
        svg = '';
      }

      return {
        key,
        svg,
        amount: get(value, 'amount'),
        type: get(value, 'object'),
        value: get(value, 'type'),
        title,
      };
    });
});

const reset = () => {
  selectedIndex.value = null;
  selectedSocialItem.value = {};
};
const dismissModal = () => {
  reset();
  emits('close');
};

const selected = (item: any, index: any) => {
  selectedIndex.value = index;
  selectedSocialItem.value = item;
};
const buyComplimentBomb = () => {
  dismissModal();
  router.push({ name: 'shop' });
};
</script>
<style lang="sass" scoped>
.active
  border: 2px solid var(--ion-color-primary) !important
.amount
 color: var(--ion-color-primary)
.owned
  font-weight: bold
  font-size: 14px
.token-icon
  width: 20px
  height: 20px
.token-btn
  --background: linear-gradient(to right, rgb(215 27 217), rgb(180 51 225))
  border-radius: 10px
  font-size: 17px
  font-weight: bold

.title
  color: #214163
  font-weight: bold
  font-size: 18px
.dark .toolbar
  --background: #17074c !important
.modal-inner-content
  background: var(--ion-background-color, #fff)
  max-height: 70vh
  max-height: 70dvh
  overflow-x: hidden
  overflow-y: auto
.icon
  width: 90px
  height: 90px
.badge
  border-radius: 10px
  border: 2px solid #EAECF6

.hide-cursor
  pointer-events: none !important

.dark .toolbar
  --background: #17074c !important

.gift-modal-big
  --width: 500px
  --height: auto
  --border-radius: 10px
  @media(max-width: 576px)
    --width: 100%
.close-button
  width: 50px
</style>
