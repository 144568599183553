<template>
  <ion-page class="ads page scrollable no-padding">
    <ClientOnly>
      <div id="pw-leaderboard" class="mb-2"></div>

      <Ad :index="0" id="pw-leaderboard2" :wide="true" class="mb-2" />

      <div class="ad-container" v-for="(item, index) in ads" :key="item.id">
        <Ad :index="index" :id="item.id" />
      </div>
    </ClientOnly>
  </ion-page>
</template>

<script lang="ts" setup>
import Ad from '@/shared/pages/feed/components/Ad.vue';

const ads = ref([{ id: 'pw-med_rect' }, { id: 'pw-med_rect2' }]);
</script>

<style lang="sass" scoped>
.ads
  flex-wrap: wrap !important
  flex-direction: row !important
  gap: 4px
  overflow: auto
.ad-container
  width: 33%
  @media(max-width: 768px)
    width: 49%
  @media(max-width: 550px)
    width: 100%
</style>
