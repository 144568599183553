<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ion-header translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-between align-items-center px-3 py-2" v-if="!loading">
          <div>
            <i class="ti-arrow-left" v-if="goBack" />
          </div>
          <div>
            <div v-if="!isEmpty(username)" class="d-flex align-items-center">
              <router-link
                :to="{ name: 'profile', params: { username: username } }"
                style="font-weight: bold; font-size: 20px"
                >{{ username }}
              </router-link>
              <span class="text-black cb-text">
                sent you a {{ get(data, 'object') === FANART_FLARE ? 'FanArt Flare' : 'Compliment Bomb!' }}</span
              >
            </div>
            <div class="title" v-html="modalTitle" />
          </div>
          <div>
            <ion-buttons slot="end">
              <ion-button @click="dismissModal()">
                <i class="ti-close" />
              </ion-button>
            </ion-buttons>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>
    <modal-compliment-bomb-content :giftCbData="updatedCbomb" :animation="animation" :loading="loading" />

    <ion-footer translucent>
      <ion-toolbar class="toolbar" v-if="!loading">
        <div class="d-flex justify-content-center text-center align-items-center">
          <ion-button @click="dismissModal" :disabled="(!isEmpty(gift) && !isNull(gift.response)) || loading">
            <ion-icon v-if="!isEmpty(buttonIcon)" class="icon mx-1" :icon="buttonIcon" />
            {{ buttonText }}</ion-button
          >
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-modal>
</template>

<script lang="ts" setup>
import { getGiftRespondStatus } from '@/shared/actions/complimentBomb';
import ModalComplimentBombContent from './ModalComponents/ModalComplimentBombContent.vue';
import { FANART_FLARE } from '../statics/constants';
const props = defineProps({
  isOpen: { type: Boolean, default: false, required: true },
  modalTitle: { type: String, default: '' },
  sectionTitle: { type: String, default: '' },
  sectionDescription: { type: String, default: '' },
  svgIcon: { type: String, default: '' },
  username: { type: String, default: '' },
  goBack: { type: Boolean, default: false },
  showBadge: { type: Boolean, default: false },
  buttonText: { type: String, default: '' },
  loading: { type: Boolean, default: false },
  buttonIcon: { type: String, default: '' },
  data: { type: Object, default: {} },
  animation: { type: String, default: '' },
  giftId: { type: String, default: '' },
});
const emits = defineEmits(['close', 'open']);
const isOpen = toRef(props, 'isOpen');
const text = ref('SUCCESSFULLY BOMBED');
const route = useRoute();
const gift: any = ref({});
const giftId = toRef(props, 'giftId');
const sectionTitle = toRef(props, 'sectionTitle');
const sectionDescription = toRef(props, 'sectionDescription');
const svgIcon = toRef(props, 'svgIcon');
const fetchGiftDetails = async () => {
  const resp = await getGiftRespondStatus(giftId.value);

  gift.value = resp;
};

watch(
  isOpen,
  () => {
    if (isOpen.value && !isEmpty(giftId.value)) {
      fetchGiftDetails();
    }
  },
  { immediate: true }
);
const updatedCbomb = computed(() => {
  return [
    {
      svgIcon: svgIcon.value,
      title: sectionTitle.value,
      text: sectionDescription.value,
    },
  ];
});

const dismissModal = () => {
  !isEmpty(gift.value) && isNull(gift.value.response) ? emits('open') : emits('close');
};
</script>
<style lang="sass" scoped>
.cb-text
  font-size: 18px
  margin-left: 0.25rem
  font-weight: bold

.dark .toolbar
  --background: #17074c !important

.modal-inner-content
  background: #fff
  max-height: calc(85vh - 45px)
  overflow: auto
  overflow-x: hidden

.modal-big
  --width: 450px
  --height: auto
  --border-radius: 10px
  @media(max-width: 576px)
    --width: 100%
.close-button
  width: 50px
.title
  color: #214163
  font-size: 20px
  font-weight: bold
  text-align: center
  align-items: center
</style>
