<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ion-header translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-center text-center align-items-center">
          <ion-title class="title w-100">{{
            isComplimentBomb ? 'Select Character' : 'Choose Your Character'
          }}</ion-title>
        </div>
        <ion-buttons slot="end">
          <ion-button class="close-button" @click="dismissModal('')">
            <i class="ti-close" />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <div class="modal-inner-content" :class="{ 'char-auto-scroll': !isComplimentBomb }">
      <div class="pb-3 px-4 mt-3">
        <div v-if="isComplimentBomb" class="sub-title text-black">
          Select Character to {{ get(selectedInventoryItem, 'title', 'Compliment Bomb') }}
        </div>
        <div class="py-3">
          <SearchInput
            v-model="text"
            :search-icon="true"
            :shortcut-icon="false"
            :clear-icon="false"
            :hide-shortcut-icon-on-blur="true"
            :clear-on-esc="false"
            :blur-on-esc="true"
            :select-on-focus="false"
            :shortcut-listener-enabled="true"
            placeholder="Aa"
            @input="onSearch"
          />
        </div>
        <!-- <div v-if="isComplimentBomb">
          <small>*Mature Characters cannot be Compliment Bombed</small>
        </div> -->
        <div v-if="isComplimentBomb">
          <compliment-bomb-characters-tabs
            class="mt-2"
            :tabs="tabs"
            :active-index="tabIndex"
            @tab-changed="tabChanged"
          />
        </div>
        <div v-if="isloading" class="d-flex justify-content-center mt-2">
          <ChLoading size="sm" class="spinner" />
        </div>

        <Grid
          v-else-if="!isEmpty(userCharacters)"
          :scrollSm="false"
          :sm="2"
          :class="{ 'char-auto-scroll comp-section-scroll-height': isComplimentBomb }"
        >
          <CharacterCard
            v-for="character in filteredUserCharacters"
            :key="`char-${character.id}`"
            class="grid-item"
            :character="character"
            :showCheckmark="showCheckmark"
            :is-compliment-bomb="isComplimentBomb"
            :selected-char="selectedCharacterId === character.id"
            @click="isCharacterSelected(character)"
          />
          <ChLoading size="sm" v-if="isCharLoading" class="spinner" />
          <p v-else-if="userCharsPaging.next && !isCharLoading" class="clickable" @click="requestLoadMore">Load More</p>
        </Grid>
        <div v-else class="d-flex justify-content-center align-items-center text-center py-3">
          <span style="text-decoration: underline; color: var(--ion-color-primary)">
            <router-link :to="{ name: 'character-creator' }" @click="dismissModal" target="_blank">
              Create a Character!</router-link
            >
          </span>
        </div>
      </div>
    </div>

    <ion-footer translucent v-if="showSelectionButton">
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-center text-center align-items-center">
          <ion-button :disabled="isEmpty(selectedCharacterId) || isLoader" class="choose-btn" @click="chooseChar">
            <span>{{ isComplimentBomb ? 'Select' : 'Choose' }}</span>
          </ion-button>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-modal>
</template>

<script lang="ts" setup>
import SearchInput from 'vue-search-input';
import { complimentBombCharTabs } from '@/shared/statics/tabs';
import Grid from '@/shared/components/storage/Grid.vue';
import CharacterCard from '@/shared/components/EventCharacterCard.vue';
import { Character, Paging, Tab } from '@/shared/types/static-types';
import { getUserCharacters } from '@/shared/actions/characters';
import { getNextPage } from '@/shared/helpers/pagination';
import { authStore } from '@/shared/pinia-store/auth';
import ComplimentBombCharactersTabs from '@/shared/pages/compliment-bombs/components/ComplimentBombCharactersTabs.vue';
import { getComplimentBombUserCharacters } from '@/shared/actions/complimentBomb';

const text = ref('');
const tabs = ref(complimentBombCharTabs);
const tabIndex = ref(1);
const currentTab = ref('');
const isCharLoading = ref(false);
const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
  exclude: {
    type: Array,
    default: () => [],
  },
  showCheckmark: {
    type: Boolean,
    default: true,
  },
  index: {
    type: Number,
    default: 0,
  },
  showSelectionButton: {
    type: Boolean,
    default: true,
  },
  isComplimentBomb: {
    type: Boolean,
    default: false,
  },
  onClickSelectedChar: {
    type: Boolean,
    default: false,
  },
  excludeFancharSpinoffs: {
    type: Boolean,
    default: false,
  },
  selectedInventoryItem: {
    type: Object,
    default: {},
  },
  isLoader: {
    type: Boolean,
    default: false,
  },
  allowPrivate: {
    type: Boolean,
    default: false,
  },
});
const emits = defineEmits(['close', 'selected']);
const isOpen = toRef(props, 'isOpen');
const index = toRef(props, 'index');
const toExclude = toRef(props, 'exclude');
const userCharacters = ref([]);
const userCharsPaging: any = ref<Paging>();
const selectedCharacterId: any = ref('');
const selectedCharacter: any = ref({});
const isloading = ref(false);
const isComplimentBomb = toRef(props, 'isComplimentBomb');
const excludeFancharSpinoffs = toRef(props, 'excludeFancharSpinoffs');
const onClickSelectedChar = toRef(props, 'onClickSelectedChar');
const selectedInventoryItem = toRef(props, 'selectedInventoryItem');
const allowPrivate = toRef(props, 'allowPrivate');
const getCurrentTab = async () => {
  if (isComplimentBomb.value) currentTab.value = 'mychars';
};

watch(isOpen, async () => {
  if (isOpen.value) {
    await getCurrentTab();
    isComplimentBomb.value ? fetchCharacters('my_chars') : fetchUserCharacters();
  }
});

const filteredUserCharacters = computed(() => {
  return userCharacters.value.filter((char) => !toExclude.value?.some((excludeChar) => excludeChar?.id === char?.id));
});

watch(currentTab, async () => {
  if (currentTab.value) {
    userCharacters.value = [];
    text.value = '';
    userCharsPaging.value = null;
    currentTab.value === 'mychars'
      ? fetchCharacters('my_chars')
      : currentTab.value === 'all'
      ? fetchAllCharacters()
      : fetchCharacters('my_followed_chars');
  }
});

const tabChanged = ({ value }: Tab) => {
  currentTab.value = value;
};
const isCharacterSelected = (character: Character) => {
  if (onClickSelectedChar.value) {
    emits('selected', { character, index: index.value });
  }
  if (get(character, 'compliment_bombed') && isComplimentBomb.value) return;
  if (selectedCharacterId.value === character.id) {
    selectedCharacter.value = {};
    selectedCharacterId.value = '';
  } else {
    selectedCharacter.value = character;
    selectedCharacterId.value = character.id;
  }
};

const user = computed(() => {
  const { user } = authStore();
  return user.value;
});

const userCharactersNextPage = async () => {
  isCharLoading.value = true;
  const { results, ...paging } = await getNextPage(userCharsPaging.value!);
  userCharacters.value = userCharacters.value.concat(results);
  userCharsPaging.value = paging;
  isCharLoading.value = false;
};

const requestLoadMore = async (ev: CustomEvent) => {
  if (!userCharsPaging.value.next) {
    (ev?.target as any).complete();
  } else {
    await userCharactersNextPage();
  }
};

const fetchUserCharacters = async () => {
  isloading.value = true;
  alert(allowPrivate.value);
  const { results, ...paging } = !isEmpty(text.value)
    ? await getUserCharacters(user.value.id, 1, {
        page_size: 48,
        search: text.value,
        excl_fs: excludeFancharSpinoffs.value ? 1 : 0,
        incl_u_m: allowPrivate.value ? true : undefined,
      })
    : await getUserCharacters(user.value.id, 1, {
        page_size: 48,
        excl_fs: excludeFancharSpinoffs.value ? 1 : 0,
        incl_u_m: allowPrivate.value ? true : undefined
      });
  userCharacters.value = results;
  userCharsPaging.value = paging;
  isloading.value = false;
};

const fetchCharacters = async (currentVal: any) => {
  isloading.value = true;
  const { results, ...paging } = isEmpty(text.value)
    ? await getComplimentBombUserCharacters({
        page: 1,
        page_size: 50,
        filter_type: currentVal,
        excl_fs: excludeFancharSpinoffs.value ? 1 : 0,
        type: get(selectedInventoryItem.value, 'type'),
      })
    : await getComplimentBombUserCharacters({
        page: 1,
        page_size: 50,
        filter_type: currentVal,
        search: text.value,
        excl_fs: excludeFancharSpinoffs.value ? 1 : 0,
        type: get(selectedInventoryItem.value, 'type'),
      });
  userCharacters.value = results;
  userCharsPaging.value = paging;
  isloading.value = false;
};

const fetchAllCharacters = async () => {
  isloading.value = true;
  const { results, ...paging } = isEmpty(text.value)
    ? await getComplimentBombUserCharacters({
        page: 1,
        page_size: 48,
        excl_fs: excludeFancharSpinoffs.value ? 1 : 0,
        type: get(selectedInventoryItem.value, 'type'),
      })
    : await getComplimentBombUserCharacters({
        page: 1,
        page_size: 48,
        search: text.value,
        excl_fs: excludeFancharSpinoffs.value ? 1 : 0,
        type: get(selectedInventoryItem.value, 'type'),
      });
  userCharacters.value = results;
  userCharsPaging.value = paging;
  isloading.value = false;
};

const dismissModal = (val: any) => {
  emits('close', val);
  if (isComplimentBomb.value) resetData();
};

const resetData = () => {
  selectedCharacterId.value = '';
  selectedCharacter.value = {};
  text.value = '';
};
const onSearch = () => {
  searchTextChanged();
};

const chooseChar = () => {
  emits('selected', {
    character: selectedCharacter.value,
    index: index.value,
  });
};

const searchTextChanged = debounce(async () => {
  !isComplimentBomb.value
    ? await fetchUserCharacters()
    : currentTab.value === 'mychars'
    ? fetchCharacters('my_chars')
    : currentTab.value === 'all'
    ? fetchAllCharacters()
    : fetchCharacters('my_followed_chars');
}, 500);
</script>
<style lang="sass" scoped>
.char-auto-scroll
  overflow: auto
  overflow-x: hidden
.comp-section-scroll-height
  height: 38vh
.dark .toolbar
  --background: #17074c !important
.choose-btn
  --background: #00b4c5
  --border-radius: 11px
  text-transform: none
  color: white
.dark .choose-btn
  color: white
.choose-btn::part(native)
  color: white !important
.spinner
  width: 30px
  height: 30px

.quiz-content
  height: calc(100vh - 330px) !important
  overflow: auto
.modal-inner-content
  background: var(--ion-background-color, #fff)
  max-height: calc(100vh - 178px)
  max-height: calc(100dvh - 178px)

.modal-big
  --width: 700px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
.close-button
  width: 50px
.sub-title
  font-size: 16px
  font-weight: bold

.title
  color: #214163
  font-size: 20px
  font-weight: bold
  text-align: center
  padding-left: 55px
  padding-right: 0px
</style>
