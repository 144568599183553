<template>
  <ion-modal
    class="modal"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <div class="inner-content d-flex flex-column py-3 px-2">
      <div v-if="loader" class="p-2">
        <div class="d-flex flex-column align-items-center">
          <ion-skeleton-text animated class="my-1" style="width: 250px; height: 18px" />
          <ion-skeleton-text animated class="mb-2" style="width: 300px; height: 18px" />
        </div>
        <div class="d-flex m-0">
          <div class="row w-100 m-0 mr-2">
            <span class="col-md-6 px-1">
              <ion-skeleton-text animated class="small-image" />
            </span>
            <span class="col-md-6 px-1">
              <ion-skeleton-text animated class="small-image" />
            </span>
            <span class="col-md-6 px-1">
              <ion-skeleton-text animated class="small-image" />
            </span>
            <span class="col-md-6 px-1">
              <ion-skeleton-text animated class="small-image" />
            </span>
          </div>

          <ion-skeleton-text animated class="image" />
        </div>
      </div>
      <div v-else class="d-flex align-items-center text-center justify-content-between">
        <div>
          <ion-buttons>
            <ion-button class="close-button text-black" @click="dismissModal()">
              <i class="ti-close" />
            </ion-button>
          </ion-buttons>
        </div>
        <div class="d-flex flex-column">
          <div class="title text-primary">
            {{
              isDecorated && count <= 6 && !isEmpty(decChars)
                ? 'NICE!! Looks dope!!'
                : count <= 6 && !isDecorated && !isEmpty(decChars)
                ? 'Decorate This Character!!'
                : 'You did it!!'
            }}
          </div>
          <ion-button v-if="count && count <= 6" class="char-btn"
            >Character {{ isDecorated ? count - 1 : count }} out of 6</ion-button
          >
          <ion-button v-else class="char-btn"> Character 6 out of 6 </ion-button>
        </div>

        <div></div>
      </div>
      <div
        v-if="(count >= 7 && !loader && showSubmissions) || (isEmpty(decChars) && !loader)"
        class="d-flex text-center justify-content-center mt-1"
      >
        <small> Hubert's delivering your decoration! It’ll show up here as soon as the owner gets it! </small>
      </div>
      <div
        v-if="(count >= 7 && !loader && showSubmissions) || (isEmpty(decChars) && !loader)"
        class="d-flex flex-column px-3 py-2"
      >
        <oc-decoration-tabs
          :count="totalCharCount"
          :tabs="subtabs"
          :curr-tab="currentSubTab"
          :active-index="subtabIndex"
          :not-icon="true"
          @tab-changed="tabSubChanged"
        />
        <div
          v-if="pendingOcsub > 0"
          class="d-flex align-items-center justify-content-center pt-2 pb-1"
          style="font-size: 12px; color: gray"
        >
          <i class="ti-info-alt icon mr-1" /> {{ pendingOcsub }} decoration(s) pending delivery
        </div>

        <div v-if="currentSubTab === 'community'">
          <oc-submissions-and-community-card
            :oc-decorations="characters"
            :task="task"
            :event-time="eventTime"
            :total-chars="totalCommunityChars"
            @load-more="loadMoreCommunityChars"
            @onReaction="(reactionResp) => onReaction(reactionResp)"
          />
        </div>
        <div v-else>
          <oc-submissions-and-community-card
            :event-time="eventTime"
            :oc-decorations="myOcsub"
            :task="task"
            :is-sub="true"
            :total-chars="myOCCount"
            @load-more="loadMoreChars"
          />
        </div>
      </div>

      <div class="oc-decoration px-2">
        <div v-if="!showSubmissions && !isDecorated && count <= 6 && !loader && !isEmpty(decChars)" class="w-100">
          <oc-decoration-tabs :tabs="tabs" :active-index="tabIndex" @tab-changed="tabChanged" />
          <CurrencyBar
            v-if="isCurrentTabOCSticker"
            :setTopBottompadding="true"
            :set-position-end="false"
            :displayShop="false"
            :set-start-slot="true"
            :coins="get(usersCurrency, 'coins')"
            :gems="get(usersCurrency, 'gems')"
          />
          <div v-if="!stickerLoad" class="oc-container mx-1" :class="{ 'oc-sticker-container': isCurrentTabOCSticker }">
            <div
              class="row no-gutters"
              :class="{ 'row-space': isCurrentTabOCSticker, 'no-oc-gutters': !isCurrentTabOCSticker }"
            >
              <div
                v-for="(item, index) of displayStickers"
                :key="index"
                class="stickers-column text-center mx-0"
                :class="{ 'own-sticker-card': isCurrentTabOCSticker }"
                @click.prevent="actionClicked(item, index, $event)"
              >
                <img :id="`stickerimg-${index}`" class="clickable-item-hov sticker-size" :src="getSticker(item)" />
                <div v-if="isCurrentTabOCSticker">
                  <ion-button class="w-100 purchased-btn" @click.stop.prevent="purchaseDecorGif(item, $event)">
                    <inline-svg
                      v-if="!get(item, 'is_bought')"
                      :src="getSvg(item)"
                      class="mr-1"
                      height="15"
                      width="15"
                    />
                    {{ get(item, 'is_bought') ? 'Purchased' : get(item, 'amount') }}</ion-button
                  >
                </div>
              </div>
              <div
                v-if="isCurrentTabOCSticker && isEmpty(displayStickers)"
                class="d-flex align-items-center justify-content-center text-center mx-auto"
              >
                <div class="no-data">No stickers yet</div>
              </div>
              <client-only v-if="isCurrentTabOCSticker">
                <div class="d-flex justify-content-center">
                  <infinite-loading v-if="get(stickerPaging, 'next')" @infinite="loadHandler">
                    <template #complete>
                      <span></span>
                    </template>
                    <template #spinner>
                      <div class="d-flex justify-content-center">
                        <ChLoading v-if="isTotalLoadMore" size="sm" class="spinner" />
                      </div>
                    </template>
                  </infinite-loading>
                </div>
              </client-only>
            </div>
          </div>
          <div v-else class="d-flex justify-content-center">
            <ChLoading size="sm" class="spinner" />
          </div>

          <div class="d-flex arrow-btn mt-4" v-if="!isCurrentTabOCSticker">
            <ion-button class="back-btn mx-1" :disabled="offsetValue === 0" @click="fetchOcStickers(-1)">
              <span> <i class="ti-arrow-left icon" /></span>
            </ion-button>
            <div class="mx-1 w-100 text-center">
              <small style="color: rgb(191 188 188)">Powered by Tenor</small>
            </div>

            <ion-button :disabled="nextStickersPage === null" class="back-btn mx-1" @click="fetchOcStickers(1)">
              <span> <i class="ti-arrow-right icon" /></span>
            </ion-button>
          </div>
        </div>

        <div v-if="!showSubmissions && count <= 7 && !loader && !isEmpty(decChars)" class="w-100">
          <div class="" style="position: relative" :class="isDecorated ? 'oc-decorated' : 'oc-border'">
            <div class="d-flex p-2">
              <ion-button
                v-if="!isDecorated && count <= 6 && !isEmpty(decChars)"
                style="--background: #214163; --padding-start: 0; --padding-end: 0; height: 30px; width: 30px"
                :disabled="!selectedDragIndex && selectedDragIndex !== 0"
                class="mr-1"
                @click.prevent="deleteSelectedImg"
              >
                <ion-icon class="icon" style="font-size: 18px !important" :icon="trashOutline" />
              </ion-button>

              <div
                id="charbox"
                class="d-flex justify-content-center charbox"
                style="overflow: hidden; width: 100%"
                @click="setSelectedValue"
              >
                <div id="ocBorder" ref="ocBorder" class="img-border">
                  <div v-for="(image, index) in selectedDragImg" :key="`img-${image.id}`">
                    <div>
                      <img
                        :class="`target-${index}`"
                        :src="image.sticker"
                        class="sticker-deco"
                        style="position: absolute; z-index: 1; width: 0px; height: 0px"
                        @click="imgClicked(index)"
                        @touchstart="imgClicked(index)"
                      />

                      <Moveable
                        v-if="!isDecorated && image.showMoveable"
                        :id="`move-${index}`"
                        ref="moveable"
                        :style="`visibility: ${getSelectedIndex(index)}; --zoom:${nmbr}`"
                        :target="getTarget"
                        :draggable="true"
                        :resizable="true"
                        :rotatable="true"
                        :keep-ratio="true"
                        :pinchable="true"
                        class="moveable"
                        @drag="(transform) => onDrag(transform, index)"
                        @resize="({ width, height, drag }) => onResize(width, height, drag, index)"
                        @rotate="(transform) => onRotate(transform, index)"
                      />
                    </div>
                  </div>
                  <img loading="lazy" id="charimg" class="char-img" :src="getImg" @click="setSelectedValue" />
                </div>
              </div>
            </div>
            <div class="text-center mb-2">
              <router-link :to="`/character/${get(getCharInfo, 'slug')}`" target="_blank">
                {{ truncateText(get(getCharInfo, 'info.name'), 39) }}
              </router-link>
              by
              <router-link
                :class="{
                  'text-color-username': usernameColor(getCharInfo) && !usernameColor(getCharInfo).startsWith('#'),
                  'bold-username': usernameColor(getCharInfo),
                }"
                :style="
                  !usernameColor(getCharInfo)
                    ? ''
                    : usernameColor(getCharInfo).startsWith('#')
                    ? `color: ${usernameColor(getCharInfo)} !important`
                    : `background-image: ${usernameColor(getCharInfo)} !important`
                "
                :to="`/profile/${get(getCharInfo, 'user')}`"
                target="_blank"
              >
                @{{ get(getCharInfo, 'user') }}
              </router-link>
            </div>
          </div>
          <div v-if="isDecorated" class="text-center mt-2">
            Hubert is delivering this decoration to
            <router-link
              :class="{
                'text-color-username': usernameColor(getCharInfo) && !usernameColor(getCharInfo).startsWith('#'),
                'bold-username': usernameColor(getCharInfo),
              }"
              :style="
                !usernameColor(getCharInfo)
                  ? ''
                  : usernameColor(getCharInfo).startsWith('#')
                  ? `color: ${usernameColor(getCharInfo)} !important`
                  : `background-image: ${usernameColor(getCharInfo)} !important`
              "
              :to="`/profile/${get(getCharInfo, 'user')}`"
              target="_blank"
            >
              @{{ get(getCharInfo, 'user') }}
            </router-link>
          </div>
        </div>
      </div>
      <div v-if="!showSubmissions && count <= 7 && !loader && !isEmpty(decChars)" class="d-flex px-3 btn-part">
        <ion-button
          v-if="!isDecorated"
          color="transparent"
          :disabled="loading"
          class="text-black skip-btn"
          @click="skip"
          >Skip</ion-button
        >

        <ion-button
          v-if="!isDecorated && !showSubmissions"
          class="w-100 next-btn"
          :disabled="isEmpty(selectedDragImg) || loading"
          @click="decorateImage"
        >
          <ChLoading size="sm" v-if="isDecoratingImg" style="width: 25px; height: 25px" />
          <span v-else> Next</span>
        </ion-button>

        <ion-button v-else class="w-100 next-btn" @click="nextDecorateImage">
          <span> Next</span>
        </ion-button>
      </div>
      <DecorGifPurchaseModal
        :is-open="isOpenConfirmationPurchaseModal"
        @close="isOpenConfirmationPurchaseModal = false"
        @purchase="closeModal"
        :item="selectedSticker"
        @updated="closePurchasedModal"
        :isPurchased="isPurchased"
      />
      <OcDecorStickerDisplayModal
        :is-open="isOpenDisplayStickerModal"
        @close="isOpenDisplayStickerModal = false"
        :sticker="selectedSticker"
        @purchase="purchaseDecorGif"
      />
    </div>
  </ion-modal>
</template>

<script lang="ts" setup>
import Moveable from 'vue3-moveable';
import DecorGifPurchaseModal from '@/shared/modals/ItemsPurchaseModal.vue';
import { trashOutline } from 'ionicons/icons';
import { Tab, Image, Paging } from '@/shared/types/static-types';
import OcDecorStickerDisplayModal from './OcDecorStickerDisplayModal.vue';
import OcDecorationTabs from '@/shared/pages/oc-decoration/oc-decoration-tabs.vue';
import { ocDecorTabs, ocSubmissionTabs } from '@/shared/statics/tabs';
import OcSubmissionsAndCommunityCard from '@/shared/components/OcSubmissionsAndCommunityCard.vue';
import { toast } from '@/shared/native';
import { decorateCharacters, ocDecorationSticker } from '@/shared/actions/characters';
import NotEnoughCoinsPopover from '@/shared/pages/store/popovers/NotEnoughCoinsPopover.vue';
import InfiniteLoading from 'v3-infinite-loading';
import CurrencyBar from '@/shared/pages/store/CurrencyBar.vue';

import {
  characterEventTime,
  fetchCommunityFanArt,
  fetchDecorCharacters,
  getCharactersEventPollCounts,
} from '@/shared/actions/events';
import { getFanWorks } from '@/shared/actions/fanwork';
import { getNextPage, getShopVirtualItems } from '../actions/virtualItems';
import { authStore } from '../pinia-store/auth';
import { popovers } from '@/shared/native/popovers';
import { truncateText } from '@/shared/utils/string';
const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },

  task: {
    type: Object,
    default: () => {},
  },
});
const isOpen = toRef(props, 'isOpen');
const task = toRef(props, 'task');
const loading = ref(false);
const selectedSticker = ref({});
const nmbr = ref(0.5);
const stickerCount = ref(0);
const count = ref(1);
const isOpenConfirmationPurchaseModal = ref(false);
const allStickers = ref([]);
const subtabIndex = ref(1);
const isTotalLoadMore = ref(false);
const nextStickersPage = ref('');
const decCharCounts = ref(0);
const decImg: any = ref('');
const currentPage = ref(1);
const decChars: any = ref([]);
const characters: any = ref([]);
const totalCommunityChars: any = ref(0);
const stickerPaging: any = ref<Paging>();
const pageCommunity = ref(1);
const page = ref(1);

const myOcsub = ref([]);
const myOCCount = ref(0);
const totalCharCount = ref(0);

const pendingOcsub = ref();
const loader = ref(true);
const stickerLoad = ref(false);
const router = useRouter();
const rating = ref('pg-13');
const { usersCurrency, getUsersCurrency } = authStore();
const getTabIndex = () => {
  const tIndex = indexOf(
    tabs.value,
    find(tabs.value, (tab) => tab.value === currentTab.value)
  );
  if (tIndex !== -1) {
    tabIndex.value = tIndex;
  }
};
const fetchOCDecorationStickers = async (name: any, pos: any, limit: number = 25) => {
  stickerLoad.value = true;
  const resp = await ocDecorationSticker(name, pos, limit, rating.value);
  allStickers.value = [...allStickers.value, ...resp.results] as any;
  nextStickersPage.value = resp.next;
  stickerLoad.value = false;
};

const fetchOwnOCDecorationSticker = async (page = 1) => {
  try {
    stickerLoad.value = true;
    const payload = {
      page,
      page_size: 30,
      item_group__title: 'Decor Gifs',
    };
    const { results, ...paging } = await getShopVirtualItems(payload);
    allStickers.value = results.sort((a: any, b: any) => b.is_bought - a.is_bought);
    stickerPaging.value = paging;
  } catch (error) {
    console.error('Error fetching OC Decoration Stickers:', error);
  } finally {
    stickerLoad.value = false;
  }
};

const getSticker = (item: any) => {
  return isCurrentTabOCSticker.value ? get(item, 'image') : get(item, 'media_formats.nanogif_transparent.url');
};
const fetchCounts = async () => {
  const resp = await getCharactersEventPollCounts();
  count.value = get(resp, 'total per day submission') + 1;
  if (decCharCounts.value <= 6) {
    await fetchDecChars();
  }
};

const usernameColor = (user: any) => {
  return user?.customize_profile?.username_color || '';
};

const fetchDecChars = async () => {
  const resp = await fetchDecorCharacters(task.value.id);
  decChars.value = resp.results;
  decCharCounts.value = resp.count;
};

const loadMoreChars = async () => {
  page.value = page.value + 1;

  const resp = await getFanWorks('submitter', {
    type: 'decoration',
    page: page.value,
    page_size: 20,
  });

  myOcsub.value = myOcsub.value.concat(resp.results);
  myOCCount.value = resp.count;
};

const loadMoreCommunityChars = async () => {
  pageCommunity.value = pageCommunity.value + 1;
  const resp = await fetchCommunityFanArt({ page: pageCommunity.value, page_size: 20 });
  characters.value = characters.value.concat(resp.results);
  totalCommunityChars.value = resp.count;
};
watch(
  () => props.isOpen,
  async () => {
    if (isOpen.value) {
      currentSubTab.value = 'community';
      const resp = await fetchCommunityFanArt({ page: 1, page_size: 20 });
      characters.value = resp.results;
      totalCommunityChars.value = resp.count;
      isCurrentTabOCSticker.value
        ? await fetchOwnOCDecorationSticker()
        : await fetchOCDecorationStickers(currentTab.value, nextStickersPage.value, isMobSmallScreen.value ? 10 : 25);

      await fetchCounts();
      await fetchSubmissions();

      const resp2 = await characterEventTime();
      eventTime.value = resp2;
      getUsersCurrency();
      loader.value = false;
    }
  }
);

watch(isOpen, () => {
  const document = useDocument();
  if (isOpen.value) {
    document.value.getElementsByTagName('html')[0].style.overscrollBehavior = 'none';
    document.value.getElementsByTagName('html')[0].style.touchAction = 'none';

    (window as any).$crisp?.push(['do', 'chat:hide']);
    const el = document.value?.querySelector('.crisp-client') as any;
    if (el) el.style.display = 'none';
    router.replace({ name: 'home', query: { event: 'decoration-fest' } });
  } else {
    document.value.getElementsByTagName('html')[0].style.overscrollBehavior = 'auto';
    document.value.getElementsByTagName('html')[0].style.touchAction = 'auto';
    router.replace({ name: 'home' });
  }
});

const tabs = ref(ocDecorTabs);
const showSubmissions = ref(false);
const isPurchased = ref(false);
const isOpenPurchasedModal = ref(false);
const subtabs = ref(ocSubmissionTabs);
const currentTab = ref('ocsticker');
const currentSubTab = ref('community');
const eventTime = ref({});
const tabIndex = ref(0);
const isOpenDisplayStickerModal = ref(false);
const selectedDragImg: any = ref([]);
const x = ref(0);
const y = ref(0);
const width = ref(70);
const widthDiff = ref(0);
const heightDiff = ref(0);
const height = ref(0);
const imgIndex = ref(0);
const rotation = ref(0);
const isDecorated = ref(false);
const isDecoratingImg = ref(false);
const emits = defineEmits(['close']);
const windowHeight = ref('0px');
const selectedDragIndex: any = ref(null);
const selectedItem: any = ref({});
const offsetValue = ref(0);

const isMobSmallScreen = computed(() => {
  const { width } = useWindowSize();
  return width.value <= 700;
});
const pageSize = ref(isMobSmallScreen.value ? 10 : 25);

const ocBorder: Ref = ref(null);
const isCurrentTabOCSticker = computed(() => {
  return currentTab.value === 'ocsticker';
});
const dismissModal = () => {
  selectedDragImg.value = [];
  isDecorated.value = false;
  selectedDragIndex.value = '';
  const document = useDocument();
  document.value.getElementsByTagName('html')[0].style.overscrollBehavior = 'auto';
  document.value.getElementsByTagName('html')[0].style.touchAction = 'auto';

  (window as any).$crisp?.push(['do', 'chat:show']);
  const el = document.value?.querySelector('.crisp-client') as any;
  if (el) el.style.display = 'block';

  offsetValue.value = 0;
  currentPage.value = 1;
  nextStickersPage.value = '';
  allStickers.value = [];
  tabIndex.value = 0;
  currentTab.value = 'ocsticker';
  emits('close');
};
const setSelectedValue = (ev: any) => {
  ev.stopPropagation();
  ['charimg', 'charbox', 'ocBorder'].includes(ev.target.id) ?? (selectedDragIndex.value = '');
};
const getSelectedItem = computed(() => {
  const document = useDocument();
  const el = document.value?.querySelector(`.target-${selectedDragIndex.value}`) as any;
  return el;
});

const getSelectedIndex = (index: any) => {
  return selectedDragIndex.value === index ? 'visible' : 'hidden';
};
const getTarget = () => {
  return getSelectedItem.value;
};
const tabChanged = ({ value }: Tab) => {
  offsetValue.value = 0;
  currentTab.value = value;
  currentPage.value = 1;
  nextStickersPage.value = '';
  allStickers.value = [];
  isCurrentTabOCSticker.value
    ? fetchOwnOCDecorationSticker()
    : fetchOCDecorationStickers(value, nextStickersPage.value, isMobSmallScreen.value ? 10 : 25);
};
const removeMoveable = () => {
  const document = useDocument();
  const el1 = document.value?.getElementById(`move-${selectedDragIndex.value}`);
  const el = document.value?.querySelector(`.target-${selectedDragIndex.value}`) as HTMLElement;
  el?.remove();
  el1?.remove();
};

const deleteSelectedImg = () => {
  removeMoveable();
  selectedDragImg.value[selectedDragIndex.value].markedasDel = true;

  selectedDragImg.value = selectedDragImg.value.filter((img: any) => !img.markedasDel);

  selectedDragIndex.value = '';
};

const displayStickers = computed(() => {
  return isCurrentTabOCSticker.value
    ? allStickers.value
    : allStickers.value.slice(offsetValue.value, offsetValue.value + pageSize.value);
});

const imgClicked = (index: any) => {
  selectedDragIndex.value = index;
};

const getImg = computed(() => {
  return get(decChars.value[imgIndex.value], 'info.cropProfilePicture');
});

const getCharInfo = computed(() => {
  return decChars.value[imgIndex.value];
});

const getWidthAndHeight = () => {
  const document = useDocument();
  const charimg = document.value?.getElementById(`charimg`) as any;

  const renderedWidth = charimg.clientWidth;
  const originalWidth = charimg.naturalWidth;
  const renderedHeight = charimg.clientHeight;
  const originalHeight = charimg.naturalHeight;

  // charimg.style.width = `${charimg.clientWidth}px`;

  widthDiff.value = originalWidth / renderedWidth;
  heightDiff.value = originalHeight / renderedHeight;
};

const onReaction = (event: { reactResp: {}; image: Image }) => {
  const imageIndex = characters.value.findIndex((char: any) => char.image.id === event.image.id);
  characters.value[imageIndex].image = {
    ...event.image,
    ...get(event.reactResp, 'updatedReactionsData', {}),
  };
};

const getSvg = (item: any) => {
  return get(item, 'currency_type') === 'coin' ? '/hubuck.svg' : '/gem.svg';
};

const closeModal = () => {
  isOpenConfirmationPurchaseModal.value = false;
  setTimeout(() => {
    isPurchased.value = true;
    isOpenConfirmationPurchaseModal.value = true;
  }, 500);
};

const openPopover = (ev: MouseEvent, item: any) => {
  popovers.open(ev, NotEnoughCoinsPopover, { currencyType: get(item, 'currency_type') });
};

const purchaseDecorGif = async (item: any, ev?: MouseEvent) => {
  const currencyAmount = get(item, 'currency_type') === 'coin' ? usersCurrency.value.coins : usersCurrency.value.gems;

  if (get(item, 'is_bought')) return;
  else if (currencyAmount >= item.amount) {
    isOpenConfirmationPurchaseModal.value = true;
    selectedSticker.value = item;
  } else {
    if (ev) {
      openPopover(ev, item);
    }
  }
};

const requestLoadMore = async () => {
  isTotalLoadMore.value = true;
  try {
    const { results, ...paging } = await getNextPage(stickerPaging.value!);
    allStickers.value = allStickers.value.concat(results);

    allStickers.value.sort((a: any, b: any) => {
      return b.is_bought - a.is_bought;
    });
    stickerPaging.value = paging;
  } catch (e) {
    await toast.show('Could not load. Please try again.', 'nonative', 'danger');
  } finally {
    isTotalLoadMore.value = false;
  }
};

const loadHandler = ($state: any) => {
  if (stickerPaging.value.next) {
    requestLoadMore();
  } else {
    $state.complete();
  }
};

const closePurchasedModal = () => {
  isOpenConfirmationPurchaseModal.value = false;
  isOpenDisplayStickerModal.value = false;

  getUsersCurrency();
  fetchOwnOCDecorationSticker();
  setTimeout(() => {
    isPurchased.value = false;
    selectedItem.value = {};
  }, 100);
};
const actionClicked = async (item: any, stickerIndex: any, ev: MouseEvent) => {
  if (isCurrentTabOCSticker.value && !get(item, 'is_bought')) {
    const currencyAmount = get(item, 'currency_type') === 'coin' ? usersCurrency.value.coins : usersCurrency.value.gems;

    if (currencyAmount < item.amount) {
      if (ev) openPopover(ev, item);
      return;
    }

    selectedSticker.value = item;
    isOpenDisplayStickerModal.value = true;
    return;
  }
  const img = isCurrentTabOCSticker.value ? get(item, 'image') : get(item, 'media_formats.gif_transparent.url');
  if (selectedDragImg.value.length >= 10) {
    await toast.show('Sticker limit reached.', 'nonative', 'danger');
    return;
  }

  const document = useDocument();
  const charimg = document.value?.getElementById(`stickerimg-${stickerIndex}`) as any;
  const originalWidth = charimg.naturalWidth;
  const originalHeight = charimg.naturalHeight;

  width.value = 70;
  height.value = 70;
  rotation.value = 0;
  x.value = 0;
  y.value = 0;
  const existingValue = selectedDragImg.value.find((item: any) => item.sticker === img);

  getWidthAndHeight();

  if (originalWidth > originalHeight) {
    width.value = 70;
    height.value = (originalHeight * 70) / originalWidth;
  } else {
    width.value = (originalWidth * 70) / originalHeight;
    height.value = 70;
  }
  const initialWidth = width.value;
  const initialHeight = height.value;

  width.value = width.value * widthDiff.value;
  height.value = height.value * heightDiff.value;

  if (width.value && height.value) {
    selectedDragImg.value.push({
      sticker: img,
      markedasDel: false,
      x_pos: x.value,
      y_pos: y.value,
      height: height.value,
      width: width.value,
      rotation: rotation.value,
      id: stickerCount.value++,
      showMoveable: false,
    });

    const index = existingValue
      ? selectedDragImg.value.length - 1
      : selectedDragImg.value.findIndex((item: any) => item.sticker === img);

    selectedDragIndex.value = index;
    selectedItem.value = selectedDragImg.value[selectedDragIndex.value];

    setTimeout(() => {
      const el = getSelectedItem.value;

      el.style.width = `${initialWidth}px`;
      el.style.height = `${initialHeight}px`;
      const ocBorderElement = document.value?.getElementById(`ocBorder`) as any;
      const ocBorderRect = ocBorderElement.getBoundingClientRect();

      let xPos = ocBorderRect.width / 2 - initialWidth / 2;
      let yPos = ocBorderRect.height / 2 - initialHeight / 2;
      el.style.transform = `translate(${xPos}px,${yPos}px)`;

      x.value = xPos;
      y.value = yPos;

      xPos = Math.floor(xPos * widthDiff.value);
      yPos = Math.floor(yPos * heightDiff.value);

      if (width.value && height.value && xPos && yPos) {
        selectedDragImg.value[selectedDragIndex.value].showMoveable = true;
      }

      selectedDragImg.value[selectedDragIndex.value].x_pos = xPos;
      selectedDragImg.value[selectedDragIndex.value].y_pos = yPos;
    }, 100);
  }
};
const onResize = (width: any, height: any, drag: any, index: any) => {
  selectedDragIndex.value = index;
  const el = getSelectedItem.value;

  el.style.width = `${width}px`;
  el.style.height = `${height}px`;
  el.style.transform = drag.transform;

  x.value = drag.translate[0];
  y.value = drag.translate[1];

  let xpos = x.value;
  let ypos = y.value;

  xpos = Math.floor(xpos * widthDiff.value);
  ypos = Math.floor(ypos * heightDiff.value);

  selectedDragImg.value[selectedDragIndex.value].x_pos = xpos;
  selectedDragImg.value[selectedDragIndex.value].y_pos = ypos;

  width = width * widthDiff.value;
  height = height * heightDiff.value;

  selectedDragImg.value[selectedDragIndex.value].width = width;
  selectedDragImg.value[selectedDragIndex.value].height = height;
};

const skip = () => {
  imgIndex.value = imgIndex.value + 1;

  if (imgIndex.value >= decCharCounts.value) {
    fetchDecChars();
    imgIndex.value = 0;
  }
};

const onDrag = (transform: any, index: any) => {
  selectedDragIndex.value = index;
  const el = getSelectedItem.value;
  el.style.transform = transform.transform;

  x.value = transform.translate[0];
  y.value = transform.translate[1];

  // const document = useDocument();
  // const ocBorderElement = document.value?.getElementById(`ocBorder`) as any;
  // const ocBorderRect = ocBorderElement.getBoundingClientRect();

  // const minX = -50;
  // const minY = -50;
  // const maxX = ocBorderRect.width;

  // const maxY = ocBorderRect.height;

  // if (transform.translate[0] < minX) transform.translate[0] = minX;
  // if (transform.translate[1] < minY) transform.translate[1] = minY;
  // if (transform.translate[0] > maxX) transform.translate[0] = maxX;
  // if (transform.translate[1] > maxY) transform.translate[1] = maxY;

  // x.value = transform.translate[0];
  // y.value = transform.translate[1];
  // el.style.transform = `translate(${transform.translate[0]}px, ${transform.translate[1]}px) rotate(${rotation.value}deg)`;

  let xpos = x.value;
  let ypos = y.value;

  xpos = Math.floor(xpos * widthDiff.value);
  ypos = Math.floor(ypos * heightDiff.value);

  selectedDragImg.value[selectedDragIndex.value].x_pos = xpos;
  selectedDragImg.value[selectedDragIndex.value].y_pos = ypos;
};

const onRotate = (transform: any, index: any) => {
  selectedDragIndex.value = index;
  const el = getSelectedItem.value;
  const rotate = (transform.rotate %= 360);
  rotation.value = rotate;
  el.style.transform = transform.transform;

  // x.value = transform.translate[0];
  // y.value = transform.translate[1];
  // el.style.transform = `translate(${x.value}px, ${y.value}px) rotate(${rotation.value}deg)`;
  selectedDragImg.value[selectedDragIndex.value].rotation = rotate;
};

const fetchOcStickers = (direction: any) => {
  if (direction === -1 && currentPage.value > 1) {
    offsetValue.value -= pageSize.value;
    currentPage.value -= 1;
  } else if (direction === 1 && !!nextStickersPage.value) {
    offsetValue.value += pageSize.value;
    currentPage.value += 1;
  }

  if (direction === 1 && offsetValue.value >= allStickers.value.length) {
    fetchOCDecorationStickers(currentTab.value, nextStickersPage.value, isMobSmallScreen.value ? 10 : 25);
  }
};

const fetchSubmissions = async () => {
  const resp1 = await getFanWorks('submitter', {
    type: 'decoration',
    page: 1,
    page_size: 20,
  });

  myOcsub.value = resp1.results;
  myOCCount.value = resp1.count;
  totalCharCount.value = resp1.total_count;
  pendingOcsub.value = resp1.pending_count;
  if (count.value >= 7) {
    showSubmissions.value = true;
  }
};
const tabSubChanged = ({ value }: Tab) => {
  currentSubTab.value = value;
};

const nextDecorateImage = () => {
  if (count.value >= 7) {
    showSubmissions.value = true;
  }
  if (isDecorated.value) {
    fetchDecChars();
    isDecorated.value = false;
    selectedItem.value = '';
    selectedDragIndex.value = '';
    selectedDragImg.value = [];
    imgIndex.value = 0;
  }
};

const decorateImage = async () => {
  const newArray = selectedDragImg.value.filter(
    (item: any) => !item.markedasDel && !!item.width && !!item.x_pos && !!item.y_pos && !!item.height
  );

  if (!newArray.length) {
    await toast.show('Place atleast one visible sticker.', 'nonative', 'danger');
    return;
  }

  isDecoratingImg.value = true;

  const character = get(decChars.value[imgIndex.value], 'id');
  const chimg = getImg.value;
  const resp = await decorateCharacters(character, chimg, newArray);
  decImg.value = get(resp, 'image.image');

  if (count.value >= 6) {
    await fetchSubmissions();
    const resp2 = await characterEventTime();
    eventTime.value = resp2;
    isDecoratingImg.value = false;
  }
  currentTab.value = currentTab.value ? currentTab.value : 'ocsticker';
  getTabIndex();
  offsetValue.value = 0;
  currentPage.value = 1;
  nextStickersPage.value = '';
  allStickers.value = [];
  isCurrentTabOCSticker.value
    ? await fetchOwnOCDecorationSticker()
    : await fetchOCDecorationStickers(currentTab.value, offsetValue.value, isMobSmallScreen.value ? 10 : 25);

  count.value = count.value + 1;
  isDecorated.value = true;
  isDecoratingImg.value = false;
  loading.value = false;
};

const handleResize = () => {
  windowHeight.value = `${window.innerHeight}px`;
};
onMounted(() => {
  windowHeight.value = `${window.innerHeight}px`;
  window.addEventListener('resize', handleResize);
});
onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<style lang="sass" scoped>
.dark .own-sticker-card
  background: #010123
.own-sticker-card
  padding: 5px
  background: white
  border-radius: 8px
  max-width: 32% !important
  flex: 0 0 32% !important
.purchased-btn
  height: 25px
  font-size: 12px
.sticker-size
  width: 69px
  height: 69px
  object-fit: contain
  @media(max-width:733px) and (min-width:700px)
    width: 67px
  @media(max-width:700px)
    width: 35px
    height: 35px
.dec-img
  width: 370px
  height: 345px
  border-radius: 10px
  @media(min-width:700px) and (max-height:560px)
    height: calc(100vh - 217px)
.arrow-btn
  margin-top: 0px
.img-border

  @media(max-width:700px)

    border-radius: 13px
    border: 3px solid #a29f9f
.row-space
  @media(min-width: 990px)
    gap: 8px
  @media(max-width:990px)
    gap: 5px
  @media(max-width:550px)
    gap: 3px
.no-oc-gutters
  @media(max-width:900px) and (min-width:700px)
    margin-left: -15px
    margin-right: -15px
.oc-sticker-container
  height: 400px
  max-height: 38dvh
  min-height: 38dvh
  overflow: auto
  overflow-x: hidden
  @media(max-width:700px)
    max-height: 9vh !important
    min-height: 9vh !important
.oc-container
  @media(min-width:700px) and (max-height:661px)
    height: calc(v-bind(windowHeight) - 265px) !important
    overflow-x: hidden
    overflow-y: auto
  @media(max-width:700px)
    max-height: 100px

.moveable
  --zoom: 0.5
  ::v-deep
    display: none !important

.skip-btn
  border: 3px solid #e3e3e3
  border-radius: 10px
  text-transform: none
.next-btn
  --border-radius: 10px
.spinner
  width: 80px
  height: 370px

  @media(max-width:700px)
    width: 50px
    height: 100px
.oc-border
  border: 3px solid #e6e6e6
  border-radius: 20px

.oc-decorated
  border: 3px solid #e6e6e6
  border-radius: 20px

  display: flex
  justify-content: center
  flex-direction: column

.oc-decoration
  flex-direction: row
  display: flex
  @media(max-width:700px)
    flex-direction: column-reverse
.btn-part
  margin-top: 5px
.back-btn
  width: 85px
  height: 25px
  --border-radius: 15px
.badge
  background: #e3e3e3
  color: black
  width: 100%
  height: 26px
  display: flex
  align-items: center
  justify-content: center
  border-radius: 15px
.next-btn
  --border-radius: 10px
.char-btn
  height: 30px
  text-transform: none
  --border-radius: 8px
.char-img
  height: 400px
  max-width: 385px

  @media(min-width:885px)
    border: 3px solid #a29f9f
    border-radius: 10px
  @media(max-width:700px)
    max-height: 40vh
    min-height: 10vh
    border-radius: 10px
  @media(max-width:500px)
    max-height: 28vh
    min-height: 10vh
    border-radius: 10px
  @media(max-width:885px) and (min-width:600px)
    max-width: 100%

  @media(min-width:700px) and (max-height:661px)
    height: calc(v-bind(windowHeight) - 228px) !important
    max-width: calc(100% - 2px) !important

  @media(max-width:600px)
    max-width: 100%

.title
  font-weight: bold
  font-size: 20px
.modal
  --width: 900px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
.col-half-offset
  margin-left: 3.166666667%
  @media(max-width:900px) and (min-width: 819px)
    margin-left: 2.78999%
  @media(max-width:819px) and (min-width:767px)
    margin-left: 1.78999%
  @media(max-width:767px) and (min-width:700px)
    margin-left: 0.78999%
  @media(max-width:600px)
    margin-left: 4.166666667%

.inner-content
  background: var(--ion-background-color, #F9F9FF)
  max-height: 95vh
  max-height: 95dvh
  overflow: hidden
  overflow-x: hidden

.stickers-column
  flex: 0 0 20%
  max-width: 20%

.image
  width: 50%
  height: 60vh

.small-image
  height: 30vh
</style>
