<template>
  <ion-modal
    class="modal-with-content"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="close"
  >
    <ion-content class="h-100" v-if="isOpen">
      <ion-button class="clickable-item-hov top-right gray" color="transparent" @click.prevent="close">
        <i class="ti-close close-button" color="medium" />
      </ion-button>
      <h2 class="headers bold">Ownership History</h2>
      <div class="mb-2 mt-2">
        <div v-for="entry in ownershipHistory" class="pt-3 mx-3">
          <ion-badge color="tertiary">{{ formatDateTime(entry?.date) }}</ion-badge>
          <div v-if="entry.type === 'transferred'" class="history-card">
            Transferred from
            <router-link target="_blank" :disabled="!entry?.user" :to="{ path: `/profile/${entry?.user}` }"
              >@{{ entry?.user || '[deleted]' }}</router-link
            >
            to
            <router-link target="_blank" :disabled="!entry?.user_to" :to="{ path: `/profile/${entry?.user_to}` }"
              >@{{ entry?.user_to || '[deleted]' }}</router-link
            >.
            <pre class="my-1">Notes, terms, conditions:<br>{{ entry?.note }}</pre>
          </div>
          <div v-else-if="entry?.type === 'credited'" class="history-card">
            @{{ entry?.user }} listed the original creator as: <br /><span
              v-html="linkifyAndTagUsers(entry?.note)"
            ></span>
          </div>
          <div v-else-if="entry?.type === 'published'" class="history-card">
            @{{ entry?.user }} published the character on CharacterHub
            <span v-if="entry?.note !== '-'">
              <br />Listed the original creator as: <br /><a v-html="linkifyAndTagUsers(entry?.note)"></a>
            </span>
          </div>
        </div>
        <div v-if="ownershipHistory?.length" class="text-center mt-3">
          <ion-button class="bottom-close-button clickable-item-hov" color="primary" @click.prevent="close">
            <span class="no-select">Close</span>
          </ion-button>
        </div>
        <div v-if="!ownershipHistory?.length" class="text-center">
          <span>No recorded changes</span>
        </div>
      </div>
    </ion-content>
  </ion-modal>
</template>

<script lang="ts" setup>
import { Character } from '@/shared/types/static-types';
import { formatDateTime } from '@/shared/utils/dateTime';
import { characterProfileStore } from '@/shared/pinia-store/character-profile';
import { linkifyAndTagUsers } from '@/shared/utils/string';
const { ownershipHistory } = characterProfileStore();

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
  character: {
    type: Object as PropType<Character>,
  },
});

const emits = defineEmits(['close']);
const character = toRef(props, 'character');
const isOpen = toRef(props, 'isOpen');
const close = () => {
  emits('close');
};
</script>

<style lang="sass" scoped>
.close-button
  color: gray
.headers
  text-align: center
.title
  font-weight: bold
  font-size: 22px
.top-right
  position: absolute
  top: 0.5rem
  right: 0.5rem
a
  cursor: pointer !important
  &:hover
    opacity: 0.7 !important
.history-card
  background: #EAECF6
  padding: 0.5rem
  border-radius: 6px
  margin-left: 0
.dark
  .history-card
    background: #1C1C1C
.bottom-close-button
  width: 100px
</style>
