<template>
  <div :class="{ 'clickable-item-hov': isQuestModal }">
    <router-link :to="{ name: 'event-task', params: { id: task?.id } }" :class="{ 'quest-clickable': isQuestModal }">
      <div
        class="card card-dark my-2 p-3"
        style="border-radius: 12px"
        :class="selectedTask && isQuestModal ? 'active' : 'not-active'"
      >
        <div class="mb-4 text-primary">
          {{ task?.name }}

          <!-- <ion-badge v-if="hasUserSubmitted(task)" class="ml-1 completed" color="tertiary" title="Done!"
            ><i class="ti-check"
          /></ion-badge> -->
        </div>

        <div class="d-flex text-black">
          <ion-button class="end-btn" style="--background: #5bd502" v-if="hasUserSubmitted(task)">
            <i class="ti-check mr-1" />
            Done
          </ion-button>
          <ion-button
            v-if="!hasUserSubmitted(task) && (task.starts_at || task.ends_at)"
            class="end-btn m-0"
            :class="{ 'text-font': showRefreshTimer(task) }"
            :color="dateHasPassed(task.starts_at) ? (showRefreshTimer(task) ? 'tertiary' : 'primary') : 'medium'"
          >
            <i class="ti-time icon" />
            <div v-if="dateHasPassed(task.ends_at)">Ended</div>
            <div v-else-if="dateHasPassed(task.starts_at)">
              <vue-countdown
                v-if="computedEndsAt()"
                v-slot="{ days, hours, minutes, seconds }"
                :time="formatFromNowInMilliseconds(computedEndsAt())"
              >
                <span
                  ><span v-if="showRefreshTimer(task)">Restarts in </span><span v-else>Ends in </span
                  ><span v-if="days">{{ days }}d </span><span v-if="hours">{{ hours }}h </span
                  ><span v-if="!days">{{ minutes }}m </span><span v-if="!days && !hours">{{ seconds }}s</span></span
                >
              </vue-countdown>
            </div>

            <div v-else>
              <!-- Upcoming -->
              <vue-countdown
                v-if="task?.starts_at"
                v-slot="{ days, hours, minutes, seconds }"
                :time="formatFromNowInMilliseconds(task.starts_at)"
              >
                <span
                  >Starts in <span v-if="days">{{ days }}d </span><span v-if="hours">{{ hours }}h </span
                  ><span v-if="!days">{{ minutes }}m </span><span v-if="!days && !hours">{{ seconds }}s</span></span
                >
              </vue-countdown>
            </div>
          </ion-button>

          <div class="ml-auto">
            <ion-button v-if="isInteractiveTask()" color="secondary" class="end-btn m-0 ml-2" fill="outline">{{
              hasUserSubmitted(task) ? 'View Character' : 'Submit Character'
            }}</ion-button>
            <ion-button
              v-if="task.response_type === 'T' && isInteractiveTask() && requiredTemplateInputs().userResponseCount"
              color="secondary"
              class="end-btn m-0 ml-2"
              fill="outline"
              >{{ hasUserSubmitted(task) ? 'View Text' : 'Submit Text' }}</ion-button
            >
            <ion-button
              v-else-if="
                task.response_type === 'A' ||
                (task.response_type === 'T' && !isInteractiveTask()) ||
                (task.response_type === 'C' && !isInteractiveTask()) ||
                task.response_type === 'I'
              "
              color="secondary"
              class="end-btn m-0 ml-2"
              fill="outline"
            >
              {{
                task.response_type === 'A'
                  ? 'Take Action'
                  : task.response_type === 'T' && !isInteractiveTask()
                  ? hasUserSubmitted(task)
                    ? 'View Text'
                    : 'Submit Text'
                  : task.response_type === 'C' && !isInteractiveTask()
                  ? hasUserSubmitted(task)
                    ? 'View Character'
                    : 'Submit Character'
                  : task.response_type === 'I'
                  ? 'Upload Art'
                  : ''
              }}
            </ion-button>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script lang="ts" setup>
import { dateHasPassed, formatFromNowInMilliseconds } from '@/shared/utils/dateTime';
import { SiteEventTask } from '@/shared/types/static-types';

const props = defineProps({
  task: {
    type: Object,
    default: {} as SiteEventTask,
  },
  hideHasSubmitted: {
    type: Boolean,
    default: false,
  },
  isQuestModal: {
    type: Boolean,
    default: false,
  },
  selectedTask: {
    type: Boolean,
    default: false,
  },
});

const task = toRef(props, 'task');
const hideHasSubmitted = toRef(props, 'hideHasSubmitted');
const emits = defineEmits(['update:activeIndex', 'tab-changed']);

const computeEndDate = () => {
  const nowDate = new Date();
  if ((task.value.action_url || '').includes('?quest=oc')) {
    const nowHours = nowDate.getHours();
    const nowMins = nowDate.getMinutes();

    const startDate = new Date(task.value.starts_at);
    const startHours = startDate.getHours();
    const startMins = startDate.getMinutes();

    let totalHours;

    if (nowHours < startHours) {
      totalHours = nowHours + (24 - startHours);
    } else {
      totalHours = nowHours - startHours;
    }

    const totalMinutes = nowMins - startMins + 60;

    const remainingHours = 24 - totalHours;
    const remainingMins = 60 - totalMinutes;

    nowDate.setHours(nowHours + remainingHours);
    nowDate.setMinutes(nowMins + remainingMins);

    return nowDate.toISOString();
  } else {
    return nowDate.toISOString();
  }
};

const hasUserSubmitted = (task: SiteEventTask) => {
  if (hideHasSubmitted.value) return false;
  return task.user_submission_count && task.user_submission_count > 0;
};

const showRefreshTimer = (task: SiteEventTask) => {
  return task.user_submission_count && task.will_refresh;
};

const computedEndsAt = () => {
  return (task.value.action_url || '').includes('?quest=oc') ? computeEndDate() : task.value.ends_at;
};

const requiredTemplateInputs = () => {
  let userResponseCount = 0;
  let characterCount = 0;
  // get number of user responses required
  task.value.required_template_fields?.forEach((field: string) => {
    if (field.startsWith('user_response')) {
      userResponseCount++;
    }
  });
  // get higher character number (equal to the number of characters required)
  task.value.required_template_fields?.forEach((field: any) => {
    const match = field.match(/character(\d+)_/);
    if (match) {
      const [, num] = match;
      try {
        characterCount = Math.max(characterCount, parseInt(num));
      } catch (e) {}
    }
  });
  return { userResponseCount, characterCount };
};

const isInteractiveTask = () => {
  return requiredTemplateInputs().userResponseCount || requiredTemplateInputs().characterCount;
};
</script>

<style scoped lang="sass">
.active
  border: 4px solid var(--ion-color-primary)
.not-active
  border: none !important
.quest-clickable
  pointer-events: none
.completed
  position: relative
  top: 3px
.dark .card-dark
  background: #17074C !important
.icon
  margin-right: 0.5rem
  @media(max-width:419px)
    font-size: 9px
    margin-left: 0.25rem !important
    margin-right: 0.25rem !important

.end-btn
  height: 23px
  --border-radius: 20px
  text-transform: none
  font-size: 11px
</style>
