<template>
  <ion-page class="page">
    <TopBar />
    <h1 class="reset-pass-title text-center">Blocked Users</h1>
    <div v-if="loading" class="d-flex justify-content-center mt-3">
      <ChLoading size="sm" class="spinner" />
    </div>
    <i v-else-if="!get(blockedEntities, 'length')" class="mt-3 text-center">You haven't blocked any users yet.</i>
    <div
      v-for="blockedEntity in blockedEntities"
      v-else
      :key="blockedEntity?.id"
      class="character-card-small d-flex p-2 mb-2"
    >
      <img loading="lazy" v-image src="/images/user.jpg" />
      <div class="rest-info ml-3 flex-grow-1 d-flex align-items-center justify-content-start">
        <div>
          <div class="name">
            {{ blockedEntity.blocked_object?.username }}
          </div>
          <div class="description">
            <a
              href="#"
              class="clickable-item-hov"
              @click.prevent="unblockUser(blockedEntity.blocked_object, blockedEntity.id)"
              >Unblock</a
            >
          </div>
        </div>
      </div>
    </div>
  </ion-page>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-property-decorator';
import { alertController } from '@ionic/vue';
import { toast } from '@/shared/native';
import IonCustomButton from '@/shared/components/ion-custom-button.vue';
import IonPasswordInput from '@/shared/components/ion-password-input.vue';
import TopBar from '@/shared/components/TopBar.vue';
import { getBlockedEntities, unblockEntity } from '@/shared/actions/blockedEntities';

@Options({
  name: 'BlockedUsersPage',
  components: { IonCustomButton, IonPasswordInput, TopBar },
})
export default class ResetPasswordPage extends Vue {
  public blockedEntities = [];
  public loading = false;
  public isUnblocking = false;

  get = get;

  public async fetchBlockedUsers() {
    this.loading = true;

    try {
      this.blockedEntities = await getBlockedEntities();
    } catch (e) {
      toast.show('Some error has occurred. Please try again later.', 'nonative', 'danger');
    }
    this.loading = false;
  }

  public async unblockUser(user: any, blockId: string) {
    if (this.isUnblocking) return;

    this.isUnblocking = true;

    try {
      const alert = await alertController.create({
        cssClass: '',
        header: 'Are you sure?',
        message: `Please confirm that you want to unblock <strong>${user.username}</strong>.`,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'text-secondary',
            id: 'cancel-button',
          },
          {
            text: 'Confirm',
            id: 'confirm-button',
            role: 'ok',
            cssClass: 'text-secondary',
          },
        ],
      });
      await alert.present();

      const { role } = await alert.onDidDismiss();

      if (role === 'ok') {
        await unblockEntity(blockId);
        toast.show(`${user.username} has been unblocked.`, 'nonative', 'success');
        this.fetchBlockedUsers();
      }
    } catch (err) {
      toast.show('Some error has occurred. Please try again later.', 'nonative', 'danger');
    }

    this.isUnblocking = false;
  }

  mounted() {
    this.fetchBlockedUsers();
  }
}
</script>

<style scoped lang="sass">
.page
  flex-direction: column
  padding: 20px

.reset-pass-title
  font-size: 26px

.character-card-small
  img
    width: 60px
    height: 60px
    border-radius: 40px
    border: solid gray 0.1px
    object-fit: cover
  .name
    font-size: 18px
    font-weight: bold
  .description
    font-size: 13px
</style>
