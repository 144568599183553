<template>
  <div class="ss-chat-textbox">
    <div v-if="isReplying">
      <ion-card class="my-0 w-100 h-100 reply py-2">
        <div class="content">
          <div class="info-content mx-4 px-3 py-3">
            <div class="title">
              Replying to <strong> {{ replyname }}</strong>
            </div>
            <div class="sub-title mt-2 text-black">
              <img
                loading="lazy"
                v-if="replyImgUrl"
                v-image
                class="reply-img"
                :src="resizeUpload(replyImgUrl, '80x80')"
              />
              <template v-else>{{ truncateText(stripHtmlTags(replytext), 75) }}</template>
            </div>
          </div>
        </div>

        <ion-button
          class="position-absolute one-button left"
          style="--background: #eaecf600; color: black"
          @click="close"
        >
          <ion-icon :icon="closeOutline" />
        </ion-button>
      </ion-card>
    </div>

    <div v-if="image && image.length">
      <ion-card class="w-100 h-100 reply py-2">
        <div class="content">
          <div class="info-content mx-4 px-3">
            <div class="visuals w-100" style="top: 10px !important">
              <div class="d-flex visuals-list w-100 h-100">
                <scroller :scroll-size="300" :threshold="20">
                  <div class="d-flex">
                    <template v-for="(img, index) of image" :key="index">
                      <div class="visual-card ml-0 my-2 mr-3">
                        <div class="one-visual position-relative">
                          <img loading="lazy" v-image class="visual-img" :src="resizeUpload(img, '190h')" />
                          <ion-button
                            color="transparent"
                            class="float-circle-btn clickable-item-hov"
                            @click.prevent="removeImage(index)"
                          >
                            <ion-icon :icon="closeOutline"></ion-icon>
                          </ion-button>
                        </div>
                      </div>
                    </template>
                  </div>
                </scroller>
              </div>
            </div>
          </div>
        </div>
      </ion-card>
    </div>

    <div class="d-flex chatroom-textbox">
      <ion-card class="px-2 chatroom-text-card mx-0 my-0" :class="{ open: keyboardOpen }">
        <!-- <ion-card class="reply-card px-3">d</ion-card> -->
        <div class="d-flex align-items-center">
          <ion-button color="transparent" class="image-btn mx-0 clickable-item-hov" @click="changeImagePopover">
            <ion-icon class="icon-image" :icon="imageOutline" />
          </ion-button>

          <ion-card class="chat-message" :class="{ 'my-2': !isMobSmallScreen }">
            <div class="d-flex mx-2 align-items-center" style="height: 50px !important">
              <!-- <ion-icon class="icon" v-if="!selectedName" :icon="happyOutline" @click.prevent="openCommentList" /> -->
              <div class="positon-relative" @click.prevent="openCommentList">
                <ProfileRing
                  :image="
                    lastCommentAs.type === 'user'
                      ? get(lastCommentAs.item, 'customize_profile.profile_ring.image')
                      : get(lastCommentAs.item, 'profile_ring.image')
                  "
                  :borderWidth="30"
                  :ringTop="-3"
                  :ringLeft="-4"
                  class="position-absolute"
                />
                <img class="img clickable-item-hov" :src="resizeUpload(selectedName, '80x80')" />
              </div>
              <ion-textarea
                ref="chatTextBox"
                v-model="msgText"
                placeholder="Aa"
                :class="isMobSmallScreen ? 'mx-2' : ''"
                class="chat-box"
                style="--padding-top: 17px; --padding-bottom: 0px; height: 30px; margin-top: -24px"
                :disabled="isPosting || !isAuthenticated"
                required
                @keyup.enter="onEnterPost"
                @ionFocus="handleFocus"
              />
            </div>
          </ion-card>

          <div class="mx-2">
            <ion-button
              class="btn clickable-item-hov"
              :disabled="isPosting || !isAuthenticated || (isEmpty(image) && !trim(msgText))"
              :color="postSuccess ? 'success' : 'primary'"
              @click.prevent="onPost"
            >
              <ion-icon :icon="sendOutline" />
            </ion-button>
          </div>
        </div>
      </ion-card>

      <comment-as-select-modal
        :is-open="openCommentAsModal"
        modal-title="Message As"
        :include-unlisted="chatroom?.privacy === 'private' || ['M', 'U'].includes(chatroom?.world?.privacy)"
        @dismiss-modal="closeCommentAsModal"
        @item-selected="selectCommentAs"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { closeOutline, imageOutline, sendOutline } from 'ionicons/icons';
import ImagePopover from '../popovers/ImagePopover.vue';
import CommentAsSelectModal from '@/shared/modals/CommentAsSelectModal.vue';
import { stripHtmlTags } from '@/shared/utils/string';
import { socialSpaceChatroomMessage } from '@/shared/actions/socialSpaceChatroom';
import { toast, popovers } from '@/shared/native';
import constants, { ERROR_UNLISTED_CHARACTER_IN_PUBLIC_CHAT } from '@/shared/statics/constants';
import { authStore } from '@/shared/pinia-store/auth';
import { resizeUpload } from '@/shared/utils/upload';
import { truncateText } from '@/shared/utils/string';
import ProfileRing from '@/shared/components/ProfileRing.vue';
import { mainStore } from '~/shared/pinia-store/main';
const { user, isAuthenticated, lastCommentAs } = authStore();
const { keyboardOpen } = mainStore();

const props = defineProps({
  chatroom: { type: Object, required: true },
  isReplying: { type: Boolean },
  replyid: { type: String },
  replyname: { type: String },
  replytext: { type: String, default: '' },
  replyImgUrl: { type: String, default: '' },
  status: { type: Object },
});

const handleFocus = () => {
  const el = document.querySelector('.chatroom-textbox');

  if (el) {
    setTimeout(() => {
      window.scrollBy(0, -999);
    }, 300);
  }
};

const msgText = ref('');
const isPosting = ref(false);
const postSuccess = ref(false);
const image = ref([]);
const chatTextBox: Ref = ref(null);
const selectedName = ref('');
const openCommentAsModal = ref(false);
const { updateLastCommentAs } = authStore();
const openCommentList = () => {
  openCommentAsModal.value = true;
};

const reply = toRef(props, 'isReplying');
const replyid = toRef(props, 'replyid');

const route = useRoute();
const emit = defineEmits(['send', 'done', 'close', 'selected', 'added', 'remove']);

const closeCommentAsModal = () => {
  openCommentAsModal.value = false;
};

const selectCommentAs = (data: { id: string; type: string; item: {} }) => {
  updateLastCommentAs({
    lastCommentAs: data,
    lastCommentAsName:
      data.type === constants.commentedAsTypes.USER
        ? get(data.item, 'username', 'Unknown')
        : get(data.item, 'info.name', 'Unknown'),
  });

  selectedName.value =
    lastCommentAs.value.type === 'user'
      ? get(lastCommentAs.value.item, 'profile_picture_url', '/images/user.jpg')
      : get(lastCommentAs.value.item, 'info.cropProfilePicture', '/empty.png');

  if (!selectedName.value) selectedName.value = lastCommentAs.value.type === 'user' ? '/images/user.jpg' : '/empty.png';
  emit('selected', lastCommentAs.value.id);
};

const close = () => {
  emit('close');
};

const onPost = async () => {
  if (isEmpty(image.value) && !trim(msgText.value)) return;
  isPosting.value = true;
  await postData();
};

const postData = async () => {
  try {
    const data = {
      text: trim(msgText.value),
      image_urls: image.value,
      ...(replyid.value && replyid.value.length && reply.value && { reply_to: replyid.value }),
      ...(lastCommentAs.value!.type === 'character' && { character: lastCommentAs.value!.id }),
    };
    await socialSpaceChatroomMessage(route.params.id as string, data);

    // Use this if you want to show a success
    // postSuccess.value = true;
    // setTimeout(() => (postSuccess.value = false), 1000);

    emit('done');
  } catch (e: any) {
    if (e?.response?.data === ERROR_UNLISTED_CHARACTER_IN_PUBLIC_CHAT) {
      selectCommentAs({
        id: user.value.id,
        type: constants.commentedAsTypes.USER,
        item: cloneDeep(user.value),
      });
      toast.show(e.response.data, 'nonative', 'danger', 8000, 'top');
      isPosting.value = false;
      return;
    }
    toast.show('Cannot send message. Please try again.', 'nonative', 'danger', undefined, 'top');
    return;
  } finally {
    isPosting.value = false;
  }
  msgText.value = '';
  image.value = [];
  emit('send');
};

const onEnterPost = async (event: KeyboardEvent) => {
  if (event.key === 'Enter' && !event.shiftKey && !isMobSmallScreen.value) {
    if (isEmpty(image.value) && !trim(msgText.value)) return;

    isPosting.value = true;
    await postData();
    msgText.value = '';
    image.value = [];
    isPosting.value = false;
    setTimeout(() => {
      chatTextBox.value.$el.setFocus();
    }, 10);
    if (reply.value) emit('send');
  }
};

const changeImagePopover = async (ev: CustomEvent) => {
  const popover = await popovers.open(ev, ImagePopover, { cover: image.value }, 'create-popover');
  const { data } = await popover.onDidDismiss();
  if (!isEmpty(get(data, 'value'))) {
    const newImage = data.value;
    image.value = image.value.concat(newImage);
    if (!isEmpty(image.value)) emit('added', image.value);
  }
};

const removeImage = (index: number) => {
  image.value.splice(index, 1);

  emit('remove', image.value);
};
const { width: windowWidth } = useWindowSize();

const isMobSmallScreen = computed(() => {
  return windowWidth.value <= 500;
});

onBeforeMount(() => {
  if (!lastCommentAs.value) {
    const { updateLastCommentAs } = authStore();
    updateLastCommentAs({
      lastCommentAs: { id: user.value.id, type: constants.commentedAsTypes.USER, item: user.value },
      lastCommentAsName: user.value.username,
    });
  }
  emit('selected', lastCommentAs.value.id);
  let profilePictureUrl = get(lastCommentAs.value.item, 'profile_picture_url', '');
  if (!profilePictureUrl) {
    profilePictureUrl = user.value.profile_picture_url || '/images/user.jpg';
  }
  selectedName.value =
    lastCommentAs.value.type === 'user'
      ? profilePictureUrl
      : get(lastCommentAs.value.item, 'info.cropProfilePicture', '/empty.png');
  if (!selectedName.value) selectedName.value = '/images/user.jpg';
});
</script>

<style lang="sass" scoped>
.set-margin
  margin-top: -7px !important
.visuals
  overflow-x: auto
  .one-visual
    img
      min-width: 115px
      width: 121px !important
      height: 80px !important
      border-radius: 10px
      border: solid gray 0.1px
      object-fit: cover
    .name
      font-size: 18px
      font-weight: bold
.visuals-list
  overflow-x: auto
.visuals-list::-webkit-scrollbar
  display: none
.visuals-list
  -ms-overflow-style: none
  scrollbar-width: none
.visual-card
  overflow: unset
  width: 119px !important

.img
  width: 24px
  height: 24px
  border-radius: 20px
  border: solid gray 0.1px
  object-fit: cover
  // margin-top: 11px
.chat-box
  color: black
  pointer-events: auto
  margin: 0 !important
  ::v-deep
    textarea
      margin-left: 10px !important
    .textarea-highlight
      display: none !important
.dark .chat-box
  color: white
.float-circle-btn
  background-color: rgba(0,0,0,0.5)
  --border-radius: 100% !important
  border-radius: 100% !important
  width: 21px
  height: 21px
  --padding: 0
  --padding-start: 0
  --padding-end: 0
  right: 0px
  top: 0px
  position: absolute
  z-index: 1

.visuals
  .visual-content
    flex-wrap: wrap

  .visual
    background-size: cover
    width: 80px
    height: 80px

.visual-image
  object-fit: cover

.reply-img
  width: 70px
  height: 50px
  object-fit: cover
  border-radius: 10px
  border: solid gray 0.1px

.reply-card
  height: calc(100% - 68px)
  background: transparent
  margin-bottom: -8px

.btn
  --border-radius: 20px
  --align-items: center
  width: 30px
  height: 30px
  color: #FFF
  --padding-start: 0
  --padding-end: 0
  font-size: 12px

.one-button
  width: 25px
  height: 25px
  color: #FFF
  --padding-start: 0
  --padding-end: 0
  border-radius: 13px
  font-size: 12px
  right: 30px
  top: 5px
  position: absolute
  pointer-events: all

.content
  .info-content
    font-size: 14px
    background: #eaecf680
    border-radius: 7px
.reply
  background: #c6c8d6
  border-radius: 0
  margin-top: 0px
  margin-left: 0px
  margin-bottom: 0px !important
  @media (max-width: 570px)
    width: calc( 100% + 1rem ) !important
    margin-left: -0.5rem !important
    margin-right: -0.5rem !important

.icon
  font-size: 24px
  font-weight: bold

.icon-image
  font-size: 24px
  font-weight: bold
.image-btn
  color: #737373

.chat-message
  overflow: auto
  margin-inline: 4px
  border-radius: 18px
  background: #eaecf6
  width: calc(100% - 120px)
  margin-top: 8px !important
  margin-bottom: 8px !important
  // @media(max-width:570px)
  //   margin-top: 0.5rem !important
  //   margin-bottom: 1rem !important

.dark .chat-message
  background: #40466b !important
.chatroom-text-card
  border-radius: 0
  background: #c6c8d6
  height: 65%
  padding-bottom: calc(var(--safe-ios-margin) / 3)
  &.open
    padding-bottom: 0 !important
  @media(min-width: 420px)
    min-width: 100% !important
    max-width: 28% !important
  @media(max-width: 419px)
    min-width: 100% !important
    max-width: 50% !important
.chatroom-textbox
  overflow-x: auto
  overflow: auto
  @media (max-width: 570px)
    width: calc( 100% + 1rem )
    margin-left: -0.5rem !important
    margin-right: -0.5rem !important

.chatroom-textbox::-webkit-scrollbar
  display: none
.chatroom-textbox
  -ms-overflow-style: none
  scrollbar-width: none

.ss-chat-textbox
  @media(max-width:570px)
    position: sticky
    bottom: 0px
    width: 100%
</style>
