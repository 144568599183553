<template>
  <ion-page class="page scrollable character-editor-page">
    <div class="editor">
      <Transition>
        <div v-if="character" class="pb-3">
          <div class="d-flex justify-content-between align-items-center mt-2">
            <div class="title">{{ title }}</div>
            <div />
            <div
              class="onboarding-small-text clickable-item"
              v-if="(currentStep === 18 || currentStep === 19) && ongoingOnboarding"
              @click="action"
            >
              I'll do it later!
            </div>
          </div>
          <Header class="mt-4" />
          <KeyInfo v-if="!isTemplate" class="one-section" />
          <TemplateKeyInfo v-else class="one-section" :class="{ 'free-space': id }" />
          <Sections @mounted="onSectionMounted" />
          <AddToProfile />
          <AddSection class="one-section mt-3" />
          <Settings class="mt-4" />
          <Actions class="mt-3" @done="markIsDone" />
        </div>
        <HeaderLoading v-else-if="loading" />
      </Transition>
    </div>
  </ion-page>
</template>

<script lang="ts" setup>
import HeaderLoading from './components/HeaderLoading.vue';
import TemplateKeyInfo from './components/TemplateKeyInfo.vue';
import AddSection from './components/AddSection.vue';
import AddToProfile from './components/AddToProfile.vue';
import Actions from './components/Actions.vue';
import Settings from './components/Settings.vue';
import { characterEditorStore } from '@/shared/pinia-store/character-editor';
import { getElementScrollPosition } from '@/shared/utils/ui';
import { onboardingStore } from '~/shared/pinia-store/onboarding';
import { authStore } from '~/shared/pinia-store/auth';
const Header = defineAsyncComponent(() => import('./components/Header.vue'));
const KeyInfo = defineAsyncComponent(() => import('./components/KeyInfo.vue'));
const Sections = defineAsyncComponent(() => import('./components/Sections.vue'));
const { character, loadEditor, isTemplate, loading, setIsDone, clearAll } = characterEditorStore();
const hasScrolled = ref(false);
const { challengeEndsAt } = useOnboardingChallengeTimer();
const { updateFirstCharacterCreationChallenge, userCharacters } = authStore();
const { currentStep, ongoingOnboarding, stepForward } = onboardingStore();
const route = useRoute();
const challengeEndTime: any = ref(null);
const router = useRouter();
const id = computed(() => {
  const { id } = route.params;
  if (character.value?.id) return character.value.id;
  if (Array.isArray(id)) return '';
  return id;
});

const markIsDone = () => {
  setIsDone(true);
};

const title = computed(() => {
  const initialWord = id.value ? 'Edit' : 'Create';

  if (isTemplate.value) {
    return `${initialWord} character template`;
  }

  return `${initialWord} your Character`;
});

const goToImages = async () => {
  if (hasScrolled.value) return;
  await nextTick();
  const document = useDocument();
  const router = useRouter();
  const { isDesktopSize } = useWindowSize();

  const goToImages = !!router.currentRoute.value.query.images;
  if (goToImages) {
    const imagesSection = document.value.querySelector('.inline-gallery') as HTMLElement;
    const scrollPosition = getElementScrollPosition(imagesSection);
    if (!imagesSection) return;

    if (isDesktopSize.value) {
      const scroller = document.value.querySelector('.layout-scroll-area') as HTMLElement;
      if (scroller) {
        scroller.scrollTo({
          top: scrollPosition - 100,
          behavior: 'smooth',
        });
      }
    } else {
      // Find nearest ion-content by traversing up from the images section. Or try fallback
      const content =
        (imagesSection.closest('ion-content') as HTMLIonContentElement) ||
        (document.value?.querySelector('.layout-scroll-area ion-content') as HTMLIonContentElement);
      try {
        await content?.scrollToPoint(0, scrollPosition - 100, 500);
      } catch (e) {}
    }
    hasScrolled.value = true;
  }
};

const action = () => {
  stepForward();
  if (challengeEndTime.value) updateFirstCharacterCreationChallenge(true);
  router.push({ name: 'home' });
};

onMounted(async () => {
  const routeName = route.name as string;
  const presetId = route.query.presetId as string;
  const templateCharId = route.query.templateCharId as string;

  const index = userCharacters.value.findIndex((char) => char.id === templateCharId);

  if (index === -1 && !isEmpty(templateCharId)) {
    router.push({ name: 'home' });
    return;
  }
  try {
    clearAll();
    await loadEditor(id.value, routeName, presetId, templateCharId);
    challengeEndTime.value = challengeEndsAt();

    setTimeout(() => {
      challengeEndTime.value = 0;
    }, challengeEndTime.value);
  } catch (e) {
    setIsDone(true); // don't trap the user if the editor failed to load
  }
});

const onSectionMounted = (key: string) => {
  if (key === 'character_gallery') goToImages();
};

onBeforeRouteLeave((_, __, next) => {
  const { isDone } = characterEditorStore();
  if (currentStep.value === 20) {
    clearAll();
    return next();
  }
  if (isDone?.value) {
    clearAll();
    return next();
  }
  if (!confirm('Are you sure you want to leave? You may lose unsaved changes.')) {
    return;
  }
  clearAll();
  next();
});
</script>

<style lang="sass" scoped>
.onboarding-small-text
  font-size: 12px
  color: #ae38e5
  text-decoration: underline
.editor
  @media(min-width: 1330px)
    max-width: 750px
    min-width: 750px
    margin: 0 auto
  @media(min-width: 780px) and (max-width: 1184px)
    max-width: 750px
    min-width: 750px
    margin: 0 auto
.character-editor-page
  padding-top: 24px !important
  padding-bottom: 24px !important
  ::v-deep
    .reorder-selected
      border-radius: 10px !important
  .title
    color: #4D4D4D
    font-size: 40px
    font-weight: bold
  .one-section
    margin-top: 25px

@media(max-width: 500px)
  .title
    font-size: 30px !important
</style>
