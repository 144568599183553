<template>
  <div>
    <ion-item v-if="!isRoleChat" class="item-container d-flex pointer no-select" @click="dismiss('reply')">
      <div class="menu-item d-flex align-items-center no-select">
        <ion-icon class="mr-1" :icon="arrowUndoOutline" /><span>Reply</span>
      </div>
    </ion-item>

    <ion-item
      v-if="worldStatus.role == 'leader'"
      class="item-container d-flex pointer no-select"
      @click="dismiss('remove-message')"
    >
      <div class="menu-item d-flex align-items-center no-select">
        <ion-icon class="mr-1" :icon="trashOutline" /><span>Remove Message</span>
      </div>
    </ion-item>

    <ion-item
      v-if="worldStatus.role == 'leader'"
      class="item-container d-flex pointer no-select"
      @click="dismiss('remove-member')"
    >
      <div class="menu-item d-flex align-items-center no-select">
        <ion-icon class="mr-1" :icon="logOutOutline" /><span>Remove From Space</span>
      </div>
    </ion-item>

    <ion-item
      v-if="worldStatus.role == 'leader'"
      class="item-container d-flex pointer no-select"
      @click="dismiss('ban-member')"
    >
      <div class="menu-item d-flex align-items-center no-select">
        <ion-icon class="mr-1" :icon="banOutline" /><span>Ban From Space</span>
      </div>
    </ion-item>
    
    <report
      :is-popover="true"
      :report-entity-id="message?.id"
      :report-entity-type="reportEntityType"
      :reported-user="message.user"
      :reported-from="reportedFrom"
      @close="dismiss('')"
    >
      <ion-item class="item-container d-flex pointer no-select">
        <div class="menu-item d-flex align-items-center no-select text-danger">
          <i class="ti-alert mr-1" /><span>Report </span>
        </div>
      </ion-item>
    </report>

  </div>
</template>

<script lang="ts" setup>
import { arrowUndoOutline , trashOutline , logOutOutline , banOutline } from 'ionicons/icons';
import Report from '@/shared/components/Report.vue';
import { OverlayInterface } from '@ionic/core';

const props = defineProps({
  instance: {
    type: Object as PropType<OverlayInterface>,
  },
  message: {
    type: Object,
    default: {},
  },
  isRoleChat: {
    type: Boolean,
    default: false,
  },
  worldStatus: {
    type: Object,
    required: true,
  },
});

const instance = toRef(props, 'instance') as any;
const message = toRef(props, 'message');
const isRoleChat = toRef(props, 'isRoleChat');
const worldStatus = toRef(props, 'worldStatus') as any;

const reportedFrom = computed(() => {
  return isRoleChat.value ? 'rolechat' : 'chatroom';
});

const reportEntityType = computed(() => {
  return isRoleChat.value ? 'rolechatmsg' : 'worldchatmessage';
});
const dismiss = async (value: any) => {
  await instance.value.dismiss({ value });
};
</script>

<style scoped lang="sass">
.pointer
  cursor: pointer

.item-container:hover
  background-color: #eeeeee !important
  div
    background-color: #eeeeee !important
</style>
