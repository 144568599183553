<template>
  <div class="fanart-flare-card">
    <div class="clickable-item-hov">
      <div class="image position-relative d-flex align-items-center justify-content-center">
        <img loading="lazy" v-folder-card-image :src="resizeUpload(get(fanart, 'image.image'), '190h')" />
      </div>

      <div class="rest mt-1">
        <div class="name d-flex mt-1 clickable-item">
          <div class="text-black">By:</div>
          <span class="mx-1">
            <router-link
              v-if="get(fanart, 'submitter.username')"
              :to="{ name: 'profile', params: { username: get(fanart, 'submitter.username') } }"
            >
              @{{ get(fanart, 'submitter.username') }}
            </router-link></span
          >
        </div>
        <div class="description clickable-item">
          <span class="text-black">
            Feat:
            <router-link
              v-if="get(fanart, 'submitted_for.slug')"
              :to="{ name: 'character-profile-new', params: { slug: get(fanart, 'submitted_for.slug') } }"
            >
              {{ get(fanart, 'submitted_for.info.name') }}
            </router-link>
          </span>
        </div>

        <div class="d-flex text-black align-items-center my-1">
          <div class="d-flex align-items-center">{{ get(fanart, 'reaction_counts.total_count') }} votes</div>

          <div />

          <div class="vote-badge d-flex align-items-center justify-content-end ml-auto" @click="action">
            <i class="ti-arrow-up icon mr-1" />
            Vote
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { resizeUpload } from '@/shared/utils/upload';
import { fanArtVoteStore } from '@/shared/pinia-store/fanArtVote';
import { truncateText } from '@/shared/utils/string';
import { mainStore } from '../pinia-store/main';
const props = defineProps({
  fanart: {
    type: Object,
    default: () => ({}),
  },
});
const { react } = fanArtVoteStore();
const fanart = toRef(props, 'fanart');
const { dark } = mainStore();
const userReaction = computed(() => {
  return fanart?.value?.user_reaction;
});

const reactionChanged = async (reaction: string) => {
  await react(reaction, fanart.value);
};
const action = () => {
  reactionChanged('lov');
};

const colorChanged = computed(() => {
  const defaultColor = dark.value ? '#fff' : '#010123';
  return !isEmpty(userReaction.value) ? '#00b4c5 ' : defaultColor;
});
</script>

<style lang="sass" scoped>
.icon
  color: v-bind(colorChanged)
  font-size: 11px
.vote-badge
  color: v-bind(colorChanged) !important
  border: 1px solid v-bind(colorChanged)
  font-weight: bold
  border-radius: 20px
  padding: 2px 7px 2px 7px
.dark .fanart-flare-card
  background: #010123 !important
.fanart-flare-card
  min-width: 187px
  height: 205px
  border-radius: 12px
  box-shadow: 1px 1px 5px 0px rgba(33,65,99,0.19)
  overflow: hidden
  background: #FFF
  .image
    background: #ACBFD4
    height: 134px
    img
      width: 100%
      height: 100%
      object-fit: cover
  .name
    font-size: 12px
    color: #214163
    font-weight: bold
    span
      text-overflow: ellipsis
      width: 100%
      display: -webkit-box
      -webkit-line-clamp: 1
      max-height: 15px
      -webkit-box-orient: vertical
      overflow: hidden
  .name-height
    font-size: 18px !important
    span
      max-height: none !important
      font-size: 18px !important

  .description
    font-size: 10px !important
    color: #214163
    height: 15px
    overflow: hidden
    span
      text-overflow: ellipsis
      width: 100%
      display: -webkit-box
      -webkit-line-clamp: 1
      height: 15px
      -webkit-box-orient: vertical
      overflow: hidden
  .rest
    padding: 0 10px
    *
      font-size: 12px
      @media(max-width: 500px)
        font-size: 9px
</style>
