import { defineStore, storeToRefs } from 'pinia';
import { createReactionOnFanArts,deleteReactionOnFanArts } from '@/shared/actions/reactions';
import { UserReaction, ReactionCommentCounts } from '@/shared/types/static-types';

const isUserReactedSameReaction = (reaction: string, fanart: any): boolean => {
  const { user_reaction } = fanart;
  return !!(user_reaction && user_reaction.id && user_reaction.reaction === reaction);
};

const sendReactionToBackend = async (reaction: string, fanart: any) => {
  const target = 'fanwork';
  const { user_reaction, id: fanartId = '' } = fanart;

  // delete
  if (isUserReactedSameReaction(reaction,fanart)) {
    return await deleteReactionOnFanArts ({ reactionId: user_reaction?.id || '' });
  }

  // post
  const data = await createReactionOnFanArts({ objectId: fanartId, reaction, target });
  return data;
};

const updateReactionsDataForFanArt= (reaction: string, fanart: any, newReaction: UserReaction) => {
  const fanartData = fanart;
  fanartData.reaction_counts = fanartData.reaction_counts || { total_count: 0 };
  const totalCount = fanartData.reaction_counts.total_count;
  let userReaction = fanartData.user_reaction || ({} as UserReaction);
  const prevUserReaction = fanartData.user_reaction as UserReaction;

  // editing counts if un-reacting or editing reaction
  if (fanartData.user_reaction as UserReaction) {
    fanartData.reaction_counts[prevUserReaction.reaction as keyof ReactionCommentCounts]--;
    if (newReaction.reaction in fanartData.reaction_counts) {
      fanartData.reaction_counts[newReaction.reaction as keyof ReactionCommentCounts]++;
    } else {
      // ignore; newReaction.reaction will never be "ordering" (which requires type string[])
      //  @ts-ignore
      fanartData.reaction_counts[newReaction.reaction as keyof ReactionCommentCounts] = 1;
    }
  }
  // if reacting to story for the first time: editing counts & user_reaction
  if (!userReaction.id) {
    fanartData.reaction_counts!.total_count = totalCount + 1;
    if (!fanartData.reaction_counts[newReaction.reaction as keyof ReactionCommentCounts]) {
      //  @ts-ignore
      fanartData.reaction_counts[newReaction.reaction as keyof ReactionCommentCounts] = 1;
    } else {
      //  @ts-ignore
      fanartData.reaction_counts[newReaction.reaction as keyof ReactionCommentCounts] += 1;
    }
    userReaction = newReaction;
  }

  // if un-reacting
  if (isUserReactedSameReaction(reaction,fanartData) && !newReaction) {
    fanartData.reaction_counts!.total_count = totalCount > 0 ? totalCount - 1 : 0;
    fanartData.user_reaction = undefined;
  } else {
    fanartData.user_reaction = {
      ...userReaction,
      reaction,
    };
  }

  const out = {
    user_reaction: fanartData.user_reaction,
    reaction_counts: fanartData.reaction_counts,
  };
  return out;
};

export interface IFanArtVoteStore  {
  fanArtVote: null;
  isLoading: boolean;
}

const useFanArtVote = defineStore('fanArtVote', {
  state: (): IFanArtVoteStore => ({
    fanArtVote: null,
    isLoading: false,
  }),
  actions: {
    async react(reaction: string, fanart: any) {
      const newReaction = await sendReactionToBackend(reaction, fanart);
      const updatedReactionsData = updateReactionsDataForFanArt(reaction, fanart, newReaction);

      return { newReaction, updatedReactionsData };
    },
  },
  getters: {},
});

export const fanArtVoteStore = () => {
  const store = useFanArtVote();
  return {
    ...store,
    ...storeToRefs(store),
  };
};
