<template>
  <ion-page class="page d-flex align-items-center">
    <div class="content-inner mx-auto d-flex flex-column justify-content-center">
      <login-form class="login-form" />
      <div class="rest">
        <login-paragraphs />
      </div>
    </div>
  </ion-page>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-property-decorator';
import LoginParagraphs from './components/LoginParagraphs.vue';
import LoginForm from './components/LoginForm.vue';
import OrSeparator from '@/shared/components/or-separator.vue';
import constants from '@/shared/statics/constants';
import { toast } from '@/shared/native';
import { authStore } from '@/shared/pinia-store/auth';

@Options({
  name: 'LoginPage',
  components: {
    LoginForm,
    LoginParagraphs,
    OrSeparator,
  },
})
export default class LoginPage extends Vue {

  public override async mounted() {
    const { getResetPassTokenInvalid, updateResetPasswordTokenInvalid } = authStore();
    if (getResetPassTokenInvalid.value === constants.forgotPassToastOptions.INVALID_TOKEN) {
      await toast.show('Password reset token does not exist or is invalid!', 'nonative', 'danger');
    }

    updateResetPasswordTokenInvalid(null);
  }
}
</script>

<style scoped lang="sass">
.page
  min-height: unset !important
  padding: 20px
  height: calc(100% - 75px - var(--safe-ios-margin)/3)
  *
    font-size: 0.9rem
  @media(max-height: 500px)
    height: 100%
.rest
  width: 100%
.content-inner
  margin-top: 20px !important
  overflow-y: auto
  display: flex
  align-items: center
  flex-direction: column
  height: 100%
  max-width: 500px
.logo
  height: 50px
.twitter-button, .apple-button
  margin-top: 10px
.login-form
  margin: 90px 0 5px 0
</style>
