<template>
  <div>
    <ion-card
      class="compliment-card m-0 clickable-item-hov"
      @click="actionClicked"
      :class="{ 'disabled-card': get(character.user, 'id') === user.id, 'done-by-me-char': isComplimented }"
    >
      <div class="h-100 w-100 position-relative">
        <div class="comp-bomb">
          <div class="compliment-badge" :style="isComplimented ? 'background:#6ADF32' : ''">
            <inline-svg :src="getComplimentBombSvg" :class="{ 'marked-svg': isComplimented }" />
          </div>
          <div class="character-content">
            <div class="truncate char-name">{{ get(character.info, 'name') }}</div>
          </div>
        </div>

        <img
          loading="lazy"
          v-character-card-image
          class="compliment-img"
          :src="resizeUpload(get(character.info, 'cropProfilePicture'), '190h')"
          :class="{ 'p-1': !get(character.info, 'cropProfilePicture') }"
        />
        <div class="reward-badge">
          <inline-svg height="15" width="15" :src="currency" />
          <span class="ml-1">{{ amount }}</span>
        </div>
        <div cass="d-flex flex-column justify-content-center align-items-center">
          <div style="font-size: 12px">@{{ truncateEasy(get(character.user, 'username'), 12) }}</div>
          <ion-button
            :disabled="isComplimented"
            :class="character.compliment_bombed_type === COMPLIMENT_BOMB ? 'compliment-btn' : 'fanart-flare-btn'"
            >{{ character.compliment_bombed_type === COMPLIMENT_BOMB ? 'COMPLIMENT' : 'DRAW' }}</ion-button
          >
        </div>
      </div>
    </ion-card>
    <compliment-bombs-modal
      :is-open="isOpenFirstComplimentBombModal"
      :socialType="get(character, 'compliment_bombed_type', COMPLIMENT_BOMB)"
      @close="isOpenFirstComplimentBombModal = false"
      @done="closeAndOpenCharacterComplimentModal"
    />
    <character-compliment-bomb-modal
      :is-open="isOpenCharacterComplimentBomb"
      :character="character"
      :dailyLimit="getChDailyLimit"
      :complimentCharacters="complimentCharacters"
      :isCbLoader="isCbLoader"
      :currency="currency"
      :reward="amount"
      @fetch="fetchCbLimit"
      @open="closeAndOpenCBListModal"
      @mission="closeandOpenMissionSuccessfulModal"
      @close="isOpenCharacterComplimentBomb = false"
    />

    <character-compliment-mission-modal
      :is-open="isOpenCBListModal"
      :count="complimentCount"
      :limit="getChDailyLimit"
      :currBombedCharsData="character"
      :isMissionCompleted="isMissionCompleted"
      :isDisplayReward="displayReward"
      :totalComplimentCount="totalComplimentCount"
      :isBonusRewarded="isBonusRewarded"
      :socialItemType="get(character, 'compliment_bombed_type')"
      :cbRewards="cbRewards"
      @close="closeCbListModal"
    />
  </div>
</template>
<script lang="ts" setup>
import ComplimentBombsModal from '@/shared/modals/ComplimentBombsModal.vue';
import CharacterComplimentBombModal from '@/shared/modals/CharacterComplimentBombModal.vue';
import { authStore } from '@/shared/pinia-store/auth';
import { updateUserdata } from '@/shared/actions/users';
import CharacterComplimentMissionModal from '@/shared/modals/CharacterComplimentMissionSuccessfulModal.vue';
import { resizeUpload } from '@/shared/utils/upload';
import { truncateEasy } from '@/shared/utils/string';
import { COMPLIMENT_BOMB, FANART_FLARE } from '../statics/constants';

const isOpenFirstComplimentBombModal = ref(false);
const isOpenCBListModal = ref(false);
const isOpenCharacterComplimentBomb = ref(false);
const props = defineProps({
  character: {
    type: Object,
    default: () => {},
  },
  cbDailyLimit: {
    type: Object,
    default: () => {},
  },
  ffDailyLimit: {
    type: Object,
    default: () => {},
  },
  complimentCharacters: {
    type: Array,
    default: [],
  },
  cbRewards: {
    type: Array,
    default: [],
  },
  isCbLoader: {
    type: Boolean,
    default: false,
  },
});

const { user, updateLoggedInUserData, updateTempSocial } = authStore();
const character = toRef(props, 'character');
const cbDailyLimit = toRef(props, 'cbDailyLimit');
const ffDailyLimit = toRef(props, 'ffDailyLimit');
const emits = defineEmits(['fetch', 'updated']);
const isMissionCompleted = ref(false);
const displayReward = ref(false);
const complimentCount = ref(null);
const cbRewards = toRef(props, 'cbRewards') as any;
const totalComplimentCount = ref(null);
const isBonusRewarded = ref(false);
const openCbListModal = () => {
  isOpenCBListModal.value = true;
};

const getComplimentBombSvg = computed(() => {
  return isComplimented.value
    ? '/checkmark-circle-outline.svg'
    : character.value.compliment_bombed_type === FANART_FLARE
    ? '/icons/fanartFlare-consumable.svg'
    : '/compBomb.svg ';
});

const getBadgeColor = computed(() => {
  return character.value.compliment_bombed_type === FANART_FLARE ? '#FF8368' : '#45b6f5';
});

const isComplimented = computed(() => {
  return (
    (character.value.compliment_bombed_type === COMPLIMENT_BOMB && character.value.complimented_char_by_me) ||
    (character.value.compliment_bombed_type === FANART_FLARE && character.value.complimented_fanart_by_me)
  );
});

const getChDailyLimit = computed(() => {
  return character.value.compliment_bombed_type === COMPLIMENT_BOMB ? cbDailyLimit.value : ffDailyLimit.value;
});
const actionClicked = () => {
  if (user.value.bomb_first_time_use) {
    isOpenFirstComplimentBombModal.value = true;
  } else if (
    isComplimented.value ||
    getChDailyLimit.value.done_in_time >= getChDailyLimit.value.default_limit ||
    getChDailyLimit.value.available_compliments === 0
  ) {
    openCbListModal();
  } else {
    isOpenCharacterComplimentBomb.value = true;
    emits('updated', get(character.value, 'compliment_bombed_type'));
  }
};
const currency = computed(() => {
  if (cbRewards.value) {
    return get(cbRewards.value[0], 'currency_type') === 'coin' ? ' /hubuck.svg' : '/gem.svg';
  }
});
const amount = computed(() => {
  if (cbRewards.value) {
    const ffindex =
      cbRewards.value.findIndex((item: any) => get(item, 'abbr') === 'fanart-flare-individual-item-completion') || 0;
    const ccindex =
      cbRewards.value.findIndex((item: any) => get(item, 'abbr') === 'compliment-bomb-individual-item-completion') || 0;
    return character.value.compliment_bombed_type === COMPLIMENT_BOMB
      ? get(cbRewards.value[ccindex], 'max_curr_amount')
      : get(cbRewards.value[ffindex], 'max_curr_amount');
  }
});

const updateCBFirstTimeUse = async () => {
  if (user.value.bomb_first_time_use) {
    updateLoggedInUserData({ bomb_first_time_use: false });
    await updateUserdata(user.value.id, { bomb_first_time_use: false });
  }
};

const closeCbListModal = () => {
  complimentCount.value = null;
  isOpenCBListModal.value = false;
  displayReward.value = false;
  totalComplimentCount.value = null;
  isBonusRewarded.value = false;
  isMissionCompleted.value = false;
  updateTempSocial({
    fanArtCount: 1,
    complimentCount: 1,
    tempAvailableCompliment: null,
    tempAvailableFanArt: null,
    totalFanArtCount: null,
    totalComplimentedCount: null,
  });
  setTimeout(() => {
    fetchCbLimit();
  }, 50);
};

const fetchCbLimit = (boolean = false) => {
  emits('fetch', boolean);
};

const closeAndOpenCharacterComplimentModal = async () => {
  updateCBFirstTimeUse();
  isOpenFirstComplimentBombModal.value = false;
  actionClicked();
  //  isOpenCharacterComplimentBomb.value = true;
};

const closeAndOpenCbMsModal = () => {
  isOpenCharacterComplimentBomb.value = false;
  isOpenCBListModal.value = true;
};

const closeAndOpenCBListModal = (data: any, isRewarded: any) => {
  complimentCount.value = data;
  displayReward.value = isRewarded;
  closeAndOpenCbMsModal();
};

const closeandOpenMissionSuccessfulModal = (count: any, isRewarded: any) => {
  isMissionCompleted.value = true;
  displayReward.value = true;
  totalComplimentCount.value = count;
  isBonusRewarded.value = isRewarded;
  closeAndOpenCbMsModal();
};
</script>

<style lang="sass" scoped>
.reward-badge
  border-top-left-radius: 10px
  padding: 0px 5px 0px 5px
  position: absolute
  bottom: 44px
  display: flex
  align-items: center
  right: 0px
  background: #431C89
.fanart-flare-btn
  --background: linear-gradient(180deg, #FF8F6A 0%, #FF585F 100%)
  --border-radius: 61px !important
  font-size: 12px
  width: 100%
  height: 23px
  margin: 0px

.compliment-btn
  --background: linear-gradient(90deg, rgba(6, 247, 233, 0.7) 0%, rgba(6, 131, 247, 0) 100%), #00AAE0
  --border-radius: 61px !important
  font-size: 12px
  width: 100%
  height: 23px
  margin: 0px
.char-name
  color: #431c89
  font-weight: bold
  font-size: 14px
.character-content
  background: rgba(255, 255, 255, 0.75)
  border-top-right-radius: 10px
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1)
  backdrop-filter: blur(1px)
  -webkit-backdrop-filter: blur(1px)
  border: 1px solid rgba(255, 255, 255, 0.06)
  position: absolute
  left: 37px
  top: 6px
  width: 72%
.marked-svg
  color: white
  width: 20px
  height: 20px
.username
  position: absolute
  bottom: 4px
  left: 8px
  font-weight: bold
  font-size: 15px
.rest-info
  border-radius: 12px
  width: 100%
  height: 100%
  padding: 6px
  background: linear-gradient(180deg, rgba(247,6,207,0) 0%, rgba(0,0,0,0.3) 74%, #170624CF 100%)
.disabled-card
  opacity: 0.5
  pointer-events: none
.done-by-me-char
  opacity: 0.7
.comp-bomb
  position: absolute
  width: 100%
.compliment-badge
  position: absolute
  border: 4px solid #7050b7
  background-color: v-bind(getBadgeColor)
  border-radius: 10px
  width: 39px
  top: -2px
  left: -2px
  height: 39px
  display: flex
  justify-content: center
  align-items: center
.char-cb-badge
  position: absolute
  width: 90%
  border-radius: 6px
  background: #3dc2ff
.target-text
  font-weight: bold
.target-icon
  display: flex
  align-items: center
  font-weight: bold

.compliment-card
  overflow: unset !important
  background: #7050B7 !important
  color: white
  border-radius: 17px
  border: 7px solid #7050B7 !important
  width: 135px
  min-width: 145px !important
  max-width: 145px !important
  min-height: 180px
  max-height: 180px
.compliment-img
  width: 100% !important
  height: 122px !important
  object-fit: cover
  border-radius: 16px
  @media(max-width:500px)
    height: 117px !important
</style>
