import { PaginatedResponse } from './../types/static-types';
import { chAxios } from '@/shared/lib/axios';
import { CharacterOwnershipEntry, Character, CharacterTransferRequest } from '@/shared/types/static-types';
import constants, { cacheKeys } from '@/shared/statics/constants';
import { sendAnalyticsEvent } from '@/shared/services/analytics';

export async function ocDecorationSticker(name: string, pos: any, limit: number = 25, rating: string) {
  const {
    public: { tenorApiKey },
  } = useRuntimeConfig();
  const url = `https://tenor.googleapis.com/v2/search?searchfilter=sticker&q=${name}&key=${tenorApiKey}&client_key=characterhub&limit=${limit}&pos=${pos}&contentfilter=low`;

  const { data } = await chAxios().get(url);
  return data;
}

export async function postCharacterStylizeTemplate(payload: {}) {
  const url = `/customizations/templates/`;

  const { data } = await chAxios().post(url, payload);
  return data;
}

export async function submitQuestion(payload: {}) {
  const url = `/characters/submit-answer/`;

  const { data } = await chAxios().post(url, payload);
  return data;
}

export async function getCharacterTraitExample(key: string) {
  const url = `/editor/examples/`;

  const { data } = await chAxios().post(url, { k: key, t: 'c' });
  return data;
}

export async function getReactedCharacters(entityIds: string[], entityType: string) {
  const { data } = await chAxios().post(`/characters/my-reacted-entities/`, {
    entity_ids: entityIds,
    entity_type: entityType,
  });
  return data;
}

export async function postCharacterTriviaQuestions(payload: {}) {
  const url = `/characters/section-question/`;

  const { data } = await chAxios().patch(url, payload);
  return data;
}

export async function getUserCharacter(params = {}) {
  const { data } = await chAxios().get(`/characters/logged-in-user-char/`, {
    params: { ...params },
  });
  return data;
}

export async function getTriviaQuestionsStats(params = {}) {
  const { data } = await chAxios().get(`/characters/answer-stats/`, {
    params: { ...params },
  });
  return data;
}

export async function addCharacterVirtualItem(payload: {}) {
  const url = `/characters/add-virtual-item/`;
  const { data } = await chAxios().post(url, payload);
  sendAnalyticsEvent('Added Profile Ring');
  return data;
}

export async function getCharacterTriviaQuestions(params = {}) {
  const { data } = await chAxios().get(`/characters/char-section-question/`, {
    params: { ...params },
  });
  return data;
}

export async function updateCharTemplate(id: string, isChange: Boolean, payload: {}) {
  const url = `/customizations/templates/${id}/?update_existing_chars=${isChange}`;

  const { data } = await chAxios().patch(url, payload);
  return data;
}
export async function applyCharacterStylizeTemplate(payload: {}) {
  const url = `/customizations/apply-template/`;

  const { data } = await chAxios().post(url, payload);
  return data;
}
export async function postCharacterStylizeEntities(payload: {}) {
  const url = `/customizations/`;

  const { data } = await chAxios().post(url, payload);
  return data;
}
export async function duplicateCharacterTemplate(payload: {}) {
  const url = `/customizations/templates/duplicate/`;

  const { data } = await chAxios().post(url, payload);
  return data;
}
export async function deleteCharacterTemplate(id: string) {
  const { data } = await chAxios().delete(`/customizations/templates/${id}/`);
  return data;
}

export async function getCharacterStylizeTemplate(page = 1, pageSize = constants.defaultPageSize, params = {}) {
  const { data } = await chAxios().get(`/customizations/templates/`, {
    params: { page, page_size: pageSize, ...params },
  });
  return data;
}

export async function getTemplateUsedByOtherChar(
  tempId: string | undefined | null,
  page = 1,
  pageSize = constants.defaultPageSize,
  params = {}
) {
  const { data } = await chAxios().get(`/customizations/templates/used-by/?template_id=${tempId}`, {
    params: { page, page_size: pageSize, ...params },
  });
  return data;
}

export async function getCharTemplate(id: string) {
  const url = `/customizations/templates/${id}/`;

  const { data } = await chAxios().get(url);
  return data;
}

export async function getCharacterStylizeEntities(id: string | undefined, type: string) {
  const url = `/customizations/applied-customization/?entity_id=${id}&entity_type=${type}`;

  const { data } = await chAxios().get(url);
  return data;
}

export async function getCharacterStylizeEntitiesWithSlug(slug: string) {
  const url = `/customizations/applied-customization-char/?slug=${slug}`;

  const { data } = await chAxios().get(url);
  return data;
}

export async function getCharacter(id: string) {
  const url = `/characters/${id}/`;

  const { data } = await chAxios().get(url);
  return data;
}

export async function getCharacterDraft(id?: string) {
  const url = `/characters/drafts/${id}/`;

  const { data } = await chAxios().get(url);
  return data;
}

export async function getCharacterFromSlug(slug: string): Promise<Character> {
  const url = `/characters/character-from-slug/`;

  const { data } = await chAxios().get(url, { params: { slug } });
  return data;
}

export async function getCharacterByIdFull(id: string): Promise<Character> {
  const url = `/characters/details/`;

  const { data } = await chAxios().get(url, { params: { id } });
  return data;
}

export async function getRouletteCharacters() {
  const url = `/characters/roulette-characters/`;

  const { data } = await chAxios().get(url);
  return data;
}

export async function getRandomCharactersForFirstLogin() {
  const url = `/characters/mockup-characters/`;

  const { data } = await chAxios().get(url);
  return data;
}

export async function fetchOnboardingModalCharacters(tags: string[] = []): Promise<Partial<Character>[]> {
  const url = `/characters/onboarding-r/`;

  const { data } = await chAxios().post(url, { tags });
  return data;
}

export async function getHomeCharacters(page = 1, pageSize = constants.defaultPageSize, params = {}) {
  const { data } = await chAxios().get(`/characters/?page=${page}`, {
    params: { page, page_size: pageSize, ...params },
  });
  return data;
}

export async function getCharacters(params = {}) {
  const url = `/characters/`;

  const { data } = await chAxios().get(url, {
    params: { ...params },
  });
  return data;
}

export async function getTopCharacters(page = 1, orderBy = '-hot_score', pageSize = constants.defaultPageSize) {
  const url = `/characters/top/`;

  const { data } = await chAxios().get(url, { params: { page, order_by: orderBy, page_size: pageSize } });
  return data;
}

export async function getCharactersReactions(page = 1, pageSize = constants.defaultPageSize) {
  const url = `/characters/reacted/`;

  const { data } = await chAxios().get(url, { params: { page, page_size: pageSize } });
  return data;
}

export async function getUserCharacters(id: string, page: number | null = 1, params = {}) {
  const url = `/characters/user/`;
  const { data } = await chAxios().get(url, { params: { id, page, ...params } });
  return data;
}

export async function getMyCreationsUserCharacters(id: string) {
  const url = `/characters/user/`;
  const cacheKey = cacheKeys.myCreationsCharacters;
  const value = await useChFetch<PaginatedResponse<Character[]>>(
    cacheKey,
    url,
    { id, incl_u_m: true, page: 1 },
    30000 * 60
  );
  return value;
}

export async function getCharactersByUsername(name: string, page: number | null = 1, inclu = false) {
  const url = `/characters/username/`;

  const { data } = await chAxios().get(url, { params: { user: name, page, incl_u: inclu } });
  return data;
}

export async function getOwnCharacters(page: number | null = 1, pageSize = constants.defaultPageSize, params = {}) {
  const url = `/characters/own/`;

  const { data } = await chAxios().get(url, { params: { page, page_size: pageSize, ...params } });
  return data;
}

export async function getFollowedCharacters(
  page: number | null = 1,
  pageSize = constants.defaultPageSize,
  params = {}
) {
  const url = `/characters/followed/`;

  const { data } = await chAxios().get(url, { params: { page, page_size: pageSize, ...params } });
  return data;
}

export async function getHomeSectionDetails(slug: string) {
  const url = `/sections/section-from-slug/`;

  const { data } = await chAxios().get(url, { params: { slug } });
  return data;
}

export async function getHomeSectionsCharacters() {
  const url = `/sections/home-sections-characters/`;

  const { data } = await chAxios().get(url);
  return data;
}

export async function getSectionCharacters(sectionId?: string, page = 1, pageSize = constants.defaultPageSize) {
  const url = `/sections/characters/`;

  const { data } = await chAxios().get(url, { params: { id: sectionId, page, page_size: pageSize } });
  return data;
}

export async function createCharacter(
  charData: any,
  allowRoleplayRequest?: boolean,
  allowComplimentBombed?: boolean,
  isnsfw?: boolean,
  character_collections?: string[],
  visual_collections?: string[],
  draftId?: string,
  tags?: string[],
  original_creator?: string,
  is_fanchar?: boolean,
  is_fanchar_spinoff?: boolean
) {
  const url = `/characters/`;

  const { data } = await chAxios().post(url, {
    info: charData,
    allow_roleplay_req: allowRoleplayRequest,
    allow_comp_bomb: allowComplimentBombed,
    is_nsfw: isnsfw,
    character_collections,
    visual_collections,
    draft_id: draftId,
    tags,
    original_creator,
    is_fanchar,
    is_fanchar_spinoff,
  });
  return data;
}

export async function createSaveDraft(
  charData: any,
  isnsfw?: boolean,
  visual_collections?: string[] | null,
  character_collections?: string[],
  tags?: string[],
  type?: string,
  params = {}
) {
  const url = `/characters/drafts/`;

  const { data } = await chAxios().post(url, {
    info: charData,
    is_nsfw: isnsfw,
    visual_collections,
    character_collections,
    tags,
    type,
    ...params,
  });
  return data;
}

export async function editCharacter(
  id: string,
  charData?: any,
  allowRoleplayRequest?: boolean,
  allowComplimentBombed?: boolean,
  nsfw?: boolean,
  character_collections?: string[],
  visual_collections?: string[],
  tags?: string[],
  original_creator?: string,
  is_fanchar?: boolean,
  is_fanchar_spinoff?: boolean
) {
  const url = `/characters/${id}/`;

  const { data } = await chAxios().patch(url, {
    info: charData,
    allow_roleplay_req: allowRoleplayRequest,
    allow_comp_bomb: allowComplimentBombed,
    is_nsfw: nsfw,
    character_collections,
    visual_collections,
    tags,
    original_creator,
    is_fanchar,
    is_fanchar_spinoff,
  });
  return data;
}

export async function editCharacterSaveDraft(
  id?: string,
  nsfw?: boolean,
  charData?: any,
  visual_collections?: string[] | null,
  character_collections?: string[],
  tags?: string[],
  type?: string,
  params = {}
) {
  const url = `/characters/drafts/${id}/`;

  const { data } = await chAxios().patch(url, {
    info: charData,
    is_nsfw: nsfw,
    visual_collections,
    character_collections,
    tags,
    type,
    ...params,
  });
  return data;
}

export async function editCharacterAuto(
  id: string,
  charData?: any,
  allowRoleplayRequest?: boolean,
  allowComplimentBombed?: boolean,
  nsfw?: boolean,
  character_collections?: string[],
  visual_collections?: string[],
  tags?: string[],
  original_creator?: string,
  params = {}
) {
  const url = `/characters/${id}/`;

  const { data } = await chAxios().patch(url, {
    info: charData,
    allow_roleplay_req: allowRoleplayRequest,
    allow_comp_bomb: allowComplimentBombed,
    is_nsfw: nsfw,
    character_collections,
    visual_collections,
    tags,
    original_creator,
    ...params,
    a: true,
  });
  return data;
}

export async function editCharacterSaveDraftAuto(
  id: string,
  nsfw?: boolean,
  charData?: any,
  visual_collections?: string[] | null,
  character_collections?: string[],
  tags?: string[],
  type?: string,
  params = {}
) {
  const url = `/characters/drafts/${id}/`;

  const { data } = await chAxios().patch(url, {
    info: charData,
    is_nsfw: nsfw,
    visual_collections,
    character_collections,
    tags,
    type,
    ...params,
    a: true,
  });
  return data;
}

export async function deleteCharacter(id: string) {
  const url = `/characters/${id}/`;
  await chAxios().delete(url);
}

export async function deleteCharacterDraft(id: string) {
  const url = `/characters/drafts/${id}/`;
  await chAxios().delete(url);
}

export async function getHomeFeaturedCharacters() {
  const url = `/featured/characters/`;

  const cacheKey = cacheKeys.homeFeaturedCharacters;
  const value = await useChFetch<any>(
    cacheKey,
    url,
    { page: 1, order_by: '?', page_size: constants.defaultPageSize },
    30000 * 60
  );
  return value;
}

export async function getFeaturedCharacters(page = 1, pageSize = constants.defaultPageSize, orderBy?: string) {
  const url = `/featured/characters/`;

  const { data } = await chAxios().get(url, { params: { page, order_by: orderBy, page_size: pageSize } });
  return data;
}

export async function getCharactersDraft(type: string, page = 1, pageSize = constants.defaultPageSize, params = {}) {
  const url = `/characters/drafts/`;

  const { data } = await chAxios().get(url, { params: { type, page, page_size: pageSize, ...params } });
  return data;
}

export async function getHomeFeaturedVisuals() {
  const url = `/featured/visuals/`;

  const cacheKey = cacheKeys.homeFeaturedVisuals;
  const value = await useChFetch<any>(cacheKey, url, { order_by: '?', page: 1 }, 30000 * 60);

  return value;
}

export async function getFeaturedVisuals(
  orderBy?: string,
  page?: number,
  pageSize = constants.defaultPageSize,
  params = {}
) {
  const url = `/featured/visuals/`;

  const { data } = await chAxios().get(url, {
    params: { order_by: orderBy, page, page_size: pageSize, ...params },
  });
  return data;
}

export async function addInfoFields(id: string, info: Object) {
  const url = `/characters/${id}/info/`;
  const { data } = await chAxios().post(url, { info });
  return data as Character;
}

export async function getCharacterOwnershipHistory(charId: string) {
  const url = `/characters/${charId}/ownership/`;
  const { data } = await chAxios().get(url);
  return data as CharacterOwnershipEntry[];
}

export async function getPendingCharacterTransfer(charId: string) {
  const url = `/characters/transfers/pending/?character=${charId}`;
  const { data } = await chAxios().get(url);
  return data as CharacterTransferRequest;
}

export async function initiateCharacterTransfer(character: string, userTo: string, note: string) {
  const url = `/characters/transfers/`;
  const { data } = await chAxios().post(url, { character, user_to: userTo, note });
  return data as CharacterTransferRequest;
}

export async function decorateCharacters(character: string, baseImage: string, decoration?: any) {
  const url = `/characters/decorate/`;
  const { data } = await chAxios().post(url, { character, base_image: baseImage, decoration });
  sendAnalyticsEvent('Decoration Sent');
  return data;
}

export async function cancelPendingCharacterTransfer(charId: string) {
  const url = `/characters/transfers/${charId}/`;
  await chAxios().delete(url);
}

export async function acceptPendingCharacterTransfer(id: string) {
  const url = `/characters/transfers/${id}/accept/`;
  await chAxios().post(url);
}

export async function rejectPendingCharacterTransfer(id: string) {
  const url = `/characters/transfers/${id}/reject/`;
  await chAxios().post(url);
}

export async function getProfileCharacters(username: string, page = 1, pageSize = 16) {
  const url = `/characters/profile/`;
  const { data } = await chAxios().get<PaginatedResponse<Character[]>>(url, {
    params: {
      page,
      user: username,
      page_size: pageSize,
      snp: 1,
    },
  });

  return data;
}
