<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ion-header translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-between align-items-center px-3 py-2">
          <div @click="closeModal" class="clickable-item">
            <i class="ti-arrow-left" />
          </div>
          <div>
            <div class="title">Select User</div>
          </div>
          <div>
            <ion-buttons slot="end">
              <ion-button @click="closeModal">
                <i class="ti-close" />
              </ion-button>
            </ion-buttons>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>

    <div class="modal-inner-content">
      <div class="px-3 py-2">
        <compliment-bomb-vertical-card
          :svg-icon="selectedGift.svg"
          :count="selectedGift.amount"
          :title="selectedGift.title"
          :showBadge="true"
        />

        <div class="sub-title text-black py-2">Select User to {{ subtitle }}</div>
        <div>
          <div>
            <SearchInput
              v-model="text"
              :blur-on-esc="true"
              :select-on-focus="false"
              :shortcut-listener-enabled="true"
              :clear-icon="false"
              :hide-shortcut-icon-on-blur="false"
              :clear-on-esc="true"
              placeholder="Search Users here"
              @input="onSearch"
            />
          </div>

          <compliment-bomb-users-tabs class="mt-3" :tabs="tabs" :active-index="tabIndex" @tab-changed="tabChanged" />

          <div v-if="isLoading" class="d-flex justify-content-center mt-2">
            <ChLoading size="lg" class="spinner" />
          </div>
          <Grid v-else :scrollSm="false" :sm="2">
            <compliment-bomb-users-card
              v-for="(user, index) in allUsers"
              :key="index"
              :user="user"
              class="grid-item"
              :selected-user="selectedUserId === user.id || selectedUserId === get(user, 'following_id')"
              @click="isUserSelected(user)"
            />
            <ChLoading size="sm" v-if="isUserLoading" class="spinner" />
            <p v-else-if="nextPageExists && !isUserLoading" class="clickable" @click="requestLoadMore">Load More</p>
          </Grid>

          <div
            class="d-flex text-center justify-content-center"
            v-if="!isEmpty(text) && !isLoading && isEmpty(allUsers)"
          >
            No search results found
          </div>
        </div>
      </div>
    </div>
    <ion-footer translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-center text-center align-items-center">
          <ion-button @click="sendGift" class="send-btn" :disabled="isEmpty(selectedUserId) || loader">
            <inline-svg class="mr-1" src="/send.svg" />
            Send</ion-button
          >
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-modal>
</template>

<script lang="ts" setup>
import ComplimentBombVerticalCard from '@/shared/components/ComplimentBombVerticalCard.vue';
import SearchInput from 'vue-search-input';
import ComplimentBombUsersTabs from '@/shared/pages/compliment-bombs/components/ComplimentBombCharactersTabs.vue';
import { complimentBombUserTabs } from '@/shared/statics/tabs';
import Grid from '@/shared/components/storage/Grid.vue';
import { Paging, Tab, User } from '@/shared/types/static-types';
import ComplimentBombUsersCard from '@/shared/pages/compliment-bombs/components/ComplimentBombUsersCard.vue';
import { getUsers } from '@/shared/actions/users';
import { sendGiftToUser } from '@/shared/actions/complimentBomb';
import { authStore } from '@/shared/pinia-store/auth';
import { getFollowers, getFollowing, getNextPage } from '@/shared/actions/follow';
import constants, { FANART_FLARE } from '@/shared/statics/constants';
import { COMPLIMENT_BOMB } from '@/shared/statics/constants';

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
  selectedGift: {
    type: Object,
    default: {},
  },
});

const emits = defineEmits(['close', 'updated', 'updatedGift', 'dismiss']);
const selectedUserId: any = ref('');
const text = ref('');
const isOpen = toRef(props, 'isOpen');
const sectionTitle = ref('Gift Compliment Bomb');
const tabs = ref(complimentBombUserTabs);
const tabIndex = ref(0);
const allUsers = ref([]);
const currentTab = ref('all');
const selectedGift = toRef(props, 'selectedGift');
const { user } = authStore();
const loader = ref(false);
const isLoading = ref(true);
const userPaging: any = ref<Paging>();
const isUserLoading = ref(false);
const inventories = constants.inventories;
const fetchAllUsers = async (page = 1) => {
  isLoading.value = true;
  const { results, ...paging } = isEmpty(text.value)
    ? await getUsers(page, 50, { exclude_self: 1 })
    : await getUsers(page, 50, { search: text.value, exclude_self: 1 });
  allUsers.value = results;
  userPaging.value = paging;
  isLoading.value = false;
};

const subtitle = computed(() => {
  return selectedGift.value.type === 'fanart_flare' ? 'gift Fanart Flare' : 'gift Compliment Bomb';
});
const getUserFollowingAndFollowers = async (page = 1) => {
  isLoading.value = true;
  const { results, ...paging } =
    currentTab.value === 'myfollowing'
      ? isEmpty(text.value)
        ? await getFollowing(page, 30, { type: 'user' })
        : await getFollowing(page, 30, { search: text.value, type: 'user' })
      : isEmpty(text.value)
      ? await getFollowers(user.value.id, page, 30, { type: 'user' })
      : await getFollowers(user.value.id, page, 30, { search: text.value, type: 'user' });
  allUsers.value = results;
  userPaging.value = paging;
  isLoading.value = false;
};

watch(isOpen, () => {
  if (isOpen.value) {
    reset();
    fetchAllUsers();
  }
});

watch(currentTab, async () => {
  if (currentTab.value) {
    allUsers.value = [];
    text.value = '';
    userPaging.value = null;
    currentTab.value === 'all' ? fetchAllUsers() : getUserFollowingAndFollowers();
  }
});

const nextPageExists = computed(() => {
  return !!get(userPaging.value, 'next');
});

const isUserSelected = (user: any) => {
  const userId = selectedUserId.value;
  const followingId = get(user, 'following_id');

  selectedUserId.value = userId === user.id || userId === followingId ? '' : user.id || followingId;
};
const userCharactersNextPage = async () => {
  isUserLoading.value = true;
  const { results, ...paging } = await getNextPage(userPaging.value!);
  allUsers.value = allUsers.value.concat(results);
  userPaging.value = paging;
  isUserLoading.value = false;
};
const requestLoadMore = async (ev: CustomEvent) => {
  if (!userPaging.value.next) {
    (ev?.target as any).complete();
  } else {
    await userCharactersNextPage();
  }
};

const reset = () => {
  text.value = '';
  selectedUserId.value = '';
  allUsers.value = [];
  userPaging.value = null;
};
const dismissModal = (val: any) => {
  emits('dismiss', val);
  reset();
};

const closeModal = () => {
  emits('close');
  reset();
};
const onSearch = () => {
  if (!isLoading.value) searchTextChanged();
};

const tabChanged = ({ value }: Tab) => {
  currentTab.value = value;
};

const sendGift = async () => {
  if (isEmpty(selectedUserId.value)) return;
  loader.value = true;
  const payload = {
    to_user: selectedUserId.value,
    type: selectedGift.value.value,
    quantity: 1,
    object: selectedGift.value.type === 'fanart_flare' ? FANART_FLARE : COMPLIMENT_BOMB,
  };
  const resp = await sendGiftToUser(payload);
  const data = {
    type: selectedGift.value.value,
    object: selectedGift.value.type,
  };

  emits('updated', data);
  // closeModal();
  loader.value = false;
};

const searchTextChanged = debounce(async () => {
  currentTab.value === 'all' ? fetchAllUsers() : getUserFollowingAndFollowers();
}, 500);
</script>
<style lang="sass" scoped>
.send-btn::part(native)
  color: #fff !important
.grid-item
  @media(max-width:500px)
    width: calc(51% - 8px) !important
    min-width: calc(51% - 8px) !important
.dark .toolbar
  --background: #17074c !important

.modal-inner-content
  background: var(--ion-background-color, #fff)
  max-height: 70vh
  overflow-x: hidden
  overflow-y: auto

.modal-big
  --width: 450px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
.close-button
  width: 50px
.title
  color: #214163
  font-size: 20px
  font-weight: bold
  text-align: center
  align-items: center
.sub-title
  font-size: 18px
  font-weight: bold
</style>
