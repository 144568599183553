<template>
  <ion-modal :is-open="isOpen" mode="md" :backdrop-dismiss="false" :show-backdrop="true" class="custom-modal">
    <div v-if="isOpen" class="modal-container">
      <div class="modal">
        <div class="modal-header">
          <h3>Delete Account</h3>
          <button class="close-btn" @click="closeModal">×</button>
        </div>
        <div class="modal-body">
          <p class="text-red-500 font-semibold">
            Are you sure you want to delete your account? All your data, including settings, preferences, and content,
            will be permanently deleted and cannot be recovered.
          </p>
          <div class="password-section">
            <label for="password">Enter your password</label>
            <input id="password" type="password" v-model="password" placeholder="Enter your password" />
          </div>
          <div class="feedback-section">
            <label for="feedback">Would you mind telling us why you're leaving? (Optional)</label>
            <textarea
              id="feedback"
              v-model="feedback"
              placeholder="Your feedback helps us improve..."
              rows="4"
            ></textarea>
          </div>
        </div>
        <div class="modal-footer flex justify-between">
          <button class="cancel-btn" @click="closeModal">Cancel</button>
          <button class="delete-btn" @click="deleteAccount">Delete Account</button>
        </div>
      </div>
    </div>
  </ion-modal>
</template>

<script setup lang="ts">
import { ref, defineEmits } from 'vue';

const emit = defineEmits(['close', 'confirm']);

const isOpen = ref(true);
const feedback = ref('');
const password = ref('');

const closeModal = () => {
  isOpen.value = false;
  emit('close');
};

const deleteAccount = () => {
  emit('confirm', { feedback: feedback.value, password: password.value });
  closeModal();
};
</script>

<style lang="sass" scoped>
.custom-modal
  --background: rgba(0, 0, 0, 0.5) // Backdrop
  --width: 400px
  --height: auto
  --border-radius: 16px
  --box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2)
.modal-container
  display: flex
  align-items: center
  justify-content: center
  height: 100%
.modal
  background: white
  border-radius: 16px
  padding: 24px
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2)
.modal-header
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 16px
.modal-header h3
  margin: 0
  font-size: 1.25rem
  font-weight: bold
.close-btn
  background: none
  border: none
  font-size: 1.5rem
  cursor: pointer
  color: #333
.modal-body
  margin-bottom: 16px
  font-size: 0.95rem
  color: #555
.feedback-section
  margin-top: 12px
.feedback-section label
  display: block
  font-weight: bold
  margin-bottom: 8px
  font-size: 0.825rem
  color: #444
textarea
  width: 100%
  border: 1px solid #ccc
  border-radius: 6px
  padding: 8px
  font-size: 14px
  resize: vertical
.password-section
  margin-top: 16px
.password-section label
  display: block
  font-weight: bold
  margin-bottom: 8px
  font-size: 0.825rem
  color: #444
input[type="password"]
  width: 100%
  border: 1px solid #ccc
  border-radius: 6px
  padding: 8px
  font-size: 14px
  margin-top: 8px
.cancel-btn
  background: #f0f0f0
  border: none
  padding: 8px 16px
  border-radius: 6px
  font-size: 0.9rem
  cursor: pointer
  color: #555
.delete-btn
  background: #e74c3c
  border: none
  color: white
  padding: 8px 16px
  border-radius: 6px
  font-size: 0.9rem
  cursor: pointer
.delete-btn:hover
  background: #d62c1c
</style>
