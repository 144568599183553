import { chAxios } from '@/shared/lib/axios';
import type { Paging } from '@/shared/types/static-types';

export async function getNextPage(paging: Paging) {
  const url = paging.next;

  if (!url) return { results: [] };

  const { data } = await chAxios().get(url);
  return data;
}

export async function getNext(url: string) {
  const { data } = await chAxios().get(url);
  return data;
}
